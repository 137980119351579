import { Suspense, useMemo, type ReactNode } from 'react'

import { ChannelProvider } from 'ably/react'

import { SwitchTeamMemberModal } from 'src/pages/SwitchTeamMemberModal/SwitchTeamMemberModal'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { PermissionDialog } from 'src/shared/components/dialogs/PermissionDialog/PermissionDialog'
import { usePermissionDialog } from 'src/shared/components/dialogs/PermissionDialog/usePermissionDialog'
import { useIsOnlineAtomUpdaterEffect } from 'src/shared/lib/api/hooks/useIsOnline'
import { useAblyChannelName } from 'src/shared/lib/api/sse/useSseEventListenerEffect'
import { GlobalModalContext } from 'src/shared/lib/context/global/useGlobalModalContext'
import CacheManager from '../../app/apps/book/CacheManager'

interface RestaurantLayoutProps {
  restaurantId: number
  children: ReactNode
}

export const RestaurantLayout = ({ children }: RestaurantLayoutProps) => {
  useIsOnlineAtomUpdaterEffect()

  const teamMemberDialog = useDialog()
  const { permissionDialogProps, showPermissionDialog } = usePermissionDialog()

  return (
    <ChannelProvider channelName={useAblyChannelName()}>
      <GlobalModalContext
        value={useMemo(
          () => ({
            showTeamMembersModal: teamMemberDialog.handleOpen,
            closeTeamMembersModal: teamMemberDialog.handleClose,
            showPermissionModal: showPermissionDialog,
          }),
          [
            showPermissionDialog,
            teamMemberDialog.handleClose,
            teamMemberDialog.handleOpen,
          ],
        )}
      >
        {children}
        <PermissionDialog {...permissionDialogProps} />
        <Suspense>
          <SwitchTeamMemberModal open={teamMemberDialog.open} />
        </Suspense>
        <Suspense>
          <CacheManager />
        </Suspense>
      </GlobalModalContext>
    </ChannelProvider>
  )
}
