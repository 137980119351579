import { Suspense, useCallback, useState } from 'react'
import { Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type ExtendedFeedbackInterface } from 'src/entities/feedback/types/feedbackApi'
import CircularProgress from 'src/shared/components/common/CircularProgress'
import CenteredBox from 'src/shared/components/containers/CenteredBox'
import { FullScreenModalHeader } from 'src/shared/components/dialogs/FullScreenModal/components/FullScreenModalHeader'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { FeedbackModal } from './FeedbackModal/FeedbackModal'
import { FeedbacksTable } from './FeedbacksTable/FeedbacksTable'

interface FeedbacksPageProps {
  restaurantId: number
  onClose: () => void
}

export const FeedbacksPage = ({
  restaurantId,
  onClose,
}: FeedbacksPageProps) => {
  const { t } = useTranslation()

  const dialogProps = useDialog()
  const { handleOpen } = dialogProps
  const [selectedFeedback, setSelectedFeedback] =
    useState<ExtendedFeedbackInterface>()

  const onFeedbackClick = useCallback(
    (f: ExtendedFeedbackInterface) => {
      setSelectedFeedback(f)
      handleOpen()
    },
    [handleOpen],
  )

  return (
    <Stack width="100%" px={[1, 3]}>
      <FullScreenModalHeader
        title={t('feedbacks.title', 'Feedbacks')}
        onClose={onClose}
      />
      <Suspense
        fallback={
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        }
      >
        <FeedbacksTable onFeedbackClick={onFeedbackClick} />
      </Suspense>
      <FeedbackModal feedback={selectedFeedback} dialogProps={dialogProps} />
    </Stack>
  )
}
