import {
  Rating as MuiRating,
  Stack,
  Typography,
  type StackProps,
} from '@mui/material'

interface RatingProps extends StackProps {
  rating: number
}

const Rating = ({ rating, ...props }: RatingProps) => (
  <Stack direction="row" spacing={1} alignItems="center" {...props}>
    <Typography variant="labelHuge" color="text.secondary">
      {rating.toFixed(1)}
    </Typography>
    <MuiRating value={rating} readOnly precision={0.5} size="medium" />
  </Stack>
)

export default Rating
