import { useController, useFormContext } from 'react-hook-form'

import {
  type TeamMember,
  type TeamMemberUpdateInterface,
} from 'src/entities/team-member/types/teamMember'
import ColorPicker from 'src/shared/components/form/inputs/ColorPicker'
import { Colors } from 'src/shared/lib/style/types/color'

const Color = ({ teamMembers }: { teamMembers: TeamMember[] }) => {
  const { control } = useFormContext<TeamMemberUpdateInterface>()

  const { field } = useController({ name: 'color', control })

  const colors = [
    ...Object.values(Colors),
    ...teamMembers.map(member => member.color),
  ]

  return (
    <ColorPicker
      value={field.value}
      onChange={field.onChange}
      colors={colors}
    />
  )
}

export default Color
