import { useCallback, type ReactNode } from 'react'
import { Stack, useMediaQuery, type Theme } from '@mui/material'

import { Navigate, useNavigate } from '@tanstack/react-router'

import { useHasPermission } from 'src/entities/team-member/hooks/useCheckPermissions'
import useCurrentTeamMember from 'src/entities/team-member/hooks/useCurrentTeamMember'
import useAvailableTeamMembers from 'src/pages/SwitchTeamMemberModal/hooks/useAvailableTeamMembers'
import { SettingsSidebar } from './SettingsSidebar'
import { useSettingsHelpers } from '../hooks/useSettingsHelpers'

interface SettingsLayoutProps {
  restaurantId: number
  children: ReactNode
}

const SettingsLayout = ({ restaurantId, children }: SettingsLayoutProps) => {
  const navigate = useNavigate()

  const hasTeamMembers = useAvailableTeamMembers().length
  const teamMember = useCurrentTeamMember()
  const canUseConfiguration = useHasPermission('configuration')
  const { shouldRedirect } = useSettingsHelpers()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'))

  const onBack = useCallback(
    () =>
      navigate({
        to: '/restaurants/$restaurantId/config',
        params: { restaurantId },
      }),
    [navigate, restaurantId],
  )

  if (!canUseConfiguration) return null

  if ((!hasTeamMembers || teamMember) && shouldRedirect()) {
    return (
      <Navigate
        to="/restaurants/$restaurantId/reservations"
        params={{ restaurantId }}
      />
    )
  }

  return (
    <Stack direction={['column', 'row']} width={1}>
      {!isMobile && (
        <SettingsSidebar restaurantId={restaurantId} onBack={onBack} />
      )}

      <Stack sx={{ overflow: 'hidden', flexGrow: 1 }}>{children}</Stack>
    </Stack>
  )
}

export default SettingsLayout
