import { useState } from 'react'
import { isEmpty } from 'ramda'
import { Divider, RadioGroup, Stack } from '@mui/material'

import { diff } from 'deep-object-diff'
import { useTranslation } from 'react-i18next'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import {
  useSettingsObjectQuery,
  useSettingsUpdateMutation,
} from 'src/entities/setting/queries/settings'
import { DEFAULT_VIEWS } from 'src/entities/setting/services/settings'
import { SettingsScreensEnum } from 'src/entities/setting/types/settings'
import { ConfigScroll } from 'src/pages/Settings/layout/ConfigScroll'
import { useDefaultMutationHandlers } from 'src/pages/ShiftConfiguration/hooks/useDefaultMutationHandlers'
import { RadioOption } from 'src/shared/components/form/inputs/RadioChoice'
import Toggle from 'src/shared/components/form/inputs/Toggle'
import { CommsTable } from './components/CommsTable'
import { SettingsSectionTitle } from '../components/SettingsSectionTitle'
import { SettingTitle } from '../components/SettingTitle'
import { UnsavedChangesDialog } from '../components/UnsavedChangesDialog'
import { SettingsHeader } from '../layout/SettingsHeader'

interface Props {
  restaurantId: number
}

const General = ({ restaurantId }: Props) => {
  const { t } = useTranslation()
  const { data: originalSettings } = useSettingsObjectQuery()
  const [settings, setSettings] = useState(originalSettings)
  const { mutateAsync: updateSettings } = useSettingsUpdateMutation()

  const handlers = useDefaultMutationHandlers({
    successMessage: t('settings.common.settings_changed', 'Settings changed'),
  })

  const hasNoChanges = isEmpty(diff(originalSettings, settings))

  return (
    <>
      <SettingsHeader
        restaurantId={restaurantId}
        screen={SettingsScreensEnum.General}
        primaryButtonProps={{
          ...createTestIdProps('save-changes'),
          onClick: () => updateSettings(settings, handlers),
          children: t('settings.common.save_changes', 'Save changes'),
          disabled: hasNoChanges,
        }}
      />
      <ConfigScroll gap={6} pb={3}>
        <Stack gap={3}>
          <SettingsSectionTitle
            title={t(
              'settings.general.comms.section.title',
              'Communication settings',
            )}
            description={t(
              'settings.general.comms.section.subtitle',
              'Set default communication messages that will be sent to guest',
            )}
          />
          <CommsTable settings={settings} setSettings={setSettings} />
        </Stack>
        <Stack gap={3}>
          <SettingsSectionTitle
            title={t(
              'settings.general.team_members_panel.section.title',
              'Team members panel',
            )}
          />
          <Stack direction="row" gap={0.75}>
            <SettingTitle
              title={t(
                `settings.general.team_members_panel.show_when_inactive.title`,
                'Show team members panel when inactive',
              )}
              description={t(
                `settings.general.team_members_panel.show_when_inactive.description`,
                'Require selecting team members when inactive for 2 minutes.',
              )}
              flex={1}
            />
            <Toggle
              checked={settings.autoLogout}
              onChange={() =>
                setSettings(s => ({ ...s, autoLogout: !s.autoLogout }))
              }
            />
          </Stack>
        </Stack>
        <Divider />
        <Stack gap={3}>
          <SettingsSectionTitle
            title={t(
              'settings.general.view_options.section.title',
              'View options',
            )}
          />
          <Stack direction="row" gap={0.75}>
            <SettingTitle
              title={t(
                `settings.general.view_options.default_view.title`,
                'Default booking view',
              )}
              description={t(
                `settings.general.view_options.default_view.description`,
                'Select view that will be displayed when user logs in.',
              )}
              flex={1}
            />
            <RadioGroup
              sx={{ gap: 1 }}
              value={DEFAULT_VIEWS.find(v => settings[v])}
              onChange={(_event, value) =>
                setSettings(s => {
                  const newSettings = { ...s }
                  DEFAULT_VIEWS.forEach(v => {
                    newSettings[v] = false
                  })
                  newSettings[value as (typeof DEFAULT_VIEWS)[number]] = true
                  return newSettings
                })
              }
            >
              {DEFAULT_VIEWS.map(view => (
                <RadioOption
                  key={view}
                  label={t(`settings.general.${view}`)}
                  value={view}
                />
              ))}
            </RadioGroup>
          </Stack>
          <Divider />
          <Stack direction="row" gap={0.75}>
            <SettingTitle
              title={t(
                `settings.general.view_options.display_availability.title`,
                'Display availability',
              )}
              description={t(
                `settings.general.view_options.display_availability.description`,
                'Display occupancy information when selecting time in reservation panel.',
              )}
              flex={1}
            />
            <Toggle
              checked={settings.slotPicker}
              onChange={() =>
                setSettings(s => ({ ...s, slotPicker: !s.slotPicker }))
              }
            />
          </Stack>
        </Stack>
      </ConfigScroll>
      <UnsavedChangesDialog hasChanges={!hasNoChanges} />
    </>
  )
}

export default General
