import React, { useMemo } from 'react'

import { DndContext, type DragEndEvent } from '@dnd-kit/core'
import { arrayMove, SortableContext } from '@dnd-kit/sortable'
import debounce from 'debounce'
import { useTranslation } from 'react-i18next'

import { useTablesOrderUpdate } from 'src/entities/table/queries/table'
import { order } from 'src/entities/table/types/tableApi'
import { useDefaultMutationHandlers } from 'src/pages/ShiftConfiguration/hooks/useDefaultMutationHandlers'
import { useBasicDndSensors } from 'src/shared/lib/common/hooks/useDnd'
import {
  type FloorPlanTableInterface,
  type FurnitureInterface,
} from 'src/widgets/FloorPlan/types/floorPlanElement'
import TablesGrid from 'src/widgets/TabularTables/TablesGrid'
import Item from './Item'

const TabularView = ({
  tables,
  onSelect,
  selectedTable,
  onOrderChangeSave,
  orderChangeDisabled,
}: {
  tables: FloorPlanTableInterface[]
  onSelect: (table?: FloorPlanTableInterface) => void
  selectedTable: FloorPlanTableInterface | undefined
  orderChangeDisabled: boolean
  onOrderChangeSave: (
    newTables: FloorPlanTableInterface[],
    newFurniture: FurnitureInterface[],
  ) => void
}): React.ReactNode => {
  const { t } = useTranslation()
  const [sortedTables, setSortedTables] = React.useState(tables)

  const hasNewTable = React.useMemo(
    () => sortedTables.some(tbl => !tbl.id),
    [sortedTables],
  )

  const { mutate: setTablesOrder, isPending } =
    useTablesOrderUpdate(onOrderChangeSave)

  const deboucedsetTablesOrder = useMemo(
    () => debounce(setTablesOrder, 100),
    [setTablesOrder],
  )

  React.useEffect(() => {
    if (isPending) return
    setSortedTables(tables)
  }, [isPending, tables])

  const handlers = useDefaultMutationHandlers({
    successMessage: t('settings.common.order_changed', 'Order changed'),
  })

  const handleDragEnd = React.useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event

      if (!over || active.id === over.id) return

      setSortedTables(oldTables => {
        const oldIndex = oldTables.findIndex(
          table => table.id === active.id || table.uuid === active.id,
        )
        const newIndex = oldTables.findIndex(
          table => table.id === over.id || table.uuid === over.id,
        )

        const newTables = arrayMove(oldTables, oldIndex, newIndex)

        setTimeout(() => deboucedsetTablesOrder(order(newTables), handlers), 0)

        return newTables
      })
    },
    [handlers, deboucedsetTablesOrder],
  )

  const sensors = useBasicDndSensors()

  const sortedIds = React.useMemo(
    () => sortedTables.map(tbl => tbl.id ?? tbl.uuid!),
    [sortedTables],
  )

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
      <SortableContext
        items={sortedIds}
        disabled={hasNewTable || orderChangeDisabled || isPending}
      >
        <TablesGrid>
          {sortedTables.map(table => (
            <Item
              key={table.uuid ?? table.id}
              table={table}
              onSelect={() => onSelect(table)}
              isSelected={selectedTable === table}
              dragDisabled={hasNewTable || orderChangeDisabled || isPending}
            />
          ))}
        </TablesGrid>
      </SortableContext>
    </DndContext>
  )
}

export default TabularView
