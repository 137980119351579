import { type ReactNode } from 'react'
import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type IntegrationName } from 'src/entities/integration/api/integrationsApi'
import { IntegrationIcons } from '../constants/IntegrationIconMap'

interface IntegrationModalBodyWrapperProps {
  integrationName: IntegrationName
  children: ReactNode
}

export const IntegrationModalBodyWrapper = ({
  integrationName,
  children,
}: IntegrationModalBodyWrapperProps) => {
  const { t } = useTranslation()

  const Icon = IntegrationIcons[integrationName]

  return (
    <Stack gap={2.5}>
      <Stack gap={1} direction="row" alignItems="center">
        <Icon />
        <Typography>
          {t(`settings.integrations.${integrationName}.title`, integrationName)}
        </Typography>
      </Stack>
      {children}
    </Stack>
  )
}
