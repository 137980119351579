import { useCallback, useMemo, useState } from 'react'
import { compose } from 'ramda'

import { useTranslation } from 'react-i18next'

import {
  useAreasQuery,
  useUpsertAreaMutation,
} from 'src/entities/area/queries/area'
import { type ApiAreaInterface } from 'src/entities/area/types/area'
import { emptyArea, type EmptyArea } from 'src/entities/area/types/areaApi'
import { SettingsScreensEnum } from 'src/entities/setting/types/settings'
import { useDefaultMutationHandlers } from 'src/pages/ShiftConfiguration/hooks/useDefaultMutationHandlers'
import EmptyView from 'src/shared/components/common/EmptyView'
import Modal from 'src/shared/components/common/Modal'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import EditForm from './EditForm'
import { AreaTable } from './Table/AreaTable'
import { ConfigScroll } from '../layout/ConfigScroll'
import { SettingsHeader } from '../layout/SettingsHeader'

interface AreasProps {
  restaurantId: number
}

const Areas = ({ restaurantId }: AreasProps) => {
  const { t } = useTranslation()

  const { data: areas } = useAreasQuery()

  const [editedArea, setEditedArea] = useState<
    ApiAreaInterface | EmptyArea | null
  >(null)

  const { handleClose, handleOpen, open } = useDialog()

  const { mutateAsync: upsertArea } = useUpsertAreaMutation()

  const addHandler = useDefaultMutationHandlers({
    successMessage: t('settings.areas.common.area_added', 'Area added'),
  })
  const editHandler = useDefaultMutationHandlers({
    successMessage: t('settings.areas.common.area_changed', 'Area changed'),
  })

  const isNew = !editedArea?.id

  const handleEdit = useMemo(
    () => compose(handleOpen, setEditedArea),
    [handleOpen],
  )

  const handleSubmit = useCallback(
    async (a: ApiAreaInterface | EmptyArea) => {
      await upsertArea(a, isNew ? addHandler : editHandler)
      handleClose()
    },
    [addHandler, editHandler, handleClose, isNew, upsertArea],
  )

  return (
    <>
      <SettingsHeader
        restaurantId={restaurantId}
        screen={SettingsScreensEnum.Areas}
        primaryButtonProps={{
          onClick: () => handleEdit(emptyArea(areas)),
          children: t('settings.areas.addArea'),
        }}
      />
      {areas.length ? (
        <ConfigScroll>
          <AreaTable areas={areas} onEdit={handleEdit} />
        </ConfigScroll>
      ) : (
        <EmptyView
          text={t(
            'settings.areas.no_areas_defined',
            'No areas defined in the system.',
          )}
        />
      )}
      {editedArea && (
        <Modal
          open={open}
          onClose={handleClose}
          title={
            isNew
              ? t('settings.areas.new_modal_title', 'Add area')
              : t('settings.areas.modalTitle')
          }
        >
          <EditForm
            area={editedArea}
            onCancel={handleClose}
            onSubmit={handleSubmit}
          />
        </Modal>
      )}
    </>
  )
}

export default Areas
