import { createFileRoute } from '@tanstack/react-router'

import ServiceTimes from 'src/pages/Settings/ServiceTimes'

export const Route = createFileRoute(
  '/restaurants/$restaurantId/config/service-times',
)({
  component: RouteComponent,
})

function RouteComponent() {
  const { restaurantId } = Route.useParams()

  return <ServiceTimes restaurantId={restaurantId} />
}
