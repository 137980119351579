import React, { useCallback } from 'react'
import { compose, map, sortBy } from 'ramda'
import { Box } from '@mui/material'

import { replaceBy } from 'src/shared/lib/common/services/functional/functional'

interface NavigableItem<T extends string> {
  ref: React.RefObject<HTMLDivElement | null>
  key: T
  position?: number
}

interface NavigationSectionProps {
  onMount: (sectionRef: React.RefObject<HTMLDivElement | null>) => void
  onUnmount: () => void
  children: React.ReactNode
}

const NavigationSection = ({
  onMount,
  onUnmount,
  children,
}: NavigationSectionProps) => {
  const sectionRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    onMount(sectionRef)

    return onUnmount
  }, [sectionRef])

  return <Box ref={sectionRef}>{children}</Box>
}

export const useFormNavigation = <T extends string = string>() => {
  const [navigableItems, setNavigableItems] = React.useState<
    NavigableItem<T>[]
  >([])

  const addNavigationItem = useCallback(
    (key: T, position?: number) => (ref: NavigableItem<T>['ref']) =>
      setNavigableItems(oldItems =>
        replaceBy<NavigableItem<T>, T>(
          i => i.key,
          { ref, key, position },
          oldItems,
        ),
      ),
    [],
  )

  const removeNavigationItem = useCallback(
    (key: T) => () =>
      setNavigableItems(oldItems => oldItems.filter(i => i.key !== key)),
    [],
  )

  return {
    scrollToItem: React.useCallback(
      (key: NavigableItem<T>['key']) =>
        navigableItems.find(i => i.key === key)?.ref.current?.scrollIntoView(),
      [navigableItems],
    ),
    NavigationItem: React.useCallback(
      ({
        children,
        sectionKey,
        position,
        show = true,
      }: {
        children: React.ReactNode
        sectionKey: T
        position?: number
        show?: boolean
      }) =>
        show && (
          <NavigationSection
            onMount={addNavigationItem(sectionKey, position)}
            onUnmount={removeNavigationItem(sectionKey)}
          >
            {children}
          </NavigationSection>
        ),
      [addNavigationItem, removeNavigationItem],
    ),
    navigationItems: React.useMemo(
      () =>
        compose(
          map(i => i.key),
          sortBy<NavigableItem<T>>(i => i.position ?? Infinity),
        )(navigableItems),
      [navigableItems],
    ),
  }
}
