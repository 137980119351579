import { useMemo, useRef, useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useInfoQuery } from 'src/entities/info/queries/info'
import { useLabelsQuery } from 'src/entities/label/queries/label'
import {
  useAddShiftExceptionMutation,
  useEditShiftExceptionMutation,
  useNotificationsTemplatesQuery,
  useScheduleQuery,
} from 'src/entities/schedule/queries/schedule'
import type {
  Shift,
  ShiftException,
} from 'src/entities/schedule/types/scheduleApi'
import { AdvancedExceptionFormSection } from './components/AdvancedSections/AdvancedExceptionFormSection'
import FormLayout from './components/FormLayout/FormLayout'
import { type FormSection } from './components/FormNavigation/FormNavigation'
import { SimpleFormSection } from './components/SimpleFormSection/SimpleFormSection'
import { useFormNavigation } from './hooks/formNaviagtion'
import {
  fromException,
  getIntersections,
  newException,
  toException,
  validatedExceptionSchema,
  type FormException,
} from './services/exceptionFormSchema'
import {
  getOtherRelevantShiftLikesFactory,
  getRelevantExceptionsFactory,
} from './services/shiftValidation'
import { shouldShowAdvancedForm } from './services/shouldShowAdvancedForm'
import { ConflictResolutionModal } from '../ConflictResolution/ConflictResolutionModal'
import { useConflictResolutionModal } from '../ConflictResolution/useConflictResolutionModal'
import { useDefaultMutationHandlers } from '../hooks/useDefaultMutationHandlers'

interface ExceptionFormProps {
  shift: Shift
  exception: ShiftException | undefined
  onNavigateBack: () => void
}

const ExceptionForm = ({
  shift,
  exception,
  onNavigateBack,
}: ExceptionFormProps) => {
  const { t } = useTranslation()

  const {
    data: { shifts, events, shiftExceptions: exceptions, paymentPlans },
  } = useScheduleQuery()
  const { data: labels } = useLabelsQuery()
  const { data: templates } = useNotificationsTemplatesQuery()
  const {
    data: { language: restaurantLang },
  } = useInfoQuery()

  const { onSuccess, onError: onUnresolvableError } =
    useDefaultMutationHandlers({ onSuccess: onNavigateBack })

  const { onError, modalProps } =
    useConflictResolutionModal(onUnresolvableError)

  const { mutateAsync: editException } = useEditShiftExceptionMutation({
    onSuccess,
    onError,
  })
  const { mutateAsync: addException } = useAddShiftExceptionMutation({
    onSuccess,
    onError,
  })

  const formRef = useRef<HTMLDivElement>(null)

  const submitHandler = (formException: FormException) => {
    const modifiedException = toException(shift)(formException)

    if (!exception) return addException(modifiedException)

    return editException({ ...modifiedException, id: exception.id })
  }

  const otherExceptions = useMemo(
    () =>
      exception ? exceptions.filter(e => e.id !== exception.id) : exceptions,
    [exception, exceptions],
  )

  const relevantExceptions = useMemo(
    () =>
      getRelevantExceptionsFactory({
        shiftExceptions: otherExceptions,
        shifts,
      })(shift),
    [otherExceptions, shift, shifts],
  )

  const relevantEvents = useMemo(
    () =>
      getOtherRelevantShiftLikesFactory({
        others: events,
        original: undefined,
      })({ enabled: true, roomId: shift.roomId ?? 0 }),
    [events, shift.roomId],
  )

  const relevantPeriodAwares = useMemo(
    () => [...relevantExceptions, ...relevantEvents],
    [relevantEvents, relevantExceptions],
  )

  const emptyException = useMemo(
    () => newException(shift, templates),
    [shift, templates],
  )

  const editedException = exception
    ? fromException(shift, templates)(exception)
    : null

  const [isAdvanced, setIsAdvanced] = useState(
    shouldShowAdvancedForm(emptyException, editedException ?? emptyException),
  )

  const form = useForm<FormException>({
    shouldFocusError: false,
    defaultValues: editedException ?? emptyException,
    resolver: zodResolver(
      validatedExceptionSchema({
        t,
        defaultLang: restaurantLang,
      })(shifts)(relevantPeriodAwares),
    ),
  })

  const formData = form.watch()

  const intersections = useMemo(
    () => getIntersections(shifts)(relevantPeriodAwares)(formData),
    [shifts, relevantPeriodAwares, formData],
  )

  const shiftDisabled = form.watch('shiftDisabled')

  const title = exception
    ? t('schedule.exceptions.title_edit', 'Edit exception')
    : t('schedule.exceptions.title_create', 'Create exception')

  const { navigationItems, NavigationItem, scrollToItem } =
    useFormNavigation<FormSection>()

  return (
    <FormLayout
      title={`${shift.name} - ${title}`}
      ref={formRef}
      onNavigateBack={onNavigateBack}
      onSave={form.handleSubmit(submitHandler, () =>
        formRef.current?.scrollIntoView(),
      )}
      items={navigationItems}
      scrollToItem={scrollToItem}
      error={!!Object.keys(form.formState.errors).length}
      onAdvancedFormButtonClick={
        isAdvanced ? undefined : () => setIsAdvanced(true)
      }
    >
      <FormProvider {...form}>
        {isAdvanced ? (
          <AdvancedExceptionFormSection
            navItem={NavigationItem}
            intersections={intersections}
            shiftDisabled={shiftDisabled}
            showLabelsSection={!!labels.length}
            showPaymentsSection={!!paymentPlans.length}
          />
        ) : (
          <SimpleFormSection
            type="exception"
            intersections={intersections}
            shiftDisabled={shiftDisabled}
          />
        )}
      </FormProvider>
      <ConflictResolutionModal
        entityType="exception"
        entity={{ ...formData, name: shift.name }}
        actionType={exception ? 'edit' : 'new'}
        onConfirmation={onNavigateBack}
        {...modalProps}
      />
    </FormLayout>
  )
}

export default ExceptionForm
