import type React from 'react'
import { Box } from '@mui/material'

import FormContainer from '../../../FormContainer'
import FormNavigation from '../FormNavigation/FormNavigation'

interface FormLayoutProps
  extends React.ComponentProps<typeof FormContainer>,
    React.ComponentProps<typeof FormNavigation> {
  onAdvancedFormButtonClick: (() => void) | undefined
}

const FormLayout = ({
  items,
  onSave,
  title,
  onNavigateBack,
  children,
  scrollToItem,
  error,
  onAdvancedFormButtonClick,
  ref,
}: FormLayoutProps) => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: items.length ? 'minmax(0, auto) 224px' : 'auto',
    }}
    ref={ref}
  >
    <FormContainer
      title={title}
      onNavigateBack={onNavigateBack}
      onSave={onSave}
      error={error}
      onAdvancedFormButtonClick={onAdvancedFormButtonClick}
    >
      {children}
    </FormContainer>
    <FormNavigation items={items} scrollToItem={scrollToItem} />
  </Box>
)

export default FormLayout
