import { useCallback } from 'react'
import { Button, Stack, Typography, type ButtonProps } from '@mui/material'

import { useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import { type SettingsScreensEnum } from 'src/entities/setting/types/settings'
import CloseButton from 'src/shared/components/buttons/CloseButton'
import IconButton from 'src/shared/components/buttons/IconButton'
import {
  LoadingButton,
  type LButtonProps,
} from 'src/shared/components/buttons/LoadingButton/LoadingButton'
import { ChevronLeftIcon } from 'src/shared/components/icons/ChevronIcon'

interface SettingsHeaderProps {
  primaryButtonProps?: LButtonProps
  secondaryButtonProps?: ButtonProps
  screen: SettingsScreensEnum
  restaurantId: number
}

export const SettingsHeader = ({
  screen,
  restaurantId,
  primaryButtonProps,
  secondaryButtonProps,
}: SettingsHeaderProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const onClose = useCallback(
    () =>
      navigate({
        to: '/restaurants/$restaurantId/reservations',
        params: { restaurantId },
      }),
    [navigate, restaurantId],
  )

  const onBack = useCallback(
    () =>
      navigate({
        to: '/restaurants/$restaurantId/config',
        params: { restaurantId },
      }),
    [navigate, restaurantId],
  )

  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      direction="row"
      py={3}
      px={[2, 3]}
      pl={[0, 3]}
    >
      <Stack alignItems="center" direction="row" gap={0}>
        <IconButton
          sx={{ display: ['flex', 'none'] }}
          color="neutral"
          onClick={onBack}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant="labelGiant">
          {t(`settings.tab_names.${screen}`)}
        </Typography>
      </Stack>
      <Stack alignItems="center" direction="row" gap={0.5}>
        {secondaryButtonProps && (
          <Button variant="outlined" {...secondaryButtonProps} />
        )}
        {primaryButtonProps && (
          <LoadingButton
            color="primary"
            variant="contained"
            {...primaryButtonProps}
          />
        )}
        <CloseButton sx={{ display: ['none', 'flex'] }} onClick={onClose} />
      </Stack>
    </Stack>
  )
}
