import { isEmpty } from 'ramda'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import {
  useClearSelectedReservation,
  useIsReservationSelectedAtom,
  useSelectedReservationAtom,
} from 'src/shared/lib/context/state/atoms/selectedReservation'
import { useDrawerColor } from 'src/widgets/SidebarDrawer/drawerColor'
import { SidebarDrawer } from 'src/widgets/SidebarDrawer/SidebarDrawer'
import Footer from './Footer/Footer'
import { ReservationDrawer } from './ReservationDrawer'
import { ReservationDrawerHeader } from './ReservationDrawerHeader/ReservationDrawerHeader'

const InnerPart = () => {
  const [reservation] = useSelectedReservationAtom()
  const isReservationSelected = useIsReservationSelectedAtom()

  if (!isReservationSelected || isEmpty(reservation)) return null

  return (
    <>
      <ReservationDrawerHeader reservation={reservation} />
      <ReservationDrawer reservation={reservation} />
      <Footer reservation={reservation} />
    </>
  )
}

export const ReservationSidebarDrawer = () => {
  const onClose = useClearSelectedReservation()

  const isReservationSelected = useIsReservationSelectedAtom()

  const drawerColor = useDrawerColor()

  return (
    <SidebarDrawer
      {...createTestIdProps('reservation-drawer')}
      onClose={onClose}
      open={isReservationSelected}
      color={drawerColor}
    >
      <InnerPart />
    </SidebarDrawer>
  )
}
