/* eslint-disable @typescript-eslint/no-empty-interface */
import {
  alertClasses,
  buttonClasses,
  buttonGroupClasses,
  chipClasses,
  Fade,
  inputLabelClasses,
  lighten,
  listClasses,
  listSubheaderClasses,
  menuItemClasses,
  outlinedInputClasses,
  popoverClasses,
  selectClasses,
  switchClasses,
  tableRowClasses,
  tableSortLabelClasses,
  type PaperProps,
} from '@mui/material'
import { createTheme, type Shadows, type Theme } from '@mui/material/styles'

import { subMenuVariantProps } from 'src/shared/components/common/SubMenu'
import { toggleHoverStyle } from 'src/shared/components/form/inputs/Toggle'
import CheckboxIcon from 'src/shared/components/icons/CheckboxIcon'
import ChevronFilled from 'src/shared/components/icons/ChevronFilled'
import ChevronIcon from 'src/shared/components/icons/ChevronIcon'
import ErrorIcon, { InfoIcon } from 'src/shared/components/icons/ErrorIcon'
import RadioIcon from 'src/shared/components/icons/RadioIcon'
import StarIcon from 'src/shared/components/icons/StarIcon'
import SuccessIcon from 'src/shared/components/icons/SuccessIcon'
import WarningIcon from 'src/shared/components/icons/WarningIcon'
import primary from './colors/primary'
import palette from './palette'
import { svgPathSizeFactory, svgSizeFactory } from './svg'
import { themeTypography } from './typography'

const baseTheme = createTheme()

export const DropdownPaperProps = {
  elevation: 4,
  sx: {
    mt: theme => theme.spacing(1),
    maxHeight: 480,
  },
} satisfies Partial<PaperProps>

export const getGapSize = (size?: string) => (size === 'small' ? 1 : 1.5)

const shadows = baseTheme.shadows.slice()
// Elevation 50
shadows[1] =
  '0px 0px 1px rgba(1, 30, 41, 0.02), 0px 1px 1px rgba(1, 30, 41, 0.05)'
// Elevation 100
shadows[2] =
  '0px 3px 4px 0px rgba(1, 30, 41, 0.02), 0px 2px 4px 0px rgba(1, 30, 41, 0.04), 0px 1px 2px 0px rgba(1, 30, 41, 0.02), 0px 0px 2px 0px rgba(1, 30, 41, 0.05)'
// Elevation 300
shadows[4] =
  '0px 1px 2px rgba(1, 30, 41, 0.05), 0px 2px 2px rgba(1, 30, 41, 0.02), 0px 4px 4px rgba(1, 30, 41, 0.04), 0px 8px 8px rgba(1, 30, 41, 0.02), 0px 12px 15px rgba(1, 30, 41, 0.02)'

export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  scrollStyle: {
    '& *::-webkit-scrollbar-thumb': {
      backgroundColor: theme => theme.palette.grey['50'],
      borderRadius: 1,
    },
    '& *::-webkit-scrollbar': {
      maxWidth: 4,
      maxHeight: 4,
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },
  },
  shape: {
    borderRadius: 2,
  },
  inputHeights: {
    small: 32,
    medium: 40,
    large: 48,
  },
  palette: palette(baseTheme),
  shadows: shadows as Shadows,
  typography: themeTypography(baseTheme),
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: 13,
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: false,
      },
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            typography: 'labelSmall',
            transform: 'initial',
            position: 'relative',
            [`&.${inputLabelClasses.focused}`]: {
              color: theme.palette.text.secondary,
            },
            [`&.${inputLabelClasses.error}`]: {
              color: theme.palette.error.main,
            },
          }),
      },
      variants: [
        {
          props: {
            size: 'small',
          },
          style: ({ theme }) =>
            theme.unstable_sx({
              typography: 'labelTiny',
            }),
        },
      ],
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },
      variants: [
        {
          props: {
            size: 'small',
          },
          style: ({ theme }) =>
            theme.unstable_sx({
              'label + &': {
                marginTop: theme.spacing(0.25),
              },
              typography: 'labelTiny',
            }),
        },
        {
          props: {
            size: 'medium',
          },
          style: ({ theme }) =>
            theme.unstable_sx({
              typography: 'body3',
            }),
        },
        {
          props: {
            size: 'large',
          },
          style: ({ theme }) =>
            theme.unstable_sx({
              typography: 'body2',
            }),
        },
      ],
      styleOverrides: {
        root: ({ theme, ownerState }) =>
          theme.unstable_sx({
            ...svgSizeFactory(ownerState.size!),
            ...svgPathSizeFactory(ownerState.size!),
            px: 0,
            py: 0,
            'label + &': {
              marginTop: theme.spacing(0.5),
            },
            backgroundColor: 'white',
            overflow: 'hidden',
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 1,
            borderColor: theme.palette.border,
            color: theme.palette.text.secondary,
            [`&.${outlinedInputClasses.focused}, &:hover`]: {
              borderColor: theme.palette.text.quaternary,
            },
            [`&.${outlinedInputClasses.error}`]: {
              borderColor: theme.palette.error.main,
            },
            [`&.${outlinedInputClasses.disabled}`]: {
              backgroundColor: theme.palette.grey['50'],
              borderColor: theme.palette.border,
            },
            ...(!ownerState.multiline && {
              height: theme.inputHeights[ownerState.size!],
            }),
          }),
        input: ({ theme }) =>
          theme.unstable_sx({
            py: 0,
            px: 1.5,
          }),
        multiline: ({ theme }) =>
          theme.unstable_sx({
            [`.${outlinedInputClasses.input}`]: {
              py: 1.5,
            },
          }),
        notchedOutline: {
          display: 'none',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variant: 'inherit',
        variantMapping: {
          body3: 'p',
          labelGiant: 'label',
          labelHuge: 'label',
          labelBig: 'label',
          labelRegular: 'label',
          labelSmall: 'label',
          labelTiny: 'label',
          labelExtrasmall: 'label',
        },
      },
      variants: [
        {
          props: {
            component: 'a',
          },
          style: ({ theme }) =>
            theme.unstable_sx({
              color: 'primaryPalette.500',
              typography: 'labelSmall',
            }),
        },
      ],
    },
    MuiMenu: {
      defaultProps: {
        PaperProps: { elevation: DropdownPaperProps.elevation },
      },
      styleOverrides: {
        paper: ({ theme, ownerState }) =>
          theme.unstable_sx({
            ...(ownerState?.anchorOrigin?.vertical === 'top' && {
              mt: theme.spacing(-1),
            }),
            ...(ownerState?.anchorOrigin?.vertical === 'bottom' && {
              mt: theme.spacing(1),
            }),
            maxHeight: 480,
          }),
        list: ({ theme }) =>
          theme.unstable_sx({
            'li:not(:last-child)': {
              mb: 1,
            },
            [`li.${listSubheaderClasses.root}`]: {
              mt: 1,
              '&:not(:first-of-type)': {
                mt: 2,
              },
            },
            [`&.${listClasses.dense}`]: {
              'li:not(:last-child)': {
                mb: 0.5,
              },
              [`li.${listSubheaderClasses.root}`]: {
                mt: 0.5,
                '&:not(:first-of-type)': {
                  mt: 1,
                },
              },
            },
          }),
      },
    },
    MuiModal: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            ...theme.scrollStyle,
            '&:has(> div[style*="opacity: 0"])': {
              pointerEvents: 'none',
            },
          }),
      },
    },
    MuiSelect: {
      variants: [
        {
          props: {
            size: 'medium',
          },
          style: ({ theme }) =>
            theme.unstable_sx({
              [`.${selectClasses.multiple}`]: {
                pl: 0.5,
              },
            }),
        },
        {
          props: {
            size: 'large',
          },
          style: ({ theme }) =>
            theme.unstable_sx({
              [`.${selectClasses.multiple}`]: {
                pl: 1,
              },
            }),
        },
      ],
      styleOverrides: {
        icon: ({ theme }) =>
          theme.unstable_sx({
            color: 'currentColor',
          }),
        root: {
          cursor: 'pointer',
        },
        select: {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        },
      },
      defaultProps: {
        IconComponent: ChevronIcon,
        MenuProps: {
          PaperProps: DropdownPaperProps,
        },
      },
    },
    MuiMenuItem: {
      variants: [
        {
          props: {
            disableGutters: true,
          },
          style: ({ theme }) =>
            theme.unstable_sx({
              px: 1,
              ml: 1,
            }),
        },
      ],
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            borderRadius: 1,
            p: 1,
            mx: 1,
            ml: 2,
            '&:focus': {
              backgroundColor: 'transparent',
            },
            '&:hover': {
              backgroundColor: primary['50'],
              color: 'inherit',
            },
            [`&.${menuItemClasses.selected}`]: {
              backgroundColor: 'transparent',
              '&:focus': {
                backgroundColor: 'transparent',
              },
              '&:hover': {
                backgroundColor: primary['50'],
              },
            },
          }),
      },
    },
    SizeableMenuItem: {
      variants: [
        {
          props: {
            size: 'small',
          },
          style: ({ theme: untyped }) => {
            const theme = untyped as Omit<Theme, 'components'>

            return theme.unstable_sx({
              height: theme.inputHeights.small,
              minHeight: theme.inputHeights.small,
              typography: 'labelTiny',
            })
          },
        },
        {
          props: {
            size: 'medium',
          },
          style: ({ theme: untyped }) => {
            const theme = untyped as Omit<Theme, 'components'>

            return theme.unstable_sx({
              height: theme.inputHeights.medium,
              minHeight: theme.inputHeights.medium,
              typography: 'body3',
            })
          },
        },
        {
          props: {
            size: 'large',
          },
          style: ({ theme: untyped }) => {
            const theme = untyped as Omit<Theme, 'components'>

            return theme.unstable_sx({
              height: theme.inputHeights.large,
              minHeight: theme.inputHeights.large,
              typography: 'body2',
            })
          },
        },
      ],
    },
    SizeableListSubheader: {
      variants: [
        {
          props: {
            size: 'small',
          },
          style: ({ theme: untyped }) => {
            const theme = untyped as Omit<Theme, 'components'>

            return theme.unstable_sx({
              typography: 'labelTiny',
              fontWeight: 500,
            })
          },
        },
        {
          props: {
            size: 'medium',
          },
          style: ({ theme: untyped }) => {
            const theme = untyped as Omit<Theme, 'components'>

            return theme.unstable_sx({
              typography: 'body3',
              fontWeight: 500,
            })
          },
        },
        {
          props: {
            size: 'large',
          },
          style: ({ theme: untyped }) => {
            const theme = untyped as Omit<Theme, 'components'>

            return theme.unstable_sx({
              typography: 'body2',
              fontWeight: 500,
            })
          },
        },
      ],
    },
    MuiListSubheader: {
      variants: [
        {
          props: {
            disableGutters: true,
          },
          style: ({ theme }) =>
            theme.unstable_sx({
              px: 1,
            }),
        },
      ],
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            typography: 'labelSmall',
            color: 'text.primary',
          }),
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        slotProps: {
          paper: DropdownPaperProps,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'elevation',
        elevation: 4,
      },
      variants: [
        {
          props: {
            variant: 'elevation',
          },
          style: ({ theme }) =>
            theme.unstable_sx({
              borderColor: theme.palette.grey['50'],
              borderWidth: 1,
              borderStyle: 'solid',
            }),
        },
      ],
    },
    MuiPopover: {
      defaultProps: {
        TransitionComponent: Fade,
        PaperProps: DropdownPaperProps,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      },
      variants: [
        {
          props: subMenuVariantProps,
          style: ({ theme }) =>
            theme.unstable_sx({
              [`.${popoverClasses.paper}`]: {
                ml: 1,
                mt: 0,
              },
            }),
        },
      ],
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            border: `1px solid`,
            borderRadius: 2,
            alignItems: 'center',
            px: 1.5,
            py: 1,
          }),
        message: ({ theme }) => ({
          fontSize: theme.typography.labelSmall.fontSize,
          padding: 0,
          width: '100%',
          overflow: 'hidden',
        }),
        icon: ({ theme }) =>
          theme.unstable_sx({
            width: 24,
            height: 24,
            fontSize: 18,
            p: 0,
            flexShrink: 0,
            mr: 1,
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 1,
            alignSelf: 'start',
            '&>div>svg': {
              width: 18,
              height: 18,
            },
          }),
      },
      defaultProps: {
        iconMapping: {
          error: <ErrorIcon variant="filled" />,
          warning: <WarningIcon variant="filled" />,
          info: <InfoIcon variant="filled" />,
          neutral: <InfoIcon variant="filled" />,
          success: <SuccessIcon variant="filled" />,
        },
      },
      variants: [
        {
          props: { severity: 'error' },
          style: ({ theme }) => ({
            borderColor: theme.palette.errorPalette[300],
            color: theme.palette.errorPalette[700],
          }),
        },
        {
          props: { severity: 'info' },
          style: ({ theme }) => ({
            borderColor: theme.palette.primaryPalette[100],
            color: theme.palette.primaryPalette[700],
          }),
        },
        {
          props: { severity: 'neutral' },
          style: ({ theme }) => ({
            borderColor: theme.palette.grey[50],
            color: theme.palette.grey[900],
            [`.${alertClasses.icon}`]: {
              color: 'inherit',
            },
          }),
        },
        {
          props: { severity: 'warning' },
          style: ({ theme }) => ({
            borderColor: theme.palette.warningPalette[300],
            color: theme.palette.warningPalette[700],
          }),
        },
        {
          props: { severity: 'success' },
          style: ({ theme }) => ({
            borderColor: theme.palette.successPalette[300],
            color: theme.palette.successPalette[700],
          }),
        },
      ],
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
        checkedIcon: <RadioIcon checked />,
        icon: <RadioIcon />,
      },
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            p: 0,
            ml: 0,
          }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: ({ theme, ownerState: { size } }) =>
          theme.unstable_sx({
            m: 0,
            gap: getGapSize(size),
          }),
        label: ({ theme, ownerState: { size } }) =>
          theme.unstable_sx({
            typography: size === 'small' ? 'body3' : 'body2',
          }),
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
        checkedIcon: <CheckboxIcon checked />,
        icon: <CheckboxIcon />,
        indeterminateIcon: <CheckboxIcon indeterminate />,
      },
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            p: 0,
          }),
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`.${buttonGroupClasses.grouped}`]: {
            minWidth: 'initial',
          },
          [`.${buttonGroupClasses.groupedOutlined}`]: {
            zIndex: 0,
            transition: theme.transitions.create(
              [
                'background-color',
                'box-shadow',
                'border-color',
                'color',
                'z-index',
              ],
              { duration: 250 },
            ),
            [`&:hover`]: { zIndex: 1 },
          },
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      variants: [
        {
          props: {
            variant: 'text',
          },
          style: {
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
        {
          props: {
            variant: 'outlined',
          },
          style: ({ theme }) => ({
            borderWidth: 1,
            borderStyle: 'solid',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            [`&.${buttonClasses.disabled}`]: {
              borderColor: theme.palette.action.disabled,
              color: theme.palette.action.disabled,
            },
          }),
        },
        {
          props: {
            variant: 'color-outlined',
          },
          style: ({ theme }) => ({
            borderWidth: 1,
            borderStyle: 'solid',
            [`&.${buttonClasses.disabled}`]: {
              borderColor: theme.palette.action.disabled,
              color: theme.palette.action.disabled,
            },
          }),
        },
        {
          props: {
            size: 'small',
          },
          style: ({ theme }) =>
            theme.unstable_sx({
              px: 1.5,
            }),
        },
      ],
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          const colorPalette = theme.palette[ownerState.color as 'light']

          return theme.unstable_sx({
            px: 2,
            ...svgSizeFactory(ownerState.size!),
            ...svgPathSizeFactory(ownerState.size!),
            textTransform: 'none',
            height: theme.inputHeights[ownerState.size!],
            minWidth: theme.inputHeights[ownerState.size!],
            border: 'none',
            borderRadius: 1,
            typography: {
              large: 'labelRegular',
              medium: 'labelSmall',
              small: 'labelTiny',
            }[ownerState.size!],
            [`&.${buttonClasses.root}`]: {
              [`&.${buttonClasses.loading}`]: {
                backgroundColor: theme.palette.primaryPalette['100'],
                [`.${buttonClasses.loadingIndicator}`]: {
                  color: theme.palette.primaryPalette['500'],
                },
              },
            },
            ...(ownerState?.variant === 'text' && {
              '&:hover': {
                color: colorPalette.dark,
              },
            }),
            ...(ownerState?.variant === 'outlined' && {
              color: theme.palette.grey['700'],
              borderColor: theme.palette.grey['200'],
              '&:hover': {
                borderColor: theme.palette.grey['700'],
              },
            }),
            ...(ownerState?.variant === 'color-outlined' && {
              color: theme.palette.grey['700'],
              borderColor: theme.palette.grey['200'],
              '&:hover': {
                borderColor: theme.palette.grey['700'],
                backgroundColor: lighten(colorPalette.lighter!, 0.75),
              },
              [`&[aria-selected='true']`]: {
                backgroundColor: colorPalette.lighter,
                color: colorPalette.main,
                borderColor: 'currentColor',
                [`&:hover`]: {
                  color: colorPalette.main,
                  backgroundColor: colorPalette.lighter,
                  borderColor: 'currentColor',
                  cursor: 'default',
                },
              },
              [`&[aria-checked='true']`]: {
                backgroundColor: colorPalette.lighter,
                color: colorPalette.main,
                borderColor: 'currentColor',
                [`&:hover`]: {
                  backgroundColor: colorPalette.lighter,
                  color: colorPalette.dark,
                  borderColor: 'currentColor',
                },
                [`&.${buttonClasses.disabled}`]: {
                  color: colorPalette.light,
                  borderColor: 'currentColor',
                },
              },
            }),
          })
        },
      },
    },
    IconButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) =>
          theme.unstable_sx({
            px: 1,
            width: theme.inputHeights[ownerState.size!],
          }),
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            color: 'currentColor',
          }),
      },
    },
    MuiDialog: {
      defaultProps: {
        PaperProps: {
          sx: { maxWidth: 512 },
        },
        fullWidth: true,
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            typography: 'labelHuge',
            color: 'text.primary',
            p: 4,
            pb: 3,
          }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            typography: 'body2',
            color: 'grey.700',
            p: 4,
            pt: 0,
          }),
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            typography: 'body2',
            color: 'grey.700',
          }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            flexDirection: ['column', 'row'],
            gap: 1,
            p: 4,
            pt: 1,
            '>:not(:first-of-type)': {
              m: 0,
            },
          }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            color: 'text.quaternary',
            typography: {
              xs: 'labelSmall',
              sm: 'labelRegular',
            },
            textTransform: 'capitalize',
          }),
      },
    },
    MuiChip: {
      variants: [
        {
          props: {
            color: 'light',
          },
          style: ({ theme }) =>
            theme.unstable_sx({
              backgroundColor: 'grey.50',
              color: 'grey.700',
            }),
        },
        {
          props: {
            color: 'error',
          },
          style: ({ theme }) =>
            theme.unstable_sx({
              backgroundColor: 'errorPalette.100',
              color: 'errorPalette.500',
            }),
        },
      ],
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            [`.${chipClasses.label}`]: {
              p: 0,
            },
            [`.${chipClasses.icon}`]: {
              mr: 1,
              ml: 0,
            },
            typography: 'labelTiny',
            [`&.${chipClasses.filled}`]: {
              border: 'none',
            },
            [`&.${chipClasses.outlined}`]: {
              borderColor: 'grey.100',
            },
            [`&.${chipClasses.disabled}.${chipClasses.filled}`]: {
              backgroundColor: 'grey.50',
              color: 'grey.400',
              opacity: 1,
            },
            px: 1.5,
          }),
      },
    },
    MuiRating: {
      variants: [
        {
          props: {
            size: 'small',
          },
          style: {
            fontSize: '0.875rem',
          },
        },
        {
          props: {
            size: 'medium',
          },
          style: {
            fontSize: '1.625rem',
          },
        },
      ],
      defaultProps: {
        icon: (
          <StarIcon
            variant="filled"
            sx={{ color: 'warning.main' }}
            size="inherit"
          />
        ),
        emptyIcon: (
          <StarIcon
            variant="filled"
            sx={{ color: 'light.main' }}
            size="inherit"
          />
        ),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            borderColor: 'grey.100',
          }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            borderRadius: 1,
            [theme.breakpoints.up('xs')]: {
              p: 2,
            },
            [theme.breakpoints.up('md')]: {
              p: 3,
            },
          }),
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            borderRadius: 2,
            borderColor: theme.palette.grey['50'],
            borderStyle: 'solid',
            borderWidth: '1px 1px 0 1px',
          }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        scrollButtons: {
          [`&.${switchClasses.disabled}`]: {
            opacity: 0.3,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            borderColor: 'inherit',
            width: 1,
            tableLayout: 'auto',
          }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            px: 0.5,
            borderColor: 'inherit',
            transition: theme.transitions.create('background-color'),
            [`&:not(.${tableRowClasses.head}):hover`]: {
              backgroundColor: 'primaryPalette.25',
            },
          }),
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          [`&.${tableSortLabelClasses.icon}`]: {
            transitionProperty: 'opacity',
          },
        },
        root: ({ theme }) =>
          theme.unstable_sx({
            color: 'currentColor',
            display: 'flex',
            flexDirection: 'row',
            [`&.${tableSortLabelClasses.active}`]: {
              color: 'currentColor',
            },
          }),
      },
      defaultProps: {
        IconComponent: props => <ChevronFilled size="small" {...props} />,
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            px: 1,
            py: 2,
            borderColor: 'inherit',
          }),
      },
      variants: [
        {
          props: {
            variant: 'head',
          },
          style: ({ theme }) =>
            theme.unstable_sx({
              color: 'primaryPalette.700',
              borderColor: 'inherit',
              backgroundColor: theme.palette.primaryPalette['50'],
              typography: 'labelExtrasmall',
              fontWeight: 600,
              letterSpacing: '0.08em',
              px: 1,
            }),
        },
        {
          props: {
            variant: 'body',
          },
          style: ({ theme }) =>
            theme.unstable_sx({
              typography: 'body3',
              color: 'text.secondary',
              verticalAlign: 'top',
            }),
        },
      ],
    },
    MuiBackdrop: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({ backgroundColor: '#011E2966' }),
        invisible: ({ theme }) =>
          theme.unstable_sx({ backgroundColor: 'transparent' }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) =>
          theme.unstable_sx({
            typography: 'labelSmall',
            backgroundColor: 'grey.600',
            boxShadow: theme.shadows[4],
            px: 2,
            py: 1.5,
            borderRadius: 1,
          }),
      },
    },
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ theme, ownerState: { size } }) =>
          theme.unstable_sx({
            width: size === 'small' ? 28 : 40,
            height: size === 'small' ? 16 : 24,
            padding: 0,
            alignItems: 'center',
            [`& .${switchClasses.switchBase}`]: {
              padding: 0,
              margin: size === 'small' ? 0.25 : 0.5,
              transitionDuration: '300ms',
              ':hover': {
                backgroundColor: 'inherit',
              },
              [`& + .${switchClasses.track}`]: {
                backgroundColor: 'grey.300',
                borderRadius: 26 / 2,
                opacity: 1,
                transition: theme.transitions.create(['background-color'], {
                  duration: 200,
                }),
              },
              [`&.${switchClasses.checked}`]: {
                transform: `translateX(${size === 'small' ? 0.75 : 1}rem)`,
                color: '#fff',
                [`& + .${switchClasses.track}`]: {
                  backgroundColor: 'primaryPalette.500',
                  opacity: 1,
                  border: 0,
                },
                [`&.${switchClasses.disabled} + .${switchClasses.track}`]: {
                  backgroundColor: 'grey.100',
                },
              },
              [`&.Mui-focusVisible .${switchClasses.thumb}`]: {
                color: 'primaryPalette.700',
                border: `6px solid ${theme.palette.grey[50]}`,
              },
              [`&.${switchClasses.disabled} .${switchClasses.thumb}`]: {
                backgroundColor: 'grey.300',
              },
              [`&.${switchClasses.disabled} + .${switchClasses.track}`]: {
                backgroundColor: 'grey.100',
                opacity: 1,
              },
            },
            [`& .${switchClasses.thumb}`]: {
              boxSizing: 'border-box',
              transition: theme.transitions.create(['transform'], {
                duration: 200,
              }),
              width: size === 'small' ? 12 : 16,
              height: size === 'small' ? 12 : 16,
            },
            ':hover': toggleHoverStyle(size),
          }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            typography: 'labelTiny',
            mx: 0,
          }),
      },
    },
  },
})
