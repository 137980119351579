import { z } from 'zod'

import { localizedDateSchema } from 'src/shared/lib/zod/zod'

const templateSchema = z
  .object({
    channel: z.enum(['sms', 'email']),
    type: z.enum([
      'mutation',
      'confirmation',
      'cancel',
      'cancelByGuest',
      'cancel_guest',
      'reminder',
      'feedback',
    ]),
    id: z.number(),
  })
  .transform(({ id, channel, type }) => ({ id, channel, action: type }))

export const shiftSchema = z
  .object({
    id: z.string().uuid(),
    name: z.string(),
    startTime: localizedDateSchema,
    endTime: localizedDateSchema,
    rooms: z.array(z.number()),
    defaultCommunicationTemplates: z.array(templateSchema),
    lockStatus: z.enum(['open', 'locked', 'locked_online']),
    isBadWeather: z.boolean(),
    isClosed: z.boolean(),
  })
  .transform(shift => ({
    id: shift.id,
    name: shift.name,
    timeRange: [shift.startTime, shift.endTime] as [Date, Date],
    rooms: shift.rooms,
    defaultCommunicationTemplates: shift.defaultCommunicationTemplates,
    lockStatus: shift.lockStatus,
    isClosed: shift.isClosed,
    badWeatherMode: shift.isBadWeather,
  }))

export type ShiftCommunicationTemplate = z.infer<typeof templateSchema>
export type Shift = z.infer<typeof shiftSchema>
