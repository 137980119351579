import { useCallback } from 'react'
import { type StackProps } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { ReservationNotesEnum } from 'src/entities/reservation/types/reservation'
import ReservationDrawerSection from 'src/shared/components/common/ReservationDrawerSection'
import { TextArea } from 'src/shared/components/form/inputs/TextArea'
import {
  useSelectedReservationField,
  useSetSelectedReservationField,
} from 'src/shared/lib/context/state/atoms/selectedReservation'
import { NOTES_COLOURS } from 'src/widgets/Inbox/Lists/List/TodoNotification/Body/Details/Reservation/Labels'

export const RestaurantNote = ({ ...props }: Omit<StackProps, 'onChange'>) => {
  const { t } = useTranslation()

  const value = useSelectedReservationField('restaurantNote')
  const set = useSetSelectedReservationField()

  const setValue = useCallback(
    (newValue: string) => set('restaurantNote', newValue),
    [set],
  )

  return (
    <ReservationDrawerSection title={t('angular.notify_restaurant')} {...props}>
      <TextArea
        {...createTestIdProps('restaurant-note')}
        value={value ?? ''}
        onChange={e => setValue(e.target.value)}
        maxRows={15}
        placeholder={t('angular.note')}
        slotProps={{
          htmlInput: {
            style: {
              borderTop: value
                ? `4px solid ${
                    NOTES_COLOURS[ReservationNotesEnum.RestaurantNote]
                  }`
                : undefined,
            },
          },
        }}
      />
    </ReservationDrawerSection>
  )
}
