import { Box, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useIntegrationsQuery } from 'src/entities/integration/queries/integrations'
import { IntegrationItem } from './components/IntegrationItem'

const Integrations = () => {
  const { t } = useTranslation()

  const { data: integrations } = useIntegrationsQuery()

  return (
    <Stack gap={3}>
      <Typography variant="body3" color="grey.700">
        {t(
          'settings.integrations.subtitle',
          'Choose application you want to integrate your restaurant system.',
        )}
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, 300px)',
          gap: 2,
        }}
      >
        {integrations.map(({ integrationName, status }) => (
          <IntegrationItem
            key={integrationName}
            integrationName={integrationName}
            status={status}
          />
        ))}
      </Box>
    </Stack>
  )
}

export default Integrations
