import { Stack } from '@mui/material'

import { type CustomerInterface } from 'src/entities/customer/types/customer'
import { isBirthday } from 'src/shared/lib/range/services/date'
import Indicators from '../../../Customer/Indicators'
import Preferences from '../../../Customer/Preferences/Short'

const CustomerIndicators = ({
  customer,
  date,
}: {
  customer: Pick<
    CustomerInterface,
    | 'birthdate'
    | 'vip'
    | 'ban'
    | 'languageCode'
    | 'comment'
    | 'preferences'
    | 'external'
  >
  date: Date
}) => {
  const birthday = isBirthday(customer.birthdate, date)
  const showIndicators = birthday || customer.vip || customer.ban
  const showPreferences =
    !!customer.languageCode ||
    !!customer.comment ||
    !!customer.preferences?.food ||
    !!customer.preferences?.place

  if (!showIndicators && !showPreferences) return null

  return (
    <Stack direction="column" spacing={0.5} typography="inherit">
      {showIndicators && (
        <Stack direction="row" spacing={0.5}>
          <Indicators
            birthday={birthday}
            vip={customer.vip}
            ban={customer.ban}
            external={customer.external}
          />
        </Stack>
      )}
      {showPreferences && (
        <Stack direction="column">
          <Preferences
            languageCode={customer.languageCode}
            preferences={customer.preferences}
            comment={customer.comment}
          />
        </Stack>
      )}
    </Stack>
  )
}

export default CustomerIndicators
