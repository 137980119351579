import { andThen, composeWith, map, prop } from 'ramda'

import {
  convertKeysToSnakeCase,
  type ApiClient,
} from 'src/shared/lib/api/services/api'
import { createBatchedFetch } from 'src/shared/lib/api/services/createBatcher'
import { fromApi, type FeedbackApiFilters } from '../types/feedbackApi'

export const fetchAllFeedbacks =
  // eslint-disable-next-line @typescript-eslint/require-await
  (httpClient: ApiClient) => async (feedbackIds: number[]) =>
    composeWith(andThen)([map(fromApi), prop('data'), httpClient])({
      url: 'feedbacksapi/getAll',
      json: {
        feedback_ids: feedbackIds,
      },
    })

export const fetchFeedback = createBatchedFetch(fetchAllFeedbacks, 'id')

export const searchFeedbacks =
  (httpClient: ApiClient) =>
  // eslint-disable-next-line @typescript-eslint/require-await
  async (filters?: FeedbackApiFilters) =>
    composeWith(andThen)([prop('data')<{ data: number[] }>, httpClient])({
      url: 'feedbacksapi/search',
      json: {
        customer_id: filters?.customerId,
      },
    })

export interface ReplyToFeedbackDto {
  id: number
  answer: string
  isPublic: boolean
}

export const replyToFeedback =
  (httpClient: ApiClient) =>
  async ({ answer, id, isPublic }: ReplyToFeedbackDto) =>
    httpClient({
      url: 'feedbacksapi/reply',
      json: convertKeysToSnakeCase({ answer, isPublic, feedbackId: id }),
    })
