import { Stack, type StackProps } from '@mui/material'

const ItemsContainer = (props: StackProps) => (
  <Stack
    direction="row"
    spacing={0.5}
    sx={{
      position: 'absolute',
    }}
    {...props}
  />
)

export default ItemsContainer
