import React, { useMemo, useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm, type Resolver } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useInfoQuery } from 'src/entities/info/queries/info'
import { useLabelsQuery } from 'src/entities/label/queries/label'
import {
  useAddShiftMutation,
  useEditShiftMutation,
  useNotificationsTemplatesQuery,
  useScheduleQuery,
} from 'src/entities/schedule/queries/schedule'
import { type Shift } from 'src/entities/schedule/types/scheduleApi'
import { AdvancedShiftFormSections } from './components/AdvancedSections/AdvancedShiftFormSections'
import FormLayout from './components/FormLayout/FormLayout'
import type { FormSection } from './components/FormNavigation/FormNavigation'
import { SimpleFormSection } from './components/SimpleFormSection/SimpleFormSection'
import { useFormNavigation } from './hooks/formNaviagtion'
import {
  fromShift,
  getIntersections,
  newShift,
  toShift,
  validatedShiftSchema,
  type FormShift,
} from './services/shiftFormSchema'
import { getOtherRelevantShiftLikesFactory } from './services/shiftValidation'
import { shouldShowAdvancedForm } from './services/shouldShowAdvancedForm'
import { ConflictResolutionModal } from '../ConflictResolution/ConflictResolutionModal'
import { useConflictResolutionModal } from '../ConflictResolution/useConflictResolutionModal'
import { useDefaultMutationHandlers } from '../hooks/useDefaultMutationHandlers'

interface ShiftFormProps {
  shift: Shift | undefined
  onNavigateBack: () => void
}

const ShiftForm = ({ shift, onNavigateBack }: ShiftFormProps) => {
  const { t } = useTranslation()

  const {
    data: {
      shifts,
      rooms,
      paymentPlans,
      defaultSettings,
      scheduleFeatures: { rooms: roomsEnabled },
    },
  } = useScheduleQuery()
  const { data: templates } = useNotificationsTemplatesQuery()
  const { data: labels } = useLabelsQuery()
  const {
    data: { language: restaurantLang },
  } = useInfoQuery()

  const { onSuccess, onError: onUnresolvableError } =
    useDefaultMutationHandlers({ onSuccess: onNavigateBack })

  const { onError, modalProps } =
    useConflictResolutionModal(onUnresolvableError)

  const { mutateAsync: editShift } = useEditShiftMutation({
    onSuccess,
    onError,
  })
  const { mutateAsync: addShift } = useAddShiftMutation({
    onSuccess,
    onError,
  })

  const formRef = React.useRef<HTMLDivElement>(null)

  const submitHandler = (formShift: FormShift) => {
    const modifiedShift = toShift(formShift)

    if (!shift) return addShift(modifiedShift)

    return editShift({ ...modifiedShift, id: shift.id })
  }

  const getRelevantShifts = React.useMemo(
    () =>
      getOtherRelevantShiftLikesFactory({
        others: shifts,
        original: shift,
      }),
    [shift, shifts],
  )

  const emptyShift = useMemo(
    () => newShift(templates, defaultSettings),
    [defaultSettings, templates],
  )

  const editedShift = shift ? fromShift(shift, templates) : null

  const [isAdvanced, setIsAdvanced] = useState(
    !!editedShift && shouldShowAdvancedForm(emptyShift, editedShift),
  )

  const form = useForm<FormShift>({
    shouldFocusError: false,
    defaultValues: editedShift ?? emptyShift,
    resolver: React.useCallback<Resolver<FormShift>>(
      (...args) =>
        zodResolver(
          validatedShiftSchema({
            shifts: getRelevantShifts(args[0]),
            t,
            defaultLang: restaurantLang,
          }),
        )(...args),
      [getRelevantShifts, restaurantLang, t],
    ),
  })

  const formData = form.watch()

  const intersections = React.useMemo(
    () => getIntersections(getRelevantShifts(formData))(formData),
    [formData, getRelevantShifts],
  )

  const title = shift
    ? t('schedule.shifts.title_edit', 'Edit shift')
    : t('schedule.shifts.title_create', 'Create shift')

  const { navigationItems, NavigationItem, scrollToItem } =
    useFormNavigation<FormSection>()

  return (
    <FormProvider {...form}>
      <FormLayout
        title={title}
        ref={formRef}
        onNavigateBack={onNavigateBack}
        onSave={form.handleSubmit(submitHandler, () =>
          formRef.current?.scrollIntoView(),
        )}
        items={navigationItems}
        scrollToItem={scrollToItem}
        error={!!Object.keys(form.formState.errors).length}
        onAdvancedFormButtonClick={
          isAdvanced ? undefined : () => setIsAdvanced(true)
        }
      >
        {isAdvanced ? (
          <AdvancedShiftFormSections
            navItem={NavigationItem}
            intersections={intersections}
            rooms={rooms}
            roomsEnabled={roomsEnabled}
            showLabelsSection={!!labels.length}
            showPaymentsSection={!!paymentPlans.length}
          />
        ) : (
          <SimpleFormSection
            type="shift"
            intersections={intersections}
            rooms={rooms}
            roomsEnabled={roomsEnabled}
          />
        )}
      </FormLayout>
      <ConflictResolutionModal
        entityType="shift"
        entity={formData}
        actionType={shift ? 'edit' : 'new'}
        onConfirmation={onNavigateBack}
        {...modalProps}
      />
    </FormProvider>
  )
}

export default ShiftForm
