import { Suspense, useMemo } from 'react'
import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { useReservationStats } from 'src/entities/customer/queries/customer'
import { type CustomerInterface } from 'src/entities/customer/types/customer'
import { formatGuestName } from 'src/entities/reservation/services/reservation'
import {
  ReservationStatusEnum,
  type ReservationInterface,
} from 'src/entities/reservation/types/reservation'
import { useCustomerReservations } from 'src/pages/CustomerPreview/Reservations/useCustomerReservations'
import Badge from 'src/shared/components/common/Badge'
import CircularProgress from 'src/shared/components/common/CircularProgress'
import CenteredBox from 'src/shared/components/containers/CenteredBox'
import {
  getNowInRestaurantTimezone,
  isInPast,
} from 'src/shared/lib/range/services/date'
import { FiltersEnum } from 'src/shared/lib/reducer/service/reservationsFilter'
import { formatPhoneNumber } from 'src/shared/lib/string/services/phoneNumber'
import { formatCHF } from 'src/shared/lib/string/services/text'
import Indicators from 'src/widgets/Customer/Indicators'
import { SmallCustomerStats } from './SmallCustomerStats'
import { UpcomingReservationItem } from './UpcomingReservationItem'
import { PhoneCallNotificationItemWrapper } from '../wrappers/PhoneCallNotificationItemWrapper'

interface ExistingCustomerItemProps {
  phoneNumber: string
  customer: CustomerInterface
  onCreateButtonClick: () => void
  onReservationClick: (reservation: ReservationInterface) => void
  onCustomerClick: () => void
}

const findFirstReservationFromNow = (
  sortedReservations: ReservationInterface[],
) => {
  const now = getNowInRestaurantTimezone()
  if (
    !sortedReservations[0] ||
    isInPast('day')(now, sortedReservations[0].dateRange[0])
  ) {
    return null
  }

  let prev = sortedReservations[0]

  for (const r of sortedReservations) {
    const [startDate] = r.dateRange

    if (isInPast('day')(now, startDate)) return prev
    prev = r
  }

  return prev
}

export const ExistingCustomerItem = ({
  phoneNumber,
  customer,
  onCreateButtonClick,
  onReservationClick,
  onCustomerClick,
}: ExistingCustomerItemProps) => {
  const { t } = useTranslation()

  const stats = useReservationStats([customer])[0]

  const activeReservations = useCustomerReservations({
    [FiltersEnum.customer]: customer,
    [FiltersEnum.status]: [
      ReservationStatusEnum.Pending,
      ReservationStatusEnum.Confirmed,
    ],
  })

  const firstReservation = useMemo(
    () => findFirstReservationFromNow(activeReservations),
    [activeReservations],
  )

  return (
    <PhoneCallNotificationItemWrapper onButtonClick={onCreateButtonClick}>
      <Stack gap={1}>
        <Stack gap={0.5}>
          <Stack direction="row" alignItems="center" gap={1.5}>
            <Typography
              {...createTestIdProps('phone-call-guest')}
              variant="labelBig"
              lineHeight={1}
              onClick={onCustomerClick}
              sx={{
                ':hover': { textDecoration: 'underline', cursor: 'pointer' },
              }}
            >
              {formatGuestName(customer)}
            </Typography>
            {customer.external && <Badge size="small">PMS</Badge>}
          </Stack>
          <Stack direction="row" alignItems="center" gap={1.5}>
            <Typography
              {...createTestIdProps('phone-call-number')}
              color="grey.700"
            >
              {formatPhoneNumber(phoneNumber)}
            </Typography>
            <Stack direction="row" alignItems="center" color="grey.700">
              <Indicators
                vip={customer.vip}
                ban={customer.ban}
                comment={!!customer.comment}
                preferences={customer.preferences}
                external={customer.external}
                withColors
              />
            </Stack>
          </Stack>
        </Stack>
        {stats && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            color="grey.700"
          >
            <SmallCustomerStats stats={stats} />
            {!!stats.revenue && (
              <Typography>
                {t('customer.reservations.stats.revenue')}:{' '}
                <Typography component="span" fontWeight="bold" color="grey.900">
                  {formatCHF(stats.revenue)}
                </Typography>
              </Typography>
            )}
          </Stack>
        )}
      </Stack>
      {firstReservation && (
        <Suspense
          fallback={
            <CenteredBox>
              <CircularProgress />
            </CenteredBox>
          }
        >
          <UpcomingReservationItem
            reservation={firstReservation}
            onClick={() => onReservationClick(firstReservation)}
            sx={theme => ({
              cursor: 'pointer',
              ':hover': {
                bgcolor: 'grey.50',
                '.upcoming-reservation-date': { textDecoration: 'underline' },
              },
              transition: theme.transitions.create(['background-color'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.standard,
              }),
            })}
          />
        </Suspense>
      )}
    </PhoneCallNotificationItemWrapper>
  )
}
