import React from 'react'

import { useTranslation } from 'react-i18next'

import { useInfoQuery } from 'src/entities/info/queries/info'
import { type ScheduleLanguage } from 'src/entities/schedule/types/scheduleApi'
import { useBaseFormContext } from 'src/pages/ShiftConfiguration/ShiftForm/hooks/form'
import { translateLanguage } from 'src/shared/components/form/inputs/MultilingualTextArea/LanguageTabs'
import MultilingualTextArea from 'src/shared/components/form/inputs/MultilingualTextArea/MultilingualTextArea'

const MessageText = () => {
  const { t } = useTranslation()
  const {
    data: { language: restaurantLang },
  } = useInfoQuery()

  const {
    register,
    watch,
    formState: { errors },
  } = useBaseFormContext()

  const [selectedLanguage, setSelectedLanguage] =
    React.useState<ScheduleLanguage>('de')

  const message = watch(`shiftMessage.message.${selectedLanguage}`) ?? ''

  return (
    <MultilingualTextArea
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
      limit={255}
      messageLength={message.length}
      placeholder={t(
        'schedule.shifts.message_section.message_placeholder',
        'Your message...',
      )}
      errorMessage={
        errors.shiftMessage &&
        t('schedule.shifts.message_section.errors.no_default_empty', {
          defaultValue: `Restaurant language field can't be empty ({{language}})`,
          language: translateLanguage(t)(restaurantLang),
        })
      }
      {...register(`shiftMessage.message.${selectedLanguage}`, {
        setValueAs: (v: string) => v || null,
      })}
    />
  )
}

export default MessageText
