import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type ServiceTimeInterface } from 'src/entities/config/types/configApi'
import { useServiceTimeDeleteMutation } from 'src/entities/service-time/queries/serviceTime'
import { useDefaultMutationHandlers } from 'src/pages/ShiftConfiguration/hooks/useDefaultMutationHandlers'
import { ServiceTimeTableItem } from './ServiceTimeTableItem'

interface ServiceTimeTableProps {
  serviceTimes: ServiceTimeInterface[]
  onEdit: (st: ServiceTimeInterface) => void
}

export const ServiceTimeTable = ({
  serviceTimes,
  onEdit,
}: ServiceTimeTableProps) => {
  const { t } = useTranslation()

  const { mutate: deleteServiceTime } = useServiceTimeDeleteMutation()

  const deleteHandler = useDefaultMutationHandlers({
    successMessage: t(
      'settings.service_times.common.service_time_deleted',
      'Service time removed',
    ),
  })

  return (
    <TableContainer>
      <Table stickyHeader sx={{ overflow: 'clip' }}>
        <TableHead sx={{ borderColor: 'inherit' }}>
          <TableRow>
            <TableCell>
              {t('settings.service_times.name', 'Service time name')}
            </TableCell>
            <TableCell>{t('settings.service_times.time', 'Period')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody sx={{ borderColor: 'inherit' }}>
          {serviceTimes.map(st => (
            <ServiceTimeTableItem
              key={st.id}
              serviceTime={st}
              onEdit={() => onEdit(st)}
              onDelete={() => deleteServiceTime(st, deleteHandler)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
