import { Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type FeedbackStatsInterface } from 'src/entities/customer/types/customer'
import Item from './Item'

const Stats = ({
  feedbackStats,
}: {
  feedbackStats: FeedbackStatsInterface | null
}) => {
  const { t } = useTranslation()

  if (!feedbackStats) return null

  return (
    <Stack
      direction="column"
      spacing={1}
      flexGrow={1}
      sx={{ minWidth: { sm: 225 } }}
    >
      <Item
        label={t('customer.feedback.service', {
          tDescription: 'Customer preview feedback service score label',
          defaultValue: 'Service',
        })}
        score={feedbackStats.service}
      />
      <Item
        label={t('customer.feedback.kitchen', {
          tDescription: 'Customer preview feedback kitchen/food score label',
          defaultValue: 'Kitchen',
        })}
        score={feedbackStats.food}
      />
      <Item
        label={t('customer.feedback.atmosphere', {
          tDescription:
            'Customer preview feedback atmosphere/ambience score label',
          defaultValue: 'Atmosphere',
        })}
        score={feedbackStats.ambience}
      />
      <Item
        label={t('customer.feedback.performance', {
          tDescription: 'Customer preview feedback performance score label',
          defaultValue: 'Performance',
        })}
        score={feedbackStats.performance}
      />
    </Stack>
  )
}

export default Stats
