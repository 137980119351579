import { type ChipProps } from '@mui/material'

import { type LabelInterface } from 'src/entities/label/types/label'
import Label from 'src/shared/components/common/Label'

const SmallLabel = ({
  label,
  sx,
  ...props
}: {
  label: LabelInterface
} & Partial<Omit<ChipProps, 'label'>>) => (
  <Label
    color={label.color}
    label={label.name}
    size="small"
    sx={{
      height: 24,
      typography: 'labelExtrasmall',
      py: 0.5,
      px: 1.5,
      m: 0.25,
      ...sx,
    }}
    {...props}
  />
)

export default SmallLabel
