import { Alert, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type IntegrationFlow } from 'src/entities/integration/api/integrationFlowsApi'
import { type IntegrationName } from 'src/entities/integration/api/integrationsApi'
import { IntegrationModalBodyWrapper } from './IntegrationModalBodyWrapper'

interface InlayProps {
  integrationName: IntegrationName
  status: IntegrationFlow['status']
}

export const IntegrationErrorInlay = ({
  integrationName,
  status,
}: InlayProps) => {
  const { t } = useTranslation()

  return (
    <IntegrationModalBodyWrapper integrationName={integrationName}>
      <Alert severity="warning">
        {t(
          `settings.integrations.${integrationName}.errors.${status}.alert_text`,
          `settings.integrations.${integrationName}.errors.${status}.alert_text`,
        )}
      </Alert>
      <Typography>
        {t(
          `settings.integrations.${integrationName}.errors.${status}.body_text`,
          `settings.integrations.${integrationName}.errors.${status}.body_text`,
        )}
      </Typography>
    </IntegrationModalBodyWrapper>
  )
}
