import { createFileRoute } from '@tanstack/react-router'

import Rooms from 'src/pages/Rooms/Rooms'

export const Route = createFileRoute('/restaurants/$restaurantId/config/rooms')(
  {
    component: RouteComponent,
  },
)

function RouteComponent() {
  const { restaurantId } = Route.useParams()

  return <Rooms variant="book" restaurantId={restaurantId} />
}
