import { Stack, Typography } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { type ReservationStatsInterface } from 'src/entities/customer/types/customer'
import AsteriskIcon from 'src/shared/components/icons/AsteriskIcon'
import CancelIcon from 'src/shared/components/icons/CancelIcon'
import CheckedOutIcon from 'src/shared/components/icons/CheckedOutIcon'
import NoShowIcon from 'src/shared/components/icons/NoShowIcon'

interface SmallCustomerStatsProps {
  stats: ReservationStatsInterface
}

export const SmallCustomerStats = ({ stats }: SmallCustomerStatsProps) => (
  <Stack direction="row" alignItems="center" gap={1.25}>
    <Stack
      {...createTestIdProps('incoming-call-stats-future')}
      direction="row"
      alignItems="center"
      gap={0.25}
    >
      <AsteriskIcon size="small" />
      <Typography>{stats.future}</Typography>
    </Stack>
    <Stack
      {...createTestIdProps('incoming-call-stats-past')}
      direction="row"
      alignItems="center"
      gap={0.5}
    >
      <CheckedOutIcon size="small" />
      <Typography>{stats.past}</Typography>
    </Stack>
    <Stack
      {...createTestIdProps('incoming-call-stats-cancelled')}
      direction="row"
      alignItems="center"
      gap={0.5}
    >
      <CancelIcon size="small" />
      <Typography>{stats.cancelled}</Typography>
    </Stack>
    <Stack
      {...createTestIdProps('incoming-call-stats-noShow')}
      direction="row"
      alignItems="center"
      gap={0.5}
    >
      <NoShowIcon size="small" />
      <Typography>{stats.noShow}</Typography>
    </Stack>
  </Stack>
)
