import React from 'react'
import { Tooltip, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { useOccupanciesMutation } from 'src/entities/reservation/queries/reservationsMutations'
import { equalizeOverflowingSeatCount } from 'src/entities/reservation/services/occupancy'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { useTablesQuery } from 'src/entities/table/queries/table'
import ReservationDrawerInlineSection from 'src/shared/components/common/ReservationDrawerInlineSection'
import PickerGrid from 'src/shared/components/containers/PickerGrid'
import StepPicker from 'src/shared/components/form/inputs/StepPicker/StepPicker'
import { combine } from 'src/shared/lib/common/services/functional/functional'
import { interpolate } from 'src/shared/lib/common/services/math/math'
import { type DrawerColor } from 'src/widgets/SidebarDrawer/drawerColor'
import { SidebarDrawer } from 'src/widgets/SidebarDrawer/SidebarDrawer'
import SidebarHeader from 'src/widgets/SidebarDrawer/SidebarHeader'

interface SeatCountProps {
  reservation: ReservationInterface
  onSeatCountChange: (seatCount: number) => void
  open: boolean
  onOpen: () => void
  onClose: () => void
  drawerColor: DrawerColor
  assignSerie: boolean | undefined
  disabled: boolean
}

const seatCountOptions = interpolate(1, 1000)

const SeatCount = ({
  reservation,
  onSeatCountChange,
  open,
  onOpen: handleOpen,
  onClose: handleClose,
  drawerColor,
  assignSerie,
  disabled,
}: SeatCountProps) => {
  const { t } = useTranslation()

  const { data: tables } = useTablesQuery()

  const { mutate: setOccupancies } = useOccupanciesMutation()

  const seatCountChangeHandler = React.useCallback(
    (newSeatCount: number) => {
      onSeatCountChange(newSeatCount)
      if (reservation.exactMode) return

      const notOverflowingReservation = equalizeOverflowingSeatCount(tables)({
        ...reservation,
        seatCount: newSeatCount,
      })

      setOccupancies({
        reservation,
        occupancies: notOverflowingReservation.occupancies,
        wholeSerie: assignSerie,
      })
    },
    [assignSerie, tables, onSeatCountChange, reservation, setOccupancies],
  )

  const picker = (
    <StepPicker
      {...createTestIdProps('seat-count')}
      onChange={seatCountChangeHandler}
      value={reservation.seatCount}
      minValue={seatCountOptions[0]}
      maxValue={seatCountOptions.at(-1)}
      step={1}
      sx={{ bgcolor: 'white', maxWidth: ['none', 160] }}
      rendererFactory={() => ({ buttonProps: { onClick: handleOpen } })}
      disabled={disabled}
    />
  )

  const pickerWithTooltip = (
    <Tooltip
      title={t(
        'reservation_drawer.seat_count.errors.cannot_update_with_payment',
        'Cannot update PAX when reservation contains pre-payment.',
      )}
      enterTouchDelay={0}
      placement="bottom"
      arrow
    >
      {picker}
    </Tooltip>
  )

  return (
    <>
      <ReservationDrawerInlineSection title={t('angular.persons')}>
        {disabled ? pickerWithTooltip : picker}
      </ReservationDrawerInlineSection>
      <SidebarDrawer
        {...createTestIdProps('seat-count-drawer')}
        open={open && !disabled}
        onClose={handleClose}
        level={1}
        color={drawerColor}
        sx={{ overflow: 'hidden' }}
      >
        <SidebarHeader onClose={handleClose} color={drawerColor}>
          <Typography variant="labelBig">
            {t('angular.number_of_people')}
          </Typography>
        </SidebarHeader>
        <PickerGrid
          items={seatCountOptions}
          onItemClick={combine([handleClose, seatCountChangeHandler])}
        />
      </SidebarDrawer>
    </>
  )
}

export { SeatCount }
