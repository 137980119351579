import { useCallback } from 'react'
import { useMediaQuery, type Theme } from '@mui/material'

import { createFileRoute, Navigate } from '@tanstack/react-router'

import { SettingsSidebar } from 'src/pages/Settings/layout/SettingsSidebar'

export const Route = createFileRoute('/restaurants/$restaurantId/config/')({
  component: RouteComponent,
})

function RouteComponent() {
  const { restaurantId } = Route.useParams()
  const navigate = Route.useNavigate()

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'))

  const onBack = useCallback(
    () =>
      navigate({
        to: '/restaurants/$restaurantId/reservations',
        params: { restaurantId },
      }),
    [navigate, restaurantId],
  )

  if (isMobile)
    return <SettingsSidebar restaurantId={restaurantId} onBack={onBack} />

  return (
    <Navigate
      to="/restaurants/$restaurantId/config/general"
      params={{ restaurantId }}
      replace
    />
  )
}
