import { createFileRoute } from '@tanstack/react-router'

import Tables from 'src/pages/Settings/Tables'

export const Route = createFileRoute(
  '/restaurants/$restaurantId/config/tables',
)({
  component: RouteComponent,
})

function RouteComponent() {
  const { restaurantId } = Route.useParams()

  return <Tables restaurantId={restaurantId} />
}
