import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import { DndContext, type DragEndEvent } from '@dnd-kit/core'
import { arrayMove, SortableContext } from '@dnd-kit/sortable'
import debounce from 'debounce'
import { useTranslation } from 'react-i18next'

import useFeature from 'src/entities/info/hooks/useFeature'
import {
  useBatchEditRoomMutation,
  useDeleteRoomMutation,
  useEditRoomMutation,
} from 'src/entities/schedule/queries/schedule'
import {
  type Room,
  type ScheduleEvent,
  type Shift,
} from 'src/entities/schedule/types/scheduleApi'
import { useBasicDndSensors } from 'src/shared/lib/common/hooks/useDnd'
import { DraggableRoomItem } from './DraggableRoomItem'
import { useDefaultMutationHandlers } from '../../ShiftConfiguration/hooks/useDefaultMutationHandlers'

interface RoomTableProps {
  rooms: Room[]
  shifts: Shift[]
  events: ScheduleEvent[]
  onEdit: (room: Room) => void
}

export const RoomTable = ({
  rooms,
  shifts,
  events,
  onEdit,
}: RoomTableProps) => {
  const { t } = useTranslation()

  const widgetEnabled = useFeature('widgetBooking')

  const [orderedRooms, setOrderedRooms] = useState(
    rooms.sort((a, b) => a.position - b.position),
  )
  const { mutate: editRoom, isPending: isEditPending } = useEditRoomMutation()
  const { mutate: batchEdit, isPending: isBatchPending } =
    useBatchEditRoomMutation()
  const { mutateAsync: deleteRoom, isPending: isDeletePending } =
    useDeleteRoomMutation()

  const isAnyPending = isEditPending || isDeletePending || isBatchPending

  const deboucedBatchEdit = useMemo(
    () => debounce(batchEdit, 3000),
    [batchEdit],
  )

  const [isBeingReordered, setIsBeingReordered] = useState(false)

  useEffect(() => {
    if (!isBatchPending) setIsBeingReordered(false)
  }, [isBatchPending])

  const orderChangedHandler = useDefaultMutationHandlers({
    successMessage: t('settings.common.order_changed', 'Order changed'),
  })
  const editHandler = useDefaultMutationHandlers({
    successMessage: t('schedule.rooms.common.room_changed', 'Room changed'),
  })
  const deleteHandler = useDefaultMutationHandlers({
    successMessage: t('schedule.rooms.common.room_deleted', 'Room removed'),
  })

  useEffect(() => {
    setOrderedRooms(rooms.sort((a, b) => a.position - b.position))
  }, [rooms])

  useEffect(() => {
    const modifiedRooms: Room[] = []
    orderedRooms.forEach(or => {
      const pos = rooms.find(r => or.id === r.id)?.position

      if (pos !== or.position) modifiedRooms.push(or)
    })

    if (!modifiedRooms.length) return

    setIsBeingReordered(true)
    deboucedBatchEdit(modifiedRooms, orderChangedHandler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deboucedBatchEdit, orderChangedHandler, orderedRooms])

  const sensors = useBasicDndSensors()

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event

    if (!over || active.id === over.id) return

    setOrderedRooms(oldRooms => {
      const oldIndex = oldRooms.findIndex(r => r.id === active.id)
      const newIndex = oldRooms.findIndex(r => r.id === over.id)

      return arrayMove(oldRooms, oldIndex, newIndex).map((r, idx) => ({
        ...r,
        position: idx + 1,
      }))
    })
  }, [])

  const hasMoreThanOneOnline = rooms.filter(rm => rm.selectable).length > 1

  return (
    <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
      <TableContainer>
        <Table stickyHeader sx={{ overflow: 'clip' }}>
          <TableHead sx={{ borderColor: 'inherit' }}>
            <TableRow>
              <TableCell>{t('schedule.rooms.name', 'Room name')}</TableCell>
              <TableCell>
                {t('schedule.rooms.online_availability', 'Online availability')}
              </TableCell>
              {widgetEnabled && (
                <TableCell>
                  {t('schedule.rooms.reservations_link', 'Reservations link')}
                </TableCell>
              )}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody sx={{ borderColor: 'inherit' }}>
            <SortableContext items={orderedRooms} disabled={isAnyPending}>
              {orderedRooms.map(r => (
                <DraggableRoomItem
                  key={r.id}
                  room={r}
                  showWidgetLink={widgetEnabled}
                  isNotInShift={
                    !(
                      shifts.some(s => s.roomId === r.id) ||
                      events.some(s => s.roomId === r.id)
                    )
                  }
                  onlineSwitchDisabled={
                    (!hasMoreThanOneOnline && r.selectable) ||
                    isEditPending ||
                    isBeingReordered
                  }
                  isBeingReordered={isBeingReordered}
                  isReorderingDisabled={isAnyPending}
                  onSwitchClick={() =>
                    editRoom({ ...r, selectable: !r.selectable }, editHandler)
                  }
                  onEdit={() => onEdit(r)}
                  onDelete={() => deleteRoom(r, deleteHandler)}
                />
              ))}
            </SortableContext>
          </TableBody>
        </Table>
      </TableContainer>
    </DndContext>
  )
}
