import { Typography, type TypographyProps } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { mergeSx } from 'src/app/theme/helpers'
import dayjs, {
  getNowInRestaurantTimezone,
} from '../../lib/range/services/date'

interface DateFromNowProps extends TypographyProps {
  date: Date
}

const DateFromNow = ({ date, sx, ...props }: DateFromNowProps) => {
  const dayjsDate = dayjs(date)

  return (
    <Typography
      {...createTestIdProps('date-from-now')}
      variant="body3"
      sx={mergeSx(theme => ({ color: theme.palette.neutral.main }), sx)}
      {...props}
    >
      {`${dayjsDate.from(getNowInRestaurantTimezone())} (${dayjsDate.format('llll')})`}
    </Typography>
  )
}

export default DateFromNow
