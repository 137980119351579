import { z } from 'zod'

const translatedSchema = z.object({
  textDe: z.string(),
  textFr: z.string(),
  textIt: z.string(),
  textEn: z.string(),
})

export const widgetWelcomeMessageSchama = translatedSchema
export const widgetGuestMessageSchema = translatedSchema

export type WidgetWelcomeMessage = z.infer<typeof widgetWelcomeMessageSchama>
export type WidgetGuestComment = z.infer<typeof widgetGuestMessageSchema>
