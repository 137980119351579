import { useMemo, useState } from 'react'
import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { combine } from 'src/shared/lib/common/services/functional/functional'
import Picker from './Picker'
import Swatch from './Swatch'
import { Colors } from '../../../../lib/style/types/color'
import PencilIcon from '../../../icons/PencilIcon'
import PlusIcon from '../../../icons/PlusIcon'

interface ColorPickerProps {
  colors: string[]
  onChange: (color: string) => void
  value: string
}

const getColors = (colors: string[]) => {
  const predefinedColors = Object.values(Colors).map(c => c.toUpperCase())
  const customColors = [...new Set(colors)]
    .filter(c => !predefinedColors.includes(c.toUpperCase()))
    .map(c => c.toUpperCase())

  return {
    predefinedColors,
    customColors,
  }
}

const ColorPicker = ({ colors, onChange, value }: ColorPickerProps) => {
  const { t } = useTranslation()

  const { customColors, predefinedColors } = useMemo(
    () => getColors(colors),
    [colors],
  )

  const [newColor, setNewColor] = useState<string>('')

  const setNewCustomColor = useMemo(
    () => combine([onChange, setNewColor]),
    [onChange],
  )

  return (
    <Stack gap={1.5}>
      {!!colors?.length && (
        <Stack gap={0.5}>
          <Typography variant="labelExtrasmall">
            {t('common.color_picker.preset_colors', 'Preset colors')}:
          </Typography>
          <Stack direction="row" alignItems="center" gap={0.5} flexWrap="wrap">
            {predefinedColors.map(c => (
              <Swatch
                key={c}
                color={c}
                onClick={onChange}
                selected={value.toUpperCase() === c}
              />
            ))}
          </Stack>
        </Stack>
      )}
      <Stack gap={0.5}>
        <Typography variant="labelExtrasmall">
          {t('common.color_picker.custom_colors', 'Custom colors')}:
        </Typography>
        <Stack direction="row" alignItems="center" gap={0.5} flexWrap="wrap">
          {customColors.map(c => (
            <Swatch
              key={c}
              color={c}
              onClick={onChange}
              selected={value.toUpperCase() === c}
            />
          ))}
          {newColor && (
            <Swatch
              key={newColor}
              color={newColor}
              selected={newColor === value}
              onClick={onChange}
            />
          )}
          <Picker value={newColor} onChange={setNewCustomColor}>
            {newColor ? (
              <PencilIcon />
            ) : (
              <PlusIcon onClick={() => setNewCustomColor('#A12345')} />
            )}
          </Picker>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ColorPicker
