import { useCallback, useState, type ReactNode } from 'react'
import { Button, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useScheduleQuery } from 'src/entities/schedule/queries/schedule'
import { type Room } from 'src/entities/schedule/types/scheduleApi'
import { SettingsScreensEnum } from 'src/entities/setting/types/settings'
import EmptyView from 'src/shared/components/common/EmptyView'
import Modal from 'src/shared/components/common/Modal'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import PlusIcon from 'src/shared/components/icons/PlusIcon'
import { RoomForm } from './components/RoomForm'
import { RoomTable } from './components/RoomTable'
import { ConfigScroll } from '../Settings/layout/ConfigScroll'
import { SettingsHeader } from '../Settings/layout/SettingsHeader'

type RoomsProps =
  | {
      variant: 'book'
      restaurantId: number
    }
  | {
      variant: 'schedule'
    }

const Rooms = (props: RoomsProps) => {
  const { t } = useTranslation()

  const {
    data: {
      rooms,
      shifts,
      events,
      scheduleFeatures: { rooms: featureEnabled },
    },
  } = useScheduleQuery()

  const { handleClose: onClose, handleOpen, open } = useDialog()

  const [editedRoom, setEditedRoom] = useState<Room | null>(null)

  const handleClose = useCallback(() => {
    onClose()
    setEditedRoom(null)
  }, [onClose])

  const hasMoreThanOneOnline = rooms.filter(rm => rm.selectable).length > 1

  const hasRooms = !!rooms.length

  const onlineDeselectionDisabled =
    (!!editedRoom && !hasMoreThanOneOnline) || (!editedRoom && !hasRooms)

  const children = (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {featureEnabled ? (
        hasRooms ? (
          <RoomTable
            rooms={rooms}
            shifts={shifts}
            events={events}
            onEdit={room => {
              setEditedRoom(room)
              handleOpen()
            }}
          />
        ) : (
          <EmptyView text={t('rooms.no_results', 'No rooms defined.')} />
        )
      ) : (
        <EmptyView
          text={t(
            'rooms.feature_disabled',
            'Rooms feature is disabled. Enable it to add rooms.',
          )}
        />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        title={
          editedRoom
            ? t('schedule.rooms.edit', 'Edit room')
            : t('schedule.rooms.create', 'Create new room')
        }
      >
        <RoomForm
          rooms={rooms}
          room={editedRoom}
          onlineDeselectionDisabled={onlineDeselectionDisabled}
          onClose={handleClose}
        />
      </Modal>
    </>
  )

  const { variant } = props

  if (variant === 'schedule') {
    return (
      <ScheduleWrapper featureEnabled={featureEnabled} onAddNew={handleOpen}>
        {children}
      </ScheduleWrapper>
    )
  }

  const { restaurantId } = props

  return (
    <BookWrapper
      restaurantId={restaurantId}
      featureEnabled={featureEnabled}
      onAddNew={handleOpen}
    >
      {children}
    </BookWrapper>
  )
}

interface ScheduleWrapperProps {
  featureEnabled: boolean
  children: ReactNode
  onAddNew: () => void
}

function ScheduleWrapper({
  children,
  featureEnabled,
  onAddNew,
}: ScheduleWrapperProps) {
  const { t } = useTranslation()

  return (
    <Stack gap={3.5}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography component="h3" variant="labelHuge">
          {t('settings.tab_names.rooms', 'Rooms')}
        </Typography>
        {featureEnabled && (
          <Button
            startIcon={<PlusIcon />}
            variant="contained"
            onClick={onAddNew}
          >
            {t('schedule.rooms.new_room', 'New room')}
          </Button>
        )}
      </Stack>
      {children}
    </Stack>
  )
}

interface BookWrapperProps extends ScheduleWrapperProps {
  restaurantId: number
}

function BookWrapper({
  children,
  featureEnabled,
  onAddNew,
  restaurantId,
}: BookWrapperProps) {
  const { t } = useTranslation()

  return (
    <>
      <SettingsHeader
        restaurantId={restaurantId}
        screen={SettingsScreensEnum.Rooms}
        primaryButtonProps={
          featureEnabled
            ? {
                onClick: onAddNew,
                children: t('schedule.rooms.new_room', 'New room'),
              }
            : undefined
        }
      />
      <ConfigScroll>{children}</ConfigScroll>
    </>
  )
}

export default Rooms
