import React from 'react'

import {
  calculateVisibleItemsCount,
  isIndicatorRequired,
} from './service/itemVisibility'
import { useItemVisibility } from '../../../../lib/intersection-observer/hooks/useItemVisibility'
import { useNonIntersectingItemsCount } from '../../../../lib/intersection-observer/hooks/useNonIntersectingItemsCount'

export const useVisibleItemsWithCounterCount = (
  totalItemsCount: number,
  containerRef: React.RefObject<HTMLElement | null>,
): {
  itemRef: React.RefCallback<HTMLElement>
  counterRef: React.RefCallback<HTMLElement>
  visibleItemsCount: number
  nonIntersectingItemsCount: number
  counterIsShown: boolean
} => {
  const { itemsCount: nonIntersectingItemsCount, itemRef } =
    useNonIntersectingItemsCount(containerRef)

  const { isVisible: counterVisibility, itemRef: counterRef } =
    useItemVisibility(containerRef)

  const [visibleItemsCount, setVisibleItemsCount] = React.useState(0)
  const [counterIsShown, setCounterIsShown] = React.useState(false)

  React.useEffect(() => {
    setVisibleItemsCount(
      calculateVisibleItemsCount(
        totalItemsCount,
        nonIntersectingItemsCount,
        counterVisibility,
      ),
    )
    setCounterIsShown(
      isIndicatorRequired(nonIntersectingItemsCount, totalItemsCount),
    )
  }, [counterVisibility, nonIntersectingItemsCount, totalItemsCount])

  return {
    itemRef,
    counterRef,
    visibleItemsCount,
    nonIntersectingItemsCount,
    counterIsShown,
  }
}
