import { startTransition } from 'react'
import { equals } from 'ramda'

import { useIsMutating, useMutation } from '@tanstack/react-query'
import debounce from 'debounce'
import { useTranslation } from 'react-i18next'

import { showToast } from 'src/shared/components/common/toast'
import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'
import type { ApiClient } from 'src/shared/lib/api/services/api'
import { assertMeaningfulError, isApiError } from 'src/shared/lib/api/types/api'
import { captureException } from 'src/shared/lib/context/global/sentry'
import {
  useResetInitialAtom,
  useSetOpenReservationOccupancies,
  useSetPristineOpenReservationOccupancies,
  useSetSelectedReservation,
  useSetSelectedReservationGuest,
} from 'src/shared/lib/context/state/atoms/selectedReservation'
import { getNowInRestaurantTimezone } from 'src/shared/lib/range/services/date'
import { isNotFalsy } from 'src/shared/lib/zod/zod'
import {
  RESERVATION_KEY,
  useCheckInStatusOptimisticUpdate,
  useEnsureReservation,
  useOccupanciesOptimisticUpdate,
  useReservationCachePopulation,
  useReservationOptimisticUpdate,
  useReservationsSearchInvalidation,
} from './reservation'
import {
  useCustomerAddMutation,
  useFetchCustomer,
} from '../../customer/queries/customer'
import { emptyCustomer } from '../../customer/services/customerFactory'
import {
  TableCheckInStatusEnum,
  type TableOccupancyInterface,
} from '../../table/types/table'
import { tableStatusFromCheckInStatus } from '../services/checkInStatus'
import { happensOnDay, isInFuture } from '../services/reservation'
import {
  addReservations,
  cancelReservation,
  changeRestaurantOfReservation,
  createReservation,
  editReservation,
  lockReservation,
  reactivateReservation,
  saveOccupancies,
  sendMessage,
  setExactMode,
  setReservationCheckInStatus,
  setReservationLink,
  type CreateReservation,
  type UpdateReservation,
} from '../services/reservationApi'
import {
  ReservationCheckInStatusEnum,
  type ReservationInterface,
} from '../types/reservation'
import type { ReservationLink } from '../types/reservationApi'

export const useAddReservationsMutation = () => {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: addReservations(apiClient.post),
  })
}
export const useUpdateReservationLinkMutation = () => {
  const { post } = useApiClient()

  return useMutation<
    void,
    unknown,
    { reservationId: ReservationInterface['id']; link: ReservationLink }
  >({
    mutationFn: setReservationLink(post),
  })
}
export const useChangeRestaurantOfReservationMutation = () => {
  const apiClient = useApiClient()
  const invalidateSearch = useReservationsSearchInvalidation()

  return useMutation({
    mutationFn: changeRestaurantOfReservation(apiClient.post),
    onSuccess: () => invalidateSearch(),
  })
}

const createDebouncedOccupancyUpdater = (httpClient: ApiClient) =>
  debounce(saveOccupancies(httpClient), 5000)

const occupanciesRequests = new Map<
  ReservationInterface['id'],
  ReturnType<typeof createDebouncedOccupancyUpdater>
>()

export const useOccupanciesMutation = () => {
  const apiClient = useApiClient()
  const optimisticUpdate = useOccupanciesOptimisticUpdate()
  const setOpenReservationOccupancies = useSetOpenReservationOccupancies()

  return useMutation({
    mutationFn: ({
      reservation,
      occupancies,
      wholeSerie,
      immediate,
    }: {
      reservation: ReservationInterface
      occupancies: TableOccupancyInterface[]
      wholeSerie: boolean | undefined
      immediate?: boolean
    }) => {
      if (!reservation.id) return Promise.resolve(reservation)

      const pendingUpdater = occupanciesRequests.get(reservation.id)

      if (equals(reservation.occupancies, occupancies)) {
        if (immediate && pendingUpdater) {
          pendingUpdater.flush()
          occupanciesRequests.delete(reservation.id)
        }

        return Promise.resolve(reservation)
      }

      const updater =
        pendingUpdater ?? createDebouncedOccupancyUpdater(apiClient.post)

      occupanciesRequests.set(reservation.id, updater)

      if (immediate) {
        updater.clear()
        occupanciesRequests.delete(reservation.id)
        return saveOccupancies(apiClient.post)(
          reservation,
          occupancies,
          wholeSerie ?? false,
        )
      }

      return updater(reservation, occupancies, wholeSerie ?? false)!
    },
    onMutate: ({ reservation, occupancies }) => {
      startTransition(() => {
        setOpenReservationOccupancies(occupancies)
        optimisticUpdate(reservation.id, occupancies)
      })
    },
  })
}
export const useReservationCheckInStatusMutation = () => {
  const apiClient = useApiClient()
  const updateCheckInStatus = useCheckInStatusOptimisticUpdate()

  return useMutation({
    mutationFn: setReservationCheckInStatus(apiClient.post),
    onMutate: ({ reservation, status }) =>
      updateCheckInStatus(
        reservation.id,
        undefined,
        tableStatusFromCheckInStatus(status),
      ),
  })
}
export const useSetExactModeMutation = () => {
  const apiClient = useApiClient()
  const updateReservation = useReservationOptimisticUpdate()

  return useMutation({
    mutationFn: ({
      reservation,
      exactMode,
    }: {
      reservation: ReservationInterface
      exactMode: boolean
    }) => setExactMode(apiClient.post)(reservation, exactMode),
    onMutate: ({ reservation, exactMode }) =>
      updateReservation(reservation.id, r => ({ ...r, exactMode })),
  })
}

const RESERVATION_MUTATION_KEY = [...RESERVATION_KEY, 'mutation']
export const useCancelReservationMutation = () => {
  const apiClient = useApiClient()

  return useMutation({
    mutationKey: [...RESERVATION_MUTATION_KEY, 'cancel'],
    mutationFn: cancelReservation(apiClient.post),
  })
}
export const useReactivateReservationMutation = () => {
  const apiClient = useApiClient()

  return useMutation({
    mutationKey: [...RESERVATION_MUTATION_KEY, 'reactivate'],
    mutationFn: reactivateReservation(apiClient.post),
  })
}
export const useCreateReservationMutation = () => {
  const apiClient = useApiClient()
  const ensureReservation = useEnsureReservation()
  const { t } = useTranslation()

  const setOriginalOccupancies = useSetPristineOpenReservationOccupancies()
  const setSelectedReservation = useSetSelectedReservation()
  const setGuest = useSetSelectedReservationGuest()

  const { mutateAsync: mutateOccupancies } = useOccupanciesMutation()
  const { mutateAsync: mutateStatus } = useReservationCheckInStatusMutation()
  const { mutateAsync: mutateExactMode } = useSetExactModeMutation()
  const { mutateAsync: addCustomer } = useCustomerAddMutation()

  const fetchCustomer = useFetchCustomer()

  return useMutation({
    mutationKey: [...RESERVATION_MUTATION_KEY, 'create'],
    mutationFn: async (initialPayload: CreateReservation) => {
      let payload = initialPayload

      if (payload.reservation.newCustomer) {
        const response = await addCustomer({
          ...emptyCustomer,
          ...payload.reservation.newCustomer,
        })

        const newCustomer = await fetchCustomer(response.customer_id)

        if (!newCustomer.id) {
          showToast(
            t('common.something_went_wrong', 'Something went wrong'),
            'error',
            'Customer cannot be fetched',
          )
          return undefined
        }

        const newReservation = await setGuest(newCustomer)

        payload = { ...payload, reservation: newReservation }
      }

      const { reservation } = payload
      const response = await createReservation(apiClient.post)(payload)

      if (isApiError(response)) {
        assertMeaningfulError(response)

        showToast(
          t('angular.message_not_saved', {
            error: t(
              `api_error.reservation.create.${response.message}`,
              response.longMessage ?? response.message,
            ),
          }),
          'error',
        )

        return undefined
      }

      const createdReservation = (await ensureReservation(response.id))!

      if (!createdReservation) {
        captureException('Something went wrong ensuring reservation')
      }

      setSelectedReservation(createdReservation)

      const willCheckIn =
        reservation.occupancies.length &&
        !(
          isInFuture(createdReservation, 'minutes', 15) ||
          !happensOnDay(getNowInRestaurantTimezone())(createdReservation)
        )

      const originalOccupancies = willCheckIn
        ? reservation.occupancies.map(occupancy => ({
            ...occupancy,
            checkInStatus: TableCheckInStatusEnum.CheckedIn,
          }))
        : reservation.occupancies

      setOriginalOccupancies(originalOccupancies)

      const promises = [
        reservation.exactMode &&
          mutateExactMode({
            reservation: createdReservation,
            exactMode: reservation.exactMode,
          }),
        reservation.occupancies.length &&
          mutateOccupancies({
            reservation: { ...createdReservation, occupancies: [] },
            occupancies: originalOccupancies,
            wholeSerie: false,
            immediate: true,
          }).then(() => {
            if (!willCheckIn) return Promise.resolve()

            return mutateStatus({
              reservation: createdReservation,
              status: ReservationCheckInStatusEnum.CheckedIn,
            })
          }),
      ].filter(isNotFalsy)

      await Promise.all(promises)

      return response
    },
  })
}
export const useUpdateReservationMutation = () => {
  const { t } = useTranslation()

  const apiClient = useApiClient()

  const populateReservation = useReservationCachePopulation()

  const resetInitialReservation = useResetInitialAtom()
  const setOriginalOccupancies = useSetPristineOpenReservationOccupancies()

  return useMutation({
    mutationKey: [...RESERVATION_MUTATION_KEY, 'update'],
    mutationFn: async (payload: UpdateReservation) => {
      const response = await editReservation(apiClient.post)(payload)

      if (!isApiError(response)) return true

      assertMeaningfulError(response)

      showToast(
        t('angular.message_not_mutated', {
          error: t(
            `api_error.reservation.update.${response.message}`,
            response.longMessage ?? response.message,
          ),
        }),
        'error',
      )

      return undefined
    },
    onSuccess: (response, { reservation }) => {
      if (!response) return

      resetInitialReservation()
      populateReservation(reservation)

      setOriginalOccupancies(reservation.occupancies)
    },
  })
}

export const useReservationLockMutation = () => {
  const apiClient = useApiClient()

  const updateReservation = useReservationOptimisticUpdate()

  return useMutation({
    mutationFn: lockReservation(apiClient.post),
    onSuccess: (_response, { reservation, locked }) =>
      updateReservation(reservation.id, r => ({ ...r, locked })),
  })
}

export const useIsReservationMutating = () =>
  !!useIsMutating({
    mutationKey: RESERVATION_MUTATION_KEY,
  })

export const useSendMessageMutation = () => {
  const apiClient = useApiClient()

  return useMutation({
    mutationKey: [...RESERVATION_MUTATION_KEY, 'sendMessage'],
    mutationFn: sendMessage(apiClient.post),
  })
}
