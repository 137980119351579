import { type Context } from 'react'

import useReducerService from '../../reducer/hooks/useReducerService'

const withReducerServiceProvider =
  <
    Service extends { [K in keyof Service]: (...args: unknown[]) => State },
    State,
  >(
    ActionContext: Context<Service>,
    StateContext: Context<State>,
    reducer: (state: State) => Service,
    initialState: State,
  ) =>
  <Props extends object>(Component: React.ComponentType<Props>) =>
  (props: Props) => {
    const { state, actions } = useReducerService(reducer, initialState)

    return (
      <ActionContext value={actions}>
        <StateContext value={state}>
          <Component {...props} />
        </StateContext>
      </ActionContext>
    )
  }

export default withReducerServiceProvider
