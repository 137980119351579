import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type ExtendedFeedbackInterface } from 'src/entities/feedback/types/feedbackApi'
import { formatName } from 'src/entities/reservation/services/reservation'
import PrimaryDivider from 'src/shared/components/common/PrimaryDivider'
import { formatShortDatetime } from 'src/shared/lib/range/services/date'
import { ReservationSeatCount } from 'src/widgets/Tables/SlotInfo/ReservationSeatCount'

interface FeedbackModalReservationDetailsProps {
  feedback: ExtendedFeedbackInterface
}

export const FeedbackModalReservationDetails = ({
  feedback,
}: FeedbackModalReservationDetailsProps) => {
  const { t } = useTranslation()

  if (!feedback?.reservation) return null

  const guestOrCustomer = feedback.customer ?? feedback.reservation.guest

  return (
    <Stack gap={0.25}>
      <Typography variant="labelExtrasmall" color="primary">
        {t('feedbacks.modal.reservation_details', 'Reservation details')}
      </Typography>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="labelSmall">
          {formatShortDatetime(feedback.reservation.dateRange[0])}
        </Typography>
        <ReservationSeatCount
          color="neutral"
          sx={{ width: 20, height: 20, typography: 'labelSmall' }}
          label={3}
        />
      </Stack>
      <Stack
        direction="row"
        gap={1}
        divider={
          <PrimaryDivider orientation="vertical" variant="middle" flexItem />
        }
      >
        {guestOrCustomer.companyName && (
          <Typography variant="body3">{guestOrCustomer.companyName}</Typography>
        )}
        <Typography variant="body3">{formatName(guestOrCustomer)}</Typography>
      </Stack>
    </Stack>
  )
}
