import { useEffect, useRef, useState, type Ref } from 'react'
import { debounce, InputAdornment, TextField } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { useImperativeHandleFocus } from '../../../../lib/common/hooks/useImperativeHandleFocus'
import { withTargetValue } from '../../../../lib/common/services/helpers/helpers'
import IconButton from '../../../buttons/IconButton'
import CloseIcon from '../../../icons/CloseIcon'
import SearchIcon from '../../../icons/SearchIcon'

interface SearchInputProps {
  onSearch: (searchPhrase: string) => void
  initialValue?: string
  placeholder: string
  ref?: Ref<HTMLDivElement>
}

const MIN_LENGTH = 3

const SearchInput = ({
  onSearch,
  initialValue,
  placeholder,
  ref = null,
}: SearchInputProps) => {
  const [error, setError] = useState(false)
  const [value, setValue] = useState<string>(initialValue ?? '')

  useEffect(() => {
    setValue(initialValue ?? '')
  }, [initialValue])

  const changeHandler = (searchPhrase: string) => {
    const isLongEnough = searchPhrase.length >= MIN_LENGTH
    const isEmpty = !searchPhrase?.length
    setError(!isLongEnough && !isEmpty)
    onSearch(isLongEnough ? searchPhrase : '')
  }

  const inputRef = useRef<HTMLInputElement>(null)

  useImperativeHandleFocus(ref, inputRef)

  const debouncedChangeHandler = useRef(debounce(changeHandler, 500))
  useEffect(() => {
    debouncedChangeHandler.current(value)
  }, [value])

  return (
    <TextField
      {...createTestIdProps('search-phrase-input')}
      inputRef={inputRef}
      variant="outlined"
      size="large"
      autoFocus
      onChange={withTargetValue(setValue)}
      value={value}
      fullWidth
      placeholder={placeholder}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="end" disablePointerEvents>
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="start">
              <IconButton
                size="small"
                variant="text"
                color="neutral"
                onClick={() => setValue('')}
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        },
      }}
      error={error}
    />
  )
}

export default SearchInput
