import { useCallback } from 'react'
import { compose, map, prop } from 'ramda'

import {
  useQueryClient,
  type DefaultError,
  type UseSuspenseQueryOptions,
} from '@tanstack/react-query'

import { type FurnitureInterface } from 'src/widgets/FloorPlan/types/floorPlanElement'
import { useConfigCacheKey, useConfigQuery } from '../../config/queries/config'
import { type ApiConfig } from '../../config/types/configApi'
import { furnitureFromApi } from '../../table/types/tableApi'

const furnitureQueryOptions = {
  select: compose(map(furnitureFromApi), prop('room_elements')),
} satisfies Partial<
  UseSuspenseQueryOptions<ApiConfig, DefaultError, FurnitureInterface[]>
>

export const useEnsureFurniture = () => {
  const queryClient = useQueryClient()
  const queryKey = useConfigCacheKey()

  return useCallback(
    async () =>
      furnitureQueryOptions.select(
        await queryClient.ensureQueryData({ queryKey }),
      ),
    [queryClient, queryKey],
  )
}

export const useFurnitureQuery = () => useConfigQuery(furnitureQueryOptions)
