import { DragIndicator } from '@mui/icons-material'
import { Box, type BoxProps } from '@mui/material'

interface DragHandleProps extends BoxProps {
  disabled?: boolean
}

const DragHandle = ({ disabled, ...props }: DragHandleProps) => (
  <Box {...props}>
    <DragIndicator
      sx={{
        cursor: 'move',
        height: 1,
        px: 0.5,
        boxSizing: 'content-box',
        opacity: disabled ? 0.4 : undefined,
      }}
    />
  </Box>
)

export default DragHandle
