import {
  useMutation,
  useQueryClient,
  type DefaultError,
  type UseSuspenseQueryOptions,
} from '@tanstack/react-query'

import { type ApiConfig } from 'src/entities/config/types/configApi'
import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'
import { crudQueriesFactory } from 'src/shared/lib/api/queries/crudQueriesFactory'
import {
  crudApiFactory,
  type RequestUrlsInterface,
  type ResponseHandlersInterface,
} from 'src/shared/lib/api/services/crudApiFactory'
import {
  CONFIG_CACHE_KEY,
  useConfigCacheKey,
  useConfigQuery,
  useConfigRestaurantsQueries,
} from '../../config/queries/config'
import { setAreasOrder } from '../api/areaApi'
import { type ApiAreaInterface } from '../types/area'
import { type EmptyArea } from '../types/areaApi'

const urls: RequestUrlsInterface = {
  set: 'addArea',
  delete: 'deleteArea',
}

const payloadFactory = (area: ApiAreaInterface | EmptyArea) => ({
  area_id: area.id ?? null,
  name: area.name,
  room_id: area.roomId,
  capacity: area.capacity,
  days: area.days,
})

const handlers: ResponseHandlersInterface<
  ApiAreaInterface | EmptyArea,
  ReturnType<typeof payloadFactory>
> = {
  set: d => d.area_id,
}

const areaApi = crudApiFactory(urls, handlers, payloadFactory)
const queryHooks = crudQueriesFactory(CONFIG_CACHE_KEY, areaApi)

export const useDeleteAreaMutation = queryHooks.useItemDeleteMutation
export const useUpsertAreaMutation = queryHooks.useItemUpdateMutation

const areasQueryOptions: Partial<
  UseSuspenseQueryOptions<ApiConfig, DefaultError, ApiAreaInterface[]>
> = { select: d => d.areas }

const getAreasQueryOptions = () => areasQueryOptions

export const useAreasQuery = () => useConfigQuery(areasQueryOptions)
export const useChainAreas = () =>
  useConfigRestaurantsQueries(getAreasQueryOptions)

export const useAreasOrderUpdate = () => {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()
  const cacheKey = useConfigCacheKey()

  return useMutation({
    mutationFn: setAreasOrder(apiClient.post),
    onMutate: () => queryClient.cancelQueries({ queryKey: cacheKey }),
    onSettled: () => queryClient.invalidateQueries({ queryKey: cacheKey }),
  })
}
