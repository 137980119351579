import React, { useImperativeHandle } from 'react'
import {
  outlinedInputClasses,
  type InputProps,
  type SxProps,
} from '@mui/material'

import { mergeSx } from 'src/app/theme/helpers'
import { combine } from 'src/shared/lib/common/services/functional/functional'
import { withTargetValue } from '../../../../lib/common/services/helpers/helpers'
import FormTextInput, { type FormTextInputProps } from '../FormTextInput'
import { useStepPicker } from '../StepInput/useStepPicker'

export interface NumericInputElement extends HTMLInputElement {
  inc: () => void
  dec: () => void
}

export type NumericInputProps = {
  value?: number
  unit?: React.ReactNode
  readOnly?: boolean
  onClick?: () => void
  minValue?: number
  maxValue?: number
  onChange: (newValue: number) => void
  textAlign?: 'center' | 'left' | 'right'
  step?: number
} & Omit<
  FormTextInputProps,
  'value' | 'onClick' | 'onChange' | 'formControlProps'
>

const NumericInput = ({
  readOnly,
  onClick,
  slotProps,
  sx,
  minValue = 0,
  maxValue = Number.MAX_SAFE_INTEGER,
  onChange,
  value,
  unit,
  step = 1,
  textAlign = 'left',
  ref,
  ...props
}: NumericInputProps) => {
  const readOnlyStyle = {
    ...(readOnly && {
      cursor: 'pointer',
    }),
  }

  const { displayedValue, changeHandler, inc, dec, forceUpdate } =
    useStepPicker({
      step,
      minValue,
      maxValue,
      value,
      onChange,
    })

  const innerRef = React.useRef<NumericInputElement>(null)
  useImperativeHandle(ref, () => ({ ...innerRef.current!, inc, dec }), [
    inc,
    dec,
  ])

  return (
    <FormTextInput
      inputRef={innerRef}
      variant="outlined"
      inputMode="numeric"
      type="number"
      onWheel={e => (e.target as Partial<HTMLDivElement>).blur?.()}
      value={String(displayedValue)}
      onBlur={forceUpdate}
      onChange={combine([
        _ => innerRef.current?.focus(),
        withTargetValue(changeHandler),
      ])}
      slotProps={{
        ...slotProps,
        input: {
          ...slotProps?.input,
          readOnly,
          sx: mergeSx(readOnlyStyle, (slotProps?.input as InputProps)?.sx),
          onClick,
        },
        htmlInput: {
          ...slotProps?.htmlInput,
          sx: mergeSx(
            readOnlyStyle,
            unit
              ? {
                  width: `calc(${String(displayedValue).length}ch + 10px)`,
                }
              : null,
            (slotProps?.htmlInput as InputProps)?.sx as SxProps,
          ),
        },
      }}
      sx={mergeSx(
        {
          input: {
            '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
              appearance: 'none',
              margin: 0,
            },
            textAlign,
            MozAppearance: 'textfield',
          },
          [`.${outlinedInputClasses.root}`]: {
            p: 0,
          },
          ...readOnlyStyle,
          flex: unit ? 1 : undefined,
        },
        sx,
      )}
      formControlProps={unit ? { sx: { flex: 1 } } : undefined}
      {...props}
    />
  )
}

export default NumericInput
