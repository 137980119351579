import { useBlocker } from '@tanstack/react-router'
import { noop } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import {
  SimpleDialog,
  type SimpleDialogProps,
} from 'src/shared/components/dialogs/SimpleDialog/SimpleDialog'

interface UnsavedChangesDialogProps
  extends Omit<SimpleDialogProps, 'open' | 'onClose'> {
  hasChanges: boolean
}

export const UnsavedChangesDialog = ({
  hasChanges,
  ...rest
}: UnsavedChangesDialogProps) => {
  const { t } = useTranslation()
  const { proceed, reset, status } = useBlocker({
    shouldBlockFn: () => hasChanges,
    withResolver: true,
  })

  return (
    <SimpleDialog
      open={status === 'blocked'}
      title={t(
        'common.unsaved_changes_modal.title',
        'You have unsaved changes!',
      )}
      subtitle={t(
        'common.unsaved_changes_modal.subtitle',
        'It looks like you’ve made some edits that haven’t been saved yet. What would you like to do?',
      )}
      cancelButtonText={t(
        'common.unsaved_changes_modal.cancel',
        'Stay on page',
      )}
      confirmButtonText={t(
        'common.unsaved_changes_modal.confirm',
        'Leave without saving',
      )}
      onClose={reset ?? noop}
      onConfirmation={proceed}
      {...rest}
    />
  )
}
