import { useState } from 'react'
import { isEmpty } from 'ramda'

import { diff } from 'deep-object-diff'
import { useTranslation } from 'react-i18next'

import { SettingsScreensEnum } from 'src/entities/setting/types/settings'
import {
  useTeamRolesQuery,
  useUpdateTeamRolesMutation,
} from 'src/entities/team-member/queries/teamMember'
import { type RoleDefinition } from 'src/entities/team-member/types/teamMember'
import { useDefaultMutationHandlers } from 'src/pages/ShiftConfiguration/hooks/useDefaultMutationHandlers'
import { PermissionsTable } from './PermissionsTable'
import { SettingsSectionTitle } from '../components/SettingsSectionTitle'
import { UnsavedChangesDialog } from '../components/UnsavedChangesDialog'
import { ConfigScroll } from '../layout/ConfigScroll'
import { SettingsHeader } from '../layout/SettingsHeader'

const sortRoleDefinitions = (rds: RoleDefinition[]) =>
  rds.map(rd => ({
    ...rd,
    permissions: rd.permissions.slice().sort((a, b) => a.localeCompare(b)),
  }))

interface PermissionProps {
  restaurantId: number
}

const Permissions = ({ restaurantId }: PermissionProps) => {
  const { t } = useTranslation()

  const { data: originalRoleDefinitions } = useTeamRolesQuery()
  const { mutateAsync: updateRolesAndPermissions } =
    useUpdateTeamRolesMutation()

  const handlers = useDefaultMutationHandlers({
    successMessage: t('settings.common.settings_changed', 'Settings changed'),
  })

  const [roleDefinitions, setRoleDefinitions] = useState(
    originalRoleDefinitions,
  )

  const hasNoChanges = isEmpty(
    diff(
      sortRoleDefinitions(originalRoleDefinitions),
      sortRoleDefinitions(roleDefinitions),
    ),
  )

  return (
    <>
      <SettingsHeader
        restaurantId={restaurantId}
        screen={SettingsScreensEnum.Permissions}
        primaryButtonProps={{
          onClick: () => updateRolesAndPermissions(roleDefinitions, handlers),
          children: t('settings.common.save_changes', 'Save changes'),
          disabled: hasNoChanges,
        }}
      />
      <ConfigScroll gap={3}>
        <SettingsSectionTitle
          title={t('settings.permissions.title', 'Manage permissions')}
          description={t(
            'settings.permissions.subtitle',
            'Select which action are possible to perform by managers roles only.',
          )}
        />
        <PermissionsTable
          roleDefinitions={roleDefinitions}
          setRoleDefinitions={setRoleDefinitions}
        />
      </ConfigScroll>
      <UnsavedChangesDialog hasChanges={!hasNoChanges} />
    </>
  )
}

export default Permissions
