import React, { useCallback } from 'react'
import { ascend, equals, identity, isEmpty, sort, useWith } from 'ramda'

import { atom, useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { z } from 'zod'

import type { ServiceTimeInterface } from 'src/entities/config/types/configApi'
import { useFeedbackEnabledCallback } from 'src/entities/customer/queries/customer'
import { type CustomerInterface } from 'src/entities/customer/types/customer'
import { useFeatureCallback } from 'src/entities/info/hooks/useFeature'
import { useInfoQuery } from 'src/entities/info/queries/info'
import { useReservation } from 'src/entities/reservation/queries/reservation'
import { changeCustomer } from 'src/entities/reservation/services/editReservation'
import { useServiceTimesQuery } from 'src/entities/service-time/queries/serviceTime'
import { useSettingsCallback } from 'src/entities/setting/queries/settings'
import { type TableOccupancyInterface } from 'src/entities/table/types/table'
import {
  baseSelectedReservationAtom,
  NO_RESERVATION,
  updateSelectedReservationField,
  type SelectedReservation,
  type SelectedReservationFieldUpdate,
  type SelectedReservationUpdate,
} from './baseSelectedReservation'
import { currentDateAtom } from './currentDate'
import {
  getReservationServiceTime,
  serviceTimeAtom,
} from './selectedServiceTime'
import {
  getNowInRestaurantTimezone,
  isDateSame,
} from '../../../range/services/date'
import { isValidPhoneNumber } from '../../../string/services/phoneNumber'
import { zodAtLeastOne } from '../../../zod/zod'

const sortLabels = sort(ascend(identity<number>))
// eslint-disable-next-line react-hooks/rules-of-hooks
const areLabelsTheSame = useWith(equals, [sortLabels, sortLabels])

function hasChanges(
  relevantChanged: boolean,
  selectedReservation: SelectedReservation,
  initialReservation: SelectedReservation,
): boolean {
  return (
    relevantChanged ||
    selectedReservation.guest.firstName !==
      initialReservation.guest.firstName ||
    selectedReservation.guest.lastName !== initialReservation.guest.lastName ||
    selectedReservation.guest.phoneNumber !==
      initialReservation.guest.phoneNumber ||
    selectedReservation.guest.companyName !==
      initialReservation.guest.companyName ||
    selectedReservation.restaurantNote !== initialReservation.restaurantNote ||
    selectedReservation.room !== initialReservation.room ||
    selectedReservation.sendFeedback !== initialReservation.sendFeedback ||
    selectedReservation.sendReminder !== initialReservation.sendReminder ||
    !areLabelsTheSame(selectedReservation.labels, initialReservation.labels) ||
    !isDateSame(
      selectedReservation.dateRange[1],
      initialReservation.dateRange[1],
    )
  )
}

function hasRelevantChanges(
  selectedReservation: SelectedReservation,
  initialReservation: SelectedReservation,
): boolean {
  return (
    selectedReservation.dateRange[0].getTime() !==
      initialReservation.dateRange[0].getTime() ||
    selectedReservation.seatCount !== initialReservation.seatCount ||
    selectedReservation.guest.customerId !== initialReservation.guest.customerId
  )
}

export const openReservationOccupanciesAtom = atom(
  [] as TableOccupancyInterface[],
)
const pristineOpenReservationOccupanciesAtom = atom(
  [] as TableOccupancyInterface[],
)

export const useSetOpenReservationOccupancies = () =>
  useSetAtom(openReservationOccupanciesAtom)

export const useSetPristineOpenReservationOccupancies = () =>
  useSetAtom(pristineOpenReservationOccupanciesAtom)
export const usePristineOpenReservationOccupancies = () =>
  useAtomValue(pristineOpenReservationOccupanciesAtom)

const selectedReservationAtom = atom(
  get => {
    const base = get(baseSelectedReservationAtom)

    if (base === NO_RESERVATION) return base

    return {
      ...get(baseSelectedReservationAtom),
      occupancies: get(openReservationOccupanciesAtom),
    } satisfies SelectedReservation
  },
  (
    get,
    set,
    update: SelectedReservationUpdate,
    serviceTimes: ServiceTimeInterface[],
  ) => {
    set(baseSelectedReservationAtom, prev => {
      const updaterIsFunction = typeof update === 'function'

      if (prev === NO_RESERVATION && updaterIsFunction) return prev

      const newReservation = updaterIsFunction ? update(prev) : update

      set(currentDateAtom, newReservation.dateRange?.[0])

      const newServiceTime = getReservationServiceTime(
        serviceTimes,
        get(serviceTimeAtom),
      )(newReservation, newReservation !== NO_RESERVATION)
      set(serviceTimeAtom, newServiceTime)

      return newReservation
    })
  },
)

const isReservationSelectedAtom = atom(get => {
  const selectedReservation = get(selectedReservationAtom)

  return selectedReservation !== NO_RESERVATION && !isEmpty(selectedReservation)
})

export const useIsReservationSelectedAtom = () =>
  useAtomValue(isReservationSelectedAtom)

const initialReservationAtom = selectAtom(
  selectedReservationAtom,
  v => v,
  (a, b) => !a === !b && a?.id === b?.id,
)

export const useInitialReservation = () => useAtomValue(initialReservationAtom)

export const useSetSelectedReservation = () => {
  const setSelectedReservation = useSetAtom(selectedReservationAtom)
  const { data: serviceTimes } = useServiceTimesQuery()

  return React.useCallback(
    (r: SelectedReservationUpdate) => setSelectedReservation(r, serviceTimes),
    [serviceTimes, setSelectedReservation],
  )
}

export const useSelectedReservationAtom = () => {
  const selectedReservation = useAtomValue(selectedReservationAtom)
  const isReservationSelected = useIsReservationSelectedAtom()

  const { data: persistedReservation } = useReservation(
    isReservationSelected ? selectedReservation?.id : null,
  )

  const reservation = React.useMemo(() => {
    if (!persistedReservation) return selectedReservation

    return {
      ...selectedReservation,
      status: persistedReservation.status,
      exactMode: persistedReservation.exactMode,
      locked: persistedReservation.locked,
    }
  }, [persistedReservation, selectedReservation])

  const setReservation = useSetSelectedReservation()

  return [reservation, setReservation] as const
}

export const useClearSelectedReservation = () => {
  const set = useSetSelectedReservation()

  return useCallback(() => set(NO_RESERVATION), [set])
}

const relevantChangedAtom = atom(get => {
  const initialReservation = get(initialReservationAtom)
  const selectedReservation = get(selectedReservationAtom)

  if (
    !initialReservation ||
    !selectedReservation ||
    selectedReservation === NO_RESERVATION
  )
    return false

  return hasRelevantChanges(selectedReservation, initialReservation)
})

export const useReservationRelevantChanged = () =>
  useAtomValue(relevantChangedAtom)

export const changedAtom = atom(get => {
  const initialReservation = get(initialReservationAtom)
  const selectedReservation = get(selectedReservationAtom)
  const relevantChanged = get(relevantChangedAtom)

  if (
    !initialReservation ||
    !selectedReservation ||
    selectedReservation === NO_RESERVATION
  )
    return false

  return hasChanges(relevantChanged, selectedReservation, initialReservation)
})

export const useReservationChanged = () => useAtomValue(changedAtom)

export const useSetSelectedReservationField = () => {
  const setSR = useSetSelectedReservation()

  return useCallback(
    <T extends keyof SelectedReservation>(
      key: T,
      update: SelectedReservationFieldUpdate<T>,
    ) => setSR(updateSelectedReservationField<T>(key, update)),
    [setSR],
  )
}

export const useSetSelectedReservationGuest = () => {
  const { data: info } = useInfoQuery()

  const isSettingOn = useSettingsCallback()
  const isFeatureOn = useFeatureCallback()
  const hasFeedbackEnabled = useFeedbackEnabledCallback()

  const changeGuest = React.useMemo(
    () =>
      changeCustomer({
        getNow: () => getNowInRestaurantTimezone(),
        isFeatureOn,
        isSettingOn,
        hasFeedbackEnabled,
        getReminderSettings: () => info.reminderData,
      }),
    [hasFeedbackEnabled, info.reminderData, isFeatureOn, isSettingOn],
  )
  const [reservation, setReservation] = useSelectedReservationAtom()

  return React.useCallback(
    async (customer?: CustomerInterface) => {
      const newReservation = await changeGuest(reservation, customer)
      setReservation(newReservation)
      return newReservation
    },
    [changeGuest, reservation, setReservation],
  )
}

export const useSelectedReservationField = <
  T extends keyof SelectedReservation,
>(
  key: T,
) => {
  const [reservation] = useSelectedReservationAtom()

  return React.useMemo(() => reservation[key], [key, reservation])
}

export const useResetInitialAtom = () => {
  const id = useSelectedReservationField('id')

  const set = useSetSelectedReservationField()

  return useCallback(() => {
    set('id', 0)
    set('id', id)
  }, [id, set])
}

const semiOptionalNameSchema = z.preprocess(
  val => val || undefined,
  z.string().min(1).optional(),
)

export const selectedReservationSchema = z
  .object({
    newCustomer: z
      .object({
        firstName: semiOptionalNameSchema,
        lastName: semiOptionalNameSchema,
        phoneNumbers: z.array(
          z.object({
            phone: z
              .string()
              .refine(isValidPhoneNumber, 'Invalid phone number'),
          }),
        ),
      })
      .superRefine(zodAtLeastOne('firstName', 'lastName'))
      .optional(),
  })
  .passthrough()

export const useResetGlobalReservationState = () => {
  const setSelectedReservation = useSetAtom(selectedReservationAtom)
  const setPristineOpenReservationOccupancies =
    useSetPristineOpenReservationOccupancies()
  const setOpenReservationOccupancies = useSetOpenReservationOccupancies()

  return useCallback(() => {
    setSelectedReservation(NO_RESERVATION, [])
    setPristineOpenReservationOccupancies([])
    setOpenReservationOccupancies([])
  }, [
    setOpenReservationOccupancies,
    setPristineOpenReservationOccupancies,
    setSelectedReservation,
  ])
}
