import { useCallback } from 'react'

import { useTranslation } from 'react-i18next'

import StepInput from 'src/shared/components/form/inputs/StepInput'
import {
  useBaseFormContext,
  useBaseFormController,
} from '../../../../hooks/form'

const name = 'shiftGuestCountLimit'

const ShiftLimit = () => {
  const { t } = useTranslation()

  const { setValue } = useBaseFormContext()
  const { field } = useBaseFormController(name)

  const onChange = useCallback((v: number) => setValue(name, v), [setValue])

  return (
    <StepInput
      value={field.value}
      onChange={onChange}
      minValue={0}
      sx={{ maxWidth: 168 }}
      label={t(
        'schedule.shifts.limits_section.shift_limit.label',
        'Guests limit per shift',
      )}
    />
  )
}

export default ShiftLimit
