import { Suspense } from 'react'
import { Stack, type StackProps } from '@mui/material'

import { type ReactNode } from '@tanstack/react-router'

import { mergeSx } from 'src/app/theme/helpers'
import Loader from 'src/pages/CustomerChoice/SearchResults/SummaryRow/Summary/Loader'

interface ConfigScrollProps extends StackProps {
  children: ReactNode
}

export const ConfigScroll = ({ children, sx, ...rest }: ConfigScrollProps) => (
  <Stack
    sx={mergeSx({ px: [2, 3], minHeight: 0, overflow: 'auto' }, sx)}
    {...rest}
  >
    <Suspense fallback={<Loader />}>{children}</Suspense>
  </Stack>
)
