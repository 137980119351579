import { useCallback, useState } from 'react'
import { Button, Stack, Typography } from '@mui/material'

import { Trans, useTranslation } from 'react-i18next'

import {
  type Integration,
  type IntegrationName,
} from 'src/entities/integration/api/integrationsApi'
import { useStartIntegrationFlowMutation } from 'src/entities/integration/queries/integrationFlows'
import {
  useDisconnectIntegrationMutation,
  useSetupIntegrationMutation,
} from 'src/entities/integration/queries/integrations'
import { showToast } from 'src/shared/components/common/toast'
import { SimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/SimpleDialog'
import { useSimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/useSimpleDialog'
import { FakeLabel } from 'src/shared/components/form/common/FakeLabel/FakeLabel'
import CopyIcon from 'src/shared/components/icons/CopyIcon'
import { IntegrationCard } from './IntegrationCard'
import { IntegrationModalBodyWrapper } from './IntegrationModalBodyWrapper'
import { useIntegrationMutationHandlers } from '../hooks/useHandleSuccessFlow'
import {
  getIntegrationErrorModalProps,
  useIntegrationFlowEffect,
} from '../hooks/useIntegrationFlowEffect'

interface IntegrationItemProps {
  integrationName: IntegrationName
  status: Integration['status']
}

export const IntegrationItem = ({
  integrationName,
  status,
}: IntegrationItemProps) => {
  const { t } = useTranslation()

  const [popup, setPopup] = useState<WindowProxy | null>(null)

  const { mutate: connect, isPending: isConnectPending } =
    useStartIntegrationFlowMutation()
  const { mutate: disconnect, isPending: isDisconnectPending } =
    useDisconnectIntegrationMutation()
  const { mutateAsync: setup } = useSetupIntegrationMutation()
  const setupHandlers = useIntegrationMutationHandlers()

  const setupDialog = useSimpleDialog()

  const { showSimpleDialog } = setupDialog

  const { isLoading: isFlowLoading } = useIntegrationFlowEffect({
    integrationName,
    popup,
    setPopup,
    setupDialog,
  })

  const isLoading =
    !!popup || isFlowLoading || isConnectPending || isDisconnectPending

  const onConnect = useCallback(() => {
    connect(
      { integrationName },
      {
        onSuccess: f => {
          if (f.integrationName === 'lightspeed') {
            return setPopup(
              window.open(f.configuration.authorizationUrl, '_blank', 'popup'),
            )
          }
          if (f.integrationName === 'peoplefone') {
            if (f.status !== 'success') {
              return showSimpleDialog(getIntegrationErrorModalProps(f, t))
            }

            showSimpleDialog({
              children: (
                <IntegrationModalBodyWrapper
                  integrationName={f.integrationName}
                >
                  <Stack gap={1}>
                    <Typography variant="labelSmall">
                      {t(
                        'settings.integrations.peoplefone.integration_process',
                        'Integration process',
                      )}
                    </Typography>
                    <Typography
                      component="ol"
                      variant="body3"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        pl: 3,
                      }}
                    >
                      <Trans
                        i18nKey="settings.integrations.peoplefone.integration_steps"
                        components={[<Typography component="li" />]}
                        defaultValue="<0>Step 1</0><0>Step 2</0><0>Step 3</0><0>Step 4</0><0>Step 5</0>"
                      />
                    </Typography>
                  </Stack>
                  <Stack>
                    <FakeLabel>URL</FakeLabel>
                    <Stack border="1px solid" borderColor="grey.200">
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        py={0.75}
                        px={1.5}
                        bgcolor="grey.50"
                      >
                        <Typography variant="body3">link</Typography>
                        <Button
                          variant="text"
                          color="neutral"
                          size="small"
                          startIcon={<CopyIcon />}
                          onClick={() =>
                            navigator.clipboard.writeText(
                              f.configuration.tokenUrl,
                            )
                          }
                        >
                          Copy
                        </Button>
                      </Stack>
                      <Typography
                        component="pre"
                        p={1.5}
                        fontFamily="monospace"
                        fontSize={14}
                        sx={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}
                        color="textPrimary"
                      >
                        {f.configuration.tokenUrl}
                      </Typography>
                    </Stack>
                  </Stack>
                </IntegrationModalBodyWrapper>
              ),
              onConfirmation: () =>
                setup(
                  {
                    integrationFlowId: f.id,
                    integrationName: f.integrationName,
                  },
                  setupHandlers,
                ),
              confirmButtonText: t('settings.integrations.connect', 'Connect'),
            })
          }
          return undefined
        },
      },
    )
  }, [connect, integrationName, setup, setupHandlers, showSimpleDialog, t])

  const onDisconnect = useCallback(
    () =>
      disconnect(integrationName, {
        onSuccess: () =>
          showToast(
            t(
              'settings.integrations.successes.successful_disconnected',
              'Successful disconnected!',
            ),
            'success',
          ),
        onError: () =>
          showToast(
            t(
              'settings.integrations.errors.error_while_disconnecting',
              'An error occurred while disconnecting.',
            ),
            'error',
          ),
      }),
    [disconnect, integrationName, t],
  )

  return (
    <>
      <IntegrationCard
        integrationName={integrationName}
        status={status}
        isLoading={isLoading}
        onConnect={onConnect}
        onDisconnect={onDisconnect}
      />
      <SimpleDialog
        title={t(
          'settings.integrations.setup_modal_title',
          'Set up integration',
        )}
        {...setupDialog.simpleDialogProps}
      />
    </>
  )
}
