import { andThen, composeWith, prop } from 'ramda'

import {
  convertKeysToCamelCase,
  convertKeysToSnakeCase,
  type ApiClient,
} from 'src/shared/lib/api/services/api'
import {
  widgetGuestCommentLabel,
  widgetWelcomeMessageSchama,
  type WidgetGuestCommentLabel,
  type WidgetWelcomeMessage,
} from '../types/widget'

export const getWidgetWelcomeMessage =
  (httpClient: ApiClient) =>
  // eslint-disable-next-line @typescript-eslint/require-await
  async () =>
    composeWith(andThen)([
      (d: unknown) => widgetWelcomeMessageSchama.parse(d),
      convertKeysToCamelCase,
      prop('guest_message'),
      httpClient,
    ])({
      method: 'GET',
      url: `guest-message`,
    })

export const editWidgetWelcomeMessage =
  (httpClient: ApiClient) => (dto: WidgetWelcomeMessage) =>
    httpClient({
      method: 'PUT',
      url: `guest-message`,
      json: convertKeysToSnakeCase(dto),
    })

export const getWidgetGuestCommentLabel =
  (httpClient: ApiClient) =>
  // eslint-disable-next-line @typescript-eslint/require-await
  async () =>
    composeWith(andThen)([
      (d: unknown) => widgetGuestCommentLabel.parse(d),
      convertKeysToCamelCase,
      prop('reservation_comment'),
      httpClient,
    ])({
      method: 'GET',
      url: `reservation-comment`,
    })

export const editWidgetGuestCommentLabel =
  (httpClient: ApiClient) => (dto: WidgetGuestCommentLabel) =>
    httpClient({
      method: 'PUT',
      url: `reservation-comment`,
      json: convertKeysToSnakeCase(dto),
    })
