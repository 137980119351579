import { useCallback } from 'react'
import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import MinutesPicker from 'src/shared/components/form/inputs/MinutesPicker/MinutesPicker'
import StepInput from 'src/shared/components/form/inputs/StepInput'
import {
  formatTime,
  fromIsoDuration,
  fromTime,
  toIsoDuration,
} from 'src/shared/lib/range/services/date'
import {
  useBaseFormContext,
  useBaseFormController,
} from '../../../../../../hooks/form'

const intervalFieldName = 'bookingSetup.bookingDeadline.interval'

const DeadlineTime = () => {
  const { t } = useTranslation()

  const { watch, setValue } = useBaseFormContext()

  const deadlineType = watch('bookingSetup.bookingDeadline.type')

  const {
    field: timeField,
    fieldState: { error: timeFieldError },
  } = useBaseFormController('bookingSetup.bookingDeadline.time')

  const {
    field: { value: rawInterval },
  } = useBaseFormController(intervalFieldName)

  const interval = fromIsoDuration(rawInterval, 'hours')

  const handleIntervalChange = useCallback(
    (newDuration: number) => {
      setValue(intervalFieldName, toIsoDuration(newDuration, 'hours'))
    },
    [setValue],
  )

  return deadlineType === 'time-before-reservation' ? (
    <Box>
      <StepInput
        onChange={handleIntervalChange}
        value={interval}
        unit={t('common.hours', { count: interval, defaultValue: 'hours' })}
        minValue={0}
        sx={{ minWidth: 168 }}
      />
    </Box>
  ) : (
    <MinutesPicker
      onChange={newValue => {
        if (!newValue) return

        timeField.onChange(formatTime(newValue))
      }}
      value={timeField.value ? fromTime(timeField.value) : null}
      sx={{
        maxWidth: 168,
      }}
      error={!!timeFieldError}
      helperText={timeFieldError ? t('common.validation.required') : undefined}
    />
  )
}

export default DeadlineTime
