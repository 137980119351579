import { useState } from 'react'
import { capitalize, Stack, Typography } from '@mui/material'

import { type FieldPath, type UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { type ScheduleLanguage } from 'src/entities/schedule/types/scheduleApi'
import MultilingualTextArea from 'src/shared/components/form/inputs/MultilingualTextArea/MultilingualTextArea'
import { type WidgetFormValues } from '../hooks/useWidgetForm'

interface WidgetSettingRowProps {
  title: string
  subtitle: string
  formProps: UseFormReturn<WidgetFormValues>
  formKey: Extract<
    FieldPath<WidgetFormValues>,
    'welcomeMessage' | 'guestCommentLabel'
  >
}

export const WidgetSettingRow = ({
  formProps,
  formKey,
  subtitle,
  title,
}: WidgetSettingRowProps) => {
  const { t } = useTranslation()

  const {
    register,
    watch,
    formState: { errors },
  } = formProps

  const [selectedLanguage, setSelectedLanguage] =
    useState<ScheduleLanguage>('de')

  const translatedKey =
    `${formKey}.text${capitalize(selectedLanguage) as Capitalize<typeof selectedLanguage>}` as const
  const translatedMessage = watch(translatedKey)

  return (
    <Stack direction={['column', 'column', 'row']} gap={3}>
      <Stack
        pt={1.5}
        sx={{ width: ['auto', 'min(50%, 416px)'], flexShrink: 0 }}
      >
        <Typography variant="labelRegular">{title}</Typography>
        <Typography variant="body3">{subtitle}</Typography>
      </Stack>
      <Stack sx={{ flex: 1, overflow: 'hidden' }}>
        <MultilingualTextArea
          label={t('settings.widget.form.textarea_label', 'Text (optional)')}
          placeholder={t(
            'common.textarea_placeholder',
            'Write content here...',
          )}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          limit={255}
          messageLength={translatedMessage.length}
          errorMessage={errors[formKey]?.message}
          {...register(translatedKey)}
        />
      </Stack>
    </Stack>
  )
}
