import { Typography, type TypographyProps } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { formatShortDatetime } from 'src/shared/lib/range/services/date'

interface PublishedDateProps extends TypographyProps {
  date: Date
}

const PublishedDate = ({ date, ...props }: PublishedDateProps) => {
  const { t } = useTranslation()

  return (
    <Typography variant="labelTiny" color="grey.500" {...props}>
      {t('customer.feedback.published', {
        tDescription: 'Customer preview feedback publication date and time',
        defaultValue: 'Published on {{ datetime }}',
        datetime: formatShortDatetime(date),
      })}
    </Typography>
  )
}

export default PublishedDate
