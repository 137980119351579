import { createFileRoute } from '@tanstack/react-router'

import General from 'src/pages/Settings/General'

export const Route = createFileRoute(
  '/restaurants/$restaurantId/config/general',
)({
  component: RouteComponent,
})

function RouteComponent() {
  const { restaurantId } = Route.useParams()

  return <General restaurantId={restaurantId} />
}
