import React from 'react'

import Persons from 'src/widgets/Reservation/Persons'

export default React.memo(
  Persons,
  (prevProps, nextProps) =>
    prevProps.persons === nextProps.persons &&
    prevProps.disabled === nextProps.disabled,
)
