import { useEffect, useState } from 'react'
import { Button, Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useReplyToFeedbackMutation } from 'src/entities/feedback/queries/feedback'
import { type ExtendedFeedbackInterface } from 'src/entities/feedback/types/feedbackApi'
import { LoadingButton } from 'src/shared/components/buttons/LoadingButton/LoadingButton'
import Modal from 'src/shared/components/common/Modal'
import type useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { FeedbackCard } from './FeedbackCard'
import { FeedbackModalReservationDetails } from './FeedbackModalReservationDetails'
import { FeedbackReplyForm } from './FeedbackReplyForm'
import { useDefaultMutationHandlers } from '../../ShiftConfiguration/hooks/useDefaultMutationHandlers'

interface FeedbackModalProps {
  feedback: ExtendedFeedbackInterface | undefined
  dialogProps: ReturnType<typeof useDialog>
}

export const FeedbackModal = ({
  feedback,
  dialogProps,
}: FeedbackModalProps) => {
  const { t } = useTranslation()

  const { mutateAsync: replyToFeedback } = useReplyToFeedbackMutation()
  const handlers = useDefaultMutationHandlers({
    onSuccess: dialogProps.handleClose,
    successMessage: t('feedbacks.modal.reply_saved', 'Reply saved'),
  })

  const [reply, setReply] = useState(feedback?.response ?? '')
  const [isPublic, setIsPublic] = useState(feedback?.isPublic ?? false)

  useEffect(() => {
    if (!dialogProps.open) return

    setReply(feedback?.response ?? '')
    setIsPublic(feedback?.isPublic ?? false)
  }, [dialogProps.open, feedback?.isPublic, feedback?.response])

  if (!feedback?.reservation) return null

  const hasReply = !!feedback.response

  return (
    <Modal
      title={
        hasReply
          ? t('feedbacks.modal.title.view', 'View reply')
          : t('feedbacks.modal.title.reply', 'Reply on feedback')
      }
      open={dialogProps.open}
      onClose={dialogProps.handleClose}
      disablePortal={false}
      PaperProps={{ sx: { maxWidth: 912 } }}
      actions={
        <>
          <Button variant="outlined" onClick={dialogProps.handleClose}>
            {t('common.dismiss', 'Dismiss')}
          </Button>
          {!hasReply && (
            <LoadingButton
              variant="contained"
              onClick={() =>
                replyToFeedback(
                  { id: feedback.id, answer: reply, isPublic },
                  handlers,
                )
              }
            >
              {t('feedbacks.modal.publish_reply', 'Publish reply')}
            </LoadingButton>
          )}
        </>
      }
    >
      <Stack gap={3}>
        <FeedbackModalReservationDetails feedback={feedback} />
        <FeedbackCard feedback={feedback} />
        <FeedbackReplyForm
          formData={{
            isPublic,
            reply,
            onIsPublicChange: setIsPublic,
            onReplyChange: setReply,
          }}
          isDisabled={hasReply}
        />
      </Stack>
    </Modal>
  )
}
