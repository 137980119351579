import { InputAdornment } from '@mui/material'

import NumericInput, {
  type NumericInputProps,
} from '../NumericInput/NumericInput'

export const LimitInput = ({
  maxValue,
  ...props
}: Omit<NumericInputProps, 'endAdornment'>) => (
  <NumericInput
    {...props}
    slotProps={{
      input: {
        endAdornment: (
          <InputAdornment position="end" sx={{ pr: 1.5 }}>
            /{maxValue}
          </InputAdornment>
        ),
      },
    }}
  />
)
