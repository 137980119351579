import { type SettingsKeys } from '../../config/types/configApi'

export enum CommTypes {
  CreateNotifications = 'createNotifications',
  MutateNotifications = 'mutateNotifications',
  MoveNotifications = 'moveNotifications',
  Reminder = 'reminder',
  Feedback = 'feedback',
  CancelNotifications = 'cancelNotifications',
  NoShowNotifications = 'noshowNotifications',
}

export interface Setting {
  name: SettingsKeys
  value: boolean
}
