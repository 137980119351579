import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Checkbox from 'src/shared/components/form/inputs/Checkbox'
import { TextArea } from 'src/shared/components/form/inputs/TextArea'
import {
  withTargetChecked,
  withTargetValue,
} from 'src/shared/lib/common/services/helpers/helpers'

interface FeedbackReplyFormData {
  reply: string
  onReplyChange: (reply: string) => void
  isPublic: boolean
  onIsPublicChange: (isPublic: boolean) => void
}

interface FeedbackReplyFormProps {
  isDisabled: boolean
  formData: FeedbackReplyFormData
}

export const FeedbackReplyForm = ({
  formData: { onReplyChange, reply, isPublic, onIsPublicChange },
  isDisabled,
}: FeedbackReplyFormProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Stack gap={0.5}>
        <TextArea
          label={t('feedbacks.modal.your_reply', 'Your reply')}
          slotProps={{ htmlInput: { maxLength: 2500 } }}
          minRows={3}
          value={reply}
          onChange={withTargetValue(onReplyChange)}
          disabled={isDisabled}
        />
        <Typography variant="labelTiny" color="grey.500">
          {t('common.characterLimit', {
            limit: 2500 - reply.length,
            defaultValue: 'Characters left: {{ limit }}',
          })}
        </Typography>
      </Stack>
      <Checkbox
        label={t('feedbacks.modal.display_publicly', 'Display reply publicly')}
        checked={isPublic}
        onChange={withTargetChecked(onIsPublicChange)}
        disabled={isDisabled}
      />
    </>
  )
}
