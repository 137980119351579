import { type KonvaEventObject } from 'konva/lib/Node'

import { limitDrag } from 'src/entities/table/services/tableGrid'
import { useCanvasViewportContext } from 'src/shared/lib/context/state/CanvasViewportContext'
import FloorPlanElement, {
  type FloorPlanElementProps,
} from '../FloorPlanElement'

const TransformableElement = (props: FloorPlanElementProps) => {
  const { offset, padding } = useCanvasViewportContext()

  const dragHandler = ({ target }: KonvaEventObject<DragEvent>) =>
    limitDrag(offset, padding)(target)

  return (
    <FloorPlanElement
      draggable
      onDragMove={dragHandler}
      onTransform={dragHandler}
      {...props}
    />
  )
}

export default TransformableElement
