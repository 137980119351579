import { z } from 'zod'

import { colorSchema } from 'src/shared/lib/style/types/color'

const NON_ADMIN_ROLES = ['team_member', 'manager'] as const
export const ROLES = [...NON_ADMIN_ROLES, 'admin'] as const

enum TeamMemberUpdateErrorCodes {
  INVALID_PIN = 27,
}

export const TeamMemberUpdateErrors: { [K in number]: string } = {
  [TeamMemberUpdateErrorCodes.INVALID_PIN]: 'invalidPinError',
}

export interface TeamMemberUpdateInterface extends TeamMember {
  newPin?: string
}

export const PERMISSIONS = [
  'foratable_link',
  'manage_feedbacks',
  'configuration',
  'permissions_tab',
  'manage_integrations',
  'manage_team_members',
  'manage_shifts',
  'manage_service_time_message',
  'overbooking',
  'cancel_or_reactivate_reservations',
  'no_show_or_re_show_reservations',
  'manage_schedule',
  'manage_schedule_rooms',
  'manage_widget',
  'manage_schedule_shifts',
] as const

export const teamMemberSchema = z.object({
  id: z.number().gt(0),
  firstName: z.coerce.string(),
  lastName: z.coerce.string().nullable(),
  role: z.enum(ROLES),
  color: colorSchema,
  pinRequired: z.boolean(),
})

export type TeamMember = z.infer<typeof teamMemberSchema>

const roleSchema = z.object({
  role: z.enum(ROLES),
  permissions: z.array(z.enum(PERMISSIONS)),
})

export const rolesSchema = z.array(roleSchema)

export type Permission = (typeof PERMISSIONS)[number]
export type TeamMemberRole = (typeof ROLES)[number]
export type RoleDefinition = z.infer<typeof roleSchema>
