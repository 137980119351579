import { Stack, useMediaQuery, type Theme } from '@mui/material'

import { noop } from '@tanstack/react-table'

import CancelButton from './CancelButton'
import DeleteButton from './DeleteButton'
import SaveButton from './SaveButton'

const FormButtons = ({
  onCancel,
  onDelete = noop,
  onSave,
  showDeleteButton,
}: {
  onCancel: () => void
  onDelete?: () => void
  onSave: () => void
  showDeleteButton?: boolean
}) => {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('xs'),
  )

  const size = isSmallScreen ? 'small' : 'medium'
  return (
    <Stack direction="row" spacing={1}>
      <CancelButton onClick={onCancel} size={size} fullWidth />
      {showDeleteButton && (
        <DeleteButton onClick={onDelete} size={size} fullWidth />
      )}
      <SaveButton onClick={onSave} size={size} fullWidth />
    </Stack>
  )
}

export default FormButtons
