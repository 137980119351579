import {
  always,
  append,
  compose,
  find,
  identical,
  indexOf,
  reduce,
  T,
  update,
} from 'ramda'

export const hasIdRelationBy =
  <T extends string, Key extends string = 'id'>(
    relationField: T,
    key: Key = 'id' as Key,
  ) =>
  <K extends { [key in Key]: unknown }>(
    relationAwareList: (Record<T, K[Key]> | undefined)[],
  ) =>
  (relationObject?: K): boolean =>
    relationAwareList.some(
      item => item?.[relationField] === relationObject?.[key],
    )

export const replace =
  <T>(
    oldItem: T | undefined,
    newItem: T,
    onMissing: (items: T[]) => T[] = append(newItem),
  ) =>
  (items: T[]): T[] => {
    const itemIndex = indexOf(oldItem, items)
    if (itemIndex === -1) return onMissing(items)

    return update(itemIndex, newItem, items)
  }

export const updateBy = <T>(
  identifier: (item: T) => boolean,
  updater: (oldItem: T) => T,
  items: T[],
  onMissing?: (items: T[]) => T[],
): T[] => {
  if (!items) return items

  const oldItem = find(identifier, items)

  if (typeof oldItem === 'undefined') return onMissing?.(items) ?? items

  return replace(oldItem, updater(oldItem), onMissing)(items)
}

export const replaceBy = <T, U>(
  identifier: (item: T) => U,
  item: T | undefined,
  items: T[],
  onMissing?: (items: T[]) => T[],
): T[] => {
  if (typeof item === 'undefined') return items

  return updateBy(
    compose(identical(identifier(item)), identifier),
    always(item),
    items,
    onMissing ?? append(item as T),
  )
}

export const toggleBy = <T, U>(
  identifier: (item: T) => U,
  item: T,
  items: T[],
): T[] => {
  const itemsWithItem = replaceBy(identifier, item, items)

  if (itemsWithItem.length !== items.length) return itemsWithItem

  return itemsWithItem.filter(
    testItem => identifier(testItem) !== identifier(item),
  )
}

export const replaceById =
  <U, T extends Record<'id', U>>(element: T) =>
  (elements: T[]) =>
    replaceBy(e => e.id === element.id, element, elements)

export const reduceToMap = <T, U, V, W extends V = V>(
  valueFn: (keyItem: U | undefined, item: T) => U,
  keyFn: (item: T) => V[] | V,
  keyAssertion: (key: V) => key is W = T as (key: V) => key is W,
) => {
  const updateKey = (item: T) => (map: Map<W, U>, key: V) => {
    if (!keyAssertion(key)) return map

    map.set(key, valueFn(map.get(key), item))

    return map
  }

  return (items: T[]) =>
    reduce(
      (map, item: T) => {
        const key = keyFn(item)
        const keys = Array.isArray(key) ? key : [key]

        return keys.reduce(updateKey(item), map)
      },
      new Map<W, U>(),
      items,
    )
}

export const mapMap =
  <T, U>(itemMapper: (item: T) => U) =>
  <V>(container: Map<V, T>): Map<V, U> =>
    new Map(Array.from(container).map(([key, item]) => [key, itemMapper(item)]))

export const combine =
  <A extends unknown[]>(fns: ((...a: A) => unknown)[]) =>
  (...a: A) => {
    for (const fn of fns) {
      fn(...a)
    }
  }
