import { compose, map, prop, sortBy } from 'ramda'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'
import { crudQueriesFactory } from 'src/shared/lib/api/queries/crudQueriesFactory'
import {
  crudApiFactory,
  type RequestUrlsInterface,
  type ResponseHandlersInterface,
} from 'src/shared/lib/api/services/crudApiFactory'
import {
  CONFIG_CACHE_KEY,
  useConfigCacheKey,
  useConfigQuery,
  useConfigRestaurantsQueries,
} from '../../config/queries/config'
import { setLabelsOrder } from '../api/labelsApi'
import {
  type LabelInterface,
  type PersistedLabelInterface,
} from '../types/label'
import { fromApi } from '../types/labelApi'

const urls: RequestUrlsInterface = {
  set: 'addLabel',
  delete: 'removeLabel',
}

const payloadFactory = (label: LabelInterface) => ({
  label_id: label.id,
  label: label.name,
  color: label.color,
})

const handlers: ResponseHandlersInterface<
  LabelInterface,
  ReturnType<typeof payloadFactory>
> = {
  set: prop('label_id'),
}

const areaApi = crudApiFactory(urls, handlers, payloadFactory)
const queryHooks = crudQueriesFactory(CONFIG_CACHE_KEY, areaApi)

const labelsQueryOptions = (t: TFunction) => ({
  select: compose(
    sortBy<PersistedLabelInterface>(lA => !lA.systemLabel),
    map(fromApi(t)),
    prop('labels'),
  ),
})

export const useLabelsQuery = (restaurantId?: string) => {
  const { t } = useTranslation()

  return useConfigQuery(labelsQueryOptions(t), restaurantId)
}

export const useRestaurantsLabels = () => {
  const { t } = useTranslation()

  return useConfigRestaurantsQueries(() => labelsQueryOptions(t))
}

export const useDeleteLabelMutation = queryHooks.useItemDeleteMutation
export const useUpsertLabelMutation = queryHooks.useItemUpdateMutation

export const useLabelsOrderUpdateMutation = () => {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()
  const cacheKey = useConfigCacheKey()

  return useMutation({
    mutationFn: setLabelsOrder(apiClient.post),
    onMutate: () => queryClient.cancelQueries({ queryKey: cacheKey }),
    onSettled: () => queryClient.invalidateQueries({ queryKey: cacheKey }),
  })
}
