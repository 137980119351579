import { useEffect, useRef } from 'react'
import {
  Divider,
  Menu,
  MenuItem,
  Typography,
  type MenuProps,
} from '@mui/material'

import { createLink } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import withLayoutBreakpoints from 'src/app/hoc/withLayoutBreakpoints'
import useFeature from 'src/entities/info/hooks/useFeature'
import { useHasPermission } from 'src/entities/team-member/hooks/useCheckPermissions'
import useCurrentTeamMember from 'src/entities/team-member/hooks/useCurrentTeamMember'
import { SearchTabsEnum } from 'src/pages/Search/SearchTabs'
import IconButton from 'src/shared/components/buttons/IconButton'
import SwipeDrawer from 'src/shared/components/common/SwipeDrawer/SwipeDrawer'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import ExternalLinkIcon from 'src/shared/components/icons/ExternalLinkIcon'
import HamburgerIcon from 'src/shared/components/icons/HamburgerIcon'
import { useGlobalModalContext } from 'src/shared/lib/context/global/useGlobalModalContext'
import { openPrivacySettingsModal } from 'src/widgets/Footer'
import SidebarHeader from 'src/widgets/SidebarDrawer/SidebarHeader'
import { LanguageToggle } from './LanguageToggle'
import { EditTeamMemberModal } from '../../../../Settings/TeamMembers/components/EditTeamMemberModal'

interface MenuContainerProps
  extends Pick<MenuProps, 'anchorEl' | 'open' | 'children'> {
  onClose: () => void
}

const MenuItemLink = createLink(MenuItem<'a'>)

const SmMenu = (props: MenuContainerProps) => (
  <Menu
    {...props}
    MenuListProps={{ dense: true }}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    slotProps={{ paper: { sx: { maxHeight: 'calc(100% - 96px)' } } }}
  />
)

const XsMenu = ({ anchorEl, children, ...props }: MenuContainerProps) => {
  const { t } = useTranslation()

  return (
    <SwipeDrawer
      anchor="bottom"
      PaperProps={{ sx: { height: 'auto', pb: 1 } }}
      {...props}
    >
      <SidebarHeader onClose={props.onClose}>
        <Typography variant="labelBig" ml={1}>
          {t('main_menu.title', {
            defaultValue: 'Menu',
            tDescription: 'Title for the main menu mobile drawer',
          })}
        </Typography>
      </SidebarHeader>
      {children}
    </SwipeDrawer>
  )
}

const MenuContainer = withLayoutBreakpoints({
  xs: XsMenu,
  sm: SmMenu,
})

interface TopBarMenuProps {
  restaurantId: number
}

export const TopBarMenu = ({ restaurantId }: TopBarMenuProps) => {
  const { t } = useTranslation()

  const teamMember = useCurrentTeamMember()

  const ref = useRef<HTMLButtonElement>(null)
  const { handleClose, handleOpen, open } = useDialog()
  const myProfileDialog = useDialog()

  const isGuestMergeEnabled = useFeature('guestMerge')

  const showFatLink = useHasPermission('foratable_link')
  const showFeedback = useHasPermission('manage_feedbacks')
  const canOpenSettings = useHasPermission('configuration')
  const { showPermissionModal } = useGlobalModalContext()

  const closeProfileDialog = myProfileDialog.handleClose

  useEffect(() => {
    if (!teamMember) closeProfileDialog()
  }, [closeProfileDialog, teamMember])

  return (
    <>
      <IconButton
        ref={ref}
        onClick={handleOpen}
        variant="outlined"
        color="neutral"
      >
        <HamburgerIcon />
      </IconButton>
      <MenuContainer anchorEl={ref.current} open={open} onClose={handleClose}>
        {teamMember && (
          <MenuItem
            id="settingsDropdownMyProfile"
            onClick={() => {
              handleClose()
              myProfileDialog.handleOpen()
            }}
            disableGutters
          >
            {t(
              'settings.team_members.form.my_profile_profile_title',
              'My profile',
            )}
          </MenuItem>
        )}
        {teamMember && <Divider />}
        <MenuItemLink
          component="a"
          id="settingsDropdownConfig"
          to="/restaurants/$restaurantId/config"
          params={{ restaurantId }}
          onClick={e => {
            if (canOpenSettings) return

            e.preventDefault()
            showPermissionModal({
              message: t(
                'permissions.errors.configuration',
                "You don't have access to configuration.",
              ),
            })
          }}
          disableGutters
        >
          {t('angular.configuration')}
        </MenuItemLink>
        <MenuItemLink
          component="a"
          id="settingsDropdownReservationSearch"
          to="/restaurants/$restaurantId/search"
          params={{ restaurantId }}
          search={{ tab: SearchTabsEnum.Reservations }}
          disableGutters
        >
          {t('angular.reservations_list')}
        </MenuItemLink>
        <MenuItemLink
          component="a"
          id="settingsDropdownCustomerSearch"
          to="/restaurants/$restaurantId/search"
          params={{ restaurantId }}
          search={{ tab: SearchTabsEnum.Customers }}
          disableGutters
        >
          {t('angular.customers_list')}
        </MenuItemLink>
        {showFatLink && (
          <MenuItem
            id="settingsDropdownForateble"
            component="a"
            href={`${import.meta.env.PUBLIC_FAT_URL}register/logindirect`}
            disableGutters
          >
            Foratable
          </MenuItem>
        )}
        <MenuItemLink
          component="a"
          id="settingsDropdownActivityLog"
          to="/restaurants/$restaurantId/activity-log"
          params={{ restaurantId }}
          disableGutters
        >
          {t('angular.activity_log')}
        </MenuItemLink>
        {isGuestMergeEnabled && (
          <MenuItemLink
            component="a"
            id="settingsDropdownGuestsMerge"
            to="/restaurants/$restaurantId/merge-guests"
            params={{ restaurantId }}
            disableGutters
          >
            {t('angular.guests_merge')}
          </MenuItemLink>
        )}
        {showFeedback && (
          <MenuItemLink
            component="a"
            id="settingsDropdownFeedbacks"
            to="/restaurants/$restaurantId/feedbacks"
            params={{ restaurantId }}
            disableGutters
          >
            {t('feedbacks.title', 'Feedbacks')}
          </MenuItemLink>
        )}
        <MenuItem
          id="settingsDropdownHelpSupport"
          component="a"
          href="https://foratable.uservoice.com/knowledgebase/topics/67315-tischplan-book-foratable-com"
          target="_blank"
          rel="noopener"
          disableGutters
        >
          {t('angular.help_support')}
        </MenuItem>
        <MenuItemLink
          component="a"
          id="settingsDropdownLogout"
          to="/logout"
          preload={false}
          disableGutters
        >
          {t('angular.logout')}
        </MenuItemLink>
        <Divider />
        <MenuItem
          component="a"
          href="https://go.foratable.com/datenschutzerklarung"
          target="_blank"
          rel="noopener"
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
          disableGutters
        >
          {t('general.privacy_policy')}
          <ExternalLinkIcon />
        </MenuItem>
        <MenuItem
          component="a"
          href="https://go.foratable.com/impressum"
          target="_blank"
          rel="noopener"
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
          disableGutters
        >
          {t('general.impressum')}
          <ExternalLinkIcon />
        </MenuItem>
        <MenuItem
          onClick={openPrivacySettingsModal}
          disableGutters
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          {t('general.privacy_configuration')}
          <ExternalLinkIcon />
        </MenuItem>
        <Divider />
        <LanguageToggle />
      </MenuContainer>
      {teamMember && (
        <EditTeamMemberModal
          dialogProps={myProfileDialog}
          currentTeamMember={teamMember}
          editedTeamMember={teamMember}
          disableDelete
        />
      )}
    </>
  )
}
