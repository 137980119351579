import { Stack } from '@mui/material'

import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import Typography from 'src/shared/components/common/Typography'
import PersonIcon from 'src/shared/components/icons/PersonIcon'

const Persons = ({
  persons,
  disabled = false,
  size = 'small',
}: {
  persons: ReservationInterface['seatCount']
  size?: 'small' | 'medium'
  disabled?: boolean
}) => (
  <Stack spacing={0.5} direction="row" alignItems="center">
    <PersonIcon disabled={disabled} size={size} variant="filled" />
    <Typography disabled={disabled}>{persons}</Typography>
  </Stack>
)

export default Persons
