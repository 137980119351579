import { Box, type BoxProps, type PaletteOptions } from '@mui/material'

import { mergeSx } from 'src/app/theme/helpers'

const ItemContainer = ({
  disabled = false,
  sx,
  palette,
  ...props
}: BoxProps & { disabled?: boolean; palette: keyof PaletteOptions }) => (
  <Box
    {...props}
    sx={mergeSx(
      {
        flexShrink: 0,
        typography: 'labelSmall',
        color: `${palette}.700`,
        backgroundColor: `${palette}.100`,
        borderRadius: 1,
        '&:hover': {
          backgroundColor: `${palette}.200`,
        },
        px: 1,
        py: 0.5,
        ...(disabled && {
          pointerEvents: 'none',
        }),
      },
      sx,
    )}
  />
)

export default ItemContainer
