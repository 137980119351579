import {
  createContext,
  useContext,
  useMemo,
  type MutableRefObject,
} from 'react'

import {
  type PositionInterface,
  type SizeInterface,
} from 'src/widgets/FloorPlan/types/floorPlanElement'
import { useScrollPosition } from 'src/widgets/FloorPlan/useScrollPosition'
import { useSize } from 'src/widgets/FloorPlan/useSize'
import { useZoom } from 'src/widgets/FloorPlan/useZoom'

interface CanvasViewportState {
  offset: PositionInterface
  size: SizeInterface
  zoom: number
  padding: number
}

const defaultCanvasViewportContext: CanvasViewportState = {
  offset: {
    x: 0,
    y: 0,
  },
  size: {
    height: 0,
    width: 0,
  },
  zoom: 0,
  padding: 200,
}

export const CanvasViewportContext = createContext(defaultCanvasViewportContext)

export const useCanvasViewportContext = () => useContext(CanvasViewportContext)

export const useCanvasViewport = (
  canvasContainerRef: MutableRefObject<Element | null>,
) => {
  const { zoom, setZoom } = useZoom()

  const canvasContainerSize = useSize(canvasContainerRef)
  const { scrollHandler, scrollPosition: canvasOffset } = useScrollPosition()

  const canvasViewportContextState = useMemo(
    () => ({
      ...defaultCanvasViewportContext,
      offset: canvasOffset,
      size: canvasContainerSize,
      zoom,
    }),
    [canvasOffset, zoom, canvasContainerSize],
  )

  return {
    scrollHandler,
    zoom,
    setZoom,
    canvasViewportContextState,
  }
}
