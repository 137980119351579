import { TableCell as MuiTableCell, Stack, TableRow } from '@mui/material'

import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { type ServiceTimeInterface } from 'src/entities/config/types/configApi'
import SquareIconButton from 'src/shared/components/buttons/SquareIconButton'
import { SimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/SimpleDialog'
import { useSimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/useSimpleDialog'
import PencilIcon from 'src/shared/components/icons/PencilIcon'
import TrashIcon from 'src/shared/components/icons/TrashIcon'
import { formatTime } from 'src/shared/lib/range/services/date'

interface ServiceTimeItemProps {
  serviceTime: ServiceTimeInterface
  onEdit: () => void
  onDelete: () => void
}

const TableCell = styled(MuiTableCell)({ verticalAlign: 'middle' })

export const ServiceTimeTableItem = ({
  serviceTime,
  onEdit,
  onDelete,
}: ServiceTimeItemProps) => {
  const { t } = useTranslation()

  const { showSimpleDialog, simpleDialogProps } = useSimpleDialog()

  const startTime = formatTime(serviceTime.timeRange[0])
  const endTime = formatTime(serviceTime.timeRange[1])

  return (
    <>
      <TableRow>
        <TableCell>{serviceTime.name}</TableCell>

        <TableCell sx={{ fontWeight: 'normal' }}>
          {startTime} - {endTime}
        </TableCell>

        <TableCell>
          <Stack direction="row" gap={0.25} justifyContent="flex-end">
            <SquareIconButton
              color="neutral"
              variant="outlined"
              onClick={onEdit}
            >
              <PencilIcon variant="filled" />
            </SquareIconButton>
            <SquareIconButton
              color="neutral"
              variant="outlined"
              onClick={() => showSimpleDialog()}
              sx={{
                ':disabled': { pointerEvents: 'all', cursor: 'not-allowed' },
              }}
            >
              <TrashIcon variant="filled" />
            </SquareIconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <SimpleDialog
        color="error"
        title={t('common.caution', 'Caution')}
        message={t(
          'settings.service_times.delete_disclaimer',
          'Are you sure you want to delete this service time? This action cannot be reversed.',
        )}
        onConfirmation={onDelete}
        {...simpleDialogProps}
      />
    </>
  )
}
