import { Chip, type SxProps, type Theme } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { mergeSx } from 'src/app/theme/helpers'

const TableCapacity = ({
  capacity,
  size = 20,
  sx,
}: {
  capacity: number
  size?: 16 | 20 | 28
  sx?: SxProps<Theme>
}) => (
  <Chip
    {...createTestIdProps('table-capacity')}
    color="neutral"
    label={capacity}
    size="small"
    sx={mergeSx(
      {
        pointerEvents: 'none',
        typography: 'body3',
        borderRadius: 1,
        height: size,
        minWidth: size,
        p: 0,
      },
      sx,
    )}
  />
)

export default TableCapacity
