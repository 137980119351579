import { useMemo, useState } from 'react'

import { useCookies } from 'react-cookie'

import { combine } from 'src/shared/lib/common/services/functional/functional'

const ZOOM_COOKIE_NAME = 'FatBookZoom'

export const useZoom = () => {
  const [cookies, setCookie] = useCookies([ZOOM_COOKIE_NAME])

  const setZoomCookie = (newZoom: number) =>
    setCookie(ZOOM_COOKIE_NAME, newZoom)

  const rawZoomCookieValue =
    parseFloat(cookies[ZOOM_COOKIE_NAME] as string) || 1

  const zoomCookieValue =
    rawZoomCookieValue > 1 ? rawZoomCookieValue / 100 : rawZoomCookieValue

  const [zoom, setZoom] = useState(zoomCookieValue)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setZoomHandler = useMemo(() => combine([setZoomCookie, setZoom]), [])

  return {
    zoom,
    setZoom: setZoomHandler,
  }
}
