import { useState, type ComponentProps } from 'react'
import { Button, Typography } from '@mui/material'

import { noop } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { combine } from 'src/shared/lib/common/services/functional/functional'
import { LoadingButton } from '../../buttons/LoadingButton/LoadingButton'
import Modal from '../../common/Modal'

export interface SimpleDialogProps
  extends Omit<
    ComponentProps<typeof Modal>,
    'actions' | 'title' | 'content' | 'color'
  > {
  title?: string
  message?: string
  cancelButtonText?: string
  confirmButtonText?: string
  variant?: 'dismissible' | 'decision'
  color?: 'neutral' | 'primary' | 'error'
  onConfirmation?: () => Promise<unknown> | void
  onCancellation?: () => void
}

export const SimpleDialog = (props: SimpleDialogProps) => {
  const { t } = useTranslation()

  const {
    title = t('angular.notice'),
    message = '',
    color = 'primary',
    variant = 'decision',
    onConfirmation = noop,
    onCancellation = noop,
    onClose,
    confirmButtonText = t('modal.button.ok'),
    cancelButtonText = variant === 'dismissible'
      ? t('modal.button.dismiss')
      : t('common.form_buttons.cancelButtonLabel'),
    showCloseButton,
    children,
    ...rest
  } = props

  const handleClose = combine([onClose, onCancellation])

  const [disabled, setDisabled] = useState(false)

  return (
    <Modal
      {...createTestIdProps('simple-dialog')}
      title={title}
      actions={
        <>
          <Button
            {...createTestIdProps('cancel-btn')}
            variant="outlined"
            color="neutral"
            size="large"
            sx={{ minWidth: 'fit-content' }}
            fullWidth
            onClick={handleClose}
            disabled={disabled}
          >
            {cancelButtonText}
          </Button>
          {variant === 'decision' && (
            <LoadingButton
              {...createTestIdProps('confirm-btn')}
              variant="contained"
              color={color}
              size="large"
              sx={{ minWidth: 'fit-content' }}
              fullWidth
              onClick={async () => {
                setDisabled(true)
                try {
                  // eslint-disable-next-line @typescript-eslint/await-thenable
                  await onConfirmation()
                  onClose()
                } finally {
                  setDisabled(false)
                }
              }}
            >
              {confirmButtonText}
            </LoadingButton>
          )}
        </>
      }
      onClose={handleClose}
      showCloseButton={!disabled && showCloseButton}
      {...rest}
    >
      {!!message && (
        <Typography color="grey.700" variant="body2">
          {message}
        </Typography>
      )}
      {children}
    </Modal>
  )
}
