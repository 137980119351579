import { useEffect } from 'react'
import { assocPath, dissocPath } from 'ramda'
import { Box, Button, MenuItem, Select, Stack, Typography } from '@mui/material'

import { noop } from '@tanstack/react-table'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCommunicationTemplates } from 'src/entities/communication-template/queries/useCommunicationTemplates'
import { getTemplateName } from 'src/entities/communication-template/services/communicationTemplates'
import { useLabelsQuery } from 'src/entities/label/queries/label'
import { useChangeRestaurantOfReservationMutation } from 'src/entities/reservation/queries/reservationsMutations'
import { type RestaurantChangeData } from 'src/entities/reservation/services/reservationApi'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { useRestaurantsInChain } from 'src/entities/restaurant/queries/restaurants'
import { type RoomInterface } from 'src/entities/room/types/room'
import { useSettingsObjectQuery } from 'src/entities/setting/queries/settings'
import Modal from 'src/shared/components/common/Modal'
import type useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import Checkbox from 'src/shared/components/form/inputs/Checkbox'
import SingleSelectDropdown from 'src/shared/components/form/inputs/SingleSelectDropdown'
import { combine } from 'src/shared/lib/common/services/functional/functional'

interface MoveRoomDialogProps {
  dialogProps: ReturnType<typeof useDialog>
  selectedReservation: ReservationInterface
  requestedRoom: RoomInterface
  onSuccess?: () => void
  onCancel?: () => void
}

export const MoveRoomDialog = ({
  dialogProps,
  requestedRoom,
  selectedReservation,
  onCancel = noop,
  onSuccess = noop,
}: MoveRoomDialogProps) => {
  const { labels, guest, id: reservationId } = selectedReservation
  const { t } = useTranslation()

  const { data: currentLabels } = useLabelsQuery()
  const { data: destinationLabels } = useLabelsQuery(requestedRoom.restaurantId)
  const restaurants = useRestaurantsInChain()

  const destinationRestaurantId = restaurants.find(
    ({ hash }) => hash === requestedRoom.restaurantId,
  )!.id

  const changeRestaurantOfReservationMutation =
    useChangeRestaurantOfReservationMutation()

  const { data: settings } = useSettingsObjectQuery()

  const sendGuestSmsDefault = settings.changeRestaurantGuestSms
  const sendGuestEmailDefault = settings.changeRestaurantGuestEmail
  const sendRestaurantEmail = settings.changeRestaurantGastroEmail

  const { register, handleSubmit, watch, setValue, reset } =
    useForm<RestaurantChangeData>({
      defaultValues: {
        labels: labels.map(id => ({ from: id })),
        sendGuestSms: !!guest.phoneNumber && sendGuestSmsDefault,
        sendGuestEmail: !!guest.email && sendGuestEmailDefault,
        notifyRestaurant: sendRestaurantEmail,
        destinationRestaurantHash: requestedRoom.restaurantId,
        destinationRestaurantId,
        destinationRoomId: requestedRoom.id,
        reservationId,
      },
    })

  useEffect(() => {
    if (requestedRoom || !dialogProps.open) reset()
  }, [dialogProps.open, requestedRoom, reset])

  const guestSmsEnabled = watch('sendGuestSms')

  const smsTemplates = useCommunicationTemplates(
    'change_restaurant',
    selectedReservation,
    !!guestSmsEnabled,
    'sms',
  )

  const labelValues = watch('labels')

  const areButtonsDisabled =
    changeRestaurantOfReservationMutation.isPending ||
    changeRestaurantOfReservationMutation.isSuccess

  const handleCancel = combine([dialogProps.handleClose, onCancel])
  const handleSuccess = combine([dialogProps.handleClose, onSuccess])

  const submitHandler = handleSubmit(data =>
    changeRestaurantOfReservationMutation.mutate(
      {
        ...data,
        customSmsTemplateId: smsTemplates.selectedTemplate.id,
      },
      {
        onSuccess: handleSuccess,
      },
    ),
  )

  return (
    <Modal
      title={t('reservation_edit.move_to_another_restaurant.title', {
        defaultValue: 'Restaurant change',
      })}
      open={dialogProps.open}
      onClose={handleCancel}
      actions={
        <>
          <Button
            variant="outlined"
            color="neutral"
            onClick={handleCancel}
            disabled={areButtonsDisabled}
            fullWidth
          >
            {t('modal.button.dismiss')}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={areButtonsDisabled}
            fullWidth
          >
            {t('reservation_edit.move_to_another_restaurant.confirm', {
              defaultValue: 'Yes, move reservation',
            })}
          </Button>
        </>
      }
      component="form"
      onSubmit={submitHandler}
    >
      <Stack gap={3}>
        <Typography>{t('angular.room_of_another_restaurant')}</Typography>

        {labels.length > 0 && (
          <>
            <Typography>{t('angular.what_to_do_with_labels')}</Typography>
            <Stack gap={1}>
              {labels.map((labelId, idx) => {
                const label = currentLabels.find(({ id }) => labelId === id)

                return (
                  <Stack key={labelId}>
                    <Typography>{label?.name}</Typography>
                    <Select
                      value={labelValues[idx]?.to ?? 0}
                      onChange={e => {
                        const path = [idx, 'to']
                        const newValue = Number(e.target.value)

                        setValue(
                          'labels',
                          newValue
                            ? assocPath(path, newValue, labelValues)
                            : dissocPath(path, labelValues),
                        )
                      }}
                    >
                      <MenuItem value={0}>{t('angular.no_label')}</MenuItem>
                      {destinationLabels.map(({ id, name }) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                )
              })}
            </Stack>
          </>
        )}
        {(!!guest.email || !!guest.phoneNumber) && (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr'],
              justifyItems: 'start',
              alignItems: 'center',
              gap: [2, 1],
            }}
          >
            {!!guest.phoneNumber && (
              <Checkbox
                label={t('angular.sms_to_guest')}
                defaultChecked={sendGuestSmsDefault}
                {...register('sendGuestSms')}
              />
            )}
            {!!guest.email && (
              <Checkbox
                label={t('angular.email_to_guest')}
                defaultChecked={sendGuestEmailDefault}
                {...register('sendGuestEmail')}
              />
            )}
          </Box>
        )}
        <SingleSelectDropdown
          label={t('angular.select_sms')}
          options={smsTemplates.templates}
          value={smsTemplates.selectedTemplate}
          onSelect={smsTemplates.setSelectedTemplate}
          renderOption={getTemplateName(t)}
          renderKey={template => template.id}
          disabled={!guestSmsEnabled}
        />
      </Stack>
    </Modal>
  )
}
