import { useRef, useState, type PropsWithChildren } from 'react'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from '@mui/material'

import useOnClickOutsideEffect from '../../dialogs/hooks/useOnClickOutsideEffect'

interface OutlinedContainerProps {
  label: string
  error?: string
}

const OutlinedContainer = ({
  label,
  error = '',
  ...props
}: PropsWithChildren<OutlinedContainerProps>) => {
  const [focused, setFocused] = useState(false)

  const inputRef = useRef(null)

  useOnClickOutsideEffect(inputRef, () => setFocused(false))

  return (
    <FormControl fullWidth focused={focused} error={!!error}>
      <InputLabel shrink variant="outlined">
        {label}
      </InputLabel>
      <OutlinedInput
        onClick={() => setFocused(true)}
        label={label}
        ref={inputRef}
        multiline
        notched
        inputComponent="section"
        inputProps={props}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default OutlinedContainer
