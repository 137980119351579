import React, { useCallback, useMemo } from 'react'
import { compose } from 'ramda'

import { useTranslation } from 'react-i18next'

import type { ServiceTimeInterface } from 'src/entities/config/types/configApi'
import {
  useServiceTimesQuery,
  useServiceTimeUpdateMutation,
} from 'src/entities/service-time/queries/serviceTime'
import {
  emptyServiceTime,
  type EmptyServiceTime,
} from 'src/entities/service-time/types/serviceTime'
import { SettingsScreensEnum } from 'src/entities/setting/types/settings'
import { useDefaultMutationHandlers } from 'src/pages/ShiftConfiguration/hooks/useDefaultMutationHandlers'
import EmptyView from 'src/shared/components/common/EmptyView'
import Modal from 'src/shared/components/common/Modal'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import EditForm from './EditForm'
import { ServiceTimeTable } from './Table/ServiceTimeTable'
import { ConfigScroll } from '../layout/ConfigScroll'
import { SettingsHeader } from '../layout/SettingsHeader'

interface ServiceTimeProps {
  restaurantId: number
}

const ServiceTimes = ({ restaurantId }: ServiceTimeProps) => {
  const { t } = useTranslation()

  const [editedServiceTime, setEditedServiceTime] = React.useState<
    ServiceTimeInterface | EmptyServiceTime | undefined
  >(undefined)

  const { handleClose, handleOpen, open } = useDialog()

  const { data: serviceTimes } = useServiceTimesQuery()
  const { mutateAsync: updateServiceTime } = useServiceTimeUpdateMutation()
  const addHandler = useDefaultMutationHandlers({
    successMessage: t(
      'settings.service_times.common.service_time_added',
      'Service time added',
    ),
  })
  const editHandler = useDefaultMutationHandlers({
    successMessage: t(
      'settings.service_times.common.service_time_changed',
      'Service time changed',
    ),
  })

  const isNew = !editedServiceTime?.id

  const handleSubmit = useCallback(
    async (st: ServiceTimeInterface) => {
      await updateServiceTime(st, isNew ? addHandler : editHandler)
      handleClose()
    },
    [addHandler, editHandler, handleClose, isNew, updateServiceTime],
  )

  const handleEdit = useMemo(
    () => compose(handleOpen, setEditedServiceTime),
    [handleOpen],
  )

  return (
    <>
      <SettingsHeader
        restaurantId={restaurantId}
        screen={SettingsScreensEnum.ServiceTimes}
        primaryButtonProps={{
          onClick: () => handleEdit(emptyServiceTime),
          children: t('settings.service_times.addLabel'),
        }}
      />
      {serviceTimes.length ? (
        <ConfigScroll>
          <ServiceTimeTable serviceTimes={serviceTimes} onEdit={handleEdit} />
        </ConfigScroll>
      ) : (
        <EmptyView
          text={t(
            'settings.service_times.no_service_times_defined',
            'No service times defined in the system.',
          )}
        />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        title={
          isNew
            ? t('settings.service_times.new_modal_title', 'Add service time')
            : t('settings.service_times.modalTitle')
        }
      >
        <EditForm
          serviceTime={editedServiceTime}
          serviceTimes={serviceTimes}
          onCancel={handleClose}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  )
}

export default ServiceTimes
