import React, { useCallback } from 'react'
import { compose, lensPath, map, prop } from 'ramda'

import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
  type UseSuspenseQueryOptions,
} from '@tanstack/react-query'

import { useEnsureFurniture } from 'src/entities/floor-plan/queries/furniture'
import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'
import { useOptimisticUpdate } from 'src/shared/lib/api/queries/optimisticUpdate'
import { useRestaurantCacheKeyFactory } from 'src/shared/lib/api/queries/useRestaurantCacheKey'
import useSseEventListenerEffect from 'src/shared/lib/api/sse/useSseEventListenerEffect'
import {
  replaceBy,
  toggleBy,
} from 'src/shared/lib/common/services/functional/functional'
import { getUtcDayStart } from 'src/shared/lib/range/services/date'
import {
  type FloorPlanTableInterface,
  type FurnitureInterface,
} from 'src/widgets/FloorPlan/types/floorPlanElement'
import {
  useConfigCacheKey,
  useConfigQuery,
  useConfigRestaurantsQueries,
} from '../../config/queries/config'
import {
  type ApiConfig,
  type ApiTable,
  type ServiceTimeInterface,
  type TableLock,
} from '../../config/types/configApi'
import {
  useElementRemoval,
  useElementUpdate,
} from '../../floor-plan/queries/floorPlanElement'
import { NotificationTypeEnum } from '../../notification/types/notification'
import {
  getLocks,
  getNotes,
  setLock,
  setNote,
  setTablesOrder,
} from '../api/tableApi'
import {
  type TableNoteInterface,
  type TableNoteUpdateInterface,
} from '../types/table'
import { tableFromApi } from '../types/tableApi'

const configTableLens = lensPath<ApiConfig, ApiTable[]>(['tables'])

const tablesQueryOptions = {
  select: compose(map(tableFromApi), prop('tables')),
} satisfies Partial<
  UseSuspenseQueryOptions<ApiConfig, unknown, FloorPlanTableInterface[]>
>

export const useEnsureTables = () => {
  const queryClient = useQueryClient()
  const queryKey = useConfigCacheKey()

  return useCallback(
    async () =>
      tablesQueryOptions.select(
        await queryClient.ensureQueryData({ queryKey }),
      ),
    [queryClient, queryKey],
  )
}

export const useTablesQuery = () => useConfigQuery(tablesQueryOptions)

export const useFullTableCapacity = () => {
  const { data: tables } = useTablesQuery()

  return React.useMemo(
    () => tables.reduce((s, n) => s + n.capacity, 0),
    [tables],
  )
}

export const useRestaurantsTables = () =>
  useConfigRestaurantsQueries(() => tablesQueryOptions)

export const useTableUpdate = () => useElementUpdate(configTableLens)

export const useTableRemoval = () => useElementRemoval(configTableLens)

export const useTablesOrderUpdate = (
  onSuccess: (
    newTables: FloorPlanTableInterface[],
    newFurniture: FurnitureInterface[],
  ) => void,
) => {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()
  const cacheKey = useConfigCacheKey()

  const ensureTables = useEnsureTables()
  const ensureFurniture = useEnsureFurniture()

  return useMutation({
    mutationFn: setTablesOrder(apiClient.post),
    onMutate: () => queryClient.cancelQueries({ queryKey: cacheKey }),
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: cacheKey })
      await queryClient.refetchQueries({ queryKey: cacheKey })
      const newTables = await ensureTables()
      const newFurniture = await ensureFurniture()
      onSuccess(newTables, newFurniture)
    },
    onError: () => queryClient.invalidateQueries({ queryKey: cacheKey }),
  })
}

const NOTES_CACHE_KEY = ['tableNotes']
const useTableNoteCacheKeyFactory = () => {
  const keyFactory = useRestaurantCacheKeyFactory(NOTES_CACHE_KEY)

  return React.useCallback(
    (date: Date, serviceTimeId?: number | null) =>
      keyFactory([getUtcDayStart(date), serviceTimeId]),
    [keyFactory],
  )
}

const LOCKS_CACHE_KEY = ['tableLocks']
const useTableLocksCacheKeyFactory = () => {
  const keyFactory = useRestaurantCacheKeyFactory(LOCKS_CACHE_KEY)

  return React.useCallback(
    (date: Date) => keyFactory([getUtcDayStart(date)]),
    [keyFactory],
  )
}

export const useTablesNotesQuery = (
  date: Date,
  serviceTime?: ServiceTimeInterface | null,
) => {
  const apiClient = useApiClient()
  const keyFactory = useTableNoteCacheKeyFactory()

  return useSuspenseQuery({
    queryKey: keyFactory(date, serviceTime?.id),
    queryFn: () => getNotes(apiClient.post)(date, serviceTime?.id),
    refetchOnMount: false,
    staleTime: 5 * 60 * 1000,
    refetchInterval: 5 * 60 * 1000,
  })
}

export const useTableNoteUpdate = () => {
  const queryClient = useQueryClient()
  const keyFactory = useTableNoteCacheKeyFactory()

  return React.useCallback(
    (tableNoteUpdate: TableNoteUpdateInterface) => {
      queryClient.setQueryData<TableNoteInterface[]>(
        keyFactory(tableNoteUpdate.date, tableNoteUpdate.serviceTimeId),
        cachedNotes =>
          cachedNotes
            ? replaceBy(r => r.tableId, tableNoteUpdate.tableNote, cachedNotes)
            : cachedNotes,
      )
    },
    [keyFactory, queryClient],
  )
}

export const useTableNoteMutation = () => {
  const { post } = useApiClient()

  return useMutation({ mutationFn: setNote(post) })
}

export const useTableLocksQuery = (date: Date) => {
  const apiClient = useApiClient()
  const keyFactory = useTableLocksCacheKeyFactory()

  return useSuspenseQuery({
    queryKey: keyFactory(date),
    queryFn: () => getLocks(apiClient.post)(date),
    refetchOnMount: false,
    staleTime: 5 * 60 * 1000,
    refetchInterval: 5 * 60 * 1000,
  })
}

const useTableLockOptimisticUpdate = () => {
  const update = useOptimisticUpdate()
  const keyFactory = useTableLocksCacheKeyFactory()

  return React.useCallback(
    (date: Date, updater: (oldLocks: TableLock[]) => TableLock[]) =>
      update(keyFactory(date), updater),
    [keyFactory, update],
  )
}
export const useTableLockMutation = () => {
  const { post } = useApiClient()
  const updateLocks = useTableLockOptimisticUpdate()

  return useMutation({
    mutationFn: setLock(post),
    onSuccess: (_data, { date, tableId, serviceTimeId }) => {
      updateLocks(date, oldLocks =>
        toggleBy(
          lock => `${lock.tableId}_${lock.serviceTimeId}`,
          { tableId, serviceTimeId },
          oldLocks,
        ),
      )
    },
  })
}

export const useTableSseEffect = () => {
  const tableOptimisticUpdate = useTableUpdate()
  useSseEventListenerEffect(
    NotificationTypeEnum.TableUpdated,
    React.useCallback(
      ({ data }) => {
        tableOptimisticUpdate(data.payload)
      },
      [tableOptimisticUpdate],
    ),
  )

  const removeTable = useTableRemoval()
  useSseEventListenerEffect(
    NotificationTypeEnum.TableRemoved,
    React.useCallback(
      ({ data }) => {
        removeTable(data.payload)
      },
      [removeTable],
    ),
  )

  const updateNote = useTableNoteUpdate()
  useSseEventListenerEffect(
    NotificationTypeEnum.TableNoteSet,
    React.useCallback(
      ({ data }) => {
        updateNote(data.payload)
      },
      [updateNote],
    ),
  )
}
