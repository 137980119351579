import { createFileRoute } from '@tanstack/react-router'

import Permissions from 'src/pages/Settings/Permissions'

export const Route = createFileRoute(
  '/restaurants/$restaurantId/config/permissions',
)({
  component: RouteComponent,
})

function RouteComponent() {
  const { restaurantId } = Route.useParams()

  return <Permissions restaurantId={restaurantId} />
}
