import {
  TableCell as MuiTableCell,
  Stack,
  TableRow,
  Typography,
} from '@mui/material'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { type AreaInterface } from 'src/entities/area/types/area'
import { useRoomsQuery } from 'src/entities/schedule/queries/schedule'
import SquareIconButton from 'src/shared/components/buttons/SquareIconButton'
import { SimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/SimpleDialog'
import { useSimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/useSimpleDialog'
import DragIcon from 'src/shared/components/icons/DragIcon'
import PencilIcon from 'src/shared/components/icons/PencilIcon'
import TrashIcon from 'src/shared/components/icons/TrashIcon'

interface DraggableAreaItemProps {
  area: AreaInterface
  onEdit: () => void
  onDelete: () => void
  isBeingReordered: boolean
  isReorderingDisabled: boolean
}

const TableCell = styled(MuiTableCell)({ verticalAlign: 'middle' })

export const DraggableAreaItem = ({
  area,
  isBeingReordered,
  isReorderingDisabled,
  onEdit,
  onDelete,
}: DraggableAreaItemProps) => {
  const { t } = useTranslation()

  const { data: rooms } = useRoomsQuery()
  const areaRoom = rooms.find(room => room.id === area.roomId)

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: area.id })

  const { showSimpleDialog, simpleDialogProps } = useSimpleDialog()

  return (
    <>
      <TableRow
        ref={setNodeRef}
        sx={{ transform: CSS.Translate.toString(transform), transition }}
      >
        <TableCell>
          <Stack direction="row" alignItems="center" gap={0.5}>
            <DragIcon
              sx={{ cursor: isReorderingDisabled ? 'not-allowed' : 'move' }}
              disabled={isReorderingDisabled}
              {...attributes}
              {...listeners}
            />
            <Typography variant="labelSmall">{area.name}</Typography>
          </Stack>
        </TableCell>

        <TableCell sx={{ fontWeight: 'normal' }}>
          {areaRoom?.name ?? t('settings.areas.emptyRoom')}
        </TableCell>

        <TableCell>
          <Stack direction="row" gap={0.25} justifyContent="flex-end">
            <SquareIconButton
              color="neutral"
              variant="outlined"
              onClick={onEdit}
              disabled={isBeingReordered}
            >
              <PencilIcon variant="filled" />
            </SquareIconButton>
            <SquareIconButton
              color="neutral"
              variant="outlined"
              onClick={() => showSimpleDialog()}
              disabled={isBeingReordered}
              sx={{
                ':disabled': { pointerEvents: 'all', cursor: 'not-allowed' },
              }}
            >
              <TrashIcon variant="filled" />
            </SquareIconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <SimpleDialog
        color="error"
        title={t('common.caution', 'Caution')}
        message={t(
          'settings.areas.delete_disclaimer',
          'Are you sure you want to delete this area? This action cannot be reversed.',
        )}
        onConfirmation={onDelete}
        {...simpleDialogProps}
      />
    </>
  )
}
