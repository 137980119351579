import { equals, sortBy } from 'ramda'

import { z } from 'zod'

import { convertKeysToCamelCase } from 'src/shared/lib/api/services/api'
import {
  fromDateString,
  fromTime,
  getBoundaries,
} from 'src/shared/lib/range/services/date'
import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'
import { colorSchema } from 'src/shared/lib/style/types/color'
import {
  FloorPlanElementShape,
  FloorPlanElementType,
} from 'src/widgets/FloorPlan/types/floorPlanElement'

export const elementSchema = z.object({
  id: z.number().gt(0),
  areaId: z.number().gt(0),
  rotation: z.number().default(0).catch(0),
  left: z.number(),
  top: z.number(),
  elementType: z.nativeEnum(FloorPlanElementType),
})

const trel = elementSchema.transform(({ areaId, ...t }) => ({
  ...t,
  area_id: areaId,
}))

export type ApiElement = z.infer<typeof trel>

export const tableSchema = z
  .preprocess(
    convertKeysToCamelCase,
    elementSchema.extend({
      type: z.nativeEnum(FloorPlanElementShape),
      size: z.number(),
      name: z.coerce.string(),
      places: z.number(),
      elementType: z
        .nativeEnum(FloorPlanElementType)
        .default(FloorPlanElementType.Table),
    }),
  )
  .transform(({ areaId, ...t }) => ({ ...t, area_id: areaId }))

export type ApiTable = z.infer<typeof tableSchema>

const furnitureSchema = z
  .preprocess(
    convertKeysToCamelCase,
    elementSchema.extend({
      width: z.number(),
      height: z.number(),
      color: colorSchema,
      elementType: z
        .nativeEnum(FloorPlanElementType)
        .default(FloorPlanElementType.Furniture),
    }),
  )
  .transform(({ areaId, ...t }) => ({ ...t, area_id: areaId }))

export type ApiFurniture = z.infer<typeof furnitureSchema>

const roomSchema = z.object({
  id: z.number().gt(0),
  room_name: z.coerce.string(),
  restaurant_hash: z.string(),
})

export type ApiRoom = z.infer<typeof roomSchema>

const areaSchema = z.object({
  id: z.number().gt(0),
  name: z.coerce.string(),
  position: z.number(),
  roomId: z.number(),
  capacity: z.number(),
  days: z.array(z.number()),
})

const timeRangeSchema = z
  .object({
    id: z.number().gt(0),
    name: z.coerce.string(),
    start_time: z.string().transform(fromTime),
    end_time: z.string().transform(fromTime),
  })
  .transform(tr => ({
    id: tr.id,
    name: tr.name,
    timeRange: [tr.start_time, tr.end_time] as DefinedRangeInterface<Date>,
  }))

export type ApiTimeRange = z.infer<typeof timeRangeSchema>

export const labelSchema = z.object({
  id: z.number().gt(0),
  label: z.coerce.string(),
  color: colorSchema,
  position: z.number().default(0),
})

export type ApiLabel = z.infer<typeof labelSchema>

const paymentProviderSchema = z
  .object({
    currency: z.string().length(3),
    providerName: z.string(),
  })
  .nullable()

export type PaymentProvider = z.infer<typeof paymentProviderSchema>

const settingSchema = z.coerce.number().catch(2).transform(equals(1))

export const configSchema = z.object({
  tables: z.array(tableSchema),
  room_elements: z.array(furnitureSchema),
  rooms: z.array(roomSchema),
  areas: z.preprocess(convertKeysToCamelCase, z.array(areaSchema)),
  time_ranges: z.array(timeRangeSchema),
  labels: z.array(labelSchema).transform(sortBy(l => l.position)),
  config: z.preprocess(
    convertKeysToCamelCase,
    z.object({
      mutationGuestSms: settingSchema,
      mutationGuestEmail: settingSchema,
      mutationGastroEmail: settingSchema,
      cancelGuestSms: settingSchema,
      cancelGuestEmail: settingSchema,
      cancelGastroEmail: settingSchema,
      newReservationGuestSms: settingSchema,
      newReservationGuestEmail: settingSchema,
      newReservationGastroEmail: settingSchema,
      slotDisplay: settingSchema,
      showCancelled: settingSchema,
      defaultListPlan: settingSchema,
      defaultVisuellPlan: settingSchema,
      defaultListView: settingSchema,
      defaultTimeline: settingSchema,
      autoLogout: settingSchema,
      reminderEmail: settingSchema,
      feedbackEmail: settingSchema,
      guestMerge: settingSchema,
      noshowGuestSms: settingSchema,
      pinEnabled: settingSchema,
      changeRestaurantGuestEmail: settingSchema,
      changeRestaurantGuestSms: settingSchema,
      changeRestaurantGastroEmail: settingSchema,
      noshowGuestEmail: settingSchema,
      slotPicker: settingSchema,
    }),
  ),
  payment_gateway: z.preprocess(convertKeysToCamelCase, paymentProviderSchema),
  holidays: z.array(
    z
      .object({
        from: z.string().transform(fromDateString),
        to: z.string().transform(fromDateString),
        room_id: z.number().nullable(),
      })
      .transform(h => ({
        range: [
          getBoundaries(h.from)[0],
          getBoundaries(h.to)[1],
        ] as DefinedRangeInterface<Date>,
        roomId: Number(h.room_id),
      })),
  ),
})

export type ApiConfig = z.infer<typeof configSchema>
export type ApiSettings = ApiConfig['config']
export type SettingsKeys = keyof ApiSettings
export type Holiday = z.infer<typeof configSchema>['holidays'][0]
export type ServiceTimeInterface = z.infer<typeof timeRangeSchema>

export const tableLockSchema = z.preprocess(
  convertKeysToCamelCase,
  z.object({
    tableId: z.number(),
    serviceTimeId: z.number(),
  }),
)

export type TableLock = z.infer<typeof tableLockSchema>
