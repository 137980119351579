import { useCallback, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { showToast } from 'src/shared/components/common/toast'
import { ApiError } from 'src/shared/lib/api/types/api'

interface UseDefaultMutationHandlersOptions {
  onSuccess?: () => void
  successMessage?: string
  onError?: (e: Error) => void
}

export const useDefaultMutationHandlers = ({
  onSuccess,
  successMessage,
  onError,
}: UseDefaultMutationHandlersOptions = {}) => {
  const { t } = useTranslation()

  const handleSuccess = useCallback(() => {
    onSuccess?.()
    showToast(
      successMessage ?? t('common.save_successful', 'Save successful'),
      'success',
    )
  }, [onSuccess, successMessage, t])

  const handleError = useCallback(
    (e: Error) => {
      const message = (e instanceof ApiError && e.message) || undefined

      showToast(
        t('common.something_went_wrong', 'Something went wrong'),
        'error',
        message &&
          t(
            `schedule.api_errors.${message}`,
            `Placeholder for schedule.api_errors.${message}`,
          ),
      )

      onError?.(e)
    },
    [onError, t],
  )

  return useMemo(
    () => ({ onSuccess: handleSuccess, onError: handleError }),
    [handleError, handleSuccess],
  )
}
