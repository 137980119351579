import { useCallback, useMemo } from 'react'

import { isBackoffice } from 'src/app/apps/backoffice/isBackoffice'
import { useUserType } from 'src/entities/me/queries/me'
import { UserType } from 'src/entities/me/types/me'
import useCurrentTeamMember from './useCurrentTeamMember'
import useFeature from '../../info/hooks/useFeature'
import { useTeamRolesQuery } from '../queries/teamMember'
import { type Permission } from '../types/teamMember'

export const useCheckPermissions = () => {
  /**
   * Backoffice has all permissions
   * Rules of hooks are disabled, cause `isBackoffice` will never change
   */
  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (isBackoffice()) return useCallback(() => true, [])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const featureEnabled = useFeature('pinEnabled')
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const teamMember = useCurrentTeamMember()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: roles } = useTeamRolesQuery()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userType = useUserType()

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const permissionSet = useMemo(() => {
    const { permissions } =
      roles.find(
        r =>
          (userType === UserType.RestaurantManager && r.role === 'manager') ||
          r.role === teamMember?.role,
      ) ?? {}
    if (!permissions) return null

    return new Set(permissions)
  }, [roles, teamMember?.role, userType])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (requiredPermissions: Permission[]) => {
      if (
        !featureEnabled ||
        teamMember?.role === 'admin' ||
        userType === UserType.OrganisationAdmin
      ) {
        return true
      }

      return requiredPermissions.every(permission =>
        permissionSet?.has(permission),
      )
    },
    [featureEnabled, permissionSet, teamMember?.role, userType],
  )
}

export const useHasPermission = (requiredPermission: Permission) => {
  const checkPermissions = useCheckPermissions()

  return useMemo(
    () => checkPermissions([requiredPermission]),
    [checkPermissions, requiredPermission],
  )
}
