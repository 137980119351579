import { type ComponentProps } from 'react'
import {
  FormControl,
  Stack,
  TextField,
  type FormControlProps,
  type TextFieldProps,
} from '@mui/material'

import Tooltip from '../../../common/Tooltip'
import { FakeLabel } from '../../common/FakeLabel/FakeLabel'
import FormErrorText from '../../common/FormErrorText/FormErrorText'

export type FormTextInputProps = TextFieldProps & {
  errorText?: string | boolean
  tooltipText?: string
  formControlProps?: FormControlProps
  labelProps?: ComponentProps<typeof FakeLabel>
}

const FormTextInput = ({
  errorText,
  helperText,
  tooltipText,
  label,
  fullWidth = true,
  formControlProps,
  labelProps,
  slotProps,
  ...props
}: FormTextInputProps) => (
  <FormControl
    size={props.size}
    disabled={props.disabled}
    fullWidth={fullWidth}
    {...formControlProps}
  >
    {label && <FakeLabel {...labelProps}>{label}</FakeLabel>}
    <Stack direction="row" spacing={1} alignItems="center">
      <TextField
        error={!!errorText}
        fullWidth={fullWidth}
        slotProps={{
          ...slotProps,
          htmlInput: {
            autofill: 'off',
            ...slotProps?.htmlInput,
          },
        }}
        {...props}
        helperText={
          (errorText ?? helperText) && (
            <FormErrorText
              error={!!errorText}
              helperText={errorText ?? helperText}
            />
          )
        }
      />
      <Tooltip text={tooltipText} />
    </Stack>
  </FormControl>
)

export default FormTextInput
