import React from 'react'
import { Box, Divider, Menu, menuClasses, Stack, Tooltip } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import SizeableMenuItem from 'src/shared/components/common/SizeableMenuItem'
import {
  getActionGroups,
  translateAction,
  type isActionPossible,
  type ReservationActions,
} from '../reservationActions'

interface ActionsMenuProps {
  reservation: ReservationInterface
  onActionChoice: (action: ReservationActions) => void
  anchorEl: React.RefObject<HTMLDivElement | null>
  onClose: () => void
  open: boolean
  isActionEnabled: ReturnType<typeof isActionPossible>
}

export const minWidth = 120

const ActionsMenu = ({
  onActionChoice,
  reservation,
  open,
  onClose,
  anchorEl,
  isActionEnabled,
}: ActionsMenuProps) => {
  const { t } = useTranslation()

  const availableActionGroups = React.useMemo(
    () => getActionGroups(reservation),
    [reservation],
  )

  return (
    <Menu
      MenuListProps={{ dense: true }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      anchorEl={anchorEl.current}
      open={open}
      onClose={onClose}
      sx={{
        [`.${menuClasses.list}`]: { minWidth },
      }}
    >
      <Stack direction="column" divider={<Divider />} spacing={1}>
        {availableActionGroups.map(group => (
          <Stack direction="column" key={group.type}>
            {group.actions.map(a => {
              const isEnabledWithReason = isActionEnabled(a)

              const isDisabled =
                typeof isEnabledWithReason === 'string' || !isEnabledWithReason
              const disabledReason =
                isDisabled && typeof isEnabledWithReason === 'string'
                  ? isEnabledWithReason
                  : null

              const item = (
                <SizeableMenuItem
                  key={a}
                  size="small"
                  onClick={() => onActionChoice(a)}
                  disabled={isDisabled}
                  disableGutters
                  value={a}
                >
                  {translateAction(t)(a)}
                </SizeableMenuItem>
              )

              if (disabledReason)
                return (
                  <Tooltip
                    key={a}
                    title={t(
                      `reservation_drawer.footer.action_button.disabled_reasons.${disabledReason}`,
                      disabledReason,
                    )}
                    enterTouchDelay={0}
                    placement="bottom-end"
                    arrow
                  >
                    <Box>{item}</Box>
                  </Tooltip>
                )

              return item
            })}
          </Stack>
        ))}
      </Stack>
    </Menu>
  )
}

export { ActionsMenu }
