import { useUserType } from 'src/entities/me/queries/me'
import { UserType } from 'src/entities/me/types/me'
import useCurrentTeamMember from './useCurrentTeamMember'
import { isAdmin } from '../services/teamMember'

export const useIsRestaurantAdmin = () => {
  const userType = useUserType()
  const currentTeamMember = useCurrentTeamMember()

  return isAdmin(currentTeamMember) || userType === UserType.OrganisationAdmin
}
