import { useCallback, type Dispatch, type SetStateAction } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  type TableContainerProps,
} from '@mui/material'

import { DndContext, type DragEndEvent } from '@dnd-kit/core'
import { arrayMove, SortableContext } from '@dnd-kit/sortable'
import { useTranslation } from 'react-i18next'

import { useDeleteLabelMutation } from 'src/entities/label/queries/label'
import { type PersistedLabelInterface } from 'src/entities/label/types/label'
import { useDefaultMutationHandlers } from 'src/pages/ShiftConfiguration/hooks/useDefaultMutationHandlers'
import { useBasicDndSensors } from 'src/shared/lib/common/hooks/useDnd'
import { DraggableLabelItem } from './DraggableLabelItem'

interface LabelTableProps {
  orderedLabels: PersistedLabelInterface[]
  setOrderedLabels: Dispatch<SetStateAction<PersistedLabelInterface[]>>
  isOrderPending: boolean
  isBeingReordered: boolean
  onEdit: (label: PersistedLabelInterface) => void
  tableContainerProps?: TableContainerProps
}

export const LabelTable = ({
  orderedLabels,
  setOrderedLabels,
  isOrderPending,
  isBeingReordered,
  onEdit,
  tableContainerProps,
}: LabelTableProps) => {
  const { t } = useTranslation()

  const { mutateAsync: deleteLabel, isPending: isDeletePending } =
    useDeleteLabelMutation()

  const isAnyPending = isDeletePending || isOrderPending

  const deleteHandler = useDefaultMutationHandlers({
    successMessage: t('settings.labels.common.label_deleted', 'Label removed'),
  })

  const sensors = useBasicDndSensors()

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event

      if (!over || active.id === over.id) return

      setOrderedLabels(oldLabels => {
        const oldIndex = oldLabels.findIndex(label => label.id === active.id)
        const newIndex = oldLabels.findIndex(label => label.id === over.id)

        return arrayMove(oldLabels, oldIndex, newIndex)
      })
    },
    [setOrderedLabels],
  )

  return (
    <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
      <TableContainer {...tableContainerProps}>
        <Table stickyHeader sx={{ overflow: 'clip' }}>
          <TableHead sx={{ borderColor: 'inherit' }}>
            <TableRow>
              <TableCell sx={{ width: '1%', whiteSpace: 'nowrap' }}>
                {t('settings.labels.table.name', 'Label name')}
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody sx={{ borderColor: 'inherit' }}>
            <SortableContext items={orderedLabels} disabled={isAnyPending}>
              {orderedLabels.map(l => (
                <DraggableLabelItem
                  key={l.id}
                  label={l}
                  isDisabled={isAnyPending}
                  isBeingReordered={isBeingReordered}
                  onEdit={() => onEdit(l)}
                  onDelete={() => deleteLabel(l, deleteHandler)}
                />
              ))}
            </SortableContext>
          </TableBody>
        </Table>
      </TableContainer>
    </DndContext>
  )
}
