import { type Theme } from '@mui/material'
import { type TypographyOptions } from '@mui/material/styles/createTypography'

export const themeTypography = (theme: Theme): TypographyOptions => ({
  body1: {
    ...theme.typography.body1,
    fontSize: '1.125rem',
    lineHeight: 1.56,
  },
  body2: {
    ...theme.typography.body1,
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  body3: {
    ...theme.typography.body1,
    fontSize: '0.875rem',
    lineHeight: 1.43,
  },
  labelGiant: {
    ...theme.typography.body1,
    fontWeight: 700,
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem',
      lineHeight: '2.5rem',
    },
  },
  labelHuge: {
    ...theme.typography.body1,
    fontWeight: 600,
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },
  },
  labelBig: {
    ...theme.typography.body1,
    fontWeight: 600,
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
  },
  labelRegular: {
    ...theme.typography.body1,
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  labelSmall: {
    ...theme.typography.body1,
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
  },
  labelTiny: {
    ...theme.typography.body1,
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
  },
  labelExtrasmall: {
    ...theme.typography.body1,
    fontWeight: 600,
    fontSize: '0.625rem',
    lineHeight: '1rem',
    textTransform: 'uppercase',
    letterSpacing: '0.08em',
  },
  h3: {
    ...theme.typography.body1,
    fontWeight: 600,
    fontSize: '1.75rem',
    lineHeight: 1.36,
  },
})
