import { Stack, type SxProps } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { mergeSx } from 'src/app/theme/helpers'
import { SECONDARY_TABLE_BG } from 'src/entities/floor-plan/services/floorPlanElement'
import { type TableInterface } from 'src/entities/table/types/table'
import EllipsisTypography from 'src/pages/Settings/Areas/EllipsisTypography'
import TableCapacity from './TableCapacity'

const TableItemInfo = ({
  table,
  sx,
}: {
  table: TableInterface
  sx?: SxProps
}) => (
  <Stack
    sx={mergeSx(
      {
        alignItems: 'center',
        maxWidth: 1,
        height: 1,
        width: 1,
        p: 0.5,
        bgcolor: SECONDARY_TABLE_BG,
        typography: 'body3',
      },
      sx,
    )}
    direction="row"
    useFlexGap
    spacing={1}
  >
    <TableCapacity capacity={table.capacity} />
    <EllipsisTypography
      {...createTestIdProps('table-name')}
      typography="inherit"
      sx={{ maxWidth: 1 }}
    >
      {table.name}
    </EllipsisTypography>
  </Stack>
)

export default TableItemInfo
