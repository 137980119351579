import React, { type ComponentProps, type ReactNode } from 'react'
import { Dialog } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { FullScreenModalHeader } from './components/FullScreenModalHeader'
import CircularProgress from '../../common/CircularProgress'
import CenteredBox from '../../containers/CenteredBox'

interface FullScreenModalProps
  extends Partial<ComponentProps<typeof FullScreenModalHeader>> {
  children: ReactNode
  isOpen: boolean
  onExited?: () => void
}

export const FullScreenModal = ({
  children,
  isOpen,
  onClose,
  title,
  onExited,
}: FullScreenModalProps) => (
  <Dialog
    {...createTestIdProps('full-screen-modal')}
    fullScreen
    open={isOpen}
    onClose={onClose}
    PaperProps={{ sx: { maxWidth: 'none' } }}
    TransitionProps={{
      onExited,
    }}
  >
    {!!title && !!onClose && (
      <FullScreenModalHeader title={title} onClose={onClose} />
    )}
    <React.Suspense
      fallback={
        <CenteredBox>
          <CircularProgress />
        </CenteredBox>
      }
    >
      {children}
    </React.Suspense>
  </Dialog>
)
