import React from 'react'

export const useIntersectionObserverRef = (
  ...args: ConstructorParameters<typeof IntersectionObserver>
) => {
  const intersectionObserverRef = React.useRef<IntersectionObserver>(undefined)

  React.useLayoutEffect(() => {
    intersectionObserverRef.current = new IntersectionObserver(...args)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return intersectionObserverRef
}
