import React from 'react'
import { compose, prop } from 'ramda'

import {
  useMutation,
  useQueryClient,
  type UseSuspenseQueryOptions,
} from '@tanstack/react-query'

import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'
import { useRestaurantCacheKey } from 'src/shared/lib/api/queries/useRestaurantCacheKey'
import { updateConfig } from '../../config/api/configApi'
import { CONFIG_CACHE_KEY, useConfigQuery } from '../../config/queries/config'
import {
  type ApiConfig,
  type ApiSettings,
  type SettingsKeys,
} from '../../config/types/configApi'
import { fromApi } from '../types/settingsApi'

const SETTINGS_CACHE_KEY = CONFIG_CACHE_KEY

const useSettingsCacheKey = () => useRestaurantCacheKey(SETTINGS_CACHE_KEY)

const settingsQueryOptions: Partial<
  UseSuspenseQueryOptions<ApiConfig, Error, ReturnType<typeof fromApi>>
> = {
  select: compose(fromApi, prop('config')),
}

export const useSettingsQuery = (
  options: Partial<
    UseSuspenseQueryOptions<ApiConfig, Error, ReturnType<typeof fromApi>>
  > = {},
) => useConfigQuery({ ...settingsQueryOptions, ...options })

const settingsObjectQueryOptions = { select: ({ config }: ApiConfig) => config }

export const useSettingsObjectQuery = () =>
  useConfigQuery(settingsObjectQueryOptions)

export type IsSettingOn = (setting: SettingsKeys) => boolean

export const useSettingsCallback = () => {
  const { data: settings } = useSettingsObjectQuery()

  return React.useCallback<IsSettingOn>(
    setting => settings[setting],
    [settings],
  )
}

export const useSettingsUpdateMutation = () => {
  const apiClient = useApiClient()
  const cacheKey = useSettingsCacheKey()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (newSettings: ApiSettings) =>
      updateConfig(apiClient.post)(newSettings),
    onMutate: () => queryClient.cancelQueries({ queryKey: cacheKey }),
    onSettled: () => queryClient.invalidateQueries({ queryKey: cacheKey }),
  })
}
