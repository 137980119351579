import { Button, type ButtonProps } from '@mui/material'

const SquareIconButton = ({ sx, ...props }: ButtonProps) => (
  <Button
    sx={{
      ...sx,
      minWidth: 0,
      p: 1,
    }}
    {...props}
  />
)

export default SquareIconButton
