import { compose } from 'ramda'
import { Stack } from '@mui/material'

import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  getApiDimension,
  getMaxDimension,
  getMinDimension,
  getRealDimension,
  resizeTable,
} from 'src/entities/table/services/tableGrid'
import OutlinedContainer from 'src/shared/components/containers/OutlinedContainer'
import FormTextInput from 'src/shared/components/form/inputs/FormTextInput'
import StepInput from 'src/shared/components/form/inputs/StepInput'
import Toggle from 'src/shared/components/form/inputs/Toggle'
import { type FloorPlanTableInterface } from 'src/widgets/FloorPlan/types/floorPlanElement'

const Size = ({
  onChange,
  autosize,
  setAutosize,
}: {
  onChange: (table: FloorPlanTableInterface) => void
  autosize: boolean
  setAutosize: (newAutosize: boolean) => void
}) => {
  const { t } = useTranslation('', { keyPrefix: 'settings.tables' })

  const { handleSubmit, watch } = useFormContext<FloorPlanTableInterface>()

  const width = watch('size.width')
  const shape = watch('shape')

  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'size.width',
    rules: {
      required: true,
      min: getMinDimension(shape),
      max: getMaxDimension(shape),
    },
  })

  const autosizeChangeHandler = (newAutosize: boolean) => {
    setAutosize(newAutosize)
    field.onChange(width)
  }

  return (
    <OutlinedContainer label={t('tableSize')} error="">
      <Stack direction="column">
        <Toggle
          label={t('autoSize')}
          value={t('autoSize')}
          onChange={(_e, checked) => autosizeChangeHandler(checked)}
          checked={autosize}
          size="small"
        />
        {!autosize && (
          <StepInput
            required
            minValue={getApiDimension(getMinDimension(shape))}
            maxValue={getApiDimension(getMaxDimension(shape))}
            onChange={async newValue => {
              compose(field.onChange, getRealDimension)(newValue)

              await handleSubmit(table =>
                compose(
                  onChange,
                  compose(resizeTable, getApiDimension)(table.size.width),
                )(table),
              )()
            }}
            value={getApiDimension(width)}
            helperText={error?.message}
            error={!!error?.message}
            sx={{
              mt: 1,
            }}
          />
        )}
        <FormTextInput
          type="number"
          onWheel={e => (e.target as Partial<HTMLDivElement>).blur?.()}
          inputMode="numeric"
          variant="standard"
          {...field}
          sx={{
            display: 'none',
          }}
        />
      </Stack>
    </OutlinedContainer>
  )
}

export default Size
