import { Fragment, useMemo, type ComponentProps } from 'react'
import { Box, Typography } from '@mui/material'

import { zodResolver } from '@hookform/resolvers/zod'
import { type TFunction } from 'i18next'
import { useController, useForm, type FieldErrorsImpl } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  useAddPhoneAcceptanceTimesExceptionMutation,
  useEditPhoneAcceptanceTimesExceptionMutation,
  useEditPhoneAcceptanceTimesMutation,
  useScheduleQuery,
} from 'src/entities/schedule/queries/schedule'
import {
  type PhoneAcceptanceTimes,
  type PhoneAcceptanceTimesException,
} from 'src/entities/schedule/types/scheduleApi'
import FormErrorText from 'src/shared/components/form/common/FormErrorText/FormErrorText'
import Checkbox from 'src/shared/components/form/inputs/Checkbox'
import { DateRangeInput } from 'src/shared/components/form/inputs/DateRangeInput/DateRangeInput'
import Toggle from 'src/shared/components/form/inputs/Toggle'
import PhoneCheckIcon from 'src/shared/components/icons/PhoneCheckIcon'
import { PhoneConfirmationsWeekday } from './components/PhoneConfirmationsWeekday'
import {
  phoneConfirmationsExceptionFormSchema,
  phoneConfirmationsFormSchema,
  type PhoneConfirmationsExceptionFormValues,
  type PhoneConfirmationsFormValues,
} from './service/getPhoneConfirmationsFormSchema'
import {
  fromPhoneConfirmationFormData,
  toPhoneConfirmationsFormData,
} from './service/mappers'
import FormContainer from '../FormContainer'
import FormSection from '../FormSection'
import { useDefaultMutationHandlers } from '../hooks/useDefaultMutationHandlers'

interface GetTitleOptions {
  isException: boolean | undefined
  isExisting: boolean | undefined
  t: TFunction
}

const getTitle = ({ isException, isExisting, t }: GetTitleOptions) => {
  if (!isException) {
    return t(
      'schedule.phone_confirmations.default_title',
      'Reservations confirmation',
    )
  }

  if (isExisting) {
    return t(
      'schedule.phone_confirmations.edit_exception_title',
      'Reservations confirmation - Edit exception',
    )
  }

  return t(
    'schedule.phone_confirmations.create_exception_title',
    'Reservations confirmation - Create exception',
  )
}

interface ReservationQuestionFormProps {
  onNavigateBack: () => void
  phoneConfirmations:
    | PhoneAcceptanceTimes
    | PhoneAcceptanceTimesException
    | undefined
  isException?: boolean
}

const StyledCheckbox = (props: ComponentProps<typeof Checkbox>) => (
  <Checkbox labelProps={{ sx: { mt: 0.5 } }} {...props} />
)

const isExceptionData = (
  data: PhoneConfirmationsFormValues | PhoneConfirmationsExceptionFormValues,
): data is PhoneConfirmationsExceptionFormValues =>
  !!(data as PhoneConfirmationsExceptionFormValues).dateRange

const weekdays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
] as const

export const PhoneConfirmationsForm = ({
  phoneConfirmations,
  onNavigateBack,
  isException,
}: ReservationQuestionFormProps) => {
  const { t } = useTranslation()

  const {
    data: { phoneAcceptanceTimesExceptions },
  } = useScheduleQuery()
  const { mutateAsync: editDefault } = useEditPhoneAcceptanceTimesMutation()
  const { mutateAsync: addExcpetion } =
    useAddPhoneAcceptanceTimesExceptionMutation()
  const { mutateAsync: editException } =
    useEditPhoneAcceptanceTimesExceptionMutation()

  const handlers = useDefaultMutationHandlers({ onSuccess: onNavigateBack })

  const defaultValues = phoneConfirmations
    ? toPhoneConfirmationsFormData(phoneConfirmations)
    : ({
        isActive: true,
        monday: { isActive: false, acceptanceTimes: [[null, null]] },
        tuesday: { isActive: false, acceptanceTimes: [[null, null]] },
        wednesday: { isActive: false, acceptanceTimes: [[null, null]] },
        thursday: { isActive: false, acceptanceTimes: [[null, null]] },
        friday: { isActive: false, acceptanceTimes: [[null, null]] },
        saturday: { isActive: false, acceptanceTimes: [[null, null]] },
        sunday: { isActive: false, acceptanceTimes: [[null, null]] },
      } as PhoneConfirmationsFormValues)

  const otherExceptions = useMemo(
    () =>
      isException
        ? phoneAcceptanceTimesExceptions.filter(
            ex =>
              ex.id !==
              (phoneConfirmations as PhoneAcceptanceTimesException).id,
          )
        : [],
    [isException, phoneAcceptanceTimesExceptions, phoneConfirmations],
  )

  const formProps = useForm<
    PhoneConfirmationsFormValues | PhoneConfirmationsExceptionFormValues
  >({
    defaultValues,
    resolver: zodResolver(
      isException
        ? phoneConfirmationsExceptionFormSchema(otherExceptions)
        : phoneConfirmationsFormSchema,
    ),
  })

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors: rawErrors },
  } = formProps

  const errors =
    rawErrors as FieldErrorsImpl<PhoneConfirmationsExceptionFormValues>

  const { field } = useController({
    name: register('dateRange').name,
    control,
  })

  return (
    <FormContainer
      title={getTitle({ isException, isExisting: !!phoneConfirmations, t })}
      onNavigateBack={onNavigateBack}
      onSave={handleSubmit(data => {
        if (isExceptionData(data)) {
          const dto = fromPhoneConfirmationFormData(data)

          if (
            (phoneConfirmations as PhoneAcceptanceTimesException)
              ?.effectivePeriod
          ) {
            return editException(
              {
                ...dto,
                id: (phoneConfirmations as PhoneAcceptanceTimesException).id,
              },
              handlers,
            )
          }

          return addExcpetion(dto, handlers)
        }

        return editDefault(fromPhoneConfirmationFormData(data), handlers)
      })}
    >
      <FormSection
        icon={<PhoneCheckIcon size="medium" />}
        title={t(
          'schedule.phone_confirmations.phone_confirmations_section_title',
          {
            defaultValue: 'Phone confirmations (IVR)',
            tDescription:
              'Title for the `Phone confirmations (IVR)` section in the reservations confirmation form',
          },
        )}
      >
        {isException && (
          <DateRangeInput
            inputRef={field.ref}
            placeholder={t('schedule.common.pick_dates', 'Pick dates')}
            minValue={new Date()}
            value={field.value}
            setValue={field.onChange}
            error={!!errors.dateRange}
            helperText={
              !!errors.dateRange &&
              (errors.dateRange.type === 'custom'
                ? t(
                    'schedule.phone_confirmations.errors.overlap',
                    'The dates overlap with another exception',
                  )
                : t('common.validation.required', 'This field cannot be empty'))
            }
          />
        )}
        <Toggle
          label={t(
            'shift.configuration.phone_confirmations.active_question_label',
            {
              defaultValue: 'Active phone confirmations',
              tDescription:
                'Label for the `Active phone confirmations` switch in the reservations confirmation form',
            },
          )}
          {...register('isActive')}
          defaultChecked={defaultValues.isActive}
        />
        <Box
          sx={{
            display: watch('isActive') ? 'grid' : 'none',
            gridTemplateColumns: ['1fr', '200px auto'],
            alignItems: 'start',
            rowGap: 3,
            columnGap: 5,
          }}
        >
          <Typography variant="labelExtrasmall" color="primaryPalette.700">
            {t('schedule.phone_confirmations.weekday', {
              defaultValue: 'Weekday',
              tDescription:
                'Label above the `Weekday` checkboxes in the reservations confirmation form',
            })}
          </Typography>
          <Typography variant="labelExtrasmall" color="primaryPalette.700">
            {t('schedule.phone_confirmations.acceptance_times', {
              defaultValue: 'Acceptance time',
              tDescription:
                'Label above the `Weekday` time inputs in the reservations confirmation form',
            })}
          </Typography>
          {weekdays.map(wd => (
            <Fragment key={wd}>
              <StyledCheckbox
                label={t(`common.weekdays.${wd}`)}
                defaultChecked={
                  defaultValues.isActive && defaultValues[wd].isActive
                }
                {...register(`${wd}.isActive`)}
              />
              <Box>
                <PhoneConfirmationsWeekday
                  name={`${wd}.acceptanceTimes`}
                  control={control}
                  disabled={!watch(`${wd}.isActive`)}
                />
              </Box>
            </Fragment>
          ))}
        </Box>
        <FormErrorText
          error={!!errors.isActive}
          errorText={
            errors.isActive &&
            t(
              'schedule.phone_confirmations.errors.no_day_selected',
              'Please select at least one day',
            )
          }
        />
      </FormSection>
    </FormContainer>
  )
}
