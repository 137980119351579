import { useRef } from 'react'

import { createFileRoute } from '@tanstack/react-router'

import { SettingsScreensEnum } from 'src/entities/setting/types/settings'
import ScheduleRouter from 'src/pages/Schedule/ScheduleRouter'
import { SettingsHeader } from 'src/pages/Settings/layout/SettingsHeader'
import { ConfigScroll } from '../../../../pages/Settings/layout/ConfigScroll'

export const Route = createFileRoute(
  '/restaurants/$restaurantId/config/schedule',
)({
  component: RouteComponent,
})

function RouteComponent() {
  const { restaurantId } = Route.useParams()
  const ref = useRef<HTMLDivElement>(null)

  return (
    <>
      <SettingsHeader
        restaurantId={restaurantId}
        screen={SettingsScreensEnum.Schedule}
      />
      <ConfigScroll ref={ref} pb={3}>
        <ScheduleRouter onRouteChange={() => ref.current?.scrollTo(0, 0)} />
      </ConfigScroll>
    </>
  )
}
