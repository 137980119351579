import { Rating, Stack, Typography } from '@mui/material'

const Item = ({ label, score }: { label: string; score: number }) => (
  <Stack direction="row" alignItems="center" justifyContent="space-between">
    <Typography variant="labelTiny" color="grey.700">
      {label}
    </Typography>
    <Stack direction="row" spacing={1} alignItems="center">
      <Rating value={score} readOnly precision={0.5} size="small" />
      <Typography variant="labelTiny" color="grey.700">
        {(score ?? 0).toFixed(1)}
      </Typography>
    </Stack>
  </Stack>
)

export default Item
