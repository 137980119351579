import { useCallback } from 'react'

import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query'

import { useBetterApiClient } from 'src/shared/lib/api/hooks/useBetterApiClient'
import { useRestaurantCacheKey } from 'src/shared/lib/api/queries/useRestaurantCacheKey'
import {
  editWidgetGuestMessage,
  editWidgetWelcomeMessage,
  getWidgetGuestMessage,
  getWidgetWelcomeMessage,
} from '../api/widgetApi'

const WIDGET_WELCOME_MESSAGE_CACHE_KEY = ['widget', 'welcome-message']
const WIDGET_GUEST_MESSAGE_CACHE_KEY = ['widget', 'guest-message']

const useWidgetWelcomeMessageCacheKey = () =>
  useRestaurantCacheKey(WIDGET_WELCOME_MESSAGE_CACHE_KEY)
const useWidgetGuestMessageCacheKey = () =>
  useRestaurantCacheKey(WIDGET_GUEST_MESSAGE_CACHE_KEY)

export const useWidgetApiClient = (restaurantHash?: string) =>
  useBetterApiClient({ restaurantHash, prefix: 'widget' })

export const useWidgetWelcomeMessageQuery = () => {
  const apiClient = useWidgetApiClient()
  const queryKey = useWidgetWelcomeMessageCacheKey()

  return useSuspenseQuery({
    queryKey,
    queryFn: getWidgetWelcomeMessage(apiClient),
    refetchInterval: false,
    staleTime: Infinity,
  })
}

export const useEditWidgetWelcomeMessageMutation = () => {
  const apiClient = useWidgetApiClient()
  const queryClient = useQueryClient()
  const queryKey = useWidgetWelcomeMessageCacheKey()

  return useMutation({
    mutationFn: editWidgetWelcomeMessage(apiClient),
    onMutate: () => queryClient.cancelQueries({ queryKey }),
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  })
}

export const useWidgetGuestMessageQuery = () => {
  const apiClient = useWidgetApiClient()
  const queryKey = useWidgetGuestMessageCacheKey()

  return useSuspenseQuery({
    queryKey,
    queryFn: getWidgetGuestMessage(useCallback(apiClient, [apiClient])),
    refetchInterval: false,
    staleTime: Infinity,
  })
}

export const useEditWidgetGuestMessageMutation = () => {
  const apiClient = useWidgetApiClient()
  const queryClient = useQueryClient()
  const queryKey = useWidgetGuestMessageCacheKey()

  return useMutation({
    mutationFn: editWidgetGuestMessage(apiClient),
    onMutate: () => queryClient.cancelQueries({ queryKey }),
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  })
}
