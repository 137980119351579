import { Card, styled } from '@mui/material'

const PrimaryCard = styled(Card)(({ theme }) =>
  theme.unstable_sx({
    backgroundColor: 'primaryPalette.25',
    border: 'none',
    borderRadius: 2,
  }),
)

export default PrimaryCard
