import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ascend, compose, sortWith, uniq } from 'ramda'
import { Typography } from '@mui/material'

import { noop } from '@tanstack/react-table'
import debounce from 'debounce'
import { useTranslation } from 'react-i18next'

import {
  useLabelsOrderUpdateMutation,
  useLabelsQuery,
  useUpsertLabelMutation,
} from 'src/entities/label/queries/label'
import { type LabelInterface } from 'src/entities/label/types/label'
import { emptyLabel } from 'src/entities/label/types/labelApi'
import { SettingsScreensEnum } from 'src/entities/setting/types/settings'
import { useDefaultMutationHandlers } from 'src/pages/ShiftConfiguration/hooks/useDefaultMutationHandlers'
import EmptyView from 'src/shared/components/common/EmptyView'
import Modal from 'src/shared/components/common/Modal'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import EditForm from './EditForm'
import { LabelTable } from './Table/LabelTable'
import { ConfigScroll } from '../layout/ConfigScroll'
import { SettingsHeader } from '../layout/SettingsHeader'

interface LabelsProps {
  restaurantId: number
}

const Labels = ({ restaurantId }: LabelsProps) => {
  const { t } = useTranslation()

  const [editedLabel, setEditedLabel] = React.useState<LabelInterface>()

  const { handleClose, handleOpen, open } = useDialog()

  const { data: allLabels } = useLabelsQuery()

  const labels = React.useMemo(
    () => allLabels.filter(l => !l.systemLabel),
    [allLabels],
  )

  const systemLabels = React.useMemo(
    () => allLabels.filter(l => l.systemLabel),
    [allLabels],
  )

  const orderChangedHandler = useDefaultMutationHandlers({
    successMessage: t('settings.common.order_changed', 'Order changed'),
  })
  const addHandler = useDefaultMutationHandlers({
    successMessage: t('settings.labels.common.label_added', 'Label added'),
  })
  const editHandler = useDefaultMutationHandlers({
    successMessage: t('settings.labels.common.label_changed', 'Label changed'),
  })

  const isNew = !editedLabel?.id

  const { mutateAsync: upsert, isPending: isUpsertPending } =
    useUpsertLabelMutation()

  const handleSubmit = useCallback(
    async (l: LabelInterface) => {
      await upsert(l, isNew ? addHandler : editHandler)
      handleClose()
    },
    [addHandler, editHandler, handleClose, isNew, upsert],
  )

  const handleEdit = useMemo(
    () => compose(handleOpen, setEditedLabel),
    [handleOpen],
  )

  const [orderedLabels, setOrderedLabels] = React.useState(labels)

  React.useEffect(() => {
    setOrderedLabels(labels)
  }, [labels])

  const { mutate: setOrder, isPending: isOrderPending } =
    useLabelsOrderUpdateMutation()

  const deboucedSetOrder = useMemo(() => debounce(setOrder, 3000), [setOrder])

  const groupByColor = React.useCallback(
    () =>
      setOrderedLabels(oldLabels => {
        const colors = uniq(oldLabels.map(l => l.color))
        const newLabels = sortWith(
          [ascend(l => colors.indexOf(l.color))],
          oldLabels,
        )
        setOrder(newLabels, orderChangedHandler)
        setTimeout(() => deboucedSetOrder.clear(), 0)

        return newLabels
      }),
    [deboucedSetOrder, orderChangedHandler, setOrder],
  )

  const [isBeingReordered, setIsBeingReordered] = useState(false)

  useEffect(() => {
    if (!isOrderPending) setIsBeingReordered(false)
  }, [isOrderPending])

  useEffect(() => {
    const hasModifiedLabels = orderedLabels.some(
      ({ id }, idx) => labels[idx]?.id !== id,
    )

    if (!hasModifiedLabels) return

    setIsBeingReordered(true)
    deboucedSetOrder(orderedLabels, orderChangedHandler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deboucedSetOrder, orderChangedHandler, orderedLabels])

  const children = (
    <ConfigScroll pb={3}>
      <Typography variant="labelHuge" mb={3}>
        {t('settings.labels.regular_labels', 'Regular labels')}
      </Typography>
      <LabelTable
        orderedLabels={orderedLabels}
        setOrderedLabels={setOrderedLabels}
        onEdit={handleEdit}
        isOrderPending={isOrderPending}
        isBeingReordered={isBeingReordered}
        tableContainerProps={{ sx: { flexShrink: [0, 1] } }}
      />
      {!!systemLabels.length && (
        <>
          <Typography variant="labelHuge" my={3}>
            {t('settings.labels.system_labels', 'System tags')}
          </Typography>
          <LabelTable
            orderedLabels={systemLabels}
            setOrderedLabels={noop}
            onEdit={noop}
            isOrderPending
            isBeingReordered
            tableContainerProps={{ sx: { flexShrink: 0 } }}
          />
        </>
      )}
    </ConfigScroll>
  )

  return (
    <>
      <SettingsHeader
        restaurantId={restaurantId}
        screen={SettingsScreensEnum.Labels}
        primaryButtonProps={{
          onClick: () => handleEdit(emptyLabel),
          children: t('settings.labels.addLabel'),
          disabled: isBeingReordered || isOrderPending || isUpsertPending,
        }}
        secondaryButtonProps={{
          onClick: groupByColor,
          children: t('settings.labels.group_by_color', {
            defaultValue: 'Group by color',
            tDescription: 'Label for "group by color" button',
          }),
          disabled: isBeingReordered || isOrderPending || isUpsertPending,
        }}
      />

      {orderedLabels.length ? (
        children
      ) : (
        <EmptyView
          text={t('settings.labels.no_labels_defined', 'No labels defined')}
        />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        title={
          isNew
            ? t('settings.labels.modal.add_title', 'Add label')
            : t('settings.labels.modal.edit_title', 'Edit label')
        }
      >
        {editedLabel && (
          <EditForm
            label={editedLabel}
            labels={labels}
            onCancel={handleClose}
            onSubmit={handleSubmit}
          />
        )}
      </Modal>
    </>
  )
}

export default Labels
