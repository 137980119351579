import React from 'react'

import { useTranslation } from 'react-i18next'

import StepInput from 'src/shared/components/form/inputs/StepInput'
import {
  fromIsoDuration,
  toIsoDuration,
} from 'src/shared/lib/range/services/date'
import { useBaseFormContext, useBaseFormController } from '../../../hooks/form'

const MINUTES_IN_DAY = 24 * 60

const bookindDurationFieldName = 'bookingSetup.bookingDuration'

const Duration = () => {
  const { t } = useTranslation()

  const { watch, setValue } = useBaseFormContext()

  const intervalDuration = watch('bookingSetup.timeInterval')
  const interval = fromIsoDuration(intervalDuration, 'minutes')

  const { field } = useBaseFormController(bookindDurationFieldName)
  const duration = fromIsoDuration(field.value, 'minutes')

  const onDurationChange = React.useCallback(
    (newDuration: number) => {
      setValue(bookindDurationFieldName, toIsoDuration(newDuration, 'minutes'))
    },
    [setValue],
  )

  return (
    <StepInput
      onChange={onDurationChange}
      value={duration}
      unit={t('common.minutes_short', { count: duration, defaultValue: 'min' })}
      minValue={interval}
      maxValue={MINUTES_IN_DAY}
      step={interval}
      sx={{ maxWidth: 168 }}
      label={t('schedule.shifts.general_section.duration.label', 'Duration')}
    />
  )
}

export default Duration
