import { Stack, type StackProps } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { mergeSx } from 'src/app/theme/helpers'
import { type TableInterface } from 'src/entities/table/types/table'

interface TableItemProps extends StackProps {
  table: TableInterface
  onSelect: () => void
  isSelected: boolean
}

const TableItem = ({
  table,
  onSelect,
  isSelected,
  sx,
  ...props
}: TableItemProps) => (
  <Stack
    {...createTestIdProps('table-item')}
    direction="row"
    sx={mergeSx(
      theme => ({
        cursor: 'pointer',
        borderWidth: 1,
        borderStyle: 'solid',
        backgroundClip: 'content-box',
        borderRadius: 0.5,
        borderColor: isSelected ? theme.palette.neutral.dark : 'transparent',
        overflow: 'hidden',
        flex: 1,
      }),
      sx,
    )}
    onClick={onSelect}
    {...props}
  />
)

export default TableItem
