import { createTestIdProps } from 'src/app/hoc/withDomId'
import { type PreferencesInterface } from 'src/entities/customer/types/preferences'
import BlockedIcon from 'src/shared/components/icons/BlockedIcon'
import CakeIcon from 'src/shared/components/icons/CakeIcon'
import ClipboardIcon from 'src/shared/components/icons/ClipboardIcon'
import FoodIcon from 'src/shared/components/icons/FoodIcon'
import LocationIcon from 'src/shared/components/icons/LocationIcon'
import PMSIcon from 'src/shared/components/icons/PMSIcon'
import StarIcon from 'src/shared/components/icons/StarIcon'

export interface IndicatorsProps {
  birthday?: boolean
  vip?: boolean
  comment?: boolean
  ban?: boolean
  preferences?: PreferencesInterface
  withColors?: boolean
  external?: boolean
}

const Indicators = ({
  birthday = false,
  vip = false,
  comment = false,
  ban = false,
  preferences,
  withColors = false,
  external = false,
}: IndicatorsProps) => (
  <>
    {birthday && (
      <CakeIcon
        {...createTestIdProps('ind-birthday')}
        size="small"
        variant="filled"
      />
    )}
    {vip && (
      <StarIcon
        {...createTestIdProps('ind-vip')}
        size="small"
        color={withColors ? 'warningPalette.500' : undefined}
      />
    )}
    {comment && (
      <ClipboardIcon
        {...createTestIdProps('ind-comment')}
        size="small"
        variant="filled"
      />
    )}
    {preferences?.food && (
      <FoodIcon {...createTestIdProps('ind-food')} size="small" />
    )}
    {preferences?.place && (
      <LocationIcon
        {...createTestIdProps('ind-pref')}
        size="small"
        variant="filled"
      />
    )}
    {ban && (
      <BlockedIcon
        {...createTestIdProps('ind-ban')}
        size="small"
        color={withColors ? 'errorPalette.500' : undefined}
      />
    )}
    {external && <PMSIcon size="small" />}
  </>
)

export default Indicators
