import { useRef } from 'react'
import { Box, Stack, styled } from '@mui/material'

import { GRID_STEP } from 'src/entities/table/services/tableGrid'
import {
  CanvasViewportContext,
  useCanvasViewport,
} from 'src/shared/lib/context/state/CanvasViewportContext'
import FloorPlanStage from 'src/widgets/FloorPlan/FloorPlanStage'
import StageContainer from 'src/widgets/FloorPlan/StageContainer'
import {
  FloorPlanElementType,
  type FloorPlanElementInterface,
} from 'src/widgets/FloorPlan/types/floorPlanElement'
import ZoomPicker from 'src/widgets/FloorPlan/ZoomPicker'
import Furniture from './Furniture'
import Table from './Table'

const GRID_COLOUR = '#77BBFF33'

const GridStage = styled(FloorPlanStage)(({ offset }) => ({
  '.konvajs-content': {
    backgroundSize: `${GRID_STEP / 2}px ${GRID_STEP / 2}px`,
    backgroundImage: `linear-gradient(to right, ${GRID_COLOUR} 1px, transparent 1px), linear-gradient(to bottom, ${GRID_COLOUR} 1px, transparent 1px)`,
    backgroundPosition: `${-(offset?.x ?? 0)}px ${-(offset?.y ?? 0)}px`,
  },
}))

const FloorPlanElementComponent = {
  [FloorPlanElementType.Table]: Table,
  [FloorPlanElementType.Furniture]: Furniture,
}

const FloorPlan = ({
  elements,
  selectedElement,
  onChange,
  onSelect,
}: {
  elements: FloorPlanElementInterface[]
  selectedElement?: FloorPlanElementInterface
  onChange: (element: FloorPlanElementInterface) => void
  onSelect: (element: FloorPlanElementInterface | undefined) => void
}) => {
  const floorPlanContainerRef = useRef<Element>(null)
  const { canvasViewportContextState, zoom, setZoom, scrollHandler } =
    useCanvasViewport(floorPlanContainerRef)

  return (
    <Stack
      sx={{
        position: 'relative',
        overflow: 'hidden',
        flexGrow: 1,
        background: 'white',
      }}
    >
      <Box
        ref={floorPlanContainerRef}
        onScroll={scrollHandler}
        sx={{
          position: 'relative',
          overflow: 'auto',
          flexGrow: 1,
          background: 'white',
        }}
      >
        <CanvasViewportContext value={canvasViewportContextState}>
          <StageContainer elements={elements}>
            <GridStage
              onSelect={onSelect}
              offset={canvasViewportContextState.offset}
            >
              {elements.map(element => {
                const isSelected = element === selectedElement

                const Component = FloorPlanElementComponent[element.type]

                return (
                  <Component
                    key={`${element.uuid ?? element.id}_${element.type}`}
                    element={element}
                    onChange={onChange}
                    isSelected={isSelected}
                    onSelect={() => onSelect(element)}
                  />
                )
              })}
            </GridStage>
          </StageContainer>
        </CanvasViewportContext>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          m: 1,
          bottom: 0,
          right: 0,
        }}
      >
        <ZoomPicker value={zoom} onChange={setZoom} />
      </Box>
    </Stack>
  )
}

export default FloorPlan
