import { Stack } from '@mui/material'

import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import CloseButton from 'src/shared/components/buttons/CloseButton'
import { FullScreenModal } from 'src/shared/components/dialogs/FullScreenModal/FullScreenModal'
import type useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { combine } from 'src/shared/lib/common/services/functional/functional'
import { useClearSelectedReservation } from 'src/shared/lib/context/state/atoms/selectedReservation'
import { SerialReservationsHeader } from './SerialReservationsHeader'
import { SerialReservations } from '../../../SerialReservations'
import { useSerialReservations } from '../hooks/useSerialReservations'

interface SerialReservationModalProps {
  dialogProps: ReturnType<typeof useDialog>
  selectedReservation: ReservationInterface
  onExited: () => void
}

export const SerialReservationModal = ({
  dialogProps: { handleClose, open },
  selectedReservation,
  onExited,
}: SerialReservationModalProps) => {
  const serialReservationProps = useSerialReservations(selectedReservation)

  const onSave = useClearSelectedReservation()

  const { dates, serialTemplate, reservationTemplate } = serialReservationProps

  return (
    <FullScreenModal isOpen={open} onExited={onExited}>
      <Stack sx={{ backgroundColor: 'grey.25', overflow: 'hidden' }}>
        <CloseButton
          sx={{ position: 'absolute', top: 8, right: 4, zIndex: 1 }}
          onClick={handleClose}
        />
        <SerialReservationsHeader
          serialReservationProps={serialReservationProps}
          selectedReservation={selectedReservation}
        />

        <SerialReservations
          dates={dates}
          serialTemplate={serialTemplate}
          reservationTemplate={reservationTemplate}
          onReservationsSave={combine([onSave, handleClose])}
        />
      </Stack>
    </FullScreenModal>
  )
}
