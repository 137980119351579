import { useCallback, useEffect } from 'react'
import { Box, Button } from '@mui/material'

import { zodResolver } from '@hookform/resolvers/zod'
import { noop } from '@tanstack/react-table'
import { create } from 'mutative'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import FormTextInput from 'src/shared/components/form/inputs/FormTextInput'
import { withTargetValue } from 'src/shared/lib/common/services/helpers/helpers'
import { type SelectedReservation } from 'src/shared/lib/context/state/atoms/baseSelectedReservation'
import {
  selectedReservationSchema,
  useSelectedReservationField,
  useSetSelectedReservationField,
} from 'src/shared/lib/context/state/atoms/selectedReservation'
import { CustomerEdit } from '../../../CustomerEdit'
import PhoneInput from '../../../CustomerEdit/Form/PhonesInput/Input'

const useValidatedSelectedReservationErrors = () => {
  const newCustomer = useSelectedReservationField('newCustomer')
  const invalidState = useSelectedReservationField('invalidState')

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SelectedReservation>({
    resolver: zodResolver(selectedReservationSchema),
  })

  useEffect(() => {
    setValue('newCustomer', newCustomer)
  }, [newCustomer, setValue])

  useEffect(() => {
    if (invalidState) void handleSubmit(noop)()
  }, [handleSubmit, invalidState])

  return errors
}

export const NewGuestInputs = () => {
  const { t } = useTranslation()

  const addDialog = useDialog()

  const newCustomer = useSelectedReservationField('newCustomer')
  const set = useSetSelectedReservationField()

  const handleFirstNameChange = useCallback(
    (newVal: string) =>
      set('newCustomer', nc => ({ ...nc, firstName: newVal })),
    [set],
  )

  const handleLastNameChange = useCallback(
    (newVal: string) => set('newCustomer', nc => ({ ...nc, lastName: newVal })),
    [set],
  )

  const handlePhoneNumberChange = useCallback(
    (newVal: string) =>
      set('newCustomer', nc =>
        create(nc, dr => {
          if (!dr) return

          if (!dr?.phoneNumbers?.[0]) {
            // eslint-disable-next-line no-param-reassign
            dr.phoneNumbers = [{ id: 0, phone: newVal }]
            return
          }
          // eslint-disable-next-line no-param-reassign
          dr.phoneNumbers[0].phone = newVal
        }),
      ),
    [set],
  )

  const errors = useValidatedSelectedReservationErrors()

  if (!newCustomer) return null

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 1 }}>
      <FormTextInput
        {...createTestIdProps('new-guest-first-name')}
        label={t('customer.firstName')}
        labelProps={{ sx: { typography: 'labelTiny' } }}
        value={newCustomer.firstName ?? ''}
        onChange={withTargetValue(handleFirstNameChange)}
        errorText={
          !!errors.newCustomer?.firstName && t('form_validation.required')
        }
      />
      <FormTextInput
        {...createTestIdProps('new-guest-last-name')}
        label={t('customer.lastName')}
        labelProps={{ sx: { typography: 'labelTiny' } }}
        value={newCustomer.lastName ?? ''}
        onChange={withTargetValue(handleLastNameChange)}
        errorText={
          !!errors.newCustomer?.lastName && t('form_validation.required')
        }
      />
      <Box gridColumn="span 2">
        <PhoneInput
          label={t('customer.phoneNumber')}
          labelProps={{ sx: { typography: 'labelTiny' } }}
          value={newCustomer.phoneNumbers?.[0]?.phone ?? ''}
          onChange={handlePhoneNumberChange}
          errorText={
            !!errors.newCustomer?.phoneNumbers &&
            t('customer.phoneNumberInvalid')
          }
        />
      </Box>
      <Button
        {...createTestIdProps('open-crm-btn')}
        variant="text"
        size="small"
        sx={{ gridColumn: 'span 2', width: 'max-content', px: 0 }}
        onClick={addDialog.handleOpen}
      >
        {t('reservation_drawer.guest.open_crm', 'Open CRM')}
      </Button>
      <CustomerEdit
        isOpen={addDialog.open}
        onClose={addDialog.handleClose}
        onSubmit={nc => {
          set('newCustomer', nc)
          addDialog.handleClose()
        }}
        customerId={undefined}
        hasBeenOpened={addDialog.hasBeenOpened}
        initCustomer={newCustomer}
      />
    </Box>
  )
}
