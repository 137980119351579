import { useMemo } from 'react'
import { isEmpty } from 'ramda'
import { Stack, Typography } from '@mui/material'

import { diff } from 'deep-object-diff'
import { useTranslation } from 'react-i18next'

import {
  useEditWidgetGuestMessageMutation,
  useEditWidgetWelcomeMessageMutation,
  useWidgetGuestMessageQuery,
  useWidgetWelcomeMessageQuery,
} from 'src/entities/widget/queries/widget'
import { useDefaultMutationHandlers } from 'src/pages/ShiftConfiguration/hooks/useDefaultMutationHandlers'
import PrimaryDivider from 'src/shared/components/common/PrimaryDivider'
import { WidgetPageHeader } from './components/WidgetPageHeader'
import { WidgetSettingRow } from './components/WidgetSettingRow'
import { useWidgetForm } from './hooks/useWidgetForm'
import { ConfigScroll } from '../layout/ConfigScroll'

interface WidgetPageProps {
  restaurantId: number
}

export const WidgetPage = ({ restaurantId }: WidgetPageProps) => {
  const { t } = useTranslation()
  const { data: originalWelcomeMessage } = useWidgetWelcomeMessageQuery()
  const { data: originalGuestMessage } = useWidgetGuestMessageQuery()
  const { mutateAsync: editWelcomeMessage } =
    useEditWidgetWelcomeMessageMutation()
  const { mutateAsync: editGuestMessage } = useEditWidgetGuestMessageMutation()

  const defaultValues = {
    welcomeMessage: originalWelcomeMessage,
    guestMessage: originalGuestMessage,
  }

  const formProps = useWidgetForm({
    defaultValues,
  })

  const { onError, onSuccess } = useDefaultMutationHandlers()

  const { watch, handleSubmit } = formProps

  const onSubmit = useMemo(
    () =>
      handleSubmit(async d => {
        try {
          await Promise.all([
            editWelcomeMessage(d.welcomeMessage),
            editGuestMessage(d.guestMessage),
          ])
          onSuccess()
        } catch (e) {
          if (e instanceof Error) onError(e)
        }
      }),
    [editGuestMessage, editWelcomeMessage, handleSubmit, onError, onSuccess],
  )

  const hasNoChanges = isEmpty(
    diff(defaultValues, {
      welcomeMessage: watch('welcomeMessage'),
      guestMessage: watch('guestMessage'),
    } satisfies typeof defaultValues),
  )

  return (
    <>
      <WidgetPageHeader
        restaurantId={restaurantId}
        onSave={onSubmit}
        saveDisabled={hasNoChanges}
      />
      <ConfigScroll gap={2} pb={3}>
        <Typography variant="labelHuge">
          {t('settings.widget.sections.customize.title', 'Customize')}
        </Typography>
        <Stack gap={3} divider={<PrimaryDivider />}>
          <WidgetSettingRow
            title={t(
              'settings.widget.form.welcome_message.title',
              'Welcome message',
            )}
            subtitle={t(
              'settings.widget.form.welcome_message.description',
              'This message will appear on the first page of the reservation widget.',
            )}
            formKey="welcomeMessage"
            formProps={formProps}
          />
          <WidgetSettingRow
            title={t(
              'settings.widget.form.guest_message.title',
              'Guest comment label',
            )}
            subtitle={t(
              'settings.widget.form.guest_message.description',
              'Additional label above the comment filed in widget.',
            )}
            formKey="guestMessage"
            formProps={formProps}
          />
        </Stack>
      </ConfigScroll>
    </>
  )
}
