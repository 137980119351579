import { z } from 'zod'

import { onlyValidArraySchema } from 'src/shared/lib/zod/zod'
import {
  emailTemplateSchema,
  smsTemplateSchema,
} from '../../communication-template/types/communicationTemplates'

export const reminderDataSchema = z.object({
  days: z.number(),
  pax: z.number(),
  // hours: z.number(),
})

const featureSchema = z.object({
  actionRequiredSms: z.coerce.boolean(),
  coronaCheckout: z.coerce.boolean(),
  coronaCity: z.coerce.boolean(),
  coronaCovidApp: z.coerce.boolean(),
  coronaDateOfBirth: z.coerce.boolean(),
  coronaEmail: z.coerce.boolean(),
  coronaFreeField: z.coerce.boolean(),
  coronaGuestTp: z.coerce.boolean(),
  coronaNumberPerson: z.coerce.boolean(),
  coronaTest: z.coerce.boolean(),
  coronaVaccination: z.coerce.boolean(),
  coronaZip: z.coerce.boolean(),
  customSms: z.coerce.boolean(),
  emailText: z.coerce.boolean(),
  feedback: z.coerce.boolean(),
  guestMerge: z.coerce.boolean(),
  noAreaRoom: z.coerce.boolean(),
  pinEnabled: z.coerce.boolean(),
  reminder: z.coerce.boolean(),
  reservationLink: z.coerce.boolean(),
  roomToGuest: z.coerce.boolean(),
  serialReservation: z.coerce.boolean(),
  smsVerification: z.coerce.boolean(),
  viewCorona: z.coerce.boolean(),
  viewList: z.coerce.boolean(),
  viewTable: z.coerce.boolean(),
  viewTimeline: z.coerce.boolean(),
  viewVisuell: z.coerce.boolean(),
  hotelStay: z.coerce.boolean(),
  manualResPayment: z.coerce.boolean(),
  badWeatherMode: z.coerce.boolean(),
  reserveWithGoogle: z.coerce.boolean(),
  manualBooking: z.coerce.boolean(),
  widgetBooking: z.coerce.boolean(),
  integrationLightspeed: z.coerce.boolean(),
  integrationPeoplefone: z.coerce.boolean(),
})

export const restaurantInfoSchema = z
  .object({
    id: z.number(),
    // restaurantName: z.string(),
    // lunchgate: z.null(),
    // city: z.string(),
    // plz: z.number(),
    // email: z.string(),
    // duration: z.number(),
    hash: z.string(),
    language: z.enum(['en', 'fr', 'de', 'it']),
    // crm: z.number(),
    // config: z.record(z.number()),
    duration: z.number(),
    country: z.string(),
    feature: featureSchema,
    customSms: onlyValidArraySchema(smsTemplateSchema),
    customEmail: onlyValidArraySchema(emailTemplateSchema),
    reminderData: reminderDataSchema,
  })
  .transform(t => ({
    id: t.id,
    hash: t.hash,
    language: t.language,
    feature: t.feature,
    smsCommunicationTemplates: t.customSms,
    emailCommunicationTemplates: t.customEmail,
    reminderData: t.reminderData,
    countryCode: t.country,
    defaultReservationDuration: t.duration,
  }))

// export type ReminderData = z.infer<typeof reminderDataSchema>

export type Features = z.infer<typeof featureSchema>
export type FeatureName = keyof Features
export type RestaurantInfo = z.infer<typeof restaurantInfoSchema>
