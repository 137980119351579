import { createTestIdProps } from 'src/app/hoc/withDomId'
import { useActivityLogQuery } from 'src/entities/activity-log/queries/activityLog'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { LastActivityIndicator } from './LastActivityIndicator'
import { ActivityLogModal } from '../../Reservations/components/ActivityLogModal/ActivityLogModal'

interface LastActivityIndicatorViewProps {
  reservationId: number
}

const LastActivityIndicatorView = ({
  reservationId,
}: LastActivityIndicatorViewProps) => {
  const { data: activities } = useActivityLogQuery({ reservationId, limit: 1 })

  const { handleClose, handleOpen, open } = useDialog()

  if (!activities[0]) return null

  return (
    <>
      <LastActivityIndicator
        {...createTestIdProps('last-activity')}
        latestAcivity={activities[0]}
        onClick={handleOpen}
      />
      <ActivityLogModal
        reservationId={reservationId}
        isOpen={open}
        onClose={handleClose}
      />
    </>
  )
}

export { LastActivityIndicatorView }
