import React from 'react'
import {
  Autocomplete,
  autocompleteClasses,
  Fade,
  outlinedInputClasses,
  Paper,
  Stack,
  type AutocompleteProps,
  type AutocompleteRenderInputParams,
  type PaperProps,
  type StackProps,
} from '@mui/material'

import { useVirtualizer } from '@tanstack/react-virtual'

import { mergeSx } from 'src/app/theme/helpers'
import { DropdownPaperProps } from 'src/app/theme/theme'
import VirtualList from 'src/shared/components/common/VirtualList'
import FormTextInput from 'src/shared/components/form/inputs/FormTextInput'
import SelectDropdownItem from 'src/shared/components/form/inputs/SelectDropdownItem'
import { Chevron } from 'src/shared/components/icons/ChevronIcon'

interface AutocompleteSelectProps<T>
  extends Omit<
    AutocompleteProps<T, false, boolean, false>,
    'value' | 'onChange' | 'options' | 'renderOption' | 'renderInput'
  > {
  value?: NonNullable<T>
  onChange: (value: T) => void
  options: T[]
  optionIdKey: keyof T
  optionToString: (option: T) => string
  renderOption?: (option: T) => React.ReactNode
  renderInput?: (props: AutocompleteRenderInputParams) => React.ReactNode
}

const AnimatedPaper = (props: PaperProps) => (
  <Fade in>
    <Paper {...props} />
  </Fade>
)

const VirtualListbox = ({ children, role, ...other }: StackProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null)

  const virtualizer = useVirtualizer({
    estimateSize: () => 40,
    overscan: 0,
    count: React.Children.count(children),
    getScrollElement: () => containerRef.current,
  })

  return (
    <Stack {...other}>
      <VirtualList
        ref={containerRef}
        virtualizer={virtualizer}
        render={idx => (children as React.ReactElement[])[idx]!}
        sx={{ py: 1 }}
      />
    </Stack>
  )
}

export const AutocompleteSelect = <T,>({
  onChange,
  options,
  optionIdKey,
  renderOption,
  optionToString,
  value,
  sx,
  renderInput = ({ fullWidth, ...props }) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { className: _cl1, ...inputProps } = props.inputProps
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { className: _cl2, ...InputProps } = props.InputProps

    return (
      <FormTextInput
        variant="outlined"
        fullWidth={fullWidth}
        sx={{
          [`.${outlinedInputClasses.input}`]: {
            textOverflow: 'ellipsis',
          },
        }}
        slotProps={{ input: InputProps, htmlInput: inputProps }}
      />
    )
  },
  ...props
}: AutocompleteSelectProps<T>) => (
  <Autocomplete
    disablePortal
    ListboxComponent={VirtualListbox}
    ListboxProps={{
      sx: { maxHeight: 1, overflow: 'hidden', p: 0 },
    }}
    slotProps={{
      popper: {
        placement: 'bottom-start',
        sx: { width: 'initial', minWidth: 'min-content', maxWidth: 360 },
      },
      paper: {
        ...DropdownPaperProps,
        sx: mergeSx(DropdownPaperProps.sx, {
          display: 'flex',
          flexDirection: 'column',
        }),
      },
      popupIndicator: {
        disableRipple: true,
      },
    }}
    PaperComponent={AnimatedPaper}
    options={options}
    value={value}
    popupIcon={<Chevron size="medium" sx={{ color: 'grey.800' }} />}
    onChange={(_, newValue) => newValue && onChange(newValue)}
    renderOption={({ className: _, ...rest }, option) => (
      <SelectDropdownItem
        {...rest}
        key={option[optionIdKey] as string}
        value={option}
        selected={value === option}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 1,
        }}
      >
        {(renderOption ?? optionToString)(option)}
      </SelectDropdownItem>
    )}
    getOptionLabel={optionToString}
    renderInput={renderInput}
    sx={mergeSx(
      {
        [`.${outlinedInputClasses.root}`]: {
          p: 0,
        },
        [`&.${autocompleteClasses.hasPopupIcon}`]: {
          [`.${outlinedInputClasses.root}`]: {
            pr: 3,
          },
        },
      },
      sx,
    )}
    {...props}
  />
)
