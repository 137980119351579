import { useCallback, useMemo, useState } from 'react'

import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

import { type CustomerInterface } from 'src/entities/customer/types/customer'
import { useEditReservation } from 'src/entities/reservation/hooks/useEditReservation'
import { useNewReservation } from 'src/entities/reservation/hooks/useNewReservation'
import { CustomerEdit } from 'src/pages/CustomerEdit'
import { CustomerPreviewModal } from 'src/pages/CustomerPreview/PreviewModal'
import UnwrappedSearch from 'src/pages/Search'
import { SearchTabsEnum } from 'src/pages/Search/SearchTabs'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { combine } from 'src/shared/lib/common/services/functional/functional'
import { withApiFiltersProvider } from 'src/shared/lib/reducer/service/reservationsFilter'

export const Route = createFileRoute('/restaurants/$restaurantId/search')({
  component: RouteComponent,
  validateSearch: z.object({
    q: z.string().optional(),
    tab: z.nativeEnum(SearchTabsEnum).optional(),
  }),
})

const Search = withApiFiltersProvider(UnwrappedSearch)

function RouteComponent() {
  const { restaurantId } = Route.useParams()
  const { q, tab } = Route.useSearch()
  const navigate = Route.useNavigate()

  const customerEditDialog = useDialog()
  const customerPreviewDialog = useDialog()

  const onClose = useCallback(
    () =>
      navigate({
        to: '/restaurants/$restaurantId/reservations',
        params: { restaurantId },
      }),
    [navigate, restaurantId],
  )

  const createNewReservation = useNewReservation()
  const createCustomerReservationHandler = useCallback(
    (customer: CustomerInterface) => {
      void onClose()
      void createNewReservation({ customer })
    },
    [createNewReservation, onClose],
  )

  const [customerId, setCustomerId] = useState<number>()
  const [customerInitPhrase, setCustomerInitPhrase] = useState<string>('')

  const editReservation = useEditReservation(restaurantId)

  const handleCustomerClick = useMemo(
    () => combine([customerPreviewDialog.handleOpen, setCustomerId]),
    [customerPreviewDialog.handleOpen],
  )

  const onCustomerCreated = useCallback(
    ({ id }: CustomerInterface) => handleCustomerClick(id),
    [handleCustomerClick],
  )

  return (
    <>
      <CustomerPreviewModal
        isOpen={customerPreviewDialog.open}
        hasBeenOpened={customerPreviewDialog.hasBeenOpened}
        onClose={customerPreviewDialog.handleClose}
        onAddReservation={createCustomerReservationHandler}
        onCustomerEdit={combine([
          customerEditDialog.handleOpen,
          customerPreviewDialog.handleClose,
        ])}
        customerId={customerId}
      />
      <CustomerEdit
        customerId={customerId}
        onCustomerCreated={onCustomerCreated}
        onCustomerSaved={customerEditDialog.handleClose}
        isOpen={customerEditDialog.open}
        hasBeenOpened={customerEditDialog.hasBeenOpened}
        onClose={customerEditDialog.handleClose}
        initPhrase={customerInitPhrase}
      />
      <Search
        restaurantId={restaurantId}
        onCreateReservation={createCustomerReservationHandler}
        onReservationEdit={editReservation}
        onCustomerClick={handleCustomerClick}
        onClose={onClose}
        onCreateNewCustomer={combine([
          customerEditDialog.handleOpen,
          setCustomerInitPhrase,
        ])}
        searchTab={tab ?? SearchTabsEnum.Reservations}
        searchPhrase={q}
      />
    </>
  )
}
