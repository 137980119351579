import React from 'react'
import { Box, Grid2, type SxProps } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { mergeSx } from 'src/app/theme/helpers'

const calculateItemsPerColumn = (itemsCount: number, columnsCount: number) =>
  Math.ceil(itemsCount / columnsCount)

const calculateColumnCount = (
  itemsCount: number,
  proposedColumnCount: number,
) => {
  if (itemsCount < proposedColumnCount) return itemsCount

  const itemsPerColumn = calculateItemsPerColumn(
    itemsCount,
    proposedColumnCount,
  )

  const lastColumnEmpty =
    itemsPerColumn * (proposedColumnCount - 1) >= itemsCount

  if (lastColumnEmpty) return proposedColumnCount - 1

  return proposedColumnCount
}

const getGridProps = (itemsCount: number, proposedColumnCount: number) => {
  const itemsPerColumn = calculateItemsPerColumn(
    itemsCount,
    proposedColumnCount,
  )
  const columnCount = calculateColumnCount(itemsCount, proposedColumnCount)

  return {
    gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
    gridTemplateRows: `repeat(${itemsPerColumn}, auto)`,
  }
}

const TablesGrid = ({
  sx = {},
  children,
}: {
  sx?: SxProps
  children: React.ReactNode
}) => {
  const itemsCount = React.Children.count(children)

  return (
    <Box
      {...createTestIdProps('table-grid')}
      sx={{ overflow: 'auto', height: 1 }}
    >
      <Box
        sx={mergeSx(
          theme => ({
            display: 'grid',
            gridAutoFlow: 'column',
            overflow: 'clip',
            flexShrink: 0,
            [theme.breakpoints.up('xs')]: getGridProps(itemsCount, 1),
            [theme.breakpoints.up('md')]: getGridProps(itemsCount, 2),
            [theme.breakpoints.up('xl')]: getGridProps(itemsCount, 3),
          }),
          sx,
        )}
      >
        {React.Children.map(children, child => (
          <Grid2
            size={{ xs: 1 }}
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {child}
          </Grid2>
        ))}
      </Box>
    </Box>
  )
}

export default TablesGrid
