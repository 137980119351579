import { useState } from 'react'
import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { useReservationRoomStays } from 'src/entities/hotel-stay/queries/hotelStay'
import { type RoomStayInterface } from 'src/entities/hotel-stay/services/hotelRoomStay'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { combine } from 'src/shared/lib/common/services/functional/functional'
import { type DrawerColor } from 'src/widgets/SidebarDrawer/drawerColor'
import { SidebarDrawer } from 'src/widgets/SidebarDrawer/SidebarDrawer'
import SidebarHeader from 'src/widgets/SidebarDrawer/SidebarHeader'
import RoomStayList from './RoomStayList'
import { RoomStay } from '../RoomStay/RoomStay'

interface RoomStayListViewProps {
  selectedReservation: ReservationInterface
  open: boolean
  onOpen: () => void
  onClose: () => void
  drawerColor: DrawerColor
}

const RoomStayListView = ({
  selectedReservation,
  open,
  onOpen,
  onClose,
  drawerColor,
}: RoomStayListViewProps) => {
  const { t } = useTranslation()
  const [selectedRoomStay, setSelectedRoomStay] =
    useState<RoomStayInterface | null>(null)

  const handleClose = () => {
    setSelectedRoomStay(null)
    onClose()
  }

  const roomStays = useReservationRoomStays(selectedReservation)

  if (!roomStays.length) return null

  return (
    <>
      <RoomStayList
        roomStays={roomStays}
        onRoomStayClick={combine([setSelectedRoomStay, onOpen])}
      />
      <SidebarDrawer
        open={!!selectedRoomStay && open}
        onClose={handleClose}
        level={1}
        color={drawerColor}
        sx={{
          overflow: 'hidden',
        }}
      >
        <SidebarHeader onClose={handleClose} color={drawerColor}>
          <Typography variant="labelBig">{t('angular.hotel_stay')}</Typography>
        </SidebarHeader>
        <Stack
          {...createTestIdProps('selected-room-stay')}
          sx={{ p: 1.25, overflow: 'auto' }}
          gap={2}
        >
          <RoomStay roomStay={selectedRoomStay} />
        </Stack>
      </SidebarDrawer>
    </>
  )
}

export { RoomStayListView }
