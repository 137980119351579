import { type ComponentProps } from 'react'

import { Transformer } from 'react-konva'

import { type SizeInterface } from 'src/widgets/FloorPlan/types/floorPlanElement'

const FurnitureTransformer = ({
  ref,
}: Pick<ComponentProps<typeof Transformer>, 'ref'>) => (
  <Transformer
    ref={ref}
    rotateEnabled={false}
    boundBoxFunc={(oldBox, newBox) => {
      const newBoxSize: SizeInterface = {
        width: newBox.width,
        height: newBox.height,
      }
      if (newBoxSize.height < 10 || newBoxSize.width < 10) return oldBox

      return newBox
    }}
  />
)

export default FurnitureTransformer
