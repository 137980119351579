import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import type { SlotStatus } from 'src/entities/availability/types/availability'
import { useCommunicationTemplates } from 'src/entities/communication-template/queries/useCommunicationTemplates'
import {
  hasTemplateChoice,
  shouldShowTemplateChoice,
  type Templates,
} from 'src/entities/communication-template/services/communicationTemplates'
import { type SettingsKeys } from 'src/entities/config/types/configApi'
import { useReservationSlotStatus } from 'src/entities/reservation/queries/reservation'
import { getSeatCountDifference } from 'src/entities/reservation/services/occupancy'
import {
  couldBeCharged,
  isPaymentAction,
} from 'src/entities/reservation/services/paymentStatus'
import {
  hasEmail,
  hasPhoneNumber,
} from 'src/entities/reservation/services/reservation'
import { useSettingsObjectQuery } from 'src/entities/setting/queries/settings'
import { useTablesQuery } from 'src/entities/table/queries/table'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { SimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/SimpleDialog'
import { useSimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/useSimpleDialog'
import { combine } from 'src/shared/lib/common/services/functional/functional'
import {
  isAbandoned,
  RESUMABLE_ABANDONED,
} from 'src/shared/lib/common/services/resumable/resumable'
import { type SelectedReservation } from 'src/shared/lib/context/state/atoms/baseSelectedReservation'
import {
  selectedReservationSchema,
  useClearSelectedReservation,
  useInitialReservation,
  usePristineOpenReservationOccupancies,
  useReservationChanged,
  useReservationRelevantChanged,
  useSetPristineOpenReservationOccupancies,
  useSetSelectedReservationField,
} from 'src/shared/lib/context/state/atoms/selectedReservation'
import { ActionButtonGroup } from './components/ActionButtonGroup'
import { ActionsMenu } from './components/ActionsMenu'
import { CancelConfirmationModal } from './components/CancelConfimartionModal'
import { CommunicationCheckboxes } from './components/CommunicationCheckboxes'
import { CommunicationTemplateChoice } from './components/CommunicationTemplateChoice'
import { FooterContainer } from './components/FooterContainer'
import { LockTooltip } from './components/LockTooltip'
import { PaymentConfirmation } from './components/PaymentConfirmation'
import { TemplateChoiceModal } from './components/TemplateChoiceModal'
import {
  areCommsHidden,
  getCommunicationAction,
  getCommunicationFlags,
  getNextAction,
  isActionPossible,
  ReservationActions,
  useReservationActionPermissionChecker,
} from './reservationActions'
import { useReservationCancellation } from './useReservationCancellation'
import { useReservationCreation } from './useReservationCreation'
import { useReservationReactivation } from './useReservationReactivation'
import { useReservationUpdate } from './useReservationUpdate'
import { useSendMessage } from './useSendMessage'
import ModifySerieModal from '../../Reservations/components/ModifySerieModal/ModifySerieModal'
import {
  notEveryoneSeatedDialog,
  tooManySeatedDialog,
} from '../Occupancies/occupanciesDialogs'
import { SerialReservationModal } from '../SerialReservations/components/SerialReservationModal'
import {
  slotStatusToDialog,
  useOverbookingCheck,
} from '../TimeSelection/timeSelectionDialogs'

const Footer = ({ reservation }: { reservation: SelectedReservation }) => {
  const set = useSetSelectedReservationField()
  const pristineReservation = useInitialReservation()

  const reservationChanged = useReservationChanged()
  const relevantChanged = useReservationRelevantChanged()

  const originalOccupancies = usePristineOpenReservationOccupancies()

  const originalReservation = React.useMemo(
    () => ({
      ...pristineReservation,
      occupancies: originalOccupancies,
    }),
    [originalOccupancies, pristineReservation],
  )

  const { t } = useTranslation()

  const { data: settings } = useSettingsObjectQuery()
  const { data: tables } = useTablesQuery()

  const smsEnabled = reservation.sendSms
  const emailEnabled = reservation.sendEmail
  const setSmsEnabled = useCallback((v: boolean) => set('sendSms', v), [set])
  const setEmailEnabled = useCallback(
    (v: boolean) => set('sendEmail', v),
    [set],
  )

  const onDrawerClose = useClearSelectedReservation()

  const { templates: smsMessagesTemplates } = useCommunicationTemplates(
    getCommunicationAction(ReservationActions.Message),
    reservation,
    smsEnabled,
    'sms',
  )

  const { templates: emailMessagesTemplates } = useCommunicationTemplates(
    getCommunicationAction(ReservationActions.Message),
    reservation,
    emailEnabled,
    'email',
  )

  const reservationHasEmail = hasEmail(reservation)
  const reservationHasPhoneNumber = hasPhoneNumber(reservation)

  const canChooseCommunicationTemplate = React.useCallback(
    (smsTemplates: Templates, emailTemplates: Templates) =>
      (hasTemplateChoice(emailTemplates) && reservationHasEmail) ||
      (hasTemplateChoice(smsTemplates) && reservationHasPhoneNumber),
    [reservationHasPhoneNumber, reservationHasEmail],
  )

  const isActionEnabled = React.useMemo(
    () =>
      isActionPossible(
        reservationChanged,
        canChooseCommunicationTemplate(
          smsMessagesTemplates,
          emailMessagesTemplates,
        ),
        reservation,
      ),
    [
      reservationChanged,
      canChooseCommunicationTemplate,
      smsMessagesTemplates,
      emailMessagesTemplates,
      reservation,
    ],
  )

  const isNewReservation = !reservation.id

  const [action, setAction] = React.useState(
    isNewReservation ? ReservationActions.Create : ReservationActions.Update,
  )

  React.useEffect(() => {
    const flags = getCommunicationFlags(
      (setting: SettingsKeys) => settings[setting],
    )(action)
    setEmailEnabled(flags.emailEnabled && reservationHasEmail)
    setSmsEnabled(flags.smsEnabled && reservationHasPhoneNumber)
  }, [
    action,
    settings,
    reservationHasEmail,
    reservationHasPhoneNumber,
    setEmailEnabled,
    setSmsEnabled,
  ])

  const templateChoiceDialog = useDialog()
  const cancelConfirmationDialog = useDialog()
  const assignSerieDialog = useDialog()

  const smsTemplates = useCommunicationTemplates(
    getCommunicationAction(action),
    reservation,
    smsEnabled,
    'sms',
  )

  const emailTemplates = useCommunicationTemplates(
    getCommunicationAction(action),
    reservation,
    emailEnabled,
    'email',
  )

  const setOriginalOccupancies = useSetPristineOpenReservationOccupancies()

  React.useEffect(() => {
    if (reservationChanged) return

    setOriginalOccupancies(reservation?.occupancies ?? [])
  }, [reservationChanged])

  React.useEffect(() => {
    if (
      action !== ReservationActions.Create &&
      action !== ReservationActions.Update
    )
      return

    setAction(
      isNewReservation ? ReservationActions.Create : ReservationActions.Update,
    )
  }, [action, isNewReservation])

  const showPaymentConfirmation = couldBeCharged(action)(reservation)
  const showTemplateChoice = shouldShowTemplateChoice({
    reservation,
    action,
    smsTemplates: smsTemplates.templates,
    emailTemplates: emailTemplates.templates,
    emailEnabled,
    smsEnabled,
    relevantChanged,
  })

  const reservationCancellation = useReservationCancellation({
    onConfirmation: cancelConfirmationDialog.handleOpen,
    onAssignSerie: assignSerieDialog.handleOpen,
    showTemplateChoice,
    onTemplateChoice: templateChoiceDialog.handleOpen,
    defaultSmsTemplate: smsTemplates.selectedTemplate,
    defaultEmailTemplate: emailTemplates.selectedTemplate,
  })

  const reservationReactivation = useReservationReactivation({
    onAssignSerie: assignSerieDialog.handleOpen,
  })

  const incompleteTableAssignmentDialog = useSimpleDialog()
  const openIncompleteTableAssignmentDialog = React.useCallback(
    (overflow: number) => {
      const tooManySeated = overflow > 0
      const dialogProps = tooManySeated
        ? tooManySeatedDialog(t)
        : notEveryoneSeatedDialog(t)

      incompleteTableAssignmentDialog.showSimpleDialog(dialogProps)
    },
    [incompleteTableAssignmentDialog, t],
  )

  const overbookingCheck = useOverbookingCheck()
  const slotStatusConfirmationDialog = useSimpleDialog()
  const openSlotStatusConfirmationDialog = React.useCallback(
    (slotStatus: SlotStatus) => {
      if (!overbookingCheck(slotStatus)) return RESUMABLE_ABANDONED
      const slotStatusDialogProps = slotStatusToDialog(t)(slotStatus)
      if (!slotStatusDialogProps) return undefined

      slotStatusConfirmationDialog.showSimpleDialog(slotStatusDialogProps)
      return undefined
    },
    [overbookingCheck, slotStatusConfirmationDialog, t],
  )

  const reservationCreation = useReservationCreation({
    onIncompleteTableAssignment: openIncompleteTableAssignmentDialog,
    onSlotStatusConfirmation: openSlotStatusConfirmationDialog,
    showTemplateChoice,
    onTemplateChoice: templateChoiceDialog.handleOpen,
    defaultSmsTemplate: smsTemplates.selectedTemplate,
    defaultEmailTemplate: emailTemplates.selectedTemplate,
  })

  const reservationUpdate = useReservationUpdate({
    onIncompleteTableAssignment: openIncompleteTableAssignmentDialog,
    onSlotStatusConfirmation: openSlotStatusConfirmationDialog,
    onTreatAsSerie: assignSerieDialog.handleOpen,
    showTemplateChoice,
    onTemplateChoice: templateChoiceDialog.handleOpen,
    defaultSmsTemplate: smsTemplates.selectedTemplate,
    defaultEmailTemplate: emailTemplates.selectedTemplate,
  })

  const sendMessage = useSendMessage({
    showTemplateChoice,
    onTemplateChoice: templateChoiceDialog.handleOpen,
  })

  const [chargePayment, setChargePayment] = React.useState(true)

  const slotStatus = useReservationSlotStatus()

  const recurringDialogProps = useDialog()

  const [shouldRecurringDialogMount, setShouldRecurringDialogMount] =
    React.useState(recurringDialogProps.open)

  React.useEffect(() => {
    if (recurringDialogProps.open) setShouldRecurringDialogMount(true)
  }, [recurringDialogProps.open])

  const hasPermissionToPerformAction = useReservationActionPermissionChecker()

  const actionButtonClickHandler = async (
    selectedAction: ReservationActions,
  ) => {
    if (!hasPermissionToPerformAction(selectedAction)) return undefined

    if (selectedAction === ReservationActions.Recurring) {
      return recurringDialogProps.handleOpen()
    }

    const nextAction = getNextAction(selectedAction)
    if (
      selectedAction === ReservationActions.Cancel ||
      selectedAction === ReservationActions.Noshow
    ) {
      setChargePayment(true)
      return reservationCancellation
        .init(reservation, selectedAction)
        .then(r => {
          if (isAbandoned(r)) return
          setAction(nextAction)
        })
    }

    if (
      selectedAction === ReservationActions.Reactivate ||
      selectedAction === ReservationActions.Reshow
    ) {
      return reservationReactivation
        .init(reservation, selectedAction)
        .then(r => {
          if (isAbandoned(r)) return
          setAction(getNextAction)
        })
    }

    if (selectedAction === ReservationActions.Create) {
      const { success } = selectedReservationSchema.safeParse(reservation)

      if (!success) {
        set('invalidState', true)
        return Promise.resolve()
      }
      if (reservation.invalidState) set('invalidState', false)

      const response = await reservationCreation.init({
        originalReservation,
        reservation,
        slotStatus,
        seatCountOverflow: getSeatCountDifference(tables)(reservation),
      })

      if (!response) return Promise.resolve()

      if (reservation.occupancies.length) onDrawerClose()

      return Promise.resolve().then(() => setAction(nextAction))
    }

    if (selectedAction === ReservationActions.Update) {
      const response = await reservationUpdate.init({
        originalReservation,
        reservation,
        slotStatus,
        seatCountOverflow: getSeatCountDifference(tables)(reservation),
      })

      if (!response) return Promise.resolve()

      return Promise.resolve().then(() => setAction(nextAction))
    }

    return sendMessage.init(reservation).then(() => setAction(nextAction))
  }

  const actionChoiceDialog = useDialog()
  const actionChoiceTriggerRef = React.useRef<HTMLDivElement>(null)

  const commsShown = React.useMemo(
    () => !areCommsHidden(reservation, action, relevantChanged),
    [reservation, action, relevantChanged],
  )

  const lockedTooltipDialog = useDialog()
  const actionsLocked =
    reservation.locked && action !== ReservationActions.Message

  return (
    <>
      <FooterContainer {...createTestIdProps('footer')}>
        {commsShown && (
          <CommunicationCheckboxes
            reservation={reservation}
            emailChecked={emailEnabled}
            onEmailCheckedChange={setEmailEnabled}
            smsChecked={smsEnabled}
            onSmsCheckedChange={setSmsEnabled}
          />
        )}
        <LockTooltip
          open={lockedTooltipDialog.open && actionsLocked}
          onClose={lockedTooltipDialog.handleClose}
          onOpen={() => actionsLocked && lockedTooltipDialog.handleOpen()}
        >
          <ActionButtonGroup
            actionHandler={() => actionButtonClickHandler(action)}
            action={action}
            disabled={!isActionEnabled(action) || actionsLocked}
            onChoiceOpen={actionChoiceDialog.handleOpen}
            choiceOpen={actionChoiceDialog.open}
            ref={actionChoiceTriggerRef}
          />
        </LockTooltip>
      </FooterContainer>
      <ActionsMenu
        reservation={reservation}
        onActionChoice={a => {
          setAction(a)
          actionChoiceDialog.handleClose()
        }}
        anchorEl={actionChoiceTriggerRef}
        onClose={actionChoiceDialog.handleClose}
        open={actionChoiceDialog.open}
        isActionEnabled={isActionEnabled}
      />
      <TemplateChoiceModal
        onClose={templateChoiceDialog.handleClose}
        open={templateChoiceDialog.open}
        onDismiss={combine([
          reservationCreation.abandon,
          reservationUpdate.abandon,
          sendMessage.abandon,
        ])}
        action={action}
        onSend={() =>
          combine([
            reservationCreation.resume,
            reservationUpdate.resume,
            sendMessage.resume,
          ])({
            smsTemplate: smsTemplates.selectedTemplate,
            emailTemplate: emailTemplates.selectedTemplate,
          })
        }
      >
        <CommunicationTemplateChoice
          smsTemplates={smsTemplates}
          emailTemplates={emailTemplates}
          smsEnabled={reservationHasPhoneNumber}
          emailEnabled={reservationHasEmail}
        />
      </TemplateChoiceModal>
      {isPaymentAction(action) && (
        <CancelConfirmationModal
          reservation={reservation}
          onClose={cancelConfirmationDialog.handleClose}
          open={cancelConfirmationDialog.open}
          action={action}
          onDismiss={reservationCancellation.abandon}
          onConfirm={() =>
            reservationCancellation.resume({
              smsTemplate: smsTemplates.selectedTemplate,
              emailTemplate: emailTemplates.selectedTemplate,
              charge: chargePayment,
              confirmed: true,
            })
          }
        >
          {showPaymentConfirmation && (
            <PaymentConfirmation
              reservation={reservation}
              charge={chargePayment}
              onChargeChange={setChargePayment}
            />
          )}
          {showTemplateChoice && (
            <CommunicationTemplateChoice
              smsTemplates={smsTemplates}
              emailTemplates={emailTemplates}
              smsEnabled={reservationHasPhoneNumber}
              emailEnabled={reservationHasEmail}
            />
          )}
        </CancelConfirmationModal>
      )}
      <ModifySerieModal
        open={assignSerieDialog.open}
        onClose={assignSerieDialog.handleClose}
        onDismiss={combine([
          reservationCancellation.abandon,
          reservationReactivation.abandon,
          reservationUpdate.abandon,
        ])}
        onAssign={decision =>
          combine([
            reservationCancellation.resume,
            reservationReactivation.resume,
            reservationUpdate.resume,
          ])({
            wholeSerie: decision,
          })
        }
      />
      <SimpleDialog
        {...slotStatusConfirmationDialog.simpleDialogProps}
        onCancellation={combine([
          reservationCreation.abandon,
          reservationUpdate.abandon,
        ])}
        onConfirmation={() => {
          combine([reservationCreation.resume, reservationUpdate.resume])({
            slotConfirmed: true,
          })
        }}
      />
      <SimpleDialog
        {...incompleteTableAssignmentDialog.simpleDialogProps}
        onCancellation={combine([
          reservationCreation.abandon,
          reservationUpdate.abandon,
        ])}
        onConfirmation={() => {
          combine([reservationCreation.resume, reservationUpdate.resume])({
            incompleteTableAssignmentConfirmed: true,
          })
        }}
      />
      {isNewReservation && shouldRecurringDialogMount && (
        <SerialReservationModal
          selectedReservation={reservation}
          dialogProps={recurringDialogProps}
          onExited={() => setShouldRecurringDialogMount(false)}
        />
      )}
    </>
  )
}

export default Footer
