import { useEffect, useRef, useState } from 'react'
import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type TeamMember } from 'src/entities/team-member/types/teamMember'
import { useLoginTeamMemberWithPinMutation } from 'src/pages/SwitchTeamMemberModal/hooks/useLoginTeamMemberWithPin'
import FormTextInput from 'src/shared/components/form/inputs/FormTextInput'
import { withTargetValue } from 'src/shared/lib/common/services/helpers/helpers'
import { useGlobalModalContext } from 'src/shared/lib/context/global/useGlobalModalContext'
import { useSetActiveTeamMemberAtom } from 'src/shared/lib/context/state/atoms/activeTeamMember'
import { TeamMemberCircle } from './TeamMemberCircle'

interface TeamMemberPinInputProps {
  teamMember: TeamMember
}

export const TeamMemberPinInput = ({ teamMember }: TeamMemberPinInputProps) => {
  const { t } = useTranslation()

  const { closeTeamMembersModal } = useGlobalModalContext()
  const pinInputRef = useRef<HTMLDivElement>(null)

  const [pinCode, setPinCode] = useState('')
  const {
    mutate: loginTeamMemberWithPin,
    isPending,
    isError,
  } = useLoginTeamMemberWithPinMutation()

  const setActiveTeamMemberId = useSetActiveTeamMemberAtom()

  useEffect(() => {
    if (pinCode.length < 6) return

    loginTeamMemberWithPin(
      { teamMember, pinCode },
      {
        onSuccess: () => {
          setActiveTeamMemberId(teamMember.id)
          closeTeamMembersModal()
        },
        onSettled: () => {
          setPinCode('')
        },
      },
    )
  }, [
    closeTeamMembersModal,
    loginTeamMemberWithPin,
    pinCode,
    setActiveTeamMemberId,
    teamMember,
  ])

  useEffect(() => {
    if (!isPending) pinInputRef?.current?.focus()
  }, [isPending])

  return (
    <Stack
      p={2}
      alignItems="center"
      gap={2}
      border="1px solid"
      borderColor="grey.100"
    >
      <TeamMemberCircle
        teamMember={teamMember}
        showPadlock
        small
        sx={{ pointerEvents: 'none' }}
      />
      <Stack textAlign="left">
        <Typography variant="labelTiny">
          {t('pin_input.input_label', 'PIN')}
        </Typography>
        <FormTextInput
          size="small"
          type="password"
          inputRef={pinInputRef}
          value={pinCode}
          disabled={isPending}
          error={isError}
          onChange={withTargetValue(setPinCode)}
          autoComplete="one-time-code"
          slotProps={{
            htmlInput: {
              inputMode: 'numeric',
              pattern: '[0-9]{6}',
            },
          }}
        />
      </Stack>
    </Stack>
  )
}
