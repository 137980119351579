import { type PropsWithChildren } from 'react'
import { Button, Stack, type ButtonProps } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import PlusIcon from 'src/shared/components/icons/PlusIcon'

export interface PhoneCallNotificationItemWrapperProps {
  onButtonClick: ButtonProps['onClick']
}

export const PhoneCallNotificationItemWrapper = ({
  children,
  onButtonClick,
}: PropsWithChildren<PhoneCallNotificationItemWrapperProps>) => {
  const { t } = useTranslation()

  return (
    <Stack
      {...createTestIdProps('phone-call-wrapper')}
      p={2}
      gap={2}
      borderRadius={8}
      border="1px solid"
      borderColor="grey.100"
    >
      {children}
      <Button
        {...createTestIdProps('phone-call-plus-button')}
        variant="contained"
        startIcon={<PlusIcon size="small" />}
        sx={{ alignSelf: 'flex-start' }}
        onClick={onButtonClick}
      >
        {t('common.create_reservation', 'Create reservation')}
      </Button>
    </Stack>
  )
}
