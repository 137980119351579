import { useCallback } from 'react'

import { createFileRoute, Navigate } from '@tanstack/react-router'

import { useHasPermission } from 'src/entities/team-member/hooks/useCheckPermissions'
import useCurrentTeamMember from 'src/entities/team-member/hooks/useCurrentTeamMember'
import { FeedbacksPage } from 'src/pages/Feedbacks/Feedbacks'
import useAvailableTeamMembers from 'src/pages/SwitchTeamMemberModal/hooks/useAvailableTeamMembers'

export const Route = createFileRoute('/restaurants/$restaurantId/feedbacks')({
  component: RouteComponent,
})

function RouteComponent() {
  const { restaurantId } = Route.useParams()
  const navigate = Route.useNavigate()

  const hasTeamMembers = useAvailableTeamMembers().length
  const teamMember = useCurrentTeamMember()
  const canUseFeedback = useHasPermission('manage_feedbacks')

  const onClose = useCallback(
    () =>
      navigate({
        to: '/restaurants/$restaurantId/reservations',
        params: { restaurantId },
      }),
    [navigate, restaurantId],
  )

  if ((!hasTeamMembers || teamMember) && !canUseFeedback) {
    return (
      <Navigate
        to="/restaurants/$restaurantId/reservations"
        params={{ restaurantId }}
      />
    )
  }

  if (!canUseFeedback) return null

  return <FeedbacksPage restaurantId={restaurantId} onClose={onClose} />
}
