import { type RegisteredRouter, type RoutePaths } from '@tanstack/react-router'

export enum SettingsScreensEnum {
  General = 'general',
  Labels = 'labels',
  Tables = 'tables',
  Areas = 'areas',
  ServiceTimes = 'serviceTimes',
  Rooms = 'rooms',
  Schedule = 'schedule',
  Widget = 'widget',
  TeamMembers = 'teamMembers',
  Integrations = 'integrations',
  Permissions = 'permissions',
}

export const SettingsScreenToPath = {
  areas: '/restaurants/$restaurantId/config/areas',
  general: '/restaurants/$restaurantId/config/general',
  integrations: '/restaurants/$restaurantId/config/integrations',
  labels: '/restaurants/$restaurantId/config/labels',
  permissions: '/restaurants/$restaurantId/config/permissions',
  rooms: '/restaurants/$restaurantId/config/rooms',
  schedule: '/restaurants/$restaurantId/config/schedule',
  widget: '/restaurants/$restaurantId/config/widget',
  serviceTimes: '/restaurants/$restaurantId/config/service-times',
  tables: '/restaurants/$restaurantId/config/tables',
  teamMembers: '/restaurants/$restaurantId/config/employees',
} satisfies Record<
  SettingsScreensEnum,
  RoutePaths<RegisteredRouter['routeTree']>
>
