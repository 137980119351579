import { type Dispatch, type SetStateAction } from 'react'
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import { useTranslation } from 'react-i18next'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import {
  type ApiSettings,
  type SettingsKeys,
} from 'src/entities/config/types/configApi'
import { CommTypes } from 'src/entities/setting/types/setting'
import {
  SETTINGS_TABLE_FIRST_COLUMN_WIDTH,
  SettingTitle,
} from '../../components/SettingTitle'

const CHANNELS = ['guestEmail', 'guestSms', 'restaurantEmail'] as const

type Channel = (typeof CHANNELS)[number]

type ChannelToSetting = Partial<Record<Channel, SettingsKeys>>

type CommTypeMap = Record<CommTypes, ChannelToSetting>

const COMM_TYPE_MAP: CommTypeMap = {
  [CommTypes.CreateNotifications]: {
    guestEmail: 'newReservationGuestEmail',
    guestSms: 'newReservationGuestSms',
    restaurantEmail: 'newReservationGastroEmail',
  },
  [CommTypes.MutateNotifications]: {
    guestEmail: 'mutationGuestEmail',
    guestSms: 'mutationGuestSms',
    restaurantEmail: 'mutationGastroEmail',
  },
  [CommTypes.MoveNotifications]: {
    guestEmail: 'changeRestaurantGuestEmail',
    guestSms: 'changeRestaurantGuestSms',
    restaurantEmail: 'changeRestaurantGastroEmail',
  },
  [CommTypes.Reminder]: { guestEmail: 'reminderEmail' },
  [CommTypes.Feedback]: { guestEmail: 'feedbackEmail' },
  [CommTypes.CancelNotifications]: {
    guestEmail: 'cancelGuestEmail',
    guestSms: 'cancelGuestSms',
    restaurantEmail: 'cancelGastroEmail',
  },
  [CommTypes.NoShowNotifications]: {
    guestEmail: 'noshowGuestEmail',
    guestSms: 'noshowGuestSms',
  },
}

interface CommsTableProps {
  settings: ApiSettings
  setSettings: Dispatch<SetStateAction<ApiSettings>>
}

export const CommsTable = ({ settings, setSettings }: CommsTableProps) => {
  const { t } = useTranslation()

  return (
    <TableContainer>
      <Table
        {...createTestIdProps('communications-table')}
        stickyHeader
        sx={{ td: { verticalAlign: 'middle' } }}
      >
        <TableHead>
          <TableRow>
            <TableCell width={SETTINGS_TABLE_FIRST_COLUMN_WIDTH}>
              {t('settings.general.comms.headers.type', 'Type')}
            </TableCell>
            {CHANNELS.map((channel, i) => (
              <TableCell
                key={channel}
                sx={i < CHANNELS.length - 1 ? { width: 188 } : undefined}
              >
                {t(`settings.general.${channel}`, channel)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(CommTypes).map(comm => (
            <TableRow {...createTestIdProps(comm)} key={comm}>
              <TableCell>
                <SettingTitle
                  title={t(
                    `settings.general.comms.${comm}.title`,
                    `${comm} title`,
                  )}
                  description={t(
                    `settings.general.comms.${comm}.description`,
                    `${comm} description`,
                  )}
                />
              </TableCell>
              {CHANNELS.map(channel => {
                const setting = COMM_TYPE_MAP[comm][channel]

                if (typeof setting === 'undefined')
                  return <TableCell key={channel} />

                return (
                  <TableCell key={channel}>
                    <Checkbox
                      checked={settings[setting]}
                      onChange={() =>
                        setSettings(s => ({ ...s, [setting]: !s[setting] }))
                      }
                    />
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
