import { compose, tap } from 'ramda'

import * as Sentry from '@sentry/browser'

import { getApiUrl } from '../../api/services/apiUrl'

export const initGlobalSentry = (dsn: string, releaseProject: string) => {
  const apiUrl = getApiUrl()

  if (import.meta.env.MODE === 'development') return

  Sentry.init({
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        ...(apiUrl && { networkDetailAllowUrls: [apiUrl] }),
      }),
      Sentry.browserProfilingIntegration(),
    ],
    dsn,
    environment: import.meta.env.MODE,
    release: `${releaseProject}@${
      import.meta.env.PUBLIC_RELEASE_VERSION
    }`.replaceAll('/', '_'),
    tracesSampleRate: import.meta.env.PUBLIC_SENTRY_SAMPLE_RATE,
    sampleRate: import.meta.env.PUBLIC_SENTRY_SAMPLE_RATE,
    profilesSampleRate:
      import.meta.env.MODE === 'production'
        ? import.meta.env.PUBLIC_SENTRY_SAMPLE_RATE
        : 0,
    replaysOnErrorSampleRate: import.meta.env.MODE === 'production' ? 1 : 0,
    ignoreErrors: [
      'TypeError: Failed to fetch',
      'TypeError: Load failed',
      'TypeError: NetworkError when attempting to fetch resource.',
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
      // Conflict resolution
      /^HTTPError: Request failed with status code 409.*/,
      // Ably
      'Error: Connection closed',
      'Error: Connection to server unavailable',
    ],
  })
}

export const captureException: typeof Sentry.captureException = compose(
  Sentry.captureException,
  tap(
    (...args) =>
      import.meta.env.MODE !== 'production' &&
      // eslint-disable-next-line no-console
      console.error(...args),
  ),
)
