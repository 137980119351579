import { Skeleton } from '@mui/material'

import { useUserType } from 'src/entities/me/queries/me'
import { UserType } from 'src/entities/me/types/me'
import useCurrentTeamMember from 'src/entities/team-member/hooks/useCurrentTeamMember'
import useAvailableTeamMembers from 'src/pages/SwitchTeamMemberModal/hooks/useAvailableTeamMembers'
import { useGlobalModalContext } from 'src/shared/lib/context/global/useGlobalModalContext'
import { TeamMemberCircle } from 'src/widgets/TeamMembers/List/TeamMemberButton/TeamMemberCircle'

export const TeamMemberToggle = () => {
  const hasNoTeamMembers = !useAvailableTeamMembers().length

  const teamMember = useCurrentTeamMember()
  const { showTeamMembersModal } = useGlobalModalContext()
  const userType = useUserType()

  if (userType === UserType.OrganisationAdmin) return null
  if (userType === UserType.RestaurantManager) return null
  if (hasNoTeamMembers) return null

  if (!teamMember) {
    return <Skeleton variant="circular" width={36} height={36} />
  }

  return (
    <TeamMemberCircle
      teamMember={teamMember}
      onClick={showTeamMembersModal}
      small
    />
  )
}
