import {
  PRESENTATION_VIEWS,
  type PresentationView,
} from 'src/pages/Reservations/service/presentationView'
import { type SettingsKeys } from '../../config/types/configApi'
import { type Setting } from '../types/setting'

export const DEFAULT_VIEWS = [
  'defaultListPlan',
  'defaultVisuellPlan',
  'defaultListView',
  'defaultTimeline',
] as const satisfies SettingsKeys[]

const SETTING_TO_VIEW_MAP: { [key in SettingsKeys]?: PresentationView } = {
  defaultListPlan: PRESENTATION_VIEWS.tablesTable,
  defaultVisuellPlan: PRESENTATION_VIEWS.floorplan,
  defaultListView: PRESENTATION_VIEWS.reservationsTable,
  defaultTimeline: PRESENTATION_VIEWS.timeline,
}

export const getDefaultView = (settings: Setting[]): PresentationView => {
  const viewSettingsNames = Object.keys(SETTING_TO_VIEW_MAP)
  const viewSettings = settings.filter(setting =>
    viewSettingsNames.includes(setting.name),
  )

  const defaultViewSetting = viewSettings.find(setting => setting.value)

  return defaultViewSetting
    ? SETTING_TO_VIEW_MAP[defaultViewSetting.name]!
    : PRESENTATION_VIEWS.timeline
}
