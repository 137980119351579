import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type FeedbackInterface } from 'src/entities/feedback/types/feedbackApi'
import Rating from 'src/pages/CustomerPreview/CustomerData/Feedback/Mean/Rating'
import PrimaryCard from 'src/shared/components/common/PrimaryCard'
import PrimaryDivider from 'src/shared/components/common/PrimaryDivider'
import PublishedDate from 'src/widgets/Reservation/Feedback/FeedbackItem/PublishedDate'
import Stats from 'src/widgets/Reservation/Feedback/Stats'

interface FeedbackCardProps {
  feedback: FeedbackInterface
}

export const FeedbackCard = ({ feedback }: FeedbackCardProps) => {
  const { t } = useTranslation()

  return (
    <PrimaryCard
      elevation={0}
      sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
    >
      <Stack
        direction={['column', 'row']}
        gap={2}
        divider={
          <PrimaryDivider orientation="vertical" variant="middle" flexItem />
        }
      >
        <Stack flexGrow={1} gap={1}>
          <Rating rating={feedback.rating} />
          <Typography variant="labelTiny" color="grey.500">
            {t('feedbacks.common.average_score', 'Average score')}
          </Typography>
        </Stack>
        <Stats feedbackStats={feedback.stats} />
      </Stack>
      <Typography
        variant="body3"
        color="grey.800"
        sx={{
          hyphens: 'auto',
          textAlign: 'justify',
        }}
      >
        {feedback.comment}
      </Typography>
      <PublishedDate date={feedback.date} textAlign="right" />
    </PrimaryCard>
  )
}
