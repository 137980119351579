import { type RefObject } from 'react'
import { compose } from 'ramda'

import { noop } from '@tanstack/react-table'
import type Konva from 'konva'
import { type KonvaEventObject } from 'konva/lib/Node'

import {
  dragElement,
  snapToGrid,
  transformElement,
} from 'src/entities/table/services/tableGrid'
import { type FloorPlanElementProps } from 'src/widgets/FloorPlan/FloorPlanElement'
import TransformableElement from 'src/widgets/FloorPlan/TransformableElement'
import { type FloorPlanElementInterface } from 'src/widgets/FloorPlan/types/floorPlanElement'

interface TableElementProps extends FloorPlanElementProps {
  onChange: (element: FloorPlanElementInterface) => void
  onTransformStart: () => void
  onTransformEnd: () => void
}

const TableElement = ({
  onChange,
  onSelect,
  element,
  onTransformStart,
  onTransformEnd,
  ref: elementRef,
  ...props
}: TableElementProps) => {
  const { current: konvaElement } = elementRef as RefObject<Konva.Group | null>
  const transformEndHandler = () =>
    konvaElement
      ? compose(
          onTransformEnd,
          onChange,
          snapToGrid,
          transformElement,
        )(element, konvaElement)
      : noop

  const dragHandler = ({ target }: KonvaEventObject<DragEvent>) =>
    compose(onTransformEnd, onChange, snapToGrid, dragElement(element))(target)

  return (
    <TransformableElement
      ref={elementRef}
      element={element}
      onSelect={onSelect}
      onDragStart={compose(onSelect ?? noop, onTransformStart)}
      onDragEnd={dragHandler}
      onTransformStart={onTransformStart}
      onTransformEnd={transformEndHandler}
      {...props}
    />
  )
}

export default TableElement
