import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import { DndContext, type DragEndEvent } from '@dnd-kit/core'
import { arrayMove, SortableContext } from '@dnd-kit/sortable'
import debounce from 'debounce'
import { useTranslation } from 'react-i18next'

import {
  useAreasOrderUpdate,
  useDeleteAreaMutation,
} from 'src/entities/area/queries/area'
import { type ApiAreaInterface } from 'src/entities/area/types/area'
import { order } from 'src/entities/area/types/areaApi'
import { useDefaultMutationHandlers } from 'src/pages/ShiftConfiguration/hooks/useDefaultMutationHandlers'
import { useBasicDndSensors } from 'src/shared/lib/common/hooks/useDnd'
import { DraggableAreaItem } from './DraggableAreaItem'

interface AreaTableProps {
  areas: ApiAreaInterface[]
  onEdit: (area: ApiAreaInterface) => void
}

export const AreaTable = ({ areas, onEdit }: AreaTableProps) => {
  const { t } = useTranslation()

  const [orderedAreas, setOrderedAreas] = useState(
    areas.sort((a, b) => a.position - b.position),
  )
  const { mutate: setOrder, isPending: isOrderPending } = useAreasOrderUpdate()
  const { mutateAsync: deleteArea, isPending: isDeletePending } =
    useDeleteAreaMutation()

  const orderChangedHandler = useDefaultMutationHandlers({
    successMessage: t('settings.common.order_changed', 'Order changed'),
  })
  const deleteHandler = useDefaultMutationHandlers({
    successMessage: t('settings.areas.common.area_deleted', 'Area removed'),
  })

  const isAnyPending = isDeletePending || isOrderPending

  const deboucedSetOrder = useMemo(() => debounce(setOrder, 3000), [setOrder])

  const [isBeingReordered, setIsBeingReordered] = useState(false)

  useEffect(() => {
    if (!isOrderPending) setIsBeingReordered(false)
  }, [isOrderPending])

  useEffect(() => {
    setOrderedAreas(areas.sort((a, b) => a.position - b.position))
  }, [areas])

  useEffect(() => {
    const hasModifiedAreas = orderedAreas.some(
      or => areas.find(r => or.id === r.id)?.position !== or.position,
    )

    if (!hasModifiedAreas) return

    setIsBeingReordered(true)
    deboucedSetOrder(order(orderedAreas), orderChangedHandler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deboucedSetOrder, orderChangedHandler, orderedAreas])

  const sensors = useBasicDndSensors()

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event

    if (!over || active.id === over.id) return

    setOrderedAreas(oldAreas => {
      const oldIndex = oldAreas.findIndex(r => r.id === active.id)
      const newIndex = oldAreas.findIndex(r => r.id === over.id)

      return arrayMove(oldAreas, oldIndex, newIndex).map((r, idx) => ({
        ...r,
        position: idx + 1,
      }))
    })
  }, [])

  return (
    <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
      <TableContainer>
        <Table stickyHeader sx={{ overflow: 'clip' }}>
          <TableHead sx={{ borderColor: 'inherit' }}>
            <TableRow>
              <TableCell>
                {t('settings.areas.table.name', 'Area name')}
              </TableCell>
              <TableCell>
                {t('settings.areas.table.room_assigned', 'Room assigned')}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody sx={{ borderColor: 'inherit' }}>
            <SortableContext items={orderedAreas} disabled={isAnyPending}>
              {orderedAreas.map(a => (
                <DraggableAreaItem
                  key={a.id}
                  area={a}
                  isBeingReordered={isBeingReordered}
                  isReorderingDisabled={isAnyPending}
                  onEdit={() => onEdit(a)}
                  onDelete={() => deleteArea(a, deleteHandler)}
                />
              ))}
            </SortableContext>
          </TableBody>
        </Table>
      </TableContainer>
    </DndContext>
  )
}
