import type React from 'react'
import { useImperativeHandle } from 'react'

export const useImperativeHandleFocus = (
  triggerRef: React.Ref<Pick<HTMLElement, 'focus'>>,
  targetRef: React.RefObject<HTMLElement | null>,
) =>
  useImperativeHandle(
    triggerRef,
    () => ({
      focus: () => {
        targetRef?.current?.focus()
      },
    }),
    [targetRef],
  )
