import { useTranslation } from 'react-i18next'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { SettingsScreensEnum } from 'src/entities/setting/types/settings'
import { SettingsHeader } from '../../layout/SettingsHeader'

interface WidgetPageHeaderProps {
  restaurantId: number
  onSave: () => void
  saveDisabled: boolean
}

export const WidgetPageHeader = ({
  restaurantId,
  onSave,
  saveDisabled,
}: WidgetPageHeaderProps) => {
  const { t } = useTranslation()
  return (
    <SettingsHeader
      restaurantId={restaurantId}
      screen={SettingsScreensEnum.Widget}
      primaryButtonProps={{
        ...createTestIdProps('save-changes'),
        onClick: onSave,
        children: t('settings.common.save_changes', 'Save changes'),
        disabled: saveDisabled,
      }}
    />
  )
}
