import { Badge, badgeClasses, styled, type ButtonProps } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import IconButton from 'src/shared/components/buttons/IconButton'
import BellIcon from 'src/shared/components/icons/BellIcon'

const SmallBadge = styled(Badge)({
  [`& .${badgeClasses.badge}`]: {
    padding: '0 4px',
    height: '16px',
    minWidth: '16px',
  },
})

interface BellButtonProps extends ButtonProps {
  notificationsCount: number | string
}

const BellButton = ({ notificationsCount, ...props }: BellButtonProps) => (
  <IconButton
    {...createTestIdProps('bell-btn')}
    color="neutral"
    {...props}
    variant="outlined"
  >
    <SmallBadge
      badgeContent={notificationsCount}
      color="attention"
      overlap="circular"
    >
      <BellIcon variant="filled" />
    </SmallBadge>
  </IconButton>
)

export default BellButton
