import React, { useCallback } from 'react'
import {
  complement,
  compose,
  cond,
  filter,
  identical,
  isEmpty,
  of,
  without,
} from 'ramda'
import { Box, Stack } from '@mui/material'

import { diff } from 'deep-object-diff'
import { useTranslation } from 'react-i18next'

import { useAreasQuery } from 'src/entities/area/queries/area'
import { type AreaInterface } from 'src/entities/area/types/area'
import { useSetFloorPlanMutation } from 'src/entities/floor-plan/queries/floorPlan'
import { useFurnitureQuery } from 'src/entities/floor-plan/queries/furniture'
import {
  assignUuid,
  isFurniture,
  isInArea,
  isTable,
  setArea,
  setTableName,
} from 'src/entities/floor-plan/services/floorPlanElement'
import useFeature from 'src/entities/info/hooks/useFeature'
import { SettingsScreensEnum } from 'src/entities/setting/types/settings'
import { useTablesQuery } from 'src/entities/table/queries/table'
import { newRectangularTable } from 'src/entities/table/types/tableApi'
import { useDefaultMutationHandlers } from 'src/pages/ShiftConfiguration/hooks/useDefaultMutationHandlers'
import CreateButton from 'src/shared/components/buttons/CreateButton'
import EmptyView from 'src/shared/components/common/EmptyView'
import {
  combine,
  replaceBy,
} from 'src/shared/lib/common/services/functional/functional'
import {
  type FloorPlanElementInterface,
  type FloorPlanTableInterface,
  type FurnitureInterface,
} from 'src/widgets/FloorPlan/types/floorPlanElement'
import AreaTabs from './AreaTabs'
import TableSelection from './ElementSelection'
import FloorPlan from './FloorPlan'
import GridTableEditForm from './FloorPlanTableEditForm'
import FurnitureEditForm from './FurnitureEditForm'
import TabularTableEditForm from './TabularTableEditForm'
import TabularView from './TabularView'
import ViewTabs from './ViewTabs'
import { TOP_BAR_BG } from '../../Reservations/components/CounterTabs'
import {
  PRESENTATION_VIEWS,
  type PresentationView,
} from '../../Reservations/service/presentationView'
import { UnsavedChangesDialog } from '../components/UnsavedChangesDialog'
import { SettingsHeader } from '../layout/SettingsHeader'

const upsertFloorplanElement =
  <T extends FloorPlanElementInterface>(newEl: T) =>
  (items: T[]) =>
    replaceBy(
      i => (newEl.id ? i.id === newEl.id : i.uuid === newEl.uuid),
      newEl,
      items,
    )

interface TablesProps {
  restaurantId: number
}

const Tables = ({ restaurantId }: TablesProps) => {
  const { t } = useTranslation()

  const handlers = useDefaultMutationHandlers({
    successMessage: t('settings.common.settings_changed', 'Settings changed'),
  })

  const { data: areas } = useAreasQuery()
  const [selectedArea, setSelectedArea] = React.useState<
    AreaInterface | undefined
  >(areas[0] as AreaInterface)

  const { data: initialTables } = useTablesQuery()
  const { data: initialFurniture } = useFurnitureQuery()

  const [originalTables, setOriginalTables] = React.useState(initialTables)
  const [originalFurniture, setOriginalFurniture] =
    React.useState(initialFurniture)

  const [tables, setTables] = React.useState(originalTables)
  const [furniture, setFurniture] = React.useState(originalFurniture)

  const reset = useCallback(
    (
      newTables: FloorPlanTableInterface[],
      newFurniture: FurnitureInterface[],
    ) => {
      setOriginalTables(newTables)
      setTables(newTables)
      setOriginalFurniture(newFurniture)
      setFurniture(newFurniture)
    },
    [],
  )

  const { mutateAsync: saveFloorPlan } = useSetFloorPlanMutation(reset)

  const [selectedElement, setSelectedElement] =
    React.useState<FloorPlanElementInterface>()

  const upsertElement = React.useCallback(
    (newEl: FloorPlanElementInterface) => {
      setSelectedElement(oldEl => {
        if ((!oldEl || isTable(oldEl)) && isTable(newEl)) {
          setTables(upsertFloorplanElement(newEl))
        }
        if ((!oldEl || isFurniture(oldEl)) && isFurniture(newEl)) {
          setFurniture(upsertFloorplanElement(newEl))
        }

        return newEl
      })
    },
    [],
  )

  const createHandler = React.useMemo(
    () =>
      compose(
        upsertElement,
        setArea(selectedArea),
        setTableName(t('settings.tables.unnamedTable')),
        assignUuid,
      ),
    [upsertElement, selectedArea, t],
  )

  const handleClose = React.useCallback(() => setSelectedElement(undefined), [])

  const deleteElement = React.useMemo(
    () =>
      combine([
        cond([
          [isTable, compose(setTables, without, of(Array))],
          [isFurniture, compose(setFurniture, without, of(Array))],
        ]),
        _ => handleClose(),
      ]),
    [handleClose],
  )

  const elements = React.useMemo(() => {
    const allElements = [...furniture, ...tables]

    if (!selectedElement) return allElements

    return [
      ...allElements.filter(complement(identical(selectedElement))),
      selectedElement,
    ]
  }, [furniture, tables, selectedElement])

  const deleteHandler = React.useMemo(
    () => compose(handleClose, deleteElement),
    [deleteElement, handleClose],
  )

  const areaChangeHandler = React.useCallback(
    (area: AreaInterface) => {
      setSelectedArea(area)
      handleClose()
    },
    [setSelectedArea, handleClose],
  )

  const displayedElements = React.useMemo(
    () => filter(isInArea(selectedArea), elements),
    [elements, selectedArea],
  )

  const viewsChoice = [
    useFeature('viewTable') && PRESENTATION_VIEWS.tablesTable,
    useFeature('viewVisuell') && PRESENTATION_VIEWS.floorplan,
  ].filter(Boolean) as PresentationView[]

  const [view, setView] = React.useState(
    viewsChoice.includes(PRESENTATION_VIEWS.floorplan)
      ? PRESENTATION_VIEWS.floorplan
      : viewsChoice[0]!,
  )

  React.useEffect(() => {
    if (view === PRESENTATION_VIEWS.floorplan || isTable(selectedElement))
      return

    handleClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view])

  const showTableGridForm =
    view === PRESENTATION_VIEWS.floorplan && isTable(selectedElement)

  const showFurnitureGridForm =
    view === PRESENTATION_VIEWS.floorplan && isFurniture(selectedElement)

  const showTableTabularForm =
    view === PRESENTATION_VIEWS.tablesTable && isTable(selectedElement)

  const showNewTableSelection =
    view === PRESENTATION_VIEWS.floorplan && !selectedElement

  const showNewTableButton =
    view === PRESENTATION_VIEWS.tablesTable && !selectedElement

  const tablesInArea = React.useMemo(
    () => tables.filter(isInArea(selectedArea)),
    [tables, selectedArea],
  )
  const isElementPending = false

  const hasNoChanges =
    isEmpty(diff(originalTables, tables)) &&
    isEmpty(diff(originalFurniture, furniture))

  if (!selectedArea)
    return <EmptyView text={t('settings.tables.noAreasWarning')} />

  return (
    <>
      <SettingsHeader
        restaurantId={restaurantId}
        screen={SettingsScreensEnum.Tables}
        primaryButtonProps={{
          onClick: () => {
            setSelectedElement(undefined)
            return saveFloorPlan(
              {
                tables,
                furniture,
                originalTables,
                originalFurniture,
              },
              handlers,
            )
          },
          children: t('settings.common.save_changes', 'Save changes'),
          disabled: hasNoChanges,
        }}
      />
      <Stack
        direction="row"
        sx={{
          flexGrow: 1,
          overflow: 'hidden',
          height: 1,
          backgroundColor: TOP_BAR_BG,
          p: [2, 3],
        }}
      >
        <Stack
          direction="column"
          sx={{
            flexGrow: 1,
            overflow: 'hidden',
          }}
        >
          <Stack
            direction="row"
            sx={{
              justifyContent: 'space-between',
              borderBottom: 1,
              borderColor: 'border',
              backgroundColor: TOP_BAR_BG,
            }}
          >
            <AreaTabs
              areas={areas}
              selectedArea={selectedArea}
              setSelectedArea={areaChangeHandler}
            />
            {viewsChoice.length > 1 && (
              <ViewTabs
                views={viewsChoice}
                view={view}
                onViewChange={setView}
              />
            )}
          </Stack>
          {view === PRESENTATION_VIEWS.floorplan && (
            <FloorPlan
              elements={displayedElements}
              selectedElement={selectedElement}
              onSelect={setSelectedElement}
              onChange={upsertElement}
            />
          )}
          {view === PRESENTATION_VIEWS.tablesTable && (
            <TabularView
              tables={tablesInArea}
              onSelect={setSelectedElement}
              selectedTable={
                selectedElement as FloorPlanTableInterface | undefined
              }
              orderChangeDisabled={!hasNoChanges}
              onOrderChangeSave={reset}
            />
          )}
        </Stack>
        <Box
          sx={{
            width: 250,
            flexShrink: 0,
            p: 1,
            pt: 2,
            backgroundColor: TOP_BAR_BG,
          }}
        >
          {showNewTableSelection && (
            <TableSelection
              isElementPending={isElementPending}
              onCreateNewTable={createHandler}
            />
          )}
          {showNewTableButton && (
            <CreateButton
              onClick={() => createHandler(newRectangularTable())}
              fullWidth
            >
              {t('addTable')}
            </CreateButton>
          )}
          {showTableTabularForm && (
            <TabularTableEditForm
              onCancel={handleClose}
              value={selectedElement}
              onChange={upsertElement}
              onDelete={deleteHandler}
            />
          )}
          {showTableGridForm && (
            <GridTableEditForm
              onCancel={handleClose}
              value={selectedElement}
              onChange={upsertElement}
              onDelete={deleteHandler}
            />
          )}
          {showFurnitureGridForm && (
            <FurnitureEditForm
              onCancel={handleClose}
              value={selectedElement}
              onChange={upsertElement}
              onDelete={deleteHandler}
              furniture={originalFurniture}
            />
          )}
        </Box>
      </Stack>
      <UnsavedChangesDialog hasChanges={!hasNoChanges} />
    </>
  )
}

export default Tables
