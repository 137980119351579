import React, { Suspense, useState } from 'react'
import { Stack } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import useFeature from 'src/entities/info/hooks/useFeature'
import { usePaymentProviderQuery } from 'src/entities/payment/queries/paymentProvider'
import { useSetReservationSlotStatus } from 'src/entities/reservation/queries/reservation'
import { useReservationLockMutation } from 'src/entities/reservation/queries/reservationsMutations'
import { type RoomInterface } from 'src/entities/room/types/room'
import {
  type NoPaymentPlan,
  type PaymentPlan,
} from 'src/entities/schedule/types/scheduleApi'
import { useSettingsObjectQuery } from 'src/entities/setting/queries/settings'
import { type SelectedReservation } from 'src/shared/lib/context/state/atoms/baseSelectedReservation'
import {
  useInitialReservation,
  useReservationChanged,
  useSelectedReservationField,
  useSetSelectedReservationField,
} from 'src/shared/lib/context/state/atoms/selectedReservation'
import { addToDate, getDifference } from 'src/shared/lib/range/services/date'
import {
  drawerColors,
  useDrawerColor,
} from 'src/widgets/SidebarDrawer/drawerColor'
import { DateSelection } from './DateSelection/DateSelection'
import { Duration } from './Duration/Duration'
import { GuestView } from './Guest'
import { LabelsView } from './Labels'
import { LastActivityIndicatorView } from './LastActivityIndicator'
import { LinkView } from './Link'
import Lock from './Lock'
import Messages from './Messages/Messages'
import { GuestNote } from './Notes/GuestNote/GuestNote'
import { ReservationOptions } from './Notes/ReservationOptions/ReservationOptions'
import { RestaurantNote } from './Notes/RestaurantNote/RestaurantNote'
import Occupancies from './Occupancies/Occupancies'
import { PaymentPlanSection } from './PaymentPlan/PaymentPlan'
import { RoomSelection } from './RoomSelection/RoomSelection'
import { RoomStayListView } from './RoomStaysList'
import { SeatCount } from './SeatCount/SeatCount'
import { SerialReservationsControl } from './SerialReservations/SerialReservations'
import { TimeSelection } from './TimeSelection/TimeSelection'

export const ReservationDrawer = ({
  reservation: selectedReservation,
}: {
  reservation: SelectedReservation
}) => {
  // TODO do something
  const { mutate: onLockedChange } = useReservationLockMutation()

  const { data: settings } = useSettingsObjectQuery()
  const { data: paymentProvider } = usePaymentProviderQuery()

  const isLinkEnabled = useFeature('reservationLink')

  const initialReservation = useInitialReservation()

  const set = useSetSelectedReservationField()

  const setReservationRoom = (room: RoomInterface) => set('room', room.id)

  const currentDate = selectedReservation.dateRange[0]
  const onDateChange = (d: Date) =>
    set('dateRange', dr => [
      d,
      addToDate(getDifference('minutes')(dr[0], dr[1]), 'minutes', d),
    ])

  const onDurationChange = (d: number) =>
    set('dateRange', dr => [dr[0], addToDate(d, 'minutes', dr[0])])

  const setReservationTime = (d: Date) =>
    set('dateRange', dr => [
      d,
      addToDate(getDifference('minutes')(dr[0], dr[1]), 'minutes', d),
    ])

  const onSeatCountChange = (c: number) => set('seatCount', c)

  const onPaymentPlanChange = (pp: PaymentPlan | NoPaymentPlan) =>
    set(
      'payment',
      pp.id
        ? {
            amountPerPerson: pp.amountPerPerson,
            type: pp.type,
            currency: paymentProvider?.currency ?? '',
            paymentPlanId: pp.id,
            status: 'pending',
            expirationDate: null,
            plan: pp,
          }
        : null,
    )

  const onLabelsChange = (ids: number[]) => set('labels', ids)

  const onFeedbackChange = (b: boolean) => set('sendFeedback', b)
  const onReminderChange = (b: boolean) => set('sendReminder', b)

  const onExactModeChange = (b: boolean) => set('exactMode', b)

  const assignSerie = useSelectedReservationField('assignSerie')

  const onAssignSerieChange = (b: boolean) => set('assignSerie', b)

  const isReservationModified = useReservationChanged()

  const [currentlyOpen, setCurrentlyOpen] = useState<
    | 'room'
    | 'time'
    | 'duration'
    | 'seatCount'
    | 'paymentPlan'
    | 'labels'
    | 'roomStay'
    | null
  >(null)

  const closeSubdrawers = () => setCurrentlyOpen(null)

  const setSlotStatus = useSetReservationSlotStatus()

  const drawerColor = useDrawerColor()

  if (!selectedReservation || !initialReservation) return null

  const { id, dateRange, locked, guestMessage, choices, payment } =
    selectedReservation

  const isReservationSaved = !!id

  return (
    <Stack
      gap={1}
      py={1.25}
      sx={{
        backgroundColor: drawerColors[drawerColor].body,
        flex: 1,
        overflow: 'auto',
        '>div': {
          px: 1.25,
          ':not(:last-child)': { pb: 1, borderBottom: '1px solid white' },
        },
      }}
    >
      <Suspense>
        <Stack gap={1}>
          <RoomSelection
            isReservationModified={isReservationModified}
            open={currentlyOpen === 'room'}
            onOpen={() => setCurrentlyOpen('room')}
            onClose={closeSubdrawers}
            selectedReservation={selectedReservation}
            setReservationRoom={setReservationRoom}
            drawerColor={drawerColor}
          />
          <DateSelection date={currentDate} onDateChange={onDateChange} />
          <React.Suspense fallback={null}>
            <TimeSelection
              currentDate={currentDate}
              isSlotPickerEnabled={settings.slotPicker}
              open={currentlyOpen === 'time'}
              onOpen={() => setCurrentlyOpen('time')}
              onClose={closeSubdrawers}
              onSlotStatusChange={setSlotStatus}
              selectedReservation={selectedReservation}
              initialReservation={initialReservation}
              setReservationTime={setReservationTime}
              drawerColor={drawerColor}
            />
          </React.Suspense>
          <Duration
            duration={getDifference('minutes')(...dateRange)}
            open={currentlyOpen === 'duration'}
            onOpen={() => setCurrentlyOpen('duration')}
            onClose={closeSubdrawers}
            onDurationChange={onDurationChange}
            drawerColor={drawerColor}
          />
          <SeatCount
            open={currentlyOpen === 'seatCount'}
            onOpen={() => setCurrentlyOpen('seatCount')}
            onClose={closeSubdrawers}
            reservation={selectedReservation}
            onSeatCountChange={onSeatCountChange}
            drawerColor={drawerColor}
            assignSerie={assignSerie}
            disabled={isReservationSaved && payment?.type === 'pre_payment'}
          />
          <Stack gap={2}>
            <GuestNote note={guestMessage} />
            <RestaurantNote />
            <ReservationOptions options={choices} />
            {isReservationSaved && (
              <Lock
                locked={locked}
                onLockedChange={l =>
                  onLockedChange({
                    reservation: selectedReservation,
                    locked: l,
                  })
                }
              />
            )}
          </Stack>
        </Stack>
        <GuestView selectedReservation={selectedReservation} />
        <PaymentPlanSection
          open={currentlyOpen === 'paymentPlan'}
          onOpen={() => setCurrentlyOpen('paymentPlan')}
          onClose={closeSubdrawers}
          onPaymentPlanChange={onPaymentPlanChange}
          selectedReservation={selectedReservation}
          drawerColor={drawerColor}
        />

        <RoomStayListView
          selectedReservation={selectedReservation}
          open={currentlyOpen === 'roomStay'}
          onOpen={() => setCurrentlyOpen('roomStay')}
          onClose={closeSubdrawers}
          drawerColor={drawerColor}
        />
        <Occupancies
          reservation={selectedReservation}
          onAssignSerieChange={onAssignSerieChange}
          assignSerie={assignSerie}
          onExactModeChange={onExactModeChange}
        />
        <SerialReservationsControl selectedReservation={selectedReservation} />
        <LabelsView
          {...createTestIdProps('labels')}
          open={currentlyOpen === 'labels'}
          onOpen={() => setCurrentlyOpen('labels')}
          onClose={closeSubdrawers}
          selectedReservation={selectedReservation}
          onChange={onLabelsChange}
          drawerColor={drawerColor}
        />
        {isLinkEnabled && <LinkView reservation={selectedReservation} />}
        <React.Suspense fallback={null}>
          <Messages
            onFeedbackChange={onFeedbackChange}
            onReminderChange={onReminderChange}
            reservation={selectedReservation}
          />
        </React.Suspense>
        <React.Suspense fallback={null}>
          {!!id && <LastActivityIndicatorView reservationId={id} />}
        </React.Suspense>
      </Suspense>
    </Stack>
  )
}
