import { Button, Stack, Typography, type ButtonProps } from '@mui/material'

import { createLink } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import {
  SettingsScreensEnum,
  SettingsScreenToPath,
} from 'src/entities/setting/types/settings'
import IconButton from 'src/shared/components/buttons/IconButton'
import { ChevronLeftIcon } from 'src/shared/components/icons/ChevronIcon'
import { useSettingsHelpers } from '../hooks/useSettingsHelpers'

interface SettingsSidebarButtonProps extends Omit<ButtonProps, 'href' | 'sx'> {
  isActive?: boolean
}

const SettingsSidebarButton = createLink(
  ({ isActive, ...props }: SettingsSidebarButtonProps) => (
    <Button
      component="a"
      variant="color-outlined"
      color={isActive ? 'primary' : undefined}
      aria-selected={isActive}
      sx={{
        px: 1.5,
        pl: [5, 1.5],
        height: 'auto',
        minHeight: 40,
        border: 'none',
        borderRadius: 0,
        textAlign: 'left',
        justifyContent: 'flex-start',
        ...(isActive && {
          borderRight: '2px solid',
        }),
      }}
      {...props}
    />
  ),
)

interface SettingsSidebarProps {
  restaurantId: number
  onBack: () => void
}

export const SettingsSidebar = ({
  restaurantId,
  onBack,
}: SettingsSidebarProps) => {
  const { t } = useTranslation()

  const { shouldShowTab } = useSettingsHelpers()

  return (
    <Stack
      width={[1, 224]}
      flexShrink={0}
      py={3}
      gap={2}
      height="100%"
      sx={{
        borderRight: theme => ['none', `1px solid ${theme.palette.grey[100]}`],
      }}
    >
      <Stack alignItems="center" direction="row" px={[0, 1.5]}>
        <IconButton
          sx={{ display: ['flex', 'none'] }}
          color="neutral"
          onClick={onBack}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant="labelBig">
          {t('settings.tab_names.settingsTitle')}
        </Typography>
      </Stack>
      <Stack>
        {Object.values(SettingsScreensEnum).map(screen => {
          if (!shouldShowTab(screen)) return null

          return (
            <SettingsSidebarButton
              key={screen}
              id={`settings_${screen}`}
              to={SettingsScreenToPath[screen]}
              params={{ restaurantId }}
              activeProps={{ isActive: true }}
            >
              {t(`settings.tab_names.${screen}`)}
            </SettingsSidebarButton>
          )
        })}
      </Stack>
    </Stack>
  )
}
