import { z } from 'zod'

import { restaurantSchema } from 'src/entities/restaurant/types/restaurant'

export enum UserType {
  Superadmin = 'superadmin',
  Gastro = 'gastro',
  Integration = 'integration',
  OrganisationAdmin = 'org_admin',
  RestaurantManager = 'restaurant_manager',
  Service = 'service',
  OrganisationViewer = 'org_viewer',
}

export const meSchema = z.object({
  defaultRestaurant: restaurantSchema,
  restaurants: z.array(restaurantSchema),
  superadmin: z.boolean(),
  userType: z.nativeEnum(UserType),
})

export type Me = z.infer<typeof meSchema>
