import { createFileRoute } from '@tanstack/react-router'

import Labels from 'src/pages/Settings/Labels'

export const Route = createFileRoute(
  '/restaurants/$restaurantId/config/labels',
)({
  component: RouteComponent,
})

function RouteComponent() {
  const { restaurantId } = Route.useParams()

  return <Labels restaurantId={restaurantId} />
}
