import React from 'react'

import {
  useMutation,
  useQueryClient,
  useSuspenseQueries,
  useSuspenseQuery,
} from '@tanstack/react-query'

import { isRecent } from 'src/pages/CustomerPreview/service/feedback'
import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'
import { useRestaurantCacheKeyFactory } from 'src/shared/lib/api/queries/useRestaurantCacheKey'
import { create5MinuteCronTime } from 'src/shared/lib/api/services/api'
import { getNowInRestaurantTimezone } from 'src/shared/lib/range/services/date'
import { type CustomerInterface } from '../../customer/types/customer'
import {
  fetchFeedback,
  replyToFeedback,
  searchFeedbacks,
  type ReplyToFeedbackDto,
} from '../api/feedbackApi'
import { type FeedbackApiFilters } from '../types/feedbackApi'

const FEEDBACK_QUERY_KEY = ['feedback']
const FEEDBACK_SEARCH_QUERY_KEY = ['feedbackSearch']

const useFeedbackSearchKeyFactory = () =>
  useRestaurantCacheKeyFactory(FEEDBACK_SEARCH_QUERY_KEY)

const useFeedbackCacheKeyFactory = () =>
  useRestaurantCacheKeyFactory(FEEDBACK_QUERY_KEY)

const every5Minutes = create5MinuteCronTime()

const getFeedbackRefetchInterval = () => every5Minutes.getTimeout()

const useItemQueryOptionsFactory = () => {
  const apiClient = useApiClient()
  const keyFactory = useFeedbackCacheKeyFactory()

  return React.useCallback(
    (id: number) => ({
      queryKey: keyFactory([id]),
      queryFn: () => fetchFeedback(apiClient.post)(id),
      retryOnMount: false,
      refetchOnMount: false,
      refetchInterval: getFeedbackRefetchInterval,
      staleTime: 300_000,
    }),
    [apiClient, keyFactory],
  )
}

export const useFeedbacks = (feedbackIds: number[]) => {
  const optionsFactory = useItemQueryOptionsFactory()

  return useSuspenseQueries({
    queries: feedbackIds.map(optionsFactory),
    combine: results => results.map(result => result.data),
  })
}

const useSearchQueryOptionsFactory = () => {
  const apiClient = useApiClient()
  const keyFactory = useFeedbackSearchKeyFactory()

  return React.useCallback(
    (filters?: FeedbackApiFilters) => ({
      queryKey: keyFactory(filters ? [filters.customerId] : ['all']),
      queryFn: () => {
        if (filters?.customerId === 0) return []

        return searchFeedbacks(apiClient.post)(filters)
      },
      staleTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000,
    }),
    [apiClient, keyFactory],
  )
}

export const useFeedbackSearchQuery = (filters?: FeedbackApiFilters) =>
  useSuspenseQuery(useSearchQueryOptionsFactory()(filters))

export const useRecentFeedbackFetcher = () => {
  const queryClient = useQueryClient()

  const searchOptionsFactory = useSearchQueryOptionsFactory()
  const itemOptionsFactory = useItemQueryOptionsFactory()

  return React.useCallback(
    async (customerId: CustomerInterface['id'] | undefined) => {
      const ids = await queryClient.fetchQuery(
        searchOptionsFactory({ customerId }),
      )
      const queries = ids
        .map(itemOptionsFactory)
        .map(q => queryClient.fetchQuery(q))

      const feedbacks = await Promise.all(queries)
      const now = getNowInRestaurantTimezone()

      return feedbacks.some(isRecent(now))
    },
    [itemOptionsFactory, queryClient, searchOptionsFactory],
  )
}

export const useReplyToFeedbackMutation = () => {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()

  const searchCacheKey = useFeedbackSearchKeyFactory()()
  const feedbackCacheKeyFactory = useFeedbackCacheKeyFactory()

  return useMutation({
    mutationFn: (dto: ReplyToFeedbackDto) =>
      replyToFeedback(apiClient.post)(dto),
    onSuccess: (_, { id }) =>
      queryClient.invalidateQueries({
        queryKey: feedbackCacheKeyFactory([id]),
      }),
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: searchCacheKey }),
  })
}
