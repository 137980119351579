import { useTranslation } from 'react-i18next'

import {
  LoadingButton,
  type LButtonProps,
} from 'src/shared/components/buttons/LoadingButton/LoadingButton'

const SaveButton = (props: LButtonProps) => {
  const { t } = useTranslation()

  return (
    <LoadingButton variant="contained" color="primary" {...props}>
      {t('schedule.payment_plan.save_button_label', {
        defaultValue: 'Save',
        tDescription: 'Label for the save button in payment plan forms',
      })}
    </LoadingButton>
  )
}

export default SaveButton
