import { path } from 'ramda'

import { useController, useFormContext, type FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { ServiceTimeInterface } from 'src/entities/config/types/configApi'
import { intersectTimeRange } from 'src/entities/service-time/services/serviceTime'
import MinutesPicker from 'src/shared/components/form/inputs/MinutesPicker/MinutesPicker'
import dayjs from 'src/shared/lib/range/services/date'
import { isValid } from 'src/shared/lib/range/services/timeRange'

const Time = ({
  name,
  label,
  serviceTimes,
}: {
  name: 'timeRange.0' | 'timeRange.1'
  label: string
  serviceTimes: ServiceTimeInterface[]
}) => {
  const { watch } = useFormContext<ServiceTimeInterface>()

  const { t } = useTranslation()

  const timeRange = watch('timeRange')

  const {
    field: { onChange, value: dateValue },
    formState: { errors },
  } = useController<ServiceTimeInterface, 'timeRange.0' | 'timeRange.1'>({
    name,
    rules: {
      required: t('common.validation.required'),
      validate: {
        format: date =>
          dayjs(date).isValid() || t('settings.service_times.invalidFormat'),
        noTimeTravel: () =>
          isValid(timeRange) || t('settings.service_times.timeTravelError'),
        noOverlap: date => {
          if (intersectTimeRange(serviceTimes, date)(timeRange))
            return t('settings.service_times.overlapError')

          return true
        },
        onlyQuarters: date =>
          dayjs(date).minute() % 15 === 0 ||
          t('settings.service_times.time_quarter_only'),
      },
    },
  })

  const error = path<FieldError>(name.split('.'), errors)

  return (
    <MinutesPicker
      label={label}
      onChange={onChange}
      value={dateValue}
      error={!!error}
      helperText={error?.message}
      fullWidth
    />
  )
}

export default Time
