import React, { type Ref } from 'react'
import { Box, type PaletteOptions, type SelectProps } from '@mui/material'

import HiddenItemsContainer from './HiddenItemsContainer'
import Item from './Item'
import ItemsContainer from './ItemsContainer'
import MoreIndicator from './MoreIndicator'
import { Placeholder } from './Placeholder'
import { useVisibleItemsWithCounterCount } from './useVisibleItemsWithCounterCount'

interface MultiSelectDropdownValueProps<T> {
  values: T[]
  onRemoveValue?: (item: T) => void
  itemToString: (item: T) => string
  placeholder?: string
  size: SelectProps['size']
  disabled?: boolean
  palette: keyof PaletteOptions
  ref?: Ref<HTMLDivElement>
}

const MultiSelectDropdownValue = <T,>({
  values,
  onRemoveValue,
  itemToString,
  placeholder,
  size = 'medium',
  disabled = false,
  palette,
  ref,
}: MultiSelectDropdownValueProps<T>) => {
  const containerRef = React.useRef(null)

  const {
    itemRef,
    counterRef,
    counterIsShown,
    visibleItemsCount,
    nonIntersectingItemsCount,
  } = useVisibleItemsWithCounterCount(values.length, containerRef)

  if (placeholder && values.length === 0) {
    return <Placeholder size={size} placeholder={placeholder} />
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
        position: 'relative',
      }}
      ref={containerRef}
    >
      <HiddenItemsContainer>
        {values.map((item, index) => (
          <Item
            key={`${itemToString(item)}_${index}`}
            ref={itemRef}
            palette={palette}
            {...(onRemoveValue && { onRemove: () => onRemoveValue(item) })}
          >
            {itemToString(item)}
          </Item>
        ))}
      </HiddenItemsContainer>
      <HiddenItemsContainer>
        {values.slice(0, nonIntersectingItemsCount).map((item, index) => (
          <Item
            key={`${itemToString(item)}_${index}`}
            palette={palette}
            {...(onRemoveValue && { onRemove: () => onRemoveValue(item) })}
          >
            {itemToString(item)}
          </Item>
        ))}
        <MoreIndicator
          ref={counterRef}
          visibleItemsCount={nonIntersectingItemsCount}
          totalItemsCount={values.length}
          palette={palette}
        />
      </HiddenItemsContainer>
      <ItemsContainer ref={ref}>
        {values.slice(0, visibleItemsCount).map((item, index) => (
          <Item
            key={`${itemToString(item)}_${index}`}
            disabled={disabled}
            palette={palette}
            {...(onRemoveValue && { onRemove: () => onRemoveValue(item) })}
          >
            {itemToString(item)}
          </Item>
        ))}
        {counterIsShown && (
          <MoreIndicator
            visibleItemsCount={visibleItemsCount}
            totalItemsCount={values.length}
            disabled={disabled}
            palette={palette}
          />
        )}
      </ItemsContainer>
    </Box>
  )
}

export default MultiSelectDropdownValue
