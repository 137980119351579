import { createFileRoute } from '@tanstack/react-router'

import Areas from 'src/pages/Settings/Areas'

export const Route = createFileRoute('/restaurants/$restaurantId/config/areas')(
  {
    component: RouteComponent,
  },
)

function RouteComponent() {
  const { restaurantId } = Route.useParams()

  return <Areas restaurantId={restaurantId} />
}
