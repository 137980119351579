import { useMemo } from 'react'

import { useCustomers } from 'src/entities/customer/queries/customer'
import {
  useFeedbacks,
  useFeedbackSearchQuery,
} from 'src/entities/feedback/queries/feedback'
import { type ExtendedFeedbackInterface } from 'src/entities/feedback/types/feedbackApi'
import { useReservations } from 'src/entities/reservation/queries/reservation'
import { isNotFalsy } from 'src/shared/lib/zod/zod'

export const useExtendedFeedbacks = () => {
  const { data: ids } = useFeedbackSearchQuery()
  const feedbacks = useFeedbacks(ids)
  const reservations = useReservations(ids)
  const customers = useCustomers([
    ...new Set(reservations.map(r => r.guest.customerId).filter(isNotFalsy)),
  ])

  return useMemo(() => {
    const reservationMap = new Map(reservations.map(r => [r.id, r]))
    const customerMap = new Map(customers.map(c => [c.id, c]))

    return feedbacks.map(f => {
      const reservation = reservationMap.get(f.reservationId)
      const customer = reservation?.guest.customerId
        ? customerMap.get(reservation?.guest.customerId)
        : undefined

      return {
        ...f,
        reservation,
        customer,
      } as ExtendedFeedbackInterface
    })
  }, [customers, feedbacks, reservations])
}
