import { type GuestInterface } from 'src/entities/reservation/types/reservation'

export const doesGuestExist = (guest: GuestInterface | undefined) =>
  !!guest?.customerId

export const isGuestSelected = (guest: GuestInterface | undefined) => {
  if (!guest) return false

  const guestExists = doesGuestExist(guest)

  const hasAnyData = guest.firstName || guest.lastName || guest.companyName
  const isWalkIn =
    guest.lastName === 'Walk-In' && (!guest.firstName || !guest.companyName)

  return guestExists || (hasAnyData && !isWalkIn)
}
