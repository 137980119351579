import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, type UseFormProps } from 'react-hook-form'
import type { SetRequired } from 'type-fest'
import { z } from 'zod'

import {
  widgetGuestMessageSchema,
  widgetWelcomeMessageSchama,
} from 'src/entities/widget/types/widget'

const formSchema = z.object({
  welcomeMessage: widgetWelcomeMessageSchama,
  guestMessage: widgetGuestMessageSchema,
})

export type WidgetFormValues = z.infer<typeof formSchema>

type Options = SetRequired<
  Omit<UseFormProps<WidgetFormValues>, 'resolver'>,
  'defaultValues'
>

export const useWidgetForm = (options: Options) =>
  useForm<WidgetFormValues>({
    resolver: zodResolver(formSchema),
    ...options,
  })
