import { useCallback, useMemo } from 'react'
import { RadioGroup, Typography } from '@mui/material'

import { type TFunction } from 'i18next'
import { useForm, type UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { type IntegrationFlow } from 'src/entities/integration/api/integrationFlowsApi'
import { useSetupIntegrationMutation } from 'src/entities/integration/queries/integrations'
import { showToast } from 'src/shared/components/common/toast'
import { type useSimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/useSimpleDialog'
import { FakeLabel } from 'src/shared/components/form/common/FakeLabel/FakeLabel'
import { RadioOption } from 'src/shared/components/form/inputs/RadioChoice'
import { combine } from 'src/shared/lib/common/services/functional/functional'

const showErrorToast = (t: TFunction) => () =>
  showToast(
    t(
      'settings.integrations.errors.error_while_integrating',
      'An error occurred while integrating.',
    ),
    'error',
  )

const showSuccessToast = (t: TFunction) => () =>
  showToast(
    t(
      'settings.integrations.successes.successful_integration',
      'Successful integration!',
    ),
    'success',
  )

export const useIntegrationMutationHandlers = () => {
  const { t } = useTranslation()

  return useMemo(
    () => ({ onSuccess: showSuccessToast(t), onError: showErrorToast(t) }),
    [t],
  )
}

interface Selectable<T> {
  id: T
  name: string
}

interface SelectableThingProps<T> {
  label?: string
  items: Selectable<T>[]
  register: UseFormRegister<{ selectedId: string }>
}

const SelectableThing = <T,>({
  label,
  items,
  register,
}: SelectableThingProps<T>) => (
  <>
    {label && <FakeLabel sx={{ mb: 1 }}>{label}</FakeLabel>}
    <RadioGroup
      sx={{ gap: 1.5 }}
      defaultValue={items[0]?.id}
      {...register('selectedId')}
    >
      {items.map(s => (
        <RadioOption
          key={String(s.id)}
          value={s.id}
          label={s.name}
          {...register('selectedId')}
        />
      ))}
    </RadioGroup>
  </>
)

export const useHandleSuccessFlow = (
  showSimpleDialog: ReturnType<typeof useSimpleDialog>['showSimpleDialog'],
) => {
  const { t } = useTranslation()
  const { mutate: setup, isPending } = useSetupIntegrationMutation()
  const { handleSubmit, register, reset } = useForm<{
    selectedId: string
  }>()

  return {
    handleSuccessFlow: useCallback(
      ({ status, integrationName, configuration, id }: IntegrationFlow) => {
        if (status !== 'success') return

        if (integrationName === 'lightspeed') {
          const { businessLocations } = configuration
          if (!businessLocations[0]) return

          showSimpleDialog({
            children: (
              <SelectableThing
                label={t(
                  'settings.integrations.lightspeed.business_location',
                  'Business location',
                )}
                items={configuration.businessLocations}
                register={register}
              />
            ),
            onConfirmation: handleSubmit(({ selectedId }) =>
              setup(
                {
                  integrationName,
                  integrationFlowId: id,
                  data: {
                    businessLocationId: selectedId,
                  },
                },
                {
                  onSuccess: combine([showSuccessToast(t), () => reset()]),
                  onError: combine([showErrorToast(t), () => reset()]),
                },
              ),
            ),
          })
        }

        if (integrationName === 'peoplefone') {
          showSimpleDialog({
            children: <Typography>{configuration.tokenUrl}</Typography>,
          })
        }
      },
      [handleSubmit, register, reset, setup, showSimpleDialog, t],
    ),
    isLoading: isPending,
  }
}
