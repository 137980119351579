import { andThen, composeWith, prop } from 'ramda'

import { z } from 'zod'

import {
  convertKeysToCamelCase,
  convertKeysToSnakeCase,
  type ApiClient,
} from 'src/shared/lib/api/services/api'
import { type IntegrationName } from './integrationsApi'

const baseSchema = z.object({
  id: z.string().uuid(),
  status: z.enum(['unauthenticated', 'unauthorized', 'success']),
})

const lightspeedSchema = baseSchema.extend({
  integrationName: z.literal('lightspeed' satisfies IntegrationName),
  configuration: z.object({
    authorizationUrl: z.string().url(),
    businessLocations: z.array(
      z.object({
        id: z.coerce.string(),
        name: z.string(),
      }),
    ),
  }),
})

const peoplefoneSchema = baseSchema.extend({
  integrationName: z.literal('peoplefone' satisfies IntegrationName),
  configuration: z.object({ tokenUrl: z.string() }),
})

const integrationFlowSchema = z.discriminatedUnion('integrationName', [
  lightspeedSchema,
  peoplefoneSchema,
])

export type IntegrationFlow = z.infer<typeof integrationFlowSchema>

export const startIntegrationFlow =
  (httpClient: ApiClient) =>
  // eslint-disable-next-line @typescript-eslint/require-await
  async (data: { integrationName: IntegrationName }) =>
    composeWith(andThen)([
      (d: unknown) => integrationFlowSchema.parse(d),
      prop('integrationFlow'),
      convertKeysToCamelCase,
      httpClient,
    ])({
      method: 'POST',
      url: '',
      json: convertKeysToSnakeCase(data),
    })

export const getIntegrationFlow =
  (httpClient: ApiClient) =>
  ({ id }: Pick<IntegrationFlow, 'id'>) =>
  // eslint-disable-next-line @typescript-eslint/require-await
  async () =>
    composeWith(andThen)([
      (d: unknown) => integrationFlowSchema.parse(d),
      prop('integrationFlow'),
      convertKeysToCamelCase,
      httpClient,
    ])({
      method: 'GET',
      url: id,
    })
