import React from 'react'

export const RESUMABLE_ABANDONED = {
  abandoned: true,
}

type ResumableAbandoned = typeof RESUMABLE_ABANDONED

export type Step<Options, Deps> = [
  (opts: Options, deps: Deps) => boolean,
  (opts: Options, deps: Deps) => void | ResumableAbandoned,
]

type Proceed<Options> = (newOptions: Options) => Proceed<Options> | Options

type StepProcessor<Options, Deps> = (
  steps: Step<Options, Deps>[],
) => Proceed<Options>

export const resumable =
  <Options extends object, Deps extends object>(
    initSteps: Step<Options, Deps>[],
    initOptions: Options = {} as Options,
  ) =>
  (deps: Deps) => {
    const processSteps: StepProcessor<Options, Deps> = steps => options => {
      const [step, ...rest] = steps

      if (!step) return options

      const [condition, conditionCallback] = step
      if (condition(options, deps)) {
        if (conditionCallback(options, deps) === RESUMABLE_ABANDONED)
          return RESUMABLE_ABANDONED as Options

        return newOptions =>
          processSteps?.(steps)?.({ ...options, ...newOptions })
      }

      return processSteps?.(rest)?.(options)
    }

    return processSteps?.(initSteps)(initOptions)
  }

const usePromise = <T>() => {
  const resolveRef = React.useRef<(result: T) => void>(undefined)

  const rejectRef = React.useRef<() => void>(undefined)

  const resolvePromise = React.useCallback((options: T) => {
    resolveRef.current?.(options)
    resolveRef.current = undefined
    rejectRef.current = undefined
  }, [])

  const rejectPromise = React.useCallback(() => {
    rejectRef.current?.()
    resolveRef.current = undefined
    rejectRef.current = undefined
  }, [])

  React.useEffect(() => rejectPromise, [rejectPromise])

  const startPromise = React.useCallback(() => {
    rejectPromise()

    return new Promise<T>((resolve, reject) => {
      resolveRef.current = resolve
      rejectRef.current = reject
    })
  }, [rejectPromise])

  return {
    start: startPromise,
    resolve: resolvePromise,
  }
}

export const isAbandoned = <T extends object>(
  result: ResumableAbandoned | T,
): result is ResumableAbandoned => result === RESUMABLE_ABANDONED

export const useResumable = <Options extends object, Deps extends object>(
  currentResumable: ReturnType<typeof resumable<Options, Deps>>,
) => {
  const [cr, setContinueResumable] =
    React.useState<ReturnType<typeof currentResumable>>()

  const { start, resolve } = usePromise<Options | ResumableAbandoned>()

  const handleContinuation = React.useCallback(
    (continuation: typeof cr) => {
      if (!continuation) return continuation

      if (typeof continuation !== 'function') {
        resolve(continuation)
        return undefined
      }
      return continuation
    },
    [resolve],
  )

  const init = React.useCallback(
    async (deps: Deps) => {
      const resumePromise = start()

      setContinueResumable(() => handleContinuation(currentResumable(deps)))

      return resumePromise
    },
    [start, handleContinuation, currentResumable],
  )

  const resume = React.useCallback(
    (options: Options) =>
      setContinueResumable((oldContinue: typeof cr) => {
        if (typeof oldContinue === 'function') {
          return handleContinuation(oldContinue(options))
        }

        return oldContinue
      }),
    [handleContinuation],
  )

  return {
    init,
    resume,
    abandon: React.useCallback(() => resolve(RESUMABLE_ABANDONED), [resolve]),
  }
}
