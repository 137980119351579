import React from 'react'

import useEventListenerEffect from '../../../lib/common/hooks/useEventListenerEffect'

const useOnClickOutsideEffect = (
  nodeRef: React.RefObject<HTMLElement | undefined | null>,
  callback: () => void,
) =>
  useEventListenerEffect(
    window,
    'click',
    React.useCallback<EventListener>(
      event =>
        !nodeRef?.current?.contains?.(event.target as Node) && callback?.(),
      [callback, nodeRef],
    ),
  )

export default useOnClickOutsideEffect
