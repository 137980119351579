import React, { useState } from 'react'
import { Stack } from '@mui/material'

import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  type SortingState,
} from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { type ExtendedFeedbackInterface } from 'src/entities/feedback/types/feedbackApi'
import ResultsCount from 'src/pages/Search/ResultsCount'
import EmptyView from 'src/shared/components/common/EmptyView'
import VirtualTable from 'src/widgets/Reservation/Table/VirtualTable'
import { useExtendedFeedbacks } from './useExtendedFeedbacks'
import {
  FeedbackTableColumnsIds,
  useFeedbackTableColumns,
} from './useFeedbackTableColumns'

interface FeedbacksTableProps {
  onFeedbackClick: (f: ExtendedFeedbackInterface) => void
}

export const FeedbacksTable = React.memo(
  ({ onFeedbackClick }: FeedbacksTableProps) => {
    const { t } = useTranslation()

    const extendedFeedbacks = useExtendedFeedbacks()

    const [sortingState, setSortingState] = useState<SortingState>([
      {
        id: FeedbackTableColumnsIds.Date,
        desc: true,
      },
    ])

    const columns = useFeedbackTableColumns(onFeedbackClick)

    const table = useReactTable({
      data: extendedFeedbacks,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      defaultColumn: {
        enableSorting: false,
        minSize: 0,
        size: 0,
      },
      state: {
        sorting: sortingState,
      },
      getRowId: f => String(f.id),
      enableSortingRemoval: false,
      onSortingChange: setSortingState,
    })

    const { rows } = table.getSortedRowModel()

    if (!rows.length)
      return (
        <EmptyView
          text={t(
            'feedbacks.no_results',
            'No feedback found matching the criteria',
          )}
        />
      )

    return (
      <Stack gap={2} px={[1, 3]} overflow="hidden">
        <ResultsCount count={rows.length} />
        <Stack overflow="hidden">
          <VirtualTable
            rows={rows}
            headerGroups={table.getHeaderGroups()}
            estimatedSize={73}
          />
        </Stack>
      </Stack>
    )
  },
)
