import { Box, type BoxProps } from '@mui/material'

import { mergeSx } from 'src/app/theme/helpers'
import { svgPathSizeFactory, svgSizeFactory } from 'src/app/theme/svg'

export interface IconProps extends BoxProps {
  component?: React.ElementType
  size?: 'small' | 'medium' | 'inherit' | 'large'
  variant?: 'filled' | 'outlined'
  disabled?: boolean
  error?: boolean
  OutlinedVariant?: React.FC
  FilledVariant?: React.FC
  smallVariants?: {
    filled?: React.FC
    outlined?: React.FC
  }
}

const IconContainer = ({
  size = 'medium',
  variant = 'outlined',
  children,
  sx,
  disabled,
  error,
  OutlinedVariant,
  smallVariants,
  FilledVariant,
  ...props
}: IconProps) => {
  const icons = {
    small: {
      filled: FilledVariant,
      outlined: OutlinedVariant,
      ...smallVariants,
    },
    medium: {
      filled: FilledVariant,
      outlined: OutlinedVariant,
    },
    large: {
      filled: FilledVariant,
      outlined: OutlinedVariant,
    },
    inherit: {
      filled: FilledVariant,
      outlined: OutlinedVariant,
    },
  }

  const Component =
    icons[size]?.[variant] ??
    icons[size]?.outlined ??
    icons[size]?.filled ??
    icons.medium[variant] ??
    OutlinedVariant ??
    FilledVariant ??
    (() => null)

  const iconVariant = Component === FilledVariant ? 'filled' : variant

  return (
    <Box
      {...props}
      sx={mergeSx(
        {
          ...svgSizeFactory(size),
          ...(!smallVariants?.outlined &&
            svgPathSizeFactory(size, iconVariant)),
        },
        theme => ({
          display: 'flex',
          alignItems: 'center',
          ...(disabled && {
            color: theme.palette.action.disabled,
          }),
          ...(error && {
            color: theme.palette.error.main,
          }),
        }),
        sx,
      )}
    >
      {children ?? <Component />}
    </Box>
  )
}

export const withIconContainer =
  (
    OutlinedVariant?: React.FC,
    FilledVariant?: React.FC,
    smallVariants?: {
      filled?: React.FC
      outlined?: React.FC
    },
  ) =>
  (props: IconProps) => (
    <IconContainer
      {...props}
      OutlinedVariant={OutlinedVariant}
      FilledVariant={FilledVariant}
      smallVariants={smallVariants}
    />
  )

export default IconContainer
