import { type ButtonProps } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import CloseIcon from '../../icons/CloseIcon'
import IconButton from '../IconButton'

const CloseButton = (props: ButtonProps) => (
  <IconButton
    {...createTestIdProps('close-btn')}
    size="medium"
    variant="text"
    color="neutral"
    {...props}
  >
    <CloseIcon />
  </IconButton>
)

export default CloseButton
