import { Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { useReservationGroupQuery } from 'src/entities/reservation/queries/reservationGroup'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import ReservationDrawerSection from 'src/shared/components/common/ReservationDrawerSection'
import SerialCalendarIcon from 'src/shared/components/icons/SerialCalendarIcon'
import { useShortDateRangeFormatter } from 'src/shared/lib/range/hooks/useFormattedDate'
import { isSinglePointRange } from 'src/shared/lib/range/services/timeRange'
import { getGroupSerialString } from './services/reservationGroup'

interface SerialReservationsProps {
  selectedReservation: ReservationInterface
}

export const SerialReservationsControl = ({
  selectedReservation,
}: SerialReservationsProps) => {
  const { t } = useTranslation()

  const isSaved = !!selectedReservation.id

  const { data: reservationGroup } = useReservationGroupQuery(
    selectedReservation?.serialId,
  )

  const serialString = getGroupSerialString(
    t,
    useShortDateRangeFormatter(),
    isSinglePointRange('day'),
  )(reservationGroup)

  if (!isSaved) return null

  if (!serialString) return null

  return (
    <ReservationDrawerSection
      {...createTestIdProps('serial-reservation-infos')}
      title={t('angular.serial_reservation')}
      icon={<SerialCalendarIcon variant="filled" size="small" />}
    >
      <Typography>{serialString}</Typography>
    </ReservationDrawerSection>
  )
}
