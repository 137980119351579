import Cancel from './cancel.svg'
import IconContainer, { type IconProps } from '../IconContainer'

const CancelIcon = (props: IconProps) => (
  <IconContainer {...props}>
    <Cancel />
  </IconContainer>
)

export default CancelIcon
