import { useEffect, useMemo } from 'react'
import { RadioGroup, Stack } from '@mui/material'

import { zodResolver } from '@hookform/resolvers/zod'
import { useController, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  useEditBookingLockMutation,
  useUpsertBookingLockMutation,
} from 'src/entities/schedule/queries/schedule'
import {
  type BookingLock,
  type Schedule,
} from 'src/entities/schedule/types/scheduleApi'
import { FakeLabel } from 'src/shared/components/form/common/FakeLabel/FakeLabel'
import FormErrorText from 'src/shared/components/form/common/FormErrorText/FormErrorText'
import { DatePicker } from 'src/shared/components/form/inputs/DatePicker/DatePicker'
import { RadioOption } from 'src/shared/components/form/inputs/RadioChoice'
import { getNowInRestaurantTimezone } from 'src/shared/lib/range/services/date'
import { getEventsOnDay, getShiftsOnDay } from './service/getShiftsOnDay'
import {
  fromBookingLockFormData,
  toBookingLockFormData,
} from './service/mappers'
import { formBookingLockSchema, type FormBookingLock } from './service/schema'
import { ShiftSelectLabel } from '../ClosureForm/ValidityScopeInput/ShiftSelectLabel'
import FormContainer from '../FormContainer'
import { useDefaultMutationHandlers } from '../hooks/useDefaultMutationHandlers'

type BookingLockChannels = FormBookingLock['bookingChannels']

type DefaultValues = NonNullable<
  Parameters<typeof useForm<FormBookingLock>>['0']
>['defaultValues']

interface BookingLockFormProps {
  schedule: Schedule
  lock?: BookingLock
  onNavigateBack: () => void
}

export const BookingLockForm = ({
  onNavigateBack,
  schedule: { shifts, events, shiftExceptions, rooms },
  lock,
}: BookingLockFormProps) => {
  const { t } = useTranslation()

  const { mutateAsync: upsertBookingLock } = useUpsertBookingLockMutation()
  const { mutateAsync: editBookingLock } = useEditBookingLockMutation()

  const handlers = useDefaultMutationHandlers({ onSuccess: onNavigateBack })

  const defaultValues: DefaultValues = lock
    ? toBookingLockFormData(lock)
    : { bookingChannels: 'online', shiftId: '' }

  const {
    getValues,
    setValue,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormBookingLock>({
    resolver: zodResolver(formBookingLockSchema),
    defaultValues,
  })

  const { field: dayField } = useController({ name: 'day', control })
  const { field: shiftIdField } = useController({ name: 'shiftId', control })

  const visibleShiftsAndEvents = useMemo(
    () =>
      dayField.value
        ? [
            ...getShiftsOnDay({ shifts, shiftExceptions }, dayField.value),
            ...getEventsOnDay({ events }, dayField.value),
          ]
        : [],
    [dayField.value, events, shiftExceptions, shifts],
  )

  useEffect(() => {
    if (!dayField.value) return

    if (visibleShiftsAndEvents.some(({ id }) => id === getValues('shiftId')))
      return

    setValue('shiftId', '')
  }, [dayField.value, getValues, setValue, visibleShiftsAndEvents])

  return (
    <FormContainer
      title={
        lock
          ? t('schedule.booking_lock.title_edit', 'Edit Booking Lock')
          : t('schedule.booking_lock.create', 'Create Booking Lock')
      }
      onNavigateBack={onNavigateBack}
      onSave={handleSubmit(data => {
        const dto = fromBookingLockFormData(data)

        return lock
          ? editBookingLock({ oldLock: lock, newLock: dto }, handlers)
          : upsertBookingLock(dto, handlers)
      })}
    >
      <Stack gap={3.5}>
        <DatePicker
          inputRef={dayField.ref}
          minValue={getNowInRestaurantTimezone()}
          value={dayField.value || null}
          setValue={dayField.onChange}
          label={t(
            'schedule.booking_lock.date_picker_title',
            'Lock booking on',
          )}
          placeholder={t('schedule.common.pick_dates', 'Pick dates')}
          error={!!errors.day}
          helperText={
            !!errors.day &&
            t('common.validation.required', 'This field cannot be empty')
          }
        />
        <RadioGroup
          sx={{
            gap: 1.5,
          }}
          defaultValue={defaultValues.bookingChannels}
          {...register('bookingChannels')}
        >
          <FakeLabel sx={{ mb: 0 }}>
            {t('schedule.booking_lock.lock_channels', 'Lock channels')}
          </FakeLabel>
          <RadioOption
            value={'online' satisfies BookingLockChannels}
            label={t(
              'schedule.booking_lock.online_only',
              'Online channels only',
            )}
            {...register('bookingChannels')}
          />
          <RadioOption
            value={'online-offline' satisfies BookingLockChannels}
            label={t(
              'schedule.booking_lock.online_and_offline',
              'Online & offline channels',
            )}
            {...register('bookingChannels')}
          />
        </RadioGroup>
        <RadioGroup
          defaultValue={defaultValues.shiftId}
          sx={{
            gap: 1.5,
          }}
          value={shiftIdField.value}
          onChange={shiftIdField.onChange}
        >
          <FakeLabel sx={{ mb: 0 }} error={!!errors.shiftId}>
            {t(
              'schedule.booking_lock.shift_or_event_to_lock',
              'Shift or event to lock',
            )}
          </FakeLabel>
          <Stack
            gap={1.5}
            sx={{
              display: shifts.length || events.length ? 'flex' : 'none',
            }}
          >
            {visibleShiftsAndEvents.map(s => (
              <RadioOption
                key={s.id}
                value={s.id}
                label={<ShiftSelectLabel shiftLike={s} rooms={rooms} />}
                checked={shiftIdField.value === s.id}
              />
            ))}
          </Stack>
          <FormErrorText
            errorText={
              !!errors.shiftId &&
              t(
                'schedule.common.errors.no_shift_event_selected',
                'Select at least one shift or event',
              )
            }
          />
        </RadioGroup>
      </Stack>
    </FormContainer>
  )
}
