import { Stack, Typography, type StackProps } from '@mui/material'

import { mergeSx } from 'src/app/theme/helpers'

interface SettingTitleProps extends Omit<StackProps, 'children'> {
  title: string
  description?: string
}

export const SETTINGS_TABLE_FIRST_COLUMN_WIDTH = 411

export const SettingTitle = ({
  title,
  description,
  sx,
  ...rest
}: SettingTitleProps) => (
  <Stack
    sx={mergeSx(
      {
        wordBreak: 'break-word',
        maxWidth: SETTINGS_TABLE_FIRST_COLUMN_WIDTH,
        minWidth: 150,
      },
      sx,
    )}
    gap={1}
    {...rest}
  >
    <Typography variant="labelSmall">{title}</Typography>
    {description && (
      <Typography variant="labelTiny" color="grey.500">
        {description}
      </Typography>
    )}
  </Stack>
)
