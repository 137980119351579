import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import {
  convertFromTimezone,
  getNowInRestaurantTimezone,
} from 'src/shared/lib/range/services/date'
import {
  type CustomerInterface,
  type FeedbackStatsInterface,
} from '../../customer/types/customer'

interface RawFeedback {
  id: number
  created_at: string
  message: string
  average_rating: number
  response?: { message: string; created_at: string; public: boolean }
  rating: {
    kitchen: number
    atmosphere: number
    service: number
    performance: number
  }
}

export interface FeedbackInterface {
  id: number
  reservationId: number
  date: Date
  rating: number
  comment: string
  response: string | undefined
  responseDate: Date
  isPublic: boolean
  stats: FeedbackStatsInterface
}

export interface ExtendedFeedbackInterface extends FeedbackInterface {
  reservation: ReservationInterface | undefined
  customer: CustomerInterface | undefined
}

export interface FeedbackApiFilters {
  customerId?: CustomerInterface['id']
}

export const fromApi = (rawFeedback: RawFeedback): FeedbackInterface => ({
  id: rawFeedback.id,
  date: convertFromTimezone(new Date(rawFeedback.created_at)),
  reservationId: rawFeedback.id,
  comment: rawFeedback.message,
  stats: {
    food: rawFeedback.rating.kitchen,
    ambience: rawFeedback.rating.atmosphere,
    service: rawFeedback.rating.service,
    performance: rawFeedback.rating.performance,
    average: rawFeedback.average_rating,
  },
  rating: rawFeedback.average_rating,
  response: rawFeedback.response?.message,
  responseDate: rawFeedback.response?.created_at
    ? convertFromTimezone(new Date(rawFeedback.response.created_at))
    : getNowInRestaurantTimezone(),
  isPublic: !!rawFeedback.response?.public,
})
