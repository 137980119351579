import { useCallback, useMemo, useRef, type FC } from 'react'
import { compose } from 'ramda'
import { Box, Button, Popover, useMediaQuery } from '@mui/material'
import { type PickersDayProps } from '@mui/x-date-pickers'

import dayjs, { type Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'

import { mergeSx } from 'src/app/theme/helpers'
import { useHolidaysQuery } from 'src/entities/holidays/queries/holidays'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { DatePickerCalendar } from 'src/shared/components/form/inputs/DatePicker/components/DatePickerCalendar'
import { DatePickerDay } from 'src/shared/components/form/inputs/DatePicker/components/DatePickerDay'
import StepPicker from 'src/shared/components/form/inputs/StepPicker/StepPicker'
import CalendarIcon from 'src/shared/components/icons/CalendarIcon'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from 'src/shared/components/icons/ChevronIcon'
import { useCurrentDateAtom } from 'src/shared/lib/context/state/atoms/currentDate'
import { useShortDateFormatter } from 'src/shared/lib/range/hooks/useFormattedDate'
import {
  formatShortDate,
  fromTimestamp,
  getNowInRestaurantTimezone,
  getStartOf,
  localToUtc,
  toTimestamp,
  utcToLocal,
} from 'src/shared/lib/range/services/date'
import {
  getGlobalHolidays,
  getHolidayBackground,
  getRoomHolidays,
  type Holiday,
} from '../service/holidays'

export const TopBarDatePickerDay = (({
  day,
  sx,
  globalHolidays,
  roomHolidays,
  ...rest
}: PickersDayProps<Dayjs> & {
  globalHolidays: Holiday[]
  roomHolidays: Holiday[]
}) => (
  <DatePickerDay
    day={day}
    sx={mergeSx(
      sx,
      getHolidayBackground({
        day: day.toDate(),
        globalHolidays,
        roomHolidays,
      }),
    )}
    {...rest}
  />
)) as FC<PickersDayProps<Dayjs>>

const maxDate = 8640000000000000
const minDate = -maxDate

export const TopBarDatePicker = () => {
  const { t } = useTranslation()

  const { data: holidays } = useHolidaysQuery()

  const [date, setDate] = useCurrentDateAtom()

  const ref = useRef<HTMLButtonElement>(null)

  const isChevronVisible = useMediaQuery('(min-width: 410px)')
  const hasEnoughSpaceForLongDate = useMediaQuery('(min-width: 450px)')

  const globalHolidays = useMemo(() => getGlobalHolidays(holidays), [holidays])
  const roomHolidays = useMemo(() => getRoomHolidays(holidays), [holidays])

  const pickerDate = useMemo(() => dayjs(date), [date])

  const formatDateWithWeekDay = useShortDateFormatter()

  const pickerDateChangeHandler = useCallback(
    (d: Dayjs | null) => {
      if (!d) return
      setDate(d.toDate())
    },
    [setDate],
  )

  const timestamp = useMemo(() => toTimestamp(localToUtc(date)), [date])
  const onStepChange = useMemo(
    () => compose(setDate, utcToLocal, fromTimestamp),
    [setDate],
  )

  const { handleClose, handleOpen, open } = useDialog()

  const createCalendarButtonProps = useCallback(
    (displayedValue: number) => {
      const displayedDate = utcToLocal(fromTimestamp(displayedValue))
      const formatter = hasEnoughSpaceForLongDate
        ? formatDateWithWeekDay
        : formatShortDate

      return {
        buttonProps: {
          ref,
          onClick: handleOpen,
          children: formatter(displayedDate),
          sx: {
            flex: 1,
            justifyContent: 'space-between',
            maxWidth: 200,
            textAlign: 'left',
            fontWeight: 'normal',
            ...getHolidayBackground({
              day: getStartOf('day')(displayedDate),
              globalHolidays,
              roomHolidays,
              overrideHover: true,
            }),
          },
          endIcon: <CalendarIcon sx={{ display: ['none', 'block'] }} />,
        },
      }
    },
    [
      handleOpen,
      hasEnoughSpaceForLongDate,
      formatDateWithWeekDay,
      globalHolidays,
      roomHolidays,
    ],
  )

  return (
    <>
      <StepPicker
        value={timestamp}
        minValue={minDate}
        maxValue={maxDate}
        onChange={onStepChange}
        prevIcon={isChevronVisible ? <ChevronLeftIcon /> : null}
        nextIcon={isChevronVisible ? <ChevronRightIcon /> : null}
        rendererFactory={createCalendarButtonProps}
        step={24 * 60 * 60 * 1000}
      />
      <Popover
        open={open}
        anchorEl={ref.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DatePickerCalendar
          displayWeekNumber
          value={pickerDate}
          onChange={pickerDateChangeHandler}
          slotProps={{
            day: {
              globalHolidays,
              roomHolidays,
              onClick: handleClose,
            },
          }}
          slots={{
            day: TopBarDatePickerDay,
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={() => {
              setDate(getNowInRestaurantTimezone())
              handleClose()
            }}
          >
            {t('angular.today')}
          </Button>
          <Button onClick={handleClose}>{t('angular.close')}</Button>
        </Box>
      </Popover>
    </>
  )
}
