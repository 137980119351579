import { andThen, compose, composeWith } from 'ramda'

import {
  convertKeysToSnakeCase,
  type ApiClient,
} from 'src/shared/lib/api/services/api'
import { type RestaurantInterface } from '../../restaurant/types/restaurant'
import { toApi } from '../../setting/types/settingsApi'
import {
  configSchema,
  type ApiConfig,
  type ApiSettings,
} from '../types/configApi'

export const getConfig =
  (httpClient: ApiClient) => (restaurantHash?: RestaurantInterface['hash']) =>
    Promise.resolve(
      composeWith(andThen)([
        (data: ApiConfig) => configSchema.parse(data),
        httpClient,
      ])({
        url: 'getConfig',
        ...(restaurantHash && {
          json: {
            restaurant_hash: restaurantHash,
          },
        }),
      }),
    )

export const updateConfig = (httpClient: ApiClient) => (config: ApiSettings) =>
  httpClient({
    url: 'addConfig',
    json: compose(convertKeysToSnakeCase, toApi)(config),
  })
