import { type ComponentProps, type RefObject } from 'react'

import type Konva from 'konva'
import { Transformer } from 'react-konva'

import { isTooLarge, isTooSmall } from 'src/entities/table/services/tableGrid'
import {
  FloorPlanElementShape,
  type FloorPlanElementInterface,
} from 'src/widgets/FloorPlan/types/floorPlanElement'

const TransformCorners = {
  [FloorPlanElementShape.Rectangular]: ['middle-left', 'middle-right'],
  [FloorPlanElementShape.Square]: [
    'top-left',
    'top-right',
    'bottom-left',
    'bottom-right',
  ],
  [FloorPlanElementShape.Elliptic]: [
    'top-left',
    'top-right',
    'bottom-left',
    'bottom-right',
  ],
  [FloorPlanElementShape.DeprecatedRectangular]: undefined,
} satisfies Record<FloorPlanElementShape, string[] | undefined>

interface TableTransformerProps
  extends Pick<ComponentProps<typeof Transformer>, 'ref'> {
  element: FloorPlanElementInterface
}

const TableTransformer = ({
  element,
  ref: transformerRef,
}: TableTransformerProps) => (
  <Transformer
    ref={transformerRef}
    enabledAnchors={TransformCorners[element.shape]}
    ignoreStroke
    boundBoxFunc={(oldBox, newBox) => {
      const { current: transformer } =
        transformerRef as RefObject<Konva.Transformer | null>
      const zoomLevel = transformer?.getStage()?.scaleX() ?? 1

      const newBoxSize = {
        width: newBox.width * (1 / zoomLevel),
        height: newBox.height * (1 / zoomLevel),
      }

      if (isTooSmall(element.shape)(newBoxSize)) return oldBox
      if (isTooLarge(element.shape)(newBoxSize)) return oldBox

      return newBox
    }}
  />
)

export default TableTransformer
