import { useCallback, useEffect, useMemo, useState } from 'react'
import { groupBy, head, mapObjIndexed, prop } from 'ramda'
import { Button, ButtonGroup, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { useChainInfoQuery } from 'src/entities/info/queries/info'
import { type RestaurantInfo } from 'src/entities/info/types/info'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { useRestaurantsRooms } from 'src/entities/room/queries/room'
import { type RoomInterface } from 'src/entities/room/types/room'
import IconButton from 'src/shared/components/buttons/IconButton'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { SimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/SimpleDialog'
import { useSimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/useSimpleDialog'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from 'src/shared/components/icons/ChevronIcon'
import { cycleArray } from 'src/shared/lib/common/services/array/array'
import { combine } from 'src/shared/lib/common/services/functional/functional'
import { useRestaurantHash } from 'src/shared/lib/context/global/useRestaurantHash'
import { useClearSelectedReservation } from 'src/shared/lib/context/state/atoms/selectedReservation'
import { type DrawerColor } from 'src/widgets/SidebarDrawer/drawerColor'
import { SidebarDrawer } from 'src/widgets/SidebarDrawer/SidebarDrawer'
import SidebarHeader from 'src/widgets/SidebarDrawer/SidebarHeader'
import { MoveRoomDialog } from './components/MoveRoomDialog'
import { RoomRestaurantSelector } from './components/RoomRestaurantSelector'
import { RoomSelectionSection } from './components/RoomSelectionSection'

interface RoomSelectionProps {
  selectedReservation: ReservationInterface
  isReservationModified: boolean
  open: boolean
  onOpen: () => void
  onClose: () => void
  setReservationRoom: (room: RoomInterface) => void
  drawerColor: DrawerColor
}

export const RoomSelection = ({
  selectedReservation,
  isReservationModified,
  open,
  onOpen,
  onClose,
  setReservationRoom,
  drawerColor,
}: RoomSelectionProps) => {
  const { t } = useTranslation()

  const currentRestaurantHash = useRestaurantHash()

  const allRooms = useRestaurantsRooms()

  const { simpleDialogProps, showSimpleDialog } = useSimpleDialog()
  const clearSelectedReservation = useClearSelectedReservation()

  const [requestedRoom, setRequestedRoom] = useState<RoomInterface>()
  const [selectedRestaurantHash, setSelectedRestaurantHash] = useState(
    currentRestaurantHash,
  )

  useEffect(() => {
    if (!open) {
      setSelectedRestaurantHash(currentRestaurantHash)
    }
  }, [currentRestaurantHash, open])

  const moveDialogProps = useDialog()

  const isNewReservation = !selectedReservation.id

  const chainInfo = useChainInfoQuery()
  const infoByHash = useMemo(() => {
    const infosByHashes = groupBy<RestaurantInfo>(prop('hash'))(chainInfo)

    return mapObjIndexed(head<RestaurantInfo>)(
      infosByHashes as Record<string, RestaurantInfo[]>,
    )
  }, [chainInfo])

  const assignableRooms = useMemo(
    () =>
      allRooms.filter(({ id, restaurantId }) => {
        const featuresOfRestaurant = infoByHash[restaurantId]?.feature

        const areRoomsEnabled = !!featuresOfRestaurant?.roomToGuest

        return id === 0 || areRoomsEnabled
      }),
    [allRooms, infoByHash],
  )

  const assignableRoomsByRestaurants = useMemo(
    () => groupBy(prop('restaurantId'), assignableRooms),
    [assignableRooms],
  )

  const saveAndClose = useMemo(
    () => combine([onClose, setReservationRoom]),
    [onClose, setReservationRoom],
  )

  const handleRoomSelection = useCallback(
    (room: RoomInterface) => {
      if (room.restaurantId === currentRestaurantHash) {
        return saveAndClose(room)
      }

      if (selectedReservation.locked) {
        return showSimpleDialog({
          title: t(
            'reservation_edit.move_to_another_restaurant.errors.locked.title',
            { defaultValue: 'Reservation locked' },
          ),
          message: t('angular.message_reservation_locked'),
        })
      }

      if (isReservationModified) {
        return showSimpleDialog({
          title: t(
            'reservation_edit.move_to_another_restaurant.errors.save_first.title',
            { defaultValue: 'Please save reservation first' },
          ),
          message: t('angular.not_possible_into_other_restaurant'),
        })
      }

      setRequestedRoom(room)
      return moveDialogProps.handleOpen()
    },
    [
      isReservationModified,
      currentRestaurantHash,
      moveDialogProps,
      selectedReservation.locked,
      saveAndClose,
      showSimpleDialog,
      t,
    ],
  )

  const moveRoomBy = useMemo(
    () =>
      cycleArray(
        assignableRoomsByRestaurants[currentRestaurantHash]!,
        prop('id'),
        setReservationRoom,
      )(selectedReservation.room),
    [
      assignableRoomsByRestaurants,
      currentRestaurantHash,
      selectedReservation.room,
      setReservationRoom,
    ],
  )

  const isPickerShown =
    assignableRoomsByRestaurants[currentRestaurantHash]!.length > 1 ||
    (selectedReservation.id && assignableRooms.length > 1)

  if (!isPickerShown) return null

  return (
    <>
      <ButtonGroup
        {...createTestIdProps('room-selection')}
        variant="outlined"
        sx={{
          width: 1,
          backgroundColor: 'white',
        }}
      >
        <IconButton onClick={() => moveRoomBy(-1)}>
          <ChevronLeftIcon />
        </IconButton>
        <Button sx={{ flex: 1 }} onClick={onOpen}>
          <Typography
            component="span"
            sx={{
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              lineHeight: '1',
              wordBreak: 'break-word',
            }}
          >
            {allRooms.find(({ id }) => id === selectedReservation.room)?.name ??
              t('angular.no_room')}
          </Typography>
        </Button>
        <IconButton onClick={() => moveRoomBy(1)}>
          <ChevronRightIcon />
        </IconButton>
      </ButtonGroup>
      <SidebarDrawer
        {...createTestIdProps('room-selection-drawer')}
        open={open}
        onClose={onClose}
        level={1}
        color={drawerColor}
        sx={{
          overflow: 'hidden',
        }}
      >
        <SidebarHeader onClose={onClose} color={drawerColor}>
          <Typography variant="labelBig">
            {t('angular.room_selection')}
          </Typography>
        </SidebarHeader>
        <Stack sx={{ p: 1.25, overflow: 'auto' }} gap={2}>
          {!isNewReservation && (
            <RoomRestaurantSelector
              selectedRestaurantHash={selectedRestaurantHash}
              setSelectedRestaurantHash={setSelectedRestaurantHash}
            />
          )}
          <RoomSelectionSection
            selectedRoomId={
              currentRestaurantHash === selectedRestaurantHash
                ? selectedReservation.room
                : undefined
            }
            rooms={assignableRoomsByRestaurants[selectedRestaurantHash] ?? []}
            onRoomSelect={handleRoomSelection}
          />
        </Stack>
      </SidebarDrawer>
      <SimpleDialog variant="dismissible" {...simpleDialogProps} />
      {requestedRoom && (
        <MoveRoomDialog
          dialogProps={moveDialogProps}
          selectedReservation={selectedReservation}
          requestedRoom={requestedRoom}
          onSuccess={clearSelectedReservation}
        />
      )}
    </>
  )
}
