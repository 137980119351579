import { TextField, type TextFieldProps } from '@mui/material'

export const TextArea = ({ slotProps, ...props }: TextFieldProps) => (
  <TextField
    variant="outlined"
    fullWidth
    multiline
    slotProps={{
      ...slotProps,
      inputLabel: { ...slotProps?.inputLabel, shrink: true },
    }}
    {...props}
  />
)
