import { isNil } from 'ramda'
import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

const ResultsCount = ({ count }: { count?: number }) => {
  const { t } = useTranslation('', { keyPrefix: 'search' })

  if (isNil(count)) return null

  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <Typography variant="body3">
        {t('results_count', {
          defaultValue: 'Results found:',
          tDescription: 'Search results count label',
        })}
      </Typography>
      <Typography variant="labelSmall">{count}</Typography>
    </Stack>
  )
}

export default ResultsCount
