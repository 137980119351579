import React from 'react'
import { Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import {
  useCustomers,
  useCustomersSearchQuery,
} from 'src/entities/customer/queries/customer'
import { type PhoneInterface } from 'src/entities/customer/types/customer'
import { formatGuestName } from 'src/entities/reservation/services/reservation'
import { getFlatPaginatedItems } from 'src/shared/lib/api/services/api'

const Customer = ({
  phoneNumber,
}: {
  phoneNumber: PhoneInterface['phone']
}) => {
  const { t } = useTranslation('', { keyPrefix: 'inbox' })

  const query = useCustomersSearchQuery(phoneNumber)

  const customerIds = React.useMemo(
    () => getFlatPaginatedItems(query.data.pages),
    [query.data],
  )
  const numberOfCustomers = customerIds?.length

  const customerId = customerIds[0]

  const customers = useCustomers([customerId].filter(Boolean) as number[])

  const customer = customers[0]

  const getCustomerName = () => {
    if (numberOfCustomers > 1) return t('multipleCustomers')
    if (customer) return formatGuestName(customer)
    return t('newCustomer')
  }

  return <Typography variant="body2">{getCustomerName()}</Typography>
}

export default Customer
