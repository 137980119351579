import { createFileRoute } from '@tanstack/react-router'

import { SettingsScreensEnum } from 'src/entities/setting/types/settings'
import { SettingsHeader } from 'src/pages/Settings/layout/SettingsHeader'
import TeamMembers from 'src/pages/Settings/TeamMembers'
import { ConfigScroll } from '../../../../pages/Settings/layout/ConfigScroll'

export const Route = createFileRoute(
  '/restaurants/$restaurantId/config/employees',
)({
  component: RouteComponent,
})

function RouteComponent() {
  const { restaurantId } = Route.useParams()

  return (
    <>
      <SettingsHeader
        restaurantId={restaurantId}
        screen={SettingsScreensEnum.TeamMembers}
      />
      <ConfigScroll>
        <TeamMembers />
      </ConfigScroll>
    </>
  )
}
