import { useCallback, useState } from 'react'

import { type CustomerInterface } from 'src/entities/customer/types/customer'
import { useNewReservation } from 'src/entities/reservation/hooks/useNewReservation'
import { type OnCreateNewCustomerOptions } from 'src/pages/CustomerChoice/CustomerChoice'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { combine } from 'src/shared/lib/common/services/functional/functional'
import { type SelectedReservation } from 'src/shared/lib/context/state/atoms/baseSelectedReservation'
import { useSetSelectedReservationGuest } from 'src/shared/lib/context/state/atoms/selectedReservation'
import Guest from './Guest'
import { CustomerEdit } from '../../CustomerEdit'
import { CustomerPreviewModal } from '../../CustomerPreview/PreviewModal'

const GuestView = ({
  selectedReservation,
}: {
  selectedReservation: SelectedReservation
}) => {
  const onCustomerSelect = useSetSelectedReservationGuest()
  const onCreateReservation = useNewReservation()

  const editDialog = useDialog()
  const previewDialog = useDialog()

  const [searchPhrase, setSearchPhrase] = useState('')
  const [customerFromGuest, setCustomerFromGuest] =
    useState<CustomerInterface>()

  const [editCustomerId, setEditCustomerId] =
    useState<CustomerInterface['id']>()

  const { handleClose: closeEditDialog, handleOpen: openEditDialog } =
    editDialog

  const onCreateNewCustomer = useCallback(
    ({ initCustomer, initPhrase }: OnCreateNewCustomerOptions) => {
      if (initPhrase) setSearchPhrase(initPhrase)
      if (initCustomer) setCustomerFromGuest(initCustomer)
      openEditDialog()
    },
    [openEditDialog],
  )

  const onEditClose = useCallback(() => {
    closeEditDialog()
    setSearchPhrase('')
    setCustomerFromGuest(undefined)
  }, [closeEditDialog])

  if (!selectedReservation) return null

  const {
    guest: { customerId },
  } = selectedReservation

  return (
    <>
      <Guest
        reservation={selectedReservation}
        onCustomerChange={onCustomerSelect}
        onPreviewOpen={previewDialog.handleOpen}
        onCreateNewCustomer={onCreateNewCustomer}
        onEditCustomer={c => {
          setEditCustomerId(c.id)
          editDialog.handleOpen()
        }}
      />
      <CustomerEdit
        customerId={editCustomerId ?? customerId ?? undefined}
        onCustomerSaved={combine([
          onCustomerSelect,
          () => setEditCustomerId(undefined),
          onEditClose,
        ])}
        hasBeenOpened={editDialog.hasBeenOpened}
        isOpen={editDialog.open}
        onClose={combine([() => setEditCustomerId(undefined), onEditClose])}
        initPhrase={searchPhrase}
        initCustomer={customerFromGuest}
      />
      {customerId && (
        <CustomerPreviewModal
          onCustomerEdit={combine([
            editDialog.handleOpen,
            previewDialog.handleClose,
          ])}
          onAddReservation={c => onCreateReservation({ customer: c })}
          onClose={previewDialog.handleClose}
          customerId={customerId}
          isOpen={previewDialog.open}
          hasBeenOpened={previewDialog.hasBeenOpened}
        />
      )}
    </>
  )
}

export { GuestView }
