import { useMemo } from 'react'
import {
  Rating,
  Stack,
  TableCell,
  Typography,
  type TableCellProps,
} from '@mui/material'

import { createColumnHelper, type CellContext } from '@tanstack/react-table'
import { useTranslation, withTranslation } from 'react-i18next'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { type ExtendedFeedbackInterface } from 'src/entities/feedback/types/feedbackApi'
import { formatName } from 'src/entities/reservation/services/reservation'
import Persons from 'src/pages/Search/Reservation/ReservationsTable/Cells/Persons'
import IconButton from 'src/shared/components/buttons/IconButton'
import SortHeader from 'src/shared/components/common/Table/SortHeader'
import AddReplyIcon from 'src/shared/components/icons/AddReplyIcon'
import ReviewAnsweredIcon from 'src/shared/components/icons/ReviewAnsweredIcon'
import ReviewCommentIcon from 'src/shared/components/icons/ReviewCommentIcon'
import { formatShortDatetime } from 'src/shared/lib/range/services/date'

export enum FeedbackTableColumnsIds {
  Date = 'date',
  Pax = 'pax',
  Name = 'name',
  ReservationDate = 'reservation_date',
  Service = 'service',
  Food = 'food',
  Ambience = 'ambience',
  Performance = 'performance',
  Average = 'average',
  Reply = 'comment_reply',
  Action = 'action',
}

const reservationColumnHelper = createColumnHelper<ExtendedFeedbackInterface>()

const ReservationSortHeader = withTranslation('', {
  keyPrefix: 'feedbacks.table.headers',
})(SortHeader<ExtendedFeedbackInterface>)

const Cell = <T, U>({
  sx,
  cellContext,
  ...props
}: Partial<TableCellProps> & { cellContext: CellContext<T, U> }) => (
  <TableCell
    sx={{
      verticalAlign: 'middle',
      ...sx,
    }}
    {...createTestIdProps(`cell-${cellContext.column.id}`)}
    {...props}
  />
)

const numberFormatter = Intl.NumberFormat('en', {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
})

export const useFeedbackTableColumns = (
  onFeedbackClick: (reservation: ExtendedFeedbackInterface) => void,
) => {
  const { t } = useTranslation()

  return useMemo(
    () => [
      reservationColumnHelper.accessor('date', {
        id: FeedbackTableColumnsIds.Date,
        header: props => <ReservationSortHeader {...props} />,
        sortingFn: 'datetime',
        enableSorting: true,
        cell: cellContext => (
          <Cell
            sx={{
              minWidth: 150,
              width: 150,
              typography: 'labelSmall',
            }}
            cellContext={cellContext}
          >
            {formatShortDatetime(cellContext.getValue(), {
              showWeekday: false,
            })}
          </Cell>
        ),
      }),
      reservationColumnHelper.accessor('reservation.seatCount', {
        id: FeedbackTableColumnsIds.Pax,
        header: t('angular.pax'),
        cell: cellContext => (
          <Cell
            sx={{
              minWidth: 52,
              width: 52,
            }}
            cellContext={cellContext}
          >
            <Persons persons={cellContext.getValue()} />
          </Cell>
        ),
      }),
      reservationColumnHelper.accessor('reservation', {
        id: FeedbackTableColumnsIds.Name,
        header: t('feedbacks.table.headers.guest_name', 'Name'),
        cell: cellContext => {
          const { customer, reservation } = cellContext.row.original
          const guestOrCustomer = customer ?? reservation?.guest

          return (
            <Cell
              sx={{
                minWidth: 128,
              }}
              cellContext={cellContext}
            >
              {formatName(guestOrCustomer)}
            </Cell>
          )
        },
      }),
      reservationColumnHelper.accessor('reservation.dateRange', {
        id: FeedbackTableColumnsIds.ReservationDate,
        header: props => <ReservationSortHeader {...props} />,
        sortingFn: 'datetime',
        enableSorting: true,
        cell: cellContext => (
          <Cell
            sx={{
              minWidth: 170,
              width: 170,
            }}
            cellContext={cellContext}
          >
            {formatShortDatetime(cellContext.getValue()[0], {
              showWeekday: false,
            })}
          </Cell>
        ),
      }),
      reservationColumnHelper.accessor('stats.average', {
        id: FeedbackTableColumnsIds.Average,
        header: props => <ReservationSortHeader {...props} />,
        sortingFn: 'alphanumeric',
        enableSorting: true,
        cell: cellContext => (
          <Cell
            sx={{
              minWidth: 115,
              width: 115,
              typography: 'labelSmall',
            }}
            cellContext={cellContext}
          >
            <Stack direction="row" gap={1} alignItems="center" lineHeight={1}>
              <Typography>
                {numberFormatter.format(cellContext.getValue())}
              </Typography>
              <Rating
                value={cellContext.getValue()}
                readOnly
                precision={0.5}
                size="small"
              />
            </Stack>
          </Cell>
        ),
      }),
      reservationColumnHelper.accessor('stats.service', {
        id: FeedbackTableColumnsIds.Service,
        header: props => <ReservationSortHeader {...props} />,
        sortingFn: 'alphanumeric',
        enableSorting: true,
        cell: cellContext => (
          <Cell
            sx={{
              minWidth: 96,
              width: 96,
            }}
            cellContext={cellContext}
          >
            {numberFormatter.format(cellContext.getValue())}
          </Cell>
        ),
      }),
      reservationColumnHelper.accessor('stats.food', {
        id: FeedbackTableColumnsIds.Food,
        header: props => <ReservationSortHeader {...props} />,
        sortingFn: 'alphanumeric',
        enableSorting: true,
        cell: cellContext => (
          <Cell
            sx={{
              minWidth: 96,
              width: 96,
            }}
            cellContext={cellContext}
          >
            {numberFormatter.format(cellContext.getValue())}
          </Cell>
        ),
      }),
      reservationColumnHelper.accessor('stats.ambience', {
        id: FeedbackTableColumnsIds.Ambience,
        header: props => <ReservationSortHeader {...props} />,
        sortingFn: 'alphanumeric',
        enableSorting: true,
        cell: cellContext => (
          <Cell
            sx={{
              minWidth: 96,
              width: 96,
            }}
            cellContext={cellContext}
          >
            {numberFormatter.format(cellContext.getValue())}
          </Cell>
        ),
      }),
      reservationColumnHelper.accessor('stats.performance', {
        id: FeedbackTableColumnsIds.Performance,
        header: props => <ReservationSortHeader {...props} />,
        sortingFn: 'alphanumeric',
        enableSorting: true,
        cell: cellContext => (
          <Cell
            sx={{
              minWidth: 96,
              width: 96,
            }}
            cellContext={cellContext}
          >
            {numberFormatter.format(cellContext.getValue())}
          </Cell>
        ),
      }),
      reservationColumnHelper.display({
        id: FeedbackTableColumnsIds.Reply,
        header: t('feedbacks.table.headers.comment_reply', 'Comment/Reply'),
        cell: cellContext => (
          <Cell
            sx={{
              minWidth: 110,
              width: 110,
            }}
            cellContext={cellContext}
          >
            <Stack direction="row" gap={0.5}>
              <ReviewCommentIcon
                sx={{
                  visibility: cellContext.row.original.comment
                    ? 'visible'
                    : 'hidden',
                }}
              />
              <ReviewAnsweredIcon
                sx={{
                  visibility: cellContext.row.original.response
                    ? 'visible'
                    : 'hidden',
                }}
              />
            </Stack>
          </Cell>
        ),
      }),
      reservationColumnHelper.display({
        id: FeedbackTableColumnsIds.Action,
        cell: cellContext => {
          const hasResponse = !!cellContext.row.original.response

          return (
            <Cell
              cellContext={cellContext}
              sx={{
                minWidth: 58,
                width: 58,
                position: 'sticky',
                right: 0,
                background: 'white',
              }}
            >
              <IconButton
                variant={hasResponse ? 'outlined' : 'contained'}
                onClick={() => onFeedbackClick(cellContext.row.original)}
              >
                {hasResponse ? <ReviewAnsweredIcon /> : <AddReplyIcon />}
              </IconButton>
            </Cell>
          )
        },
      }),
    ],
    [onFeedbackClick, t],
  )
}
