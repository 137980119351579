import type React from 'react'
import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  type CheckboxProps as MuiCheckboxProps,
} from '@mui/material'

import { mergeSx } from 'src/app/theme/helpers'
import { useDefaultValue } from '../../hooks/useDefaultValue'

interface CheckboxProps extends MuiCheckboxProps {
  label: React.ReactNode
  labelProps?: Partial<React.ComponentProps<typeof FormControlLabel>>
}

const Checkbox = ({
  label,
  labelProps: { sx: labelSx, ...labelProps } = {},
  checked,
  ...props
}: CheckboxProps) => {
  const defaultValue = useDefaultValue(props)

  return (
    <FormControlLabel
      disabled={props.disabled}
      size={props.size ?? 'medium'}
      control={
        <MuiCheckbox
          checked={checked}
          defaultChecked={
            typeof checked === undefined ? defaultValue : undefined
          }
          {...props}
        />
      }
      label={label}
      sx={mergeSx({ maxWidth: 'max-content' }, labelSx)}
      {...labelProps}
    />
  )
}

export default Checkbox
