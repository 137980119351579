import { type JSX, type Ref } from 'react'
import { Box, type SxProps, type Theme } from '@mui/material'

import { type Virtualizer } from '@tanstack/react-virtual'

import { mergeSx } from 'src/app/theme/helpers'

interface VirtualListProps<T extends Element> {
  render: (idx: number) => JSX.Element
  virtualizer: Virtualizer<HTMLDivElement, T>
  sx?: SxProps<Theme>
  ref?: Ref<HTMLDivElement>
}

const VirtualList = <T extends Element>({
  render,
  virtualizer,
  sx,
  ref,
}: VirtualListProps<T>) => (
  <Box
    ref={ref}
    sx={mergeSx(
      {
        flex: 1,
        overflow: 'auto',
      },
      sx,
    )}
  >
    <Box
      sx={{
        height: `${virtualizer.getTotalSize()}px`,
        width: '100%',
        position: 'relative',
      }}
    >
      {virtualizer.getVirtualItems().map(virtualItem => (
        <Box
          key={virtualItem.key}
          data-index={virtualItem.index}
          ref={virtualizer.measureElement}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            transform: `translateY(${virtualItem.start}px)`,
          }}
        >
          {render(virtualItem.index)}
        </Box>
      ))}
    </Box>
  </Box>
)

export default VirtualList
