import { useTranslation } from 'react-i18next'

const releaseIsProd = import.meta.env.MODE === 'production'

const Meta = () => {
  const { t } = useTranslation()

  const documentTitle = t('common.meta.title')

  return (
    <>
      <title>{documentTitle}</title>
      <meta name="application-name" content={documentTitle} />
      <meta name="apple-mobile-web-app-title" content={documentTitle} />
      {releaseIsProd && (
        <script
          id="usercentrics-cmp"
          src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
          data-settings-id="IQ4Hyc_LCgWpZ7"
          async
        />
      )}
    </>
  )
}

export default Meta
