import { Divider, Stack } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { type RoomStayInterface } from 'src/entities/hotel-stay/services/hotelRoomStay'
import RoomStayItem from './RoomStayItem'

interface RoomStayListProps {
  roomStays: RoomStayInterface[]
  onRoomStayClick: (roomStay: RoomStayInterface) => void
}

const RoomStaysList = ({ roomStays, onRoomStayClick }: RoomStayListProps) => (
  <Stack
    {...createTestIdProps('room-stay-list')}
    direction="column"
    spacing={1}
    divider={<Divider orientation="horizontal" variant="middle" flexItem />}
  >
    {roomStays.map((roomStay, index) => (
      <RoomStayItem
        onClick={() => onRoomStayClick(roomStay)}
        key={`${roomStay.room.name}_${index}`}
        roomStay={roomStay}
      />
    ))}
  </Stack>
)

export default RoomStaysList
