import { ButtonGroup, type ButtonGroupProps } from '@mui/material'

import { useTranslation } from 'react-i18next'

import ChevronButton from 'src/shared/components/buttons/ChevronButton'
import { LoadingButton } from 'src/shared/components/buttons/LoadingButton/LoadingButton'
import { minWidth } from './ActionsMenu'
import { translateAction, type ReservationActions } from '../reservationActions'

interface ActionButtonGroupProps extends ButtonGroupProps {
  actionHandler: () => Promise<void>
  disabled: boolean
  action: ReservationActions
  onChoiceOpen: () => void
  choiceOpen: boolean
}

const ActionButtonGroup = ({
  actionHandler,
  action,
  choiceOpen,
  onChoiceOpen,
  disabled,
  ...props
}: ActionButtonGroupProps) => {
  const { t } = useTranslation()

  return (
    <ButtonGroup
      color="primary"
      variant="contained"
      size="small"
      sx={{ ml: 'auto', minWidth }}
      {...props}
    >
      <LoadingButton
        color="primary"
        variant="contained"
        onClick={actionHandler}
        disabled={disabled}
        sx={{
          width: 1,
          justifyContent: 'flex-start',
        }}
      >
        {translateAction(t)(action)}
      </LoadingButton>
      <ChevronButton
        onClick={onChoiceOpen}
        variant="contained"
        color="primary"
        orientation={choiceOpen ? 'up' : 'down'}
      />
    </ButtonGroup>
  )
}

export { ActionButtonGroup }
