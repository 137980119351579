import { Stack } from '@mui/material'

import { useReservationCustomerQuery } from 'src/entities/customer/queries/customer'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { isBirthday } from 'src/shared/lib/range/services/date'
import Indicators from 'src/widgets/Customer/Indicators'

const IndicatorsLoader = ({
  reservation,
  reservationDate,
}: {
  reservation: ReservationInterface
  reservationDate: Date
}) => {
  const { data: customer } = useReservationCustomerQuery(reservation)

  return (
    <Stack
      direction="row"
      spacing={0.5}
      sx={{
        typography: 'body3',
        justifyContent: 'center',
      }}
    >
      <Indicators
        birthday={
          !!customer.birthdate &&
          isBirthday(customer.birthdate, reservationDate)
        }
        vip={customer.vip}
        ban={customer.ban}
        preferences={customer.preferences}
        comment={!!customer.comment}
        external={customer.external}
      />
    </Stack>
  )
}

export default IndicatorsLoader
