import { useMemo, type Dispatch, type SetStateAction } from 'react'
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import { create } from 'mutative'
import { useTranslation } from 'react-i18next'

import {
  PERMISSIONS,
  ROLES,
  type Permission,
  type RoleDefinition,
  type TeamMemberRole,
} from 'src/entities/team-member/types/teamMember'
import CheckmarkIcon from 'src/shared/components/icons/CheckmarkIcon'
import { withTargetChecked } from 'src/shared/lib/common/services/helpers/helpers'
import {
  SETTINGS_TABLE_FIRST_COLUMN_WIDTH,
  SettingTitle,
} from '../components/SettingTitle'

const ADMIN_ONLY_PERMISSIONS: Permission[] = [
  'permissions_tab',
  'manage_integrations',
]

const MANAGER_ONLY_PERMISSIONS: Permission[] = ['manage_team_members']

interface TableCheckboxProps {
  permission: Permission
  role: TeamMemberRole
  rolePermissionsMap: Map<TeamMemberRole, Set<Permission>>
  onToggle: (isEnabled: boolean) => void
}

const TableCheckbox = ({
  permission,
  role,
  rolePermissionsMap,
  onToggle,
}: TableCheckboxProps) => {
  if (role === 'admin') return <CheckmarkIcon />

  return (
    <Checkbox
      checked={!!rolePermissionsMap.get(role)?.has(permission)}
      onChange={withTargetChecked(onToggle)}
    />
  )
}

interface PermissionsTableProps {
  roleDefinitions: RoleDefinition[]
  setRoleDefinitions: Dispatch<SetStateAction<RoleDefinition[]>>
}

export const PermissionsTable = ({
  roleDefinitions,
  setRoleDefinitions,
}: PermissionsTableProps) => {
  const { t } = useTranslation()

  const rolePermissionMap = useMemo(
    () =>
      new Map(roleDefinitions.map(rd => [rd.role, new Set(rd.permissions)])),
    [roleDefinitions],
  )

  return (
    <TableContainer>
      <Table stickyHeader sx={{ td: { verticalAlign: 'middle' } }}>
        <TableHead>
          <TableRow>
            <TableCell width={SETTINGS_TABLE_FIRST_COLUMN_WIDTH}>
              {t('settings.permissions.headers.permission', 'Permission')}
            </TableCell>
            {ROLES.map((role, i) => (
              <TableCell
                key={role}
                sx={i < ROLES.length - 1 ? { width: 188 } : undefined}
              >
                {t(`settings.permissions.headers.${role}`, role)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {PERMISSIONS.map(permission => (
            <TableRow key={permission}>
              <TableCell>
                <SettingTitle
                  title={t(
                    `settings.permissions.permission.${permission}.title`,
                    `${permission} title`,
                  )}
                  description={t(
                    `settings.permissions.permission.${permission}.description`,
                    `${permission} description`,
                  )}
                />
              </TableCell>
              {ROLES.map(role => {
                if (
                  role === 'team_member' &&
                  [
                    ...ADMIN_ONLY_PERMISSIONS,
                    ...MANAGER_ONLY_PERMISSIONS,
                  ].includes(permission)
                ) {
                  return <TableCell key={role} />
                }

                if (
                  role === 'manager' &&
                  ADMIN_ONLY_PERMISSIONS.includes(permission)
                ) {
                  return <TableCell key={role} />
                }

                return (
                  <TableCell key={role}>
                    <TableCheckbox
                      role={role}
                      permission={permission}
                      rolePermissionsMap={rolePermissionMap}
                      onToggle={() =>
                        setRoleDefinitions(
                          create(dr => {
                            const thisRole = dr.find(rd => rd.role === role)
                            if (!thisRole) return

                            if (thisRole.permissions.includes(permission)) {
                              thisRole.permissions =
                                thisRole.permissions.filter(
                                  p => p !== permission,
                                )
                            } else thisRole.permissions.push(permission)
                          }),
                        )
                      }
                    />
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
