import { Stack, Typography } from '@mui/material'

import { formatGuestName } from 'src/entities/reservation/services/reservation'
import { type GuestInterface } from 'src/entities/reservation/types/reservation'
import { formatPhoneNumber } from 'src/shared/lib/string/services/phoneNumber'

interface GuestDataProps {
  guest: GuestInterface
}

const GuestName = ({ guest }: GuestDataProps) => (
  <Stack direction="column" alignItems="start">
    <Typography
      variant="body3"
      textAlign="start"
      sx={{ wordBreak: 'break-word' }}
    >
      {formatGuestName(guest)}
    </Typography>
    {guest.phoneNumber && (
      <Typography variant="body3">
        {formatPhoneNumber(guest.phoneNumber)}
      </Typography>
    )}
  </Stack>
)

export default GuestName
