import { TableCell as MuiTableCell, Stack, TableRow } from '@mui/material'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { type PersistedLabelInterface } from 'src/entities/label/types/label'
import SquareIconButton from 'src/shared/components/buttons/SquareIconButton'
import { SimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/SimpleDialog'
import { useSimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/useSimpleDialog'
import DragIcon from 'src/shared/components/icons/DragIcon'
import PencilIcon from 'src/shared/components/icons/PencilIcon'
import TrashIcon from 'src/shared/components/icons/TrashIcon'
import ExtraSmallLabel from 'src/widgets/Label/ExtraSmallLabel'
import MediumLabel from 'src/widgets/Label/MediumLabel'

interface DraggableLabelItemProps {
  label: PersistedLabelInterface
  onEdit: () => void
  onDelete: () => void
  isDisabled: boolean
  isBeingReordered: boolean
}

const TableCell = styled(MuiTableCell)({ verticalAlign: 'middle' })

export const DraggableLabelItem = ({
  label,
  isDisabled,
  isBeingReordered,
  onEdit,
  onDelete,
}: DraggableLabelItemProps) => {
  const { t } = useTranslation()

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: label.id })

  const { showSimpleDialog, simpleDialogProps } = useSimpleDialog()

  return (
    <>
      <TableRow
        ref={setNodeRef}
        sx={{ transform: CSS.Translate.toString(transform), transition }}
      >
        <TableCell sx={{ width: '1%', whiteSpace: 'nowrap' }}>
          <Stack
            direction="row"
            alignItems="center"
            gap={0.5}
            maxWidth="max-content"
          >
            <DragIcon
              sx={{ cursor: isDisabled ? 'not-allowed' : 'move' }}
              disabled={isDisabled}
              {...attributes}
              {...listeners}
            />
            <MediumLabel label={label} />
          </Stack>
        </TableCell>

        <TableCell>
          <ExtraSmallLabel label={label} />
        </TableCell>

        <TableCell>
          <Stack direction="row" gap={0.25} justifyContent="flex-end">
            <SquareIconButton
              color="neutral"
              variant="outlined"
              onClick={onEdit}
              disabled={isDisabled || isBeingReordered}
            >
              <PencilIcon variant="filled" />
            </SquareIconButton>
            <SquareIconButton
              color="neutral"
              variant="outlined"
              onClick={() => showSimpleDialog()}
              disabled={isDisabled || isBeingReordered}
              sx={{
                ':disabled': { pointerEvents: 'all', cursor: 'not-allowed' },
              }}
            >
              <TrashIcon variant="filled" />
            </SquareIconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <SimpleDialog
        color="error"
        title={t('common.caution', 'Caution')}
        message={t(
          'settings.labels.delete_disclaimer',
          'Are you sure you want to delete this label? This action cannot be reversed.',
        )}
        onConfirmation={onDelete}
        {...simpleDialogProps}
      />
    </>
  )
}
