import {
  Stack,
  Typography,
  type StackProps,
  type TextFieldProps,
} from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type ScheduleLanguage } from 'src/entities/schedule/types/scheduleApi'
import FormErrorText from 'src/shared/components/form/common/FormErrorText/FormErrorText'
import { TextArea } from 'src/shared/components/form/inputs/TextArea'
import LanguageTabs from './LanguageTabs'

type MultilingualTextAreaProps = {
  selectedLanguage: ScheduleLanguage
  setSelectedLanguage: (l: ScheduleLanguage) => void
  limit?: number
  messageLength?: number
  errorMessage?: string
  wrapperProps?: StackProps
} & TextFieldProps

const MultilingualTextArea = ({
  selectedLanguage,
  setSelectedLanguage,
  errorMessage,
  limit,
  messageLength,
  wrapperProps,
  ...rest
}: MultilingualTextAreaProps) => {
  const { t } = useTranslation()

  const hasLimit =
    typeof limit !== 'undefined' && typeof messageLength !== 'undefined'

  return (
    <Stack direction="column" spacing={1.5} {...wrapperProps}>
      <LanguageTabs
        selectedLanguage={selectedLanguage}
        onLanguageChange={setSelectedLanguage}
      />
      <Stack direction="column" spacing={0.5}>
        <TextArea
          key={selectedLanguage}
          slotProps={hasLimit ? { htmlInput: { maxLength: limit } } : undefined}
          minRows={3}
          {...rest}
        />

        {hasLimit && (
          <Typography variant="labelTiny" color="grey.500">
            {t('common.characterLimit', {
              limit: limit - messageLength,
              defaultValue: 'Characters left: {{ limit }}',
            })}
          </Typography>
        )}
        {errorMessage && <FormErrorText errorText={errorMessage} />}
      </Stack>
    </Stack>
  )
}

export default MultilingualTextArea
