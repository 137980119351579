import React from 'react'
import { Box, Divider, ListItem, List as MuiList } from '@mui/material'

import { useVirtualizer } from '@tanstack/react-virtual'

import {
  NotificationTypeEnum,
  type NotificationInterface,
} from 'src/entities/notification/types/notification'
import CircularProgress from 'src/shared/components/common/CircularProgress'
import CenteredBox from 'src/shared/components/containers/CenteredBox'
import { captureException } from 'src/shared/lib/context/global/sentry'
import PhoneCallNotification from './PhoneCallNotification'
import TodoNotification from './TodoNotification'

const FallbackNotificationComponent = (_: {
  notification: NotificationInterface
  onBodyClick: (notification: NotificationInterface) => void
}) => {
  captureException('Invalid notification type')

  return <div />
}

const getNotificationComponent = (type: NotificationTypeEnum) => {
  switch (type) {
    case NotificationTypeEnum.TodoItemCreated:
      return TodoNotification as typeof FallbackNotificationComponent
    case NotificationTypeEnum.PhoneCall:
      return PhoneCallNotification as typeof FallbackNotificationComponent
    default:
      return FallbackNotificationComponent
  }
}

const List = ({
  notifications,
  onLastItemMount,
  onNotificationClick,
}: {
  notifications: NotificationInterface[]
  onLastItemMount: () => void
  onNotificationClick: (notification: NotificationInterface) => void
}) => {
  const parentRef = React.useRef<Element>(null)

  const { getVirtualItems, getTotalSize, measureElement } = useVirtualizer({
    count: notifications.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 100,
    overscan: 10,
  })

  const virtualItems = getVirtualItems()
  const totalSize = getTotalSize()

  const paddingTop = virtualItems?.[0]?.start ?? 0
  const paddingBottom = totalSize - (virtualItems.at(-1)?.end ?? 0)

  return (
    <Box
      ref={parentRef}
      sx={{
        overflow: 'auto',
        height: 1,
        width: 1,
      }}
    >
      <MuiList
        sx={{
          width: 1,
          p: 0,
        }}
      >
        {paddingTop > 0 && <ListItem sx={{ height: paddingTop }} />}
        {virtualItems.map(virtualRow => {
          const notification = notifications[virtualRow.index]!

          const NotificationComponent = getNotificationComponent(
            notification.type,
          )

          return (
            <ListItem
              key={virtualRow.key}
              data-index={virtualRow.index}
              ref={el => {
                measureElement(el)
                if (virtualRow.index === notifications.length - 1)
                  onLastItemMount()
              }}
              disablePadding
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                width: 1,
              }}
            >
              <React.Suspense
                fallback={
                  <CenteredBox>
                    <CircularProgress />
                  </CenteredBox>
                }
              >
                <NotificationComponent
                  notification={notification}
                  onBodyClick={onNotificationClick}
                />
              </React.Suspense>
              <Divider
                flexItem
                orientation="horizontal"
                sx={{
                  opacity: '0.6',
                }}
              />
            </ListItem>
          )
        })}
        {paddingBottom > 0 && <ListItem sx={{ height: paddingBottom }} />}
      </MuiList>
    </Box>
  )
}

export default List
