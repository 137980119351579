import React, { useMemo, useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm, type Resolver } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useInfoQuery } from 'src/entities/info/queries/info'
import { useLabelsQuery } from 'src/entities/label/queries/label'
import {
  useAddEventMutation,
  useEditEventMutation,
  useNotificationsTemplatesQuery,
  useScheduleQuery,
} from 'src/entities/schedule/queries/schedule'
import { type ScheduleEvent } from 'src/entities/schedule/types/scheduleApi'
import { AdvancedEventFormSections } from './components/AdvancedSections/AdvancedEventFormSections'
import FormLayout from './components/FormLayout/FormLayout'
import type { FormSection } from './components/FormNavigation/FormNavigation'
import { SimpleFormSection } from './components/SimpleFormSection/SimpleFormSection'
import { useFormNavigation } from './hooks/formNaviagtion'
import {
  fromEvent,
  getIntersections,
  newEvent,
  toEvent,
  validatedEventSchema,
  type FormEvent,
} from './services/eventFormSchema'
import {
  getOtherRelevantShiftLikesFactory,
  getRelevantExceptionsFactory,
} from './services/shiftValidation'
import { shouldShowAdvancedForm } from './services/shouldShowAdvancedForm'
import { ConflictResolutionModal } from '../ConflictResolution/ConflictResolutionModal'
import { useConflictResolutionModal } from '../ConflictResolution/useConflictResolutionModal'
import { useDefaultMutationHandlers } from '../hooks/useDefaultMutationHandlers'

interface EventFormProps {
  event: ScheduleEvent | undefined
  onNavigateBack: () => void
}

const EventForm = ({ event, onNavigateBack }: EventFormProps) => {
  const { t } = useTranslation()

  const {
    data: {
      shifts,
      events,
      shiftExceptions,
      rooms,
      paymentPlans,
      defaultSettings,
      scheduleFeatures: { rooms: roomsEnabled },
    },
  } = useScheduleQuery()
  const { data: templates } = useNotificationsTemplatesQuery()
  const { data: labels } = useLabelsQuery()
  const {
    data: { language: restaurantLang },
  } = useInfoQuery()

  const { onSuccess, onError: onUnresolvableError } =
    useDefaultMutationHandlers({ onSuccess: onNavigateBack })

  const { onError, modalProps } =
    useConflictResolutionModal(onUnresolvableError)

  const { mutateAsync: editEvent } = useEditEventMutation({
    onSuccess,
    onError,
  })
  const { mutateAsync: addEvent } = useAddEventMutation({ onSuccess, onError })

  const formRef = React.useRef<HTMLDivElement>(null)

  const submitHandler = (formException: FormEvent) => {
    const modifiedEvent = toEvent(formException)

    if (!event) return addEvent(modifiedEvent)

    return editEvent({ ...modifiedEvent, id: event.id })
  }

  const getRelevantEvents = React.useMemo(
    () =>
      getOtherRelevantShiftLikesFactory({
        others: events,
        original: event,
      }),
    [event, events],
  )

  const emptyEvent = useMemo(
    () => newEvent(templates, defaultSettings),
    [defaultSettings, templates],
  )

  const editedEvent = event ? fromEvent(event, templates) : null

  const [isAdvanced, setIsAdvanced] = useState(
    !!editedEvent && shouldShowAdvancedForm(emptyEvent, editedEvent),
  )

  const getRelevantExceptions = useMemo(
    () => getRelevantExceptionsFactory({ shiftExceptions, shifts }),
    [shiftExceptions, shifts],
  )

  const form = useForm<FormEvent>({
    shouldFocusError: false,
    defaultValues: editedEvent ?? emptyEvent,
    resolver: React.useCallback<Resolver<FormEvent>>(
      (...args) =>
        zodResolver(
          validatedEventSchema({
            t,
            defaultLang: restaurantLang,
          })(shifts)([
            ...getRelevantEvents(args[0]),
            ...getRelevantExceptions(args[0]),
          ]),
        )(...args),
      [getRelevantEvents, getRelevantExceptions, restaurantLang, shifts, t],
    ),
  })

  const formData = form.watch()

  const intersections = React.useMemo(
    () =>
      getIntersections(shifts)([
        ...getRelevantEvents(formData),
        ...getRelevantExceptions(formData),
      ])(formData),
    [shifts, getRelevantEvents, formData, getRelevantExceptions],
  )

  const title = event
    ? t('schedule.events.title_edit', 'Edit event')
    : t('schedule.events.title_create', 'Create event')

  const { navigationItems, NavigationItem, scrollToItem } =
    useFormNavigation<FormSection>()

  return (
    <FormLayout
      title={title}
      ref={formRef}
      onNavigateBack={onNavigateBack}
      onSave={form.handleSubmit(submitHandler, () =>
        formRef.current?.scrollIntoView(),
      )}
      items={navigationItems}
      scrollToItem={scrollToItem}
      error={!!Object.keys(form.formState.errors).length}
      onAdvancedFormButtonClick={
        isAdvanced ? undefined : () => setIsAdvanced(true)
      }
    >
      <FormProvider {...form}>
        {isAdvanced ? (
          <AdvancedEventFormSections
            navItem={NavigationItem}
            intersections={intersections}
            rooms={rooms}
            roomsEnabled={roomsEnabled}
            showLabelsSection={!!labels.length}
            showPaymentsSection={!!paymentPlans.length}
          />
        ) : (
          <SimpleFormSection
            type="event"
            intersections={intersections}
            rooms={rooms}
            roomsEnabled={roomsEnabled}
          />
        )}
      </FormProvider>
      <ConflictResolutionModal
        entityType="event"
        entity={formData}
        actionType={event ? 'edit' : 'new'}
        onConfirmation={onNavigateBack}
        {...modalProps}
      />
    </FormLayout>
  )
}

export default EventForm
