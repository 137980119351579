import { Stack, Typography } from '@mui/material'

interface SettingsSectionTitleProps {
  title: string
  description?: string
}

export const SettingsSectionTitle = ({
  title,
  description,
}: SettingsSectionTitleProps) => (
  <Stack gap={0.5}>
    <Typography variant="labelBig" gutterBottom>
      {title}
    </Typography>
    {description && (
      <Typography variant="body3" color="grey.700">
        {description}
      </Typography>
    )}
  </Stack>
)
