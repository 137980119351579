import { type StackProps } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { ReservationNotesEnum } from 'src/entities/reservation/types/reservation'
import { NOTES_COLOURS } from 'src/widgets/Inbox/Lists/List/TodoNotification/Body/Details/Reservation/Labels'
import { StaticNote } from '../StaticNote/StaticNote'

interface GuestNoteProps extends StackProps {
  note: string | null
}

export const GuestNote = ({ note, ...props }: GuestNoteProps) => {
  const { t } = useTranslation()

  if (!note) return null

  return (
    <StaticNote
      {...createTestIdProps('guest-note')}
      title={t('angular.notify_guest')}
      note={note}
      accent={NOTES_COLOURS[ReservationNotesEnum.GuestMessage]}
      {...props}
    />
  )
}
