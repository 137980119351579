import { Suspense } from 'react'

import { createFileRoute } from '@tanstack/react-router'
import { noop } from '@tanstack/react-table'

import Loader from 'src/pages/CustomerChoice/SearchResults/SummaryRow/Summary/Loader'
import { WidgetPage } from 'src/pages/Settings/Widget'
import { WidgetPageHeader } from 'src/pages/Settings/Widget/components/WidgetPageHeader'

export const Route = createFileRoute(
  '/restaurants/$restaurantId/config/widget',
)({
  component: RouteComponent,
})

function RouteComponent() {
  const { restaurantId } = Route.useParams()

  return (
    <Suspense
      fallback={
        <>
          <WidgetPageHeader
            restaurantId={restaurantId}
            onSave={noop}
            saveDisabled
          />
          <Loader />
        </>
      }
    >
      <WidgetPage restaurantId={restaurantId} />
    </Suspense>
  )
}
