import { compose, map, mapObjIndexed, values } from 'ramda'

import { type Setting } from './setting'
import {
  type ApiSettings,
  type SettingsKeys,
} from '../../config/types/configApi'

const SETTING_ON = 1
const SETTING_OFF = 2

const fromValueNamePair = (value: boolean, name: SettingsKeys): Setting => ({
  name,
  value,
})

export const fromApi = compose(values, mapObjIndexed(fromValueNamePair))

export const toApi = (settings: ApiSettings): Record<SettingsKeys, 1 | 2> =>
  map(isOn => (isOn ? SETTING_ON : SETTING_OFF), settings)
