import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useInfoQuery } from 'src/entities/info/queries/info'
import {
  useAddReservationQuestionMutation,
  useEditReservationQuestionMutation,
} from 'src/entities/schedule/queries/schedule'
import {
  type ReservationQuestion,
  type Schedule,
} from 'src/entities/schedule/types/scheduleApi'
import { ReservationQuestionInputs } from './components/ReservationQuestionInputs'
import { ReservationQuestionSettings } from './components/ReservationQuestionSettings'
import {
  emptyReservationQuestion,
  formDataToQuestion,
  questionToFormData,
} from './service/formValueMappers'
import {
  getReservationQuestionFormSchema,
  type ReservationQuestionFormValues,
} from './service/getReservationQuestionFormSchema'
import { getReservationQuestionTranslations } from './service/getReservationQuestionTranslations'
import FormContainer from '../FormContainer'
import { useDefaultMutationHandlers } from '../hooks/useDefaultMutationHandlers'

interface ReservationQuestionFormProps {
  onNavigateBack: () => void
  reservationQuestion: ReservationQuestion | undefined
  schedule: Schedule
}

export const ReservationQuestionForm = ({
  reservationQuestion,
  onNavigateBack,
  schedule,
}: ReservationQuestionFormProps) => {
  const { t } = useTranslation()

  const {
    data: { language },
  } = useInfoQuery()

  const { mutateAsync: add } = useAddReservationQuestionMutation()
  const { mutateAsync: edit } = useEditReservationQuestionMutation()
  const handlers = useDefaultMutationHandlers({ onSuccess: onNavigateBack })

  const formProps = useForm<ReservationQuestionFormValues>({
    defaultValues:
      questionToFormData(reservationQuestion) ?? emptyReservationQuestion,
    resolver: (...args) =>
      zodResolver(getReservationQuestionFormSchema(language, args[0].question))(
        ...args,
      ),
  })

  const tr = getReservationQuestionTranslations(t)

  return (
    <FormContainer
      title={reservationQuestion ? tr.title.edit : tr.title.create}
      onNavigateBack={onNavigateBack}
      onSave={formProps.handleSubmit(data => {
        const dto = formDataToQuestion(data)

        return reservationQuestion
          ? edit({ ...dto, id: reservationQuestion.id }, handlers)
          : add(dto, handlers)
      })}
    >
      <ReservationQuestionSettings
        hookFormProps={formProps}
        schedule={schedule}
      />

      <ReservationQuestionInputs
        hookFormProps={formProps}
        language={language}
      />
    </FormContainer>
  )
}
