import { useCallback, useEffect, useRef, useState } from 'react'

import { getNowInRestaurantTimezone } from 'src/shared/lib/range/services/date'

export const useTickingCurrentTime = (tickRate = 60 * 1000) => {
  const [time, setTime] = useState(getNowInRestaurantTimezone())
  const timeoutRef = useRef<NodeJS.Timeout>(null)

  const clear = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
  }

  const set = useCallback(() => {
    const nowInMs = getNowInRestaurantTimezone().getTime()
    let correctedTimeout = tickRate - (nowInMs % tickRate)
    if (correctedTimeout < 250) correctedTimeout += tickRate

    timeoutRef.current = setTimeout(() => {
      setTime(getNowInRestaurantTimezone())
      clear()
      set()
    }, correctedTimeout)
  }, [tickRate])

  useEffect(() => {
    clear()
    set()

    return clear
  }, [set])

  return time
}
