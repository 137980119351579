import React from 'react'
import { Alert, Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { useReservationCustomerQuery } from 'src/entities/customer/queries/customer'
import { type CustomerInterface } from 'src/entities/customer/types/customer'
import ReservationDrawerSection from 'src/shared/components/common/ReservationDrawerSection'
import { FullScreenModal } from 'src/shared/components/dialogs/FullScreenModal/FullScreenModal'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { combine } from 'src/shared/lib/common/services/functional/functional'
import { type SelectedReservation } from 'src/shared/lib/context/state/atoms/baseSelectedReservation'
import CustomerIndicators from 'src/widgets/Reservation/Table/CustomerIndicators'
import { NewGuestInputs } from './components/NewGuestInputs'
import GuestButton from './GuestButton/GuestButton'
import { doesGuestExist, isGuestSelected } from './service/guest'
import { type OnCreateNewCustomerOptions } from '../../CustomerChoice/CustomerChoice'

const CustomerChoice = React.lazy(
  () => import('../../CustomerChoice/CustomerChoice'),
)

interface GuestProps {
  reservation: SelectedReservation
  onCustomerChange: (customer: CustomerInterface | undefined) => void
  onPreviewOpen: (customerId: CustomerInterface['id']) => void
  onEditCustomer: (id: CustomerInterface) => void
  onCreateNewCustomer: (options: OnCreateNewCustomerOptions) => void
}

const Guest = ({
  reservation,
  onCustomerChange,
  onPreviewOpen,
  onEditCustomer,
  onCreateNewCustomer,
}: GuestProps) => {
  const { data: customer } = useReservationCustomerQuery(reservation)
  const { t } = useTranslation()

  const guest = React.useMemo(
    () => ({
      ...reservation.guest,
      birthdate: customer.birthdate,
    }),
    [customer, reservation],
  )
  const hasNoCrmProfile = isGuestSelected(guest) && !doesGuestExist(guest)

  const { handleOpen, handleClose, open, hasBeenOpened } = useDialog()

  if (reservation.newCustomer) {
    return <NewGuestInputs />
  }

  return (
    <>
      <ReservationDrawerSection title={t('angular.guest')}>
        <Stack spacing={2}>
          <Stack gap={0.5} {...createTestIdProps('guest')}>
            <GuestButton
              onChoiceOpen={handleOpen}
              onCustomerChange={onCustomerChange}
              onPreviewOpen={onPreviewOpen}
              guest={guest}
            />
            {hasNoCrmProfile && (
              <Alert severity="warning">
                {t('customer.no_crm_profile', 'No CRM Profile')}
              </Alert>
            )}
          </Stack>
          <CustomerIndicators
            customer={guest}
            date={reservation.dateRange[0]}
          />
        </Stack>
      </ReservationDrawerSection>
      <FullScreenModal
        isOpen={open}
        title={t('angular.crm')}
        onClose={handleClose}
      >
        {hasBeenOpened && (
          <CustomerChoice
            guest={guest}
            onChooseCustomer={combine([onCustomerChange, handleClose])}
            onCreateNewCustomer={combine([onCreateNewCustomer, handleClose])}
            onEditCustomer={combine([onEditCustomer, handleClose])}
          />
        )}
      </FullScreenModal>
    </>
  )
}

export default Guest
