import React from 'react'
import { Divider, Stack } from '@mui/material'

import { type AreaInterface } from 'src/entities/area/types/area'
import { type LabelInterface } from 'src/entities/label/types/label'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { type RoomInterface } from 'src/entities/room/types/room'
import { useTableNoteMutation } from 'src/entities/table/queries/table'
import {
  type TableInterface,
  type TableNoteInterface,
} from 'src/entities/table/types/table'
import { combine } from 'src/shared/lib/common/services/functional/functional'
import { useSelectedServiceTime } from 'src/shared/lib/context/state/atoms/selectedServiceTime'
import Header from './Header/Header'
import LockButton from './LockButton/LockButton'
import NewReservationButton from './NewReservationButton/NewReservationButton'
import Note from './Note/Note'
import Occupancies from './Occupancies/Occupancies'
import { type ReservationOccupancy } from '../../../service/occupancy'
import { useLockTableCallback } from '../../FloorPlanView/useTableLocks'

export interface TableInfoProps {
  occupancies: ReservationOccupancy[]
  table?: TableInterface
  tableNotes: TableNoteInterface[]
  areas: AreaInterface[]
  tables: TableInterface[]
  labels: LabelInterface[]
  rooms: RoomInterface[]
  onReservationClick: (reservationId: ReservationInterface) => void
  onReservationButtonClick: (reservation: ReservationInterface) => void
  onClose: () => void
  locked: boolean
  onCreateNewReservation: (
    table: TableInterface,
    slot: undefined,
    locked: boolean,
  ) => void
  date: Date
}

const TableInfo = ({
  tableNotes,
  onClose,
  table: initTable,
  occupancies,
  onReservationClick,
  onCreateNewReservation,
  locked: tableIsLocked,
  date,
  ...props
}: TableInfoProps) => {
  const selectedServiceTime = useSelectedServiceTime()

  const lockTable = useLockTableCallback(date, selectedServiceTime)

  const { mutate: saveTableNote } = useTableNoteMutation()
  const handleTableNoteSave = React.useCallback(
    (tableNote: TableNoteInterface) =>
      saveTableNote({
        tableNote,
        date,
        serviceTimeId: selectedServiceTime?.id ?? 0,
      }),
    [saveTableNote, date, selectedServiceTime],
  )

  const [table, setTable] = React.useState(initTable!)
  React.useEffect(() => {
    if (!initTable) return

    setTable(initTable)
  }, [initTable])

  const handleNewReservationClick = React.useCallback(() => {
    onCreateNewReservation(table, undefined, tableIsLocked)
    onClose()
  }, [table, tableIsLocked, onCreateNewReservation, onClose])

  const handleTableLockChange = React.useCallback(
    () => lockTable(table.id!, !tableIsLocked),
    [lockTable, table.id, tableIsLocked],
  )

  return (
    <Stack direction="column" sx={{ height: 1 }} divider={<Divider />}>
      <Header table={table} onClose={onClose} />
      <Stack direction="column" spacing={2} sx={{ p: 1 }}>
        <Stack direction="row" justifyContent="space-between" spacing={0.5}>
          <NewReservationButton
            onClick={handleNewReservationClick}
            disabled={tableIsLocked && !!selectedServiceTime}
          />
          <LockButton
            locked={tableIsLocked}
            onClick={handleTableLockChange}
            disabled={!selectedServiceTime}
          />
        </Stack>
        <Note
          tableNotes={tableNotes}
          table={table}
          onSave={handleTableNoteSave}
        />
      </Stack>
      <Occupancies
        table={table}
        occupancies={occupancies}
        onReservationClick={combine([onClose, onReservationClick])}
        {...props}
      />
    </Stack>
  )
}

export default TableInfo
